
  import { defineComponent } from 'vue';
  import { RouteRecord, RouteRecordRaw } from 'vue-router';
  import { mapGetters } from 'vuex';
  import gsap from 'gsap';
  import AreaNavigation from '@/global_components/navigation/desktop/DesktopAreaNavigation.vue';
  import UserArea from '@/global_components/navigation/desktop/DesktopUserArea.vue';
  import IconsArea from '@/global_components/navigation/desktop/DesktopIcons.vue';
  import Level1Navigation from '@/global_components/navigation/desktop/DesktopLevel1Navigation.vue';
  import Level2Navigation from '@/global_components/navigation/desktop/DesktopLevel2Navigation.vue';
  import Level3Navigation from '@/global_components/navigation/desktop/DesktopLevel3Navigation.vue';
  import { getAreaForPage, getFrontPageChild, getLevel0Routes, getUserArea } from '@/utils/routingHelper';
  import navBus from '@/utils/navBus';

  export default defineComponent({
    components: {
      AreaNavigation,
      UserArea,
      IconsArea,
      Level1Navigation,
      Level2Navigation,
      Level3Navigation,
    },
    data() {
      return {
        selectedLevel1RoutePath: '',
        showLevel2And3Navigation: false,
        level1Routes: [] as RouteRecordRaw[],
        level2Routes: [] as RouteRecordRaw[],
        level3Routes: [] as RouteRecordRaw[][],
      };
    },
    computed: {
      getAreaRoutes(): RouteRecordRaw[] {
        return getLevel0Routes(this.$router);
      },
      ...mapGetters('auth', ['hasAuthenticatedUser']),
      hasUserArea(): boolean {
        return getUserArea(this.$router) !== undefined;
      },
    },
    created() {
      navBus.addHandler((areaId: number) => {
        this.level1Routes = getLevel0Routes(this.$router, true).find(({ meta }) => meta?.areaID === areaId)?.children || [];
        this.showLevel2And3Navigation = false;
      });
    },
    mounted() {
      this.level1Routes = getAreaForPage(this.$router).children || this.getAreaRoutes[0]?.children || [];
      setTimeout(() => document.addEventListener('click', this.closeLevel2And3Navigation), 0);
    },
    unmounted() {
      document.removeEventListener('click', this.closeLevel2And3Navigation);
    },
    methods: {
      navigation() {
        return {
          area: () => ({
            linkClick: (route: RouteRecord) => {
              this.level1Routes = route.children || [];
              this.showLevel2And3Navigation = false;
              this.goToFrontPage(route);
            },
          }),
          page: () => ({
            level1: (route: RouteRecord) => {
              this.level2Routes = route.children;
              this.level3Routes = route.children.reduce((acc: RouteRecordRaw[][], curr: RouteRecordRaw) => {
                if (curr.children) {
                  acc.push(curr.children);
                }
                return acc;
              }, [] as RouteRecordRaw[][]);
              this.toggleLevel2And3Display(route);
            },
          }),
        };
      },
      closeLevel2And3Navigation() {
        this.showLevel2And3Navigation = false;
      },
      toggleLevel2And3Display(route: RouteRecord): void {
        if (route.children.length) {
          if (route.path === this.selectedLevel1RoutePath || this.selectedLevel1RoutePath === '') {
            this.showLevel2And3Navigation = !this.showLevel2And3Navigation;
          } else {
            this.showLevel2And3Navigation = true;
          }
        } else {
          this.showLevel2And3Navigation = false;
        }
        this.selectedLevel1RoutePath = route.path;
      },
      goToFrontPage(route: RouteRecord) {
        this.$router.push(getFrontPageChild(route));
      },
      beforeEnter(el) {
        gsap.set(el, {
          top: `calc(-50% + 100px)`,
          scaleY: 0,
          opacity: 0,
        });
      },
      enter(el, done) {
        gsap.to(el, {
          top: '0',
          scaleY: 1,
          opacity: 1,
          duration: 0.6,
          onComplete: done,
        });
      },
    },
  });
