import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-061cd1b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["paragraph-container container", _ctx.getParagraphClasses]),
    style: _normalizeStyle([_ctx.getParagraphStyles, _ctx.getStyles])
  }, [
    _createElementVNode("p", {
      class: "paragraph-container__paragraph",
      innerHTML: _ctx.paragraphData.text
    }, null, 8 /* PROPS */, _hoisted_1)
  ], 6 /* CLASS, STYLE */))
}