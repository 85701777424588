import { computed, ComputedRef } from 'vue';
import { useStore } from 'vuex';
import { GET_USER_ORDERS } from '@/constants/store/auth/authActionTypes';
import { USER_LAST_ORDER, USER_ORDERS } from '@/constants/store/auth/authGetterTypes';
import { Order, OrderPagination } from '@/models/order/order';

interface UseUserOrderHistory {
  userLastOrder: ComputedRef<Order | null>;
  userOrders: ComputedRef<Order[]>;
  getUserOrders: () => Promise<OrderPagination>;
}

export default function useUserOrderHistory(): UseUserOrderHistory {
  const store = useStore();

  const userLastOrder = computed(() => store.getters[`auth/${USER_LAST_ORDER}`]);
  const userOrders = computed(() => store.getters[`auth/${USER_ORDERS}`]);
  const getUserOrders = () => store.dispatch(`auth/${GET_USER_ORDERS}`);

  return {
    userLastOrder,
    userOrders,
    getUserOrders,
  };
}
