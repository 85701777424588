import paymentMethods from './paymentMethods';
import shippingMethod from './shippingMethod';
import contactInfo from './contactInfo';
import deliveryInfo from './deliveryInfo';
import addedToCart from './addedToCart';
import cart from './cart';
import postCodes from './postCodes';
import giftWrap from './giftWrap';
import receiptBlackList from './receiptBlackList';

export default {
  ...addedToCart,
  ...cart,
  ...postCodes,
  ...contactInfo,
  ...deliveryInfo,
  ...giftWrap,
  ...shippingMethod,
  ...paymentMethods,
  ...receiptBlackList,
};
