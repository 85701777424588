import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f17008c6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "label" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "optionName" }
const _hoisted_4 = { class: "optionNotification" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.getStyles),
    class: _normalizeClass(["select_Container selectSize", { open: _ctx.open, disabled: _ctx.disabled }]),
    tabindex: 0,
    onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.open = false)),
    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onClickSelected && _ctx.onClickSelected(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.label), 1 /* TEXT */),
    _createElementVNode("div", {
      class: "selected bold",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.open = !_ctx.disabled ? !_ctx.open : false))
    }, _toDisplayString(_ctx.selectedOption), 1 /* TEXT */),
    _createElementVNode("div", {
      class: _normalizeClass(["options", { openAbove: _ctx.openAbove }])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass([{ disabled: option.disabled }, "option bold"]),
          onClick: ($event: any) => (_ctx.onSelectOption(option))
        }, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(option.value), 1 /* TEXT */),
          _createElementVNode("span", _hoisted_4, _toDisplayString(option.notification), 1 /* TEXT */)
        ], 10 /* CLASS, PROPS */, _hoisted_2))
      }), 128 /* KEYED_FRAGMENT */))
    ], 2 /* CLASS */)
  ], 38 /* CLASS, STYLE, HYDRATE_EVENTS */))
}