
  import { defineComponent, inject, PropType } from 'vue';
  import { ValidationArgs } from '@vuelidate/core';
  import { mapValues } from 'lodash';
  import Datepicker from 'vue3-date-time-picker';
  import RenderErrors from '@/components/shared/inputs/RenderErrors.vue';
  import useFormValidation from '@/composables/useFormValidation';
  import TextAreaInput from '@/components/shared/inputs/TextAreaInputNew.vue';
  import TextInput from '@/components/shared/inputs/TextInputNew.vue';
  // import CheckboxInput from '@/components/shared/inputs/CheckboxInput.vue';
  import Loader from '@/components/shared/Loader.vue';
  import * as CONST from './constants';
  import { Inputs } from './models';

  export default defineComponent({
    components: {
      TextInput,
      TextAreaInput,
      // CheckboxInput,
      RenderErrors,
      Loader,
      Datepicker,
    },
    props: {
      disabledDates: {
        type: Object as PropType<Array<Date>>,
        required: true,
      },
      availableTimeSlots: {
        type: Object as PropType<any>,
        required: true,
      },
    },
    emits: ['submitForm'],
    setup() {
      const inputs = inject('inputs') as Inputs;
      const rules = inject('validations') as ValidationArgs;
      const maxDate = inject('maxDate') as Date;
      const { touch, formIsValid, hasErrors, getErrorMessages, clearForm } = useFormValidation(rules, inputs);
      return {
        inputs,
        touch,
        formIsValid,
        hasErrors,
        getErrorMessages,
        clearForm,
        maxDate,
      };
    },
    data() {
      return {
        sendingForm: false,
      };
    },
    computed: {
      formData(): { [key: string]: string | boolean } {
        const userData = mapValues({ ...this.inputs }, (value) => value);
        return userData;
      },
      firstTimeSlot(): string {
        return CONST.TIME_SLOT['10_TO_13'];
      },
      secondTimeSlot(): string {
        return CONST.TIME_SLOT['13_TO_16'];
      },
    },
    methods: {
      submit() {
        if (this.formIsValid) {
          this.sendingForm = true;
          this.$emit('submitForm', this.formData);
          this.sendingForm = false;
          this.clearForm();
        }
      },
      // Function needed to map months for browsers that do not support 'is' locale
      mapMonth(month: string): string {
        const map = {
          January: 'Janúar',
          February: 'Febrúar',
          March: 'Mars',
          April: 'Apríl',
          May: 'Maí',
          June: 'Júní',
          July: 'Júlí',
          August: 'Ágúst',
          September: 'September',
          October: 'Október',
          November: 'Nóvember',
          December: 'Desember',
        };
        return map[month] || month;
      },
      // Function needed to map day information for browsers that do not support 'is' locale
      mapDay(day: string): string {
        const map = {
          Mo: 'M',
          Tu: 'Þ',
          We: 'M',
          Th: 'F',
          Fr: 'F',
          Sa: 'L',
          Su: 'S',
        };
        return map[day] || day;
      },
      formatDate(date): string {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
      },
    },
  });
