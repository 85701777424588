import { ComponentField, DisabledField, DropdownField, EnabledFrom, EnabledTo, Field, FileField, ColorField, LinkField, Image, Color } from '@/models/field';

function isField(val: unknown): val is Field {
  const field = val as Field;
  return field.Name !== undefined && field.SystemName !== undefined && field.Value !== undefined;
}

function isComponentField(val: unknown): val is ComponentField {
  return (val as ComponentField).SystemName === 'Component';
}

function isDisabledField(val: unknown): val is DisabledField {
  return (val as DisabledField).SystemName === 'Disabled';
}

function isEnabledFrom(val: unknown): val is EnabledFrom {
  return (val as EnabledFrom).SystemName === 'EnabledFrom';
}

function isEnabledTo(val: unknown): val is EnabledTo {
  return (val as EnabledTo).SystemName === 'EnabledTo';
}

function isDropdownField(val: unknown): val is DropdownField {
  const dropdownField = val as DropdownField;
  return dropdownField.Value && dropdownField.Value.SelectedValue !== undefined;
}

function isFileField(val: unknown): val is FileField {
  const file = val as FileField;
  return file.Value && file.Value[0] && file.Value[0].Path !== undefined;
}

function isColorField(val: unknown): val is ColorField {
  const color = val as ColorField;
  return typeof color.Value !== 'undefined' && color.Value.Hex !== undefined;
}

function isColor(val: unknown): val is Color {
  const color = val as Color;
  return color.Hex !== undefined;
}

function isLinkField(val: unknown): val is LinkField {
  const link = val as LinkField;
  return link.IsExternal !== undefined && link.Url !== undefined;
}

function isImageField(val: unknown): val is Image {
  const image = val as Image;
  return image.Path !== undefined && image.PathUrlEncoded !== undefined && image.Name !== undefined;
}

export {
  isField,
  isComponentField,
  isDisabledField,
  isEnabledFrom,
  isEnabledTo,
  isDropdownField,
  isFileField,
  isColorField,
  isLinkField,
  isImageField,
  isColor,
};
