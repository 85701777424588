import { useStore } from 'vuex';
import { each, has, keys, mapValues } from 'lodash';
import { MetaTags } from '@/store/modules/metatags';

export type MetaTagAttribute = { [key: string]: string | null | undefined };
export type MetaTagsPayload = { [key: string]: MetaTagAttribute | null | undefined };
export type OpenGraphMetaTag = 'og:type' | 'og:title' | 'og:url' | 'og:image' | 'og:locale' | 'og:description' | 'og:site_name';
export type OpenGraphMetaTags = { [K in OpenGraphMetaTag]?: string | null };
export type TwitterMetaTag = 'twitter:title' | 'twitter:description' | 'twitter:image' | 'twitter:site' | 'twitter:creator' | 'twitter:card';
export type TwitterMetaTags = { [K in TwitterMetaTag]?: string | null };

interface UseMetaTags {
  clearMetaTags(metaTagKeys: string[]): void;
  setMetaTags(metaTags: MetaTagsPayload, prepend?: boolean): MetaTags;
  setOpenGraphMetaTags(ogMetaTags: OpenGraphMetaTags): MetaTags;
  setTwitterMetaTags(twitterMetaTags: TwitterMetaTags): MetaTags;
  setCanonicalUrl(href: Maybe<string>): void;
}

export default function useMetaTags(): UseMetaTags {
  const store = useStore();

  const clearMetaTags = (metaTagKeys: string[]): void => {
    each(metaTagKeys, (key) => {
      if (has(store.state.metaTags.metaTags, key)) {
        document.head.removeChild(store.state.metaTags.metaTags[key]);
      }
    });
    store.commit('metaTags/clearMetaTags', keys);
  };

  const setMetaTags = (metaTags: MetaTagsPayload, prepend = false): MetaTags => {
    clearMetaTags(keys(metaTags));

    const newMetaTags = mapValues(metaTags, (metaTag) => {
      const element = document.createElement('meta');
      each(metaTag, (value, attribute) => element.setAttribute(attribute, value ?? ''));
      if (prepend) {
        document.head.prepend(element);
      } else {
        document.head.appendChild(element);
      }

      return element;
    });

    store.commit('metaTags/saveMetaTags', newMetaTags);

    return newMetaTags;
  };

  const setOpenGraphMetaTags = (ogMetaTags: OpenGraphMetaTags): MetaTags => setMetaTags(mapValues(ogMetaTags, (content, property) => ({ property, content })));
  const setTwitterMetaTags = (twitterMetaTags: TwitterMetaTags): MetaTags => setMetaTags(mapValues(twitterMetaTags, (content, name) => ({ name, content })));

  const setCanonicalUrl = (href: Maybe<string>) => {
    const canonicals = document.querySelectorAll('link[rel=canonical]');
    if (canonicals?.length) {
      canonicals.forEach((c) => c.remove());
    }

    if (href) {
      const link = document.createElement('link');
      link.setAttribute('rel', 'canonical');
      link.setAttribute('href', href);
      document.head.prepend(link);
    }
  };

  return {
    clearMetaTags,
    setMetaTags,
    setOpenGraphMetaTags,
    setTwitterMetaTags,
    setCanonicalUrl,
  };
}
