
  import { defineComponent, PropType } from 'vue';

  import { cutStringIfNeeded } from '@/utils/stringHelper';
  import { mapBaseComponentData } from '@/utils/dataMapper';

  import useBaseBoxComponent from '@/composables/useBaseBoxComponent';

  import { Field } from '@/models/field';
  import { TitleTextAndButton } from '@/models/item-types/base/boxes';

  import CustomButton from '@/components/itemTypes/base/Button.vue';

  interface Button {
    text: string;
    link: {
      IsExternal: boolean;
      Url: string;
    };
    type: string;
    buttonSize: string;
  }

  export default defineComponent({
    components: {
      CustomButton,
    },
    props: {
      box: {
        type: Object as PropType<Field>,
        required: true,
      },
      boxCount: {
        type: Number,
        required: true,
      },
      options: {
        type: Object,
        required: true,
      },
    },
    setup(props) {
      const { data, getStyles, getMobileTitle, getTitle } = useBaseBoxComponent(props.box);

      const boxData = data.value as TitleTextAndButton;
      const linkUrl = (mapBaseComponentData(boxData.button) as Button).link?.Url;

      return {
        boxData,
        linkUrl,
        getTitle,
        getMobileTitle,
        getStyles,
      };
    },
    computed: {
      wrapperAttribute() {
        return this.options.entireFrameClickable ? 'a' : 'div';
      },
    },
    methods: {
      cutSentenceIfNeeded(sentence: string, maxLength: number): string {
        return cutStringIfNeeded(sentence, maxLength);
      },
    },
  });
