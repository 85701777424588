import { RouteLocationNormalized } from 'vue-router';
import { gtag } from '@/plugins/google-tag-manager/tag';

export default (to: RouteLocationNormalized): void => {
  if (to.fullPath !== '/') {
    if (process.env.VUE_APP_ENVIRONMENT === 'PROD') {
      // gtag.googleEvent('pageView');
    }
  }
};
