export const DEFAULT_ACTIVE_LINK_COLOR = '#000000';
export const FRONTPAGE = 'frontpage';
export const PRIMARY_AREA = 'ellingsen';

export const SYSTEM_NAME = {
  PRIMARY_COLOR: 'PrimaryColor',
  LOGO_IMAGE: 'Logo_Image',
  ROUTE_PATH: 'RoutePath',
  FOOTER_ID: 'FooterID',
  PHONE_NUMBER: 'PhoneNumber',
  EMAIL: 'Email',
  VAT_NUMBER: 'VATNumber',
  CART_INFO_TEXT: 'CartInfoText',
};
