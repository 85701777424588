import { computed, ComputedRef } from 'vue';
import { useStore } from 'vuex';
import { get } from 'lodash';
import { Product } from '@/models/cart/product';
import { ImageHandler } from '@/utils/imageHelper';
import { formatAsPriceString } from '@/utils/stringHelper';
import { WishlistProduct } from '@/models/wishlist/wishlist';
import conf from '@/config';

interface Option {
  value: string;
  notification: string;
  disabled: boolean;
  meta: any;
}

interface ReturnValue {
  formattedPrice: ComputedRef<string>;
  formattedPriceBeforeDiscount: ComputedRef<string>;
  productImagePath: ComputedRef<string>;
  colorOptions: ComputedRef<
    {
      value: string;
      notification: string;
      disabled: boolean;
      meta: {
        product: Product;
      };
    }[]
  >;
  getVariantOptions: (wishlistProduct: WishlistProduct, selectedProduct: WishlistProduct) => Option[];
}

export default function useWishlistProduct(props: { product: WishlistProduct }): ReturnValue {
  const store = useStore();
  const formattedPrice = computed(() => {
    const price = get(props.product || {}, 'Price.PriceWithVat', '') as string;
    const currencyCode = get(props.product || {}, 'Price.CurrencyCode', 'KR') as string;
    if (!price) return '';
    return formatAsPriceString(price, currencyCode);
  });
  const formattedPriceBeforeDiscount = computed(() => {
    const priceBeforeDiscount = get(props.product || {}, 'PriceBeforeDiscount.PriceWithVat', '') as string;
    const currencyCode = get(props.product || {}, 'PriceBeforeDiscount.CurrencyCode', 'KR') as string;
    if (!priceBeforeDiscount) return '';
    return formatAsPriceString(priceBeforeDiscount, currencyCode);
  });

  const productImagePath = computed(() => `${conf.dwUrl}${ImageHandler(props.product.Image || '/Files/Images/Icons/photo.svg', 200, 200)}`); // =Refactor= No hardcoded path, use DW settings

  const colorOptions = computed(() => {
    if (props.product.RelatedProducts === undefined) {
      return [];
    }
    if (props.product.RelatedProducts.length === 0) {
      return [];
    }

    return [...props.product.RelatedProducts, { ...props.product }].map((product: any) => ({
      value: product.BaseColor !== '' ? product.BaseColor : 'NO COLOR',
      notification: product.Id,
      disabled: product.SoldOut,
      meta: {
        product,
      },
    }));
  });

  const getVariantOptions = (wishlistProduct: WishlistProduct, selectedProduct: WishlistProduct): Option[] => {
    let product = {} as WishlistProduct;
    if (wishlistProduct.RelatedProducts?.length === 0) {
      product = wishlistProduct;
    } else {
      product = selectedProduct;
    }

    return product?.VariantInfo?.VariantInfo.map((variant: any) => ({
      value: variant.OptionName,
      notification: (() => {
        if (variant.Stock === 0) {
          return 'UPPSELT';
        }
        if (variant.Stock < 5) {
          return 'FÁ EINTÖK EFTIR';
        }
        return '';
      })(),
      disabled: variant.Stock === 0,
      meta: {
        variant,
      },
    }));
  };

  return {
    formattedPrice,
    formattedPriceBeforeDiscount,
    productImagePath,
    colorOptions,
    getVariantOptions,
  };
}
