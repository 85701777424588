/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import conf from '@/config';

export default {
  async borgunValidation(cardPan, cardExpMonth, cardExpYear, cardCVC, publicToken, responseHandler) {
    let borgunErrorMessage = '';
    let isBorgunErrorMessage = false;
    const cardToken = '';
    // eslint-disable-next-line no-undef
    BAPIjs.setPublicToken(publicToken);
    // eslint-disable-next-line no-undef
    if (BAPIjs.isValidCardNumber(cardPan) === false) {
      borgunErrorMessage = 'Invalid card number';
      isBorgunErrorMessage = true;
    }
    // eslint-disable-next-line no-undef
    if (BAPIjs.isValidExpDate(cardExpMonth, cardExpYear) === false) {
      borgunErrorMessage = 'Invalid expiration date';
      isBorgunErrorMessage = true;
    }
    // eslint-disable-next-line no-undef
    if (BAPIjs.isValidCVC(cardCVC) === false) {
      borgunErrorMessage = 'Invalid cvc number';
      isBorgunErrorMessage = true;
    }

    if (!isBorgunErrorMessage) {
      // Request single use token from Borgun.
      // eslint-disable-next-line no-undef
      await BAPIjs.getToken(
        {
          pan: cardPan,
          expMonth: cardExpMonth,
          expYear: cardExpYear,
          cvc: cardCVC,
          tokenLifetime: 480,
        },
        responseHandler,
      );
    }

    return { borgunErrorMessage, isBorgunErrorMessage, cardToken };
  },
};

export function submitForm() {
  document.getElementById('payform').submit();
}

// RESPONSE EXAMPLE!
// Enabled: true
// ExpMonth: "09"
// ExpYear: "2022"
// PAN: "558740******2037"
// Token: "ts_CRWBXFpaiwbP23tjLOgMsSJdMfInbla2"
// Used: false
// ValidUntil: "2021-09-17T19:56:02Z"

export function getBorgunInfo() {
  if (conf.env !== 'PROD') {
    // TEST URL & TOKEN
    const borgunLibraryUrl = 'https://test.borgun.is/resources/js/borgunpayment-js/borgunpayment.v1.min.js';
    const publicToken = '890086_puywGe56LZyhz7HRS9NnpsEHiff6ZoGOJl';
    return { borgunLibraryUrl, publicToken };
  }
  // LIVE URL & TOKEN
  const borgunLibraryUrl = 'https://ecommerce.borgun.is/resources/js/borgunpayment-js/borgunpayment.v1.min.js';
  const publicToken = '361724_pujI7huAIUK+o33eOcyh5wrd5XENIPG94h';
  return { borgunLibraryUrl, publicToken };
}

export function appendBorgunScriptToHeader(borgunLibraryUrl) {
  const BAPIjs = document.createElement('script');
  BAPIjs.setAttribute('src', borgunLibraryUrl);
  document.head.appendChild(BAPIjs);
}
