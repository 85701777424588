import conf from '@/config';
import { PageInfo } from '@/models/content/PageInfo';
import useMetaTags from './useMetaTags';

export default function useSetPageMetaTags() {
  const { setMetaTags, setOpenGraphMetaTags, setTwitterMetaTags, setCanonicalUrl } = useMetaTags();

  const setPageMetaTags = async (pageInfo: PageInfo, siteName: string) => {
    const meta = {
      title: pageInfo.Title ?? '',
      description: pageInfo.Description ?? '',
      keywords: '',
    };

    const metaTypeField = pageInfo.PropertyItem?.Fields?.find((obj) => obj.SystemName === 'MetaType');
    const metaImageFieldValue = pageInfo.PropertyItem?.Fields?.find((obj) => obj.SystemName === 'MetaImage')?.Value ?? '';
    const robotsContent = conf.env !== 'PROD' ? 'noindex,nofollow' : '';
    const metaImageUrl = metaImageFieldValue?.length ? `${conf.dwUrl}${metaImageFieldValue[0].Path}` : '';

    if (pageInfo?.MetaTags) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(pageInfo.MetaTags, 'text/html');

      Object.keys(meta).forEach((k) => {
        const el = doc.querySelector(`meta[name="${k}"]`);
        if (el) {
          meta[k] = el.getAttribute('content');
        }
      });

      const canonicalUrl = doc.querySelector('link[rel="canonical"]')?.getAttribute('href');
      setCanonicalUrl(canonicalUrl);
    }

    const setMetaTagsInput: { [key: string]: { name: string; content: string } } = {
      title: { name: 'title', content: meta.title },
      description: { name: 'description', content: meta.description },
      robots: { name: 'robots', content: robotsContent },
      'google-site-verification': { name: 'google-site-verification', content: 'pbGxWPANT7EEYct_gkNmEDqxzvKbeOFufEHxU566U50' },
    };

    if (meta.keywords) {
      setMetaTagsInput.keywords = { name: 'keywords', content: meta.keywords };
    }

    setMetaTags(setMetaTagsInput, true);

    setOpenGraphMetaTags({
      'og:type': metaTypeField?.Value ?? '',
      'og:title': meta.title,
      'og:description': meta.description,
      'og:url': document.location.href,
      'og:image': metaImageUrl,
      'og:site_name': siteName,
    });

    setTwitterMetaTags({
      'twitter:title': meta.title,
      'twitter:description': meta.description,
      'twitter:image': metaImageUrl,
      'twitter:site': document.location.href,
      'twitter:card': 'summary_large_image',
    });
  };

  return {
    setPageMetaTags,
  };
}
