import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-259655be"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "multiGiftWrapOrderLine" }
const _hoisted_2 = { class: "multiGiftWrapOrderLine_Image" }
const _hoisted_3 = { class: "multiGiftWrapOrderLine_Image_Wrapper" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "multiGiftWrapOrderLine_Info" }
const _hoisted_6 = { class: "multiGiftWrapOrderLine_Info_Webshops" }
const _hoisted_7 = { class: "multiGiftWrapOrderLine_Info_Manufacturer" }
const _hoisted_8 = { class: "multiGiftWrapOrderLine_Package" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_DropDown = _resolveComponent("DropDown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: _ctx.productImagePath,
          alt: "image"
        }, null, 8 /* PROPS */, _hoisted_4)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.orderLine.Webshops), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.orderLine.Manufacturer), 1 /* TEXT */),
      _createElementVNode("div", null, _toDisplayString(_ctx.orderLine.Product.ProductName), 1 /* TEXT */),
      _createElementVNode("div", null, [
        _createVNode(_component_Checkbox, {
          value: _ctx.orderLine.IsGiftWrapped,
          label: 'GJAFAINNPÖKKUN',
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateOrderLineGiftWrapStatus($event)))
        }, null, 8 /* PROPS */, ["value"])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", null, [
        _createVNode(_component_DropDown, {
          disabled: !_ctx.orderLine.IsGiftWrapped,
          "selected-option": _ctx.selectedOption,
          options: _ctx.getOptions,
          width: '204px',
          label: 'PAKKI NR.',
          onOnSelectOption: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateOrderLineGiftWrapPackage($event)))
        }, null, 8 /* PROPS */, ["disabled", "selected-option", "options", "label"])
      ])
    ])
  ]))
}