import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1be7d629"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "accordion-item__title" }
const _hoisted_2 = { class: "accordion-item__description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Accordion = _resolveComponent("Accordion")!

  return (_openBlock(), _createElementBlock("div", {
    class: "accordion-item",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
  }, [
    _createVNode(_component_Accordion, { "show-body": _ctx.bodyVisible }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createTextVNode(_toDisplayString(_ctx.item.title) + " ", 1 /* TEXT */),
          _createVNode(_component_Icon, {
            class: _normalizeClass(['accordion-item__icon', { 'accordion-item__icon--flipped': _ctx.bodyVisible }]),
            icon: "chevron",
            size: _ctx.iconSize
          }, null, 8 /* PROPS */, ["class", "size"])
        ])
      ]),
      body: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.item.description), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["show-body"])
  ]))
}