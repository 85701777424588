/* eslint-disable @typescript-eslint/no-explicit-any */

import { OrderLine } from '@/models/cart/orderLine';
import { ProductList } from '@/models/productList/productList';
import { Product } from '@/models/product/product';
import { DwapiOrder } from './DwapiOrder';
import config from '@/config';

/**
 * Sett upp út frá leiðbeiningum frá Sahara úr email samskiptum á milli Jóa, Inga Þórs og Sigurðar frá Sahara:
 *  Leiðbeiningar: https://docs.google.com/document/d/1FCUfa9WBpFUNOQ0MXxsU-gWQ4AYPF4zImEOh-UKAIOo/edit?pli=1
 */

export interface GA4EProduct {
  item_name: string;
  item_id: string;
  price: string;
  item_brand: string;
  item_category: string;
  item_category2?: string;
  item_variant?: string;
  item_list_name?: string;
  item_list_id?: string;
  index?: number;
  quantity: string;
  item_coupon?: string;
}

export interface GA4EOrderData {
  currency: string;
  value: number;
  tax: number;
  shipping: number;
  affiliation?: string;
  transaction_id: string;
  coupon?: string;
}

export interface GA4EUserData {
  em: string;
  fn: string;
  ln: string;
  ph: string;
  user_id: string;
  street: string;
  house_number: string;
  ct: string;
  st: string;
  zp: string;
  country: string;
}

const mapDWProductToGA4EProduct = (dwProduct: any): GA4EProduct => {
  let category = '';
  if (dwProduct.ProductFields?.ProductCategory?.Value?.length) {
    category = dwProduct.ProductFields.ProductCategory.Value[0].Name;
  } else if (dwProduct.ProductFields?.ProductFamily?.Value?.length) {
    category = dwProduct.ProductFields?.ProductFamily?.Value[0].Name;
  }
  const item = {
    item_name: dwProduct.Name,
    item_id: dwProduct.Id,
    price: `${dwProduct.Price?.PriceWithVat || ''}`,
    item_brand: dwProduct.Manufacturer?.Name || '',
    item_category: category, // TODO: hvaða category vill Ingi og vill hann auka category(2,3,4)?
    item_variant: '',
    quantity: '1',
  };
  return item;
};

const mapDWProductToGA4EListProduct = (dwProduct: any, itemIndex: number, listName = 'category'): GA4EProduct => {
  const product = mapDWProductToGA4EProduct(dwProduct);
  product.item_list_name = listName;
  product.item_list_id = listName;
  product.index = itemIndex;
  return product;
};

const mapOrderlineToGA4EProduct = (orderline: OrderLine): GA4EProduct => {
  const item = {
    item_name: orderline.Product?.ProductName,
    item_id: orderline.Product?.ProductID,
    price: `${orderline.Product?.ProductPrice}`,
    item_brand: orderline.Manufacturer,
    item_category: 'category', // TODO: vantar category
    quantity: `${orderline.Quantity}`,
  };
  return item;
};

const mapProductToGA4EProduct = (product: Product): GA4EProduct => {
  const item = {
    item_name: product.ProductName,
    item_id: product.ProductID,
    price: `${product.Price}`,
    item_brand: product.Manufacturer,
    item_category: 'category', // TODO: vantar category
    quantity: `${product.Quantity}`,
  };
  return item;
};

type GA4EEvent =
  | 'view_item_list'
  | 'select_item'
  | 'view_item'
  | 'view_cart'
  | 'add_to_cart'
  | 'remove_from_cart'
  | 'begin_checkout'
  | 'add_shipping_info'
  | 'add_payment_info'
  | 'purchase';

const pushItemsToDataLayer = (event: GA4EEvent, items: GA4EProduct[], extraEcommerceParams: any = {}, userData?: GA4EUserData) => {
  if (!items?.length) {
    return;
  }

  const filtered = items.filter((item) => !!item);
  if (filtered.length) {
    const ecommerce = {
      ...extraEcommerceParams,
      items: filtered,
    };

    const payload = {
      event,
      ecommerce,
    };

    if (userData) {
      payload['user_data'] = userData;
    }

    window.dataLayer.push(payload);
  }
};

export const GA4E = {
  viewItemList(productList: ProductList) {
    const items = productList.products.map((product, i) => mapDWProductToGA4EListProduct(product, i));

    pushItemsToDataLayer('view_item_list', items);
  },

  selectItem(dwProduct: any, itemIndex: number) {
    const item = mapDWProductToGA4EListProduct(dwProduct, itemIndex);
    pushItemsToDataLayer('select_item', [item]);
  },

  viewItem(dwProduct: any) {
    const item = mapDWProductToGA4EProduct(dwProduct);
    pushItemsToDataLayer('view_item', [item]);
  },

  viewCart(orderlines: OrderLine[]) {
    const items = orderlines.map((ol) => mapOrderlineToGA4EProduct(ol));
    pushItemsToDataLayer('view_cart', items);
  },

  addToCart(product: Product) {
    const item = mapProductToGA4EProduct(product);
    pushItemsToDataLayer('add_to_cart', [item]);
  },

  removeFromCart(orderline: OrderLine) {
    const item = mapOrderlineToGA4EProduct(orderline);
    pushItemsToDataLayer('remove_from_cart', [item]);
  },

  beginCheckout(orderlines: OrderLine[]) {
    const items = orderlines.map((ol) => mapOrderlineToGA4EProduct(ol));
    pushItemsToDataLayer('begin_checkout', items);
  },

  addShippingInfo(orderlines: OrderLine[], shippingMethod: string) {
    const items = orderlines.map((ol) => mapOrderlineToGA4EProduct(ol));
    pushItemsToDataLayer('add_shipping_info', items, {
      shipping_tier: shippingMethod,
    });
  },

  addPaymentInfo(orderlines: OrderLine[], paymentType: string) {
    const items = orderlines.map((ol) => mapOrderlineToGA4EProduct(ol));
    pushItemsToDataLayer('add_payment_info', items, {
      payment_type: paymentType,
    });
  },

  async purchase(orderSecret: string) {
    const filledProperties = [
      'CustomerEmail',
      'CustomerPhone',
      'CustomerName',
      'CustomerUserId',
      'CustomerAddress',
      'CustomerCity',
      'CustomerZip',
      'CustomerCountry',
      'Price',
      'TransactionNumber',
      'OrderFields',
      'OrderLines',
    ];
    const url = `${config.dwUrl}/dwapi/ecommerce/orders/${orderSecret}?FilledProperties=${filledProperties.join(',')}`;
    try {
      const response = await fetch(url);
      const json = await response.json();
      const order = json as DwapiOrder;

      const userData: GA4EUserData = {
        em: order.CustomerEmail,
        fn: order.CustomerName,
        ln: order.CustomerName,
        ph: order.CustomerPhone,
        user_id: order.CustomerEmail,
        street: order.CustomerAddress,
        house_number: order.CustomerAddress,
        ct: order.CustomerCity,
        st: '',
        zp: order.CustomerZip,
        country: order.CustomerCountry,
      };

      const orderData: GA4EOrderData = {
        currency: order.Price.CurrencyCode,
        value: order.Price.Price,
        tax: order.Price.Vat || 0,
        shipping: 0,
        affiliation: '',
        transaction_id: order.TransactionNumber,
        coupon: order.OrderFields.CouponActivated.Value,
      };

      const item: GA4EProduct = {
        item_name: '',
        item_id: '',
        price: '',
        item_brand: '',
        item_category: '',
        item_variant: '',
        quantity: '',
      };

      const items = order.OrderLines.map((ol) => {
        return {
          item_name: ol.ProductName,
          item_id: ol.Id,
          price: ol.Price.Price.toString(),
          item_brand: '',
          item_category: ol.PrimaryOrDefaultGroup?.Name || '',
          item_variant: ol.ProductVariantId,
          quantity: ol.Quantity,
        };
      });
      pushItemsToDataLayer('purchase', items, orderData, userData);
    } catch (error) {
      console.log(error);
    }
  },
};
