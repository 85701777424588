import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d851c4f8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "video-card" }
const _hoisted_2 = { class: "video-card__video" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("iframe", {
        src: `https://www.youtube.com/embed/${_ctx.video}`,
        title: "YouTube video player",
        frameborder: "0",
        allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
        allowfullscreen: ""
      }, null, 8 /* PROPS */, _hoisted_3)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(['video-card__title', { 'video-card__title--invert': _ctx.invert }])
    }, _toDisplayString(_ctx.title), 3 /* TEXT, CLASS */),
    _createElementVNode("div", {
      class: _normalizeClass(['video-card__text', { 'video-card__text--invert': _ctx.invert }]),
      innerHTML: _ctx.text
    }, null, 10 /* CLASS, PROPS */, _hoisted_4)
  ]))
}