
  /* eslint-disable @typescript-eslint/no-explicit-any */
  import { defineComponent } from 'vue';
  import { values, flatten } from 'lodash';

  import accordionMixin from '@/mixins/accordion';
  import FacetGroup from '@/global_components/FilterSidebar/FacetGroup.vue';

  export default defineComponent({
    components: {
      FacetGroup,
    },
    mixins: [accordionMixin],
    props: {
      facets: {
        type: Array,
        required: true,
        default: () => [],
      },
    },
    emits: ['closeSidebar', 'filterData'],
    data() {
      return {
        selectedFilters: {} as any,
      };
    },
    computed: {
      mappedFilters() {
        return flatten(values(this.selectedFilters));
      },
    },
    watch: {
      mappedFilters(newVal) {
        this.$emit('filterData', newVal);
      },
    },
    methods: {
      clearFilters() {
        this.selectedFilters = {};
      },
      closeSidebar() {
        this.$emit('closeSidebar');
      },
      editFilters(val) {
        this.selectedFilters[val[0]] = [...val[1]];
      },
    },
  });
