
  import { defineComponent } from 'vue';
  import { mapGetters } from 'vuex';

  export default defineComponent({
    props: {
      size: {
        type: Number,
        required: false,
        default: 100,
      },
      thickness: {
        type: Number,
        required: false,
        default: 10,
      },
      color: {
        type: String,
        required: false,
        default: '',
      },
    },
    computed: {
      ...mapGetters('website', {
        websiteSettings: 'getWebsiteSettings',
      }),
      getStyles(): unknown {
        return {
          '--primary-color': this.color !== '' ? this.color : this.websiteSettings.primaryColor,
          '--size': `${this.$props.size}px`,
          '--thickness': `${this.$props.thickness}px`,
        };
      },
    },
  });
