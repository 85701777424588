import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fb02fde4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "accordion" }
const _hoisted_2 = { class: "accordion__bodyContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default", {
      accordionToggle: _ctx.accordionToggle,
      accordionShow: _ctx.accordionShow,
      accordionHide: _ctx.accordionHide,
      accordionBodyVisible: _ctx.bodyVisible
    }, undefined, true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_Transition, {
        appear: _ctx.animateInitially,
        onBeforeEnter: _ctx.onAnimBeforeEnter,
        onEnter: _ctx.onAnimEnter,
        onAfterEnter: _ctx.onAnimAfterEnter,
        onBeforeLeave: _ctx.onAnimBeforeLeave,
        onLeave: _ctx.onAnimLeave
      }, {
        default: _withCtx(() => [
          (_ctx.bodyVisible)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                ref: "accordionBody",
                class: _normalizeClass(["accordion__body", { accordion__anim: _ctx.animated }]),
                style: _normalizeStyle({ transitionDuration: _ctx.transitionDuration, transitionTimingFunction: _ctx.transitionTimingFunction })
              }, [
                _renderSlot(_ctx.$slots, "body", {
                  accordionToggle: _ctx.accordionToggle,
                  accordionHide: _ctx.accordionHide
                }, undefined, true)
              ], 6 /* CLASS, STYLE */))
            : _createCommentVNode("v-if", true)
        ]),
        _: 3 /* FORWARDED */
      }, 8 /* PROPS */, ["appear", "onBeforeEnter", "onEnter", "onAfterEnter", "onBeforeLeave", "onLeave"])
    ])
  ]))
}