import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fee9ca48"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = ["value"]
const _hoisted_3 = {
  key: 0,
  class: "text-area__characters"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.getStyles),
    class: "text-area"
  }, [
    _createElementVNode("label", {
      class: "text-area__label",
      for: _ctx.textAreaHTMLAttributes.id
    }, _toDisplayString(_ctx.label), 9 /* TEXT, PROPS */, _hoisted_1),
    _createElementVNode("textarea", _mergeProps(_ctx.textAreaHTMLAttributes, {
      class: "text-area__textarea",
      value: _ctx.value,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
    }), null, 16 /* FULL_PROPS */, _hoisted_2),
    (_ctx.hasMaxLength)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.getLetterCount), 1 /* TEXT */))
      : _createCommentVNode("v-if", true)
  ], 4 /* STYLE */))
}