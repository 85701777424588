import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5ca64de7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "menu__actions" }
const _hoisted_2 = { class: "option option--dropdown" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DropDown = _resolveComponent("DropDown")!
  const _component_MobileModalMenu = _resolveComponent("MobileModalMenu")!

  return (_ctx.product)
    ? (_openBlock(), _createBlock(_component_MobileModalMenu, {
        key: 0,
        title: _ctx.product.Manufacturer,
        subtitle: _ctx.product.Name,
        onClose: _ctx.onCloseMenu
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_DropDown, {
                "selected-option": _ctx.selectedColor,
                options: _ctx.colorOptions,
                disabled: _ctx.colorDropdownDisabled,
                "open-above": true,
                "background-color": "white",
                width: "100%",
                class: "product__dropdown",
                onOnSelectOption: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateColor($event)))
              }, null, 8 /* PROPS */, ["selected-option", "options", "disabled"]),
              _createVNode(_component_DropDown, {
                "selected-option": _ctx.selectedSize,
                options: _ctx.variantOptions,
                disabled: _ctx.variantDropdownDisabled,
                "open-above": true,
                "background-color": "white",
                width: "100%",
                class: "product__dropdown",
                onOnSelectOption: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateSize($event)))
              }, null, 8 /* PROPS */, ["selected-option", "options", "disabled"])
            ]),
            _createElementVNode("div", {
              class: _normalizeClass(["option option--text", { disabled: _ctx.buyButtonDisabled }]),
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onWishlistProductAddToCart && _ctx.onWishlistProductAddToCart(...args)))
            }, " BÆTA Í KÖRFU ", 2 /* CLASS */),
            _createElementVNode("div", {
              class: "option option--text text--red",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onWishlistProductDelete(_ctx.product)))
            }, " EYÐA VÖRU ")
          ])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["title", "subtitle", "onClose"]))
    : _createCommentVNode("v-if", true)
}