
  import { computed, defineComponent, ref } from 'vue';
  import CartSideOverviewOrderLines from '@/components/cart/checkout/shared/CartSideOverviewOrderLines.vue';
  import MultiGiftWrapOrderLines from '@/components/cart/checkout/gift-wrap/MultiGiftWrapOrderLines.vue';
  import CartBackAndNextButtons from '@/components/cart/checkout/shared/CartBackAndNextButtons.vue';
  import RadioInput from '@/components/shared/inputs/CheckboxInput.vue';
  import TextAreaInput from '@/components/shared/inputs/TextAreaInput.vue';
  import DropDown from '@/components/shared/inputs/DropDown.vue';
  import useCart from '@/composables/useCart';
  import { MAX_GIFT_WRAP_PACKAGES } from '@/constants/store/checkout/other';

  export default defineComponent({
    components: {
      CartSideOverviewOrderLines,
      RadioInput,
      CartBackAndNextButtons,
      TextAreaInput,
      DropDown,
      MultiGiftWrapOrderLines,
    },
    setup() {
      const textAreaValue = ref('');
      const { singleGiftWrap, multiGiftWrap, setSingleGiftWrap, setMultiGiftWrap, selectedGiftWrapCount, setSelectedGiftWrapCount } = useCart();

      const getStyles = computed(() => ({
        '--single-wrap-background-color': singleGiftWrap.value ? '#F4F4F4' : 'initial',
        '--multi-wrap-background-color': multiGiftWrap.value ? '#F4F4F4' : 'initial',
      }));

      const getOptions = computed(() => Array.from({ length: MAX_GIFT_WRAP_PACKAGES }, (_, index) => ({ value: index + 1 })));

      const setGiftWrapCount = (option: { value }) => {
        setSelectedGiftWrapCount(option.value);
      };

      return {
        textAreaValue,
        singleGiftWrap,
        multiGiftWrap,
        setSingleGiftWrap,
        setMultiGiftWrap,
        selectedGiftWrapCount,
        setGiftWrapCount,
        getStyles,
        getOptions,
      };
    },
  });
