
  /* eslint-disable @typescript-eslint/no-explicit-any */
  import { defineComponent, PropType } from 'vue';
  import ImageAndTitleBanner from '@/components/itemTypes/base/banners/ImageAndTitleBanner.vue';
  import BoxAndImageBanner from '@/components/itemTypes/base/banners/boxAndImageBanner.vue';
  import ImageTitleAndButtonBanner from '@/components/itemTypes/base/banners/ImageTitleAndButtonBanner.vue';
  import { Field } from '@/models/field';
  import { findSelectedComponentAndData } from '@/utils/dataHelper';
  import { ComponentDataAndOptions } from '@/models/componentModels';

  interface Options {
    disabled: boolean;
  }

  export default defineComponent({
    components: {
      ImageAndTitleBanner,
      BoxAndImageBanner,
      ImageTitleAndButtonBanner,
    },
    props: {
      banner: {
        type: Object as PropType<unknown>,
        required: true,
      },
    },
    computed: {
      getData(): ComponentDataAndOptions {
        const { banner } = this.$props as any;

        if (banner !== undefined) {
          const fields = banner.Fields as Field[];
          const componentAndData = findSelectedComponentAndData(fields) as ComponentDataAndOptions;
          return componentAndData;
        }

        return {} as ComponentDataAndOptions;
      },
      getOptions(): Options {
        const options = this.getData.options as Options;
        return options;
      },
    },
  });
