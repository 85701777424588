import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "iconContainer" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "certification_Text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.certifications, (certification, index) => {
    return (_openBlock(), _createElementBlock("div", {
      key: index,
      class: "certification_Item"
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          class: "certification_Icon",
          src: '/icons/' + certification.Value + '.svg',
          alt: "certification icon"
        }, null, 8 /* PROPS */, _hoisted_2)
      ]),
      _createElementVNode("p", _hoisted_3, _toDisplayString(certification.Name), 1 /* TEXT */)
    ]))
  }), 128 /* KEYED_FRAGMENT */))
}