
  import { defineComponent, PropType, inject } from 'vue';
  import { get } from 'lodash';
  import { SearchProduct, SearchResultsProducts, SearchShopConfig } from '@/models/search/searchModels';
  import useWebsiteSettings from '@/composables/useWebsiteSettings';
  import { WebsiteSettings } from '@/models/store/website';
  import { SearchForMoreKey, SearchConfigKey, SearchValueKey } from './SearchSymbols';
  import ProductCardList, { ProductCardList as ProductCardListType } from '@/components/products/ProductCardList.vue';

  interface SearchResultsProductSectionState {
    loading: boolean;
  }

  export default defineComponent({
    components: {
      ProductCardList,
    },
    props: {
      shop: {
        required: true,
        type: String,
      },
      section: {
        required: false,
        type: Object as PropType<SearchResultsProducts>,
        default: () => ({}),
      },
      showDefault: {
        required: false,
        type: Number,
        default: 4,
      },
    },
    setup() {
      const { getSettingsByAreaName } = useWebsiteSettings();

      const searchForMore = inject(SearchForMoreKey);
      const searchValue = inject(SearchValueKey);

      if (searchForMore === undefined) {
        throw new Error('Need a searchForMore function provided');
      }

      const searchConfig = inject(SearchConfigKey);

      return {
        getSettingsByAreaName,
        searchForMore,
        searchConfig,
        searchValue,
      };
    },
    data() {
      return {
        loading: false,
      } as SearchResultsProductSectionState;
    },
    computed: {
      productList(): ProductCardListType {
        return {
          products: this.section.Products,
          currentPage: this.section.CurrentPage as number,
          pageCount: this.section.PageCount as number,
        };
      },
      hasMoreResults(): boolean {
        if (this.section.CurrentPage === undefined || this.section.PageCount === undefined) {
          return false;
        }

        if (!this.showLoadMore || this.maxDisplaySize) {
          return false;
        }

        return this.section.CurrentPage < this.section.PageCount;
      },
      products(): Array<SearchProduct> {
        if (!this.section?.Products) {
          return [];
        }

        if (this.maxDisplaySize) {
          return this.section.Products.slice(0, this.maxDisplaySize);
        }

        return this.section.Products;
      },
      getSectionStyles(): Record<string, string> {
        return {
          '--section-color': this.color,
        };
      },
      areaSettings(): WebsiteSettings | null {
        return this.getSettingsByAreaName(this.shopName);
      },
      color(): string {
        return this.areaSettings?.primaryColor || '#000000';
      },
      shopConfig(): SearchShopConfig {
        return get(this.searchConfig?.shops || {}, this.shop);
      },
      maxDisplaySize(): number {
        return get(this.shopConfig || {}, 'maxDisplaySize', 0) as number;
      },
      pathPrefix(): string {
        return get(this.shopConfig || {}, 'routePath', '/s4s') as string;
      },
      showLoadMore(): boolean {
        return get(this.shopConfig || {}, 'showLoadMore', false) as boolean;
      },
      shopName(): string {
        return get(this.shopConfig || {}, 'shopName', this.shop) as string;
      },
      showMoreHref(): string {
        return `${this.pathPrefix}/${this.searchConfig?.href || 'leit'}`;
      },
      showMoreText(): string {
        return 'Sýna allt';
      },
      showMoreQuery(): Record<string, string> {
        const params = {};

        if (this.searchValue !== undefined && this.searchValue.length > 0) {
          params[this.searchConfig?.queryKey || 'Leit'] = this.searchValue;
        }

        return params;
      },
    },
    methods: {
      onLoadMoreClick() {
        this.loadMore();
      },
      async loadMore() {
        this.loading = true;
        await this.searchForMore(this.shop);
        this.loading = false;
      },
    },
  });
