import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c9be2670"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    class: _normalizeClass(['anchor', { 'anchor--white': _ctx.color === '#16425B' }]),
    href: `#${_ctx.anchorId}`,
    style: _normalizeStyle({ 'background-color': `${_ctx.color}` })
  }, _toDisplayString(_ctx.title), 15 /* TEXT, CLASS, STYLE, PROPS */, _hoisted_1))
}