export const AUTH_LOGIN = 'authLogin';
export const AUTH_LOGOUT = 'authLogout';
export const CREATE_USER = 'createUser';
export const PASSWORD_RECOVERY_INFO = 'passwordRecoveryInfo';
export const CHANGE_PASSWORD = 'changePassword';
export const PASSWORD_RECOVERY = 'requestToRecoverPassword';
export const GET_USER_ADDRESSES = 'getUserAddresses';
export const CREATE_USER_ADDRESS = 'createUserAddress';
export const UPDATE_USER_ADDRESS = 'updateUserAddress';
export const DELETE_USER_ADDRESS = 'deleteUserAddress';
export const MAKE_ADDRESS_DEFAULT = 'makeAddressDefault';
export const GET_AUTH_USER = 'getAuthUser';
export const GET_AUTH_USER_DATA = 'getAuthUserData';
export const GET_USER_ORDERS = 'getUserOrders';
export const UPDATE_USER_PROFILE = 'updateUserProfile';
export const UPDATE_USER_PASSWORD = 'updateUserPassword';
export const IS_AUTHENTICATED = 'isAuthenticated';
export const GET_USER_WISHLIST = 'getUserWishlist';
export const DELETE_USER_WISHLIST = 'deleteUserWishlist';
export const GET_WISHLIST_PRODUCTS = 'getWishlistProducts';
export const DELETE_PRODUCT_FROM_WISHLIST = 'deleteProductFromWishlist';
export const TOGGLE_PRODUCT_IN_WISHLIST = 'toggleProductInWishlist';
