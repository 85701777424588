
  import { defineComponent, PropType } from 'vue';
  import { RouteRecord } from 'vue-router';
  import { mapGetters } from 'vuex';
  import gsap from 'gsap';
  import { isFrontPage } from '@/utils/routingHelper';
  import HomeLink from '@/global_components/navigation/desktop/desktopMainNavigation/HomeLink.vue';
  import NavigationLink from '@/global_components/navigation/desktop/desktopMainNavigation/NavigationLink.vue';

  export default defineComponent({
    components: {
      HomeLink,
      NavigationLink,
    },
    props: {
      routes: {
        type: Array as PropType<RouteRecord[]>,
        required: false,
        default: () => [],
      },
      showNextLevel: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    emits: ['onLinkClick'],
    data() {
      return {
        oldLogo: '',
        oldBgColor: '',
        oldRoutes: {},
        activeLink: '',
      };
    },
    computed: {
      ...mapGetters('website', {
        websiteSettings: 'getWebsiteSettings',
      }),
      getHomeLink(): RouteRecord {
        return this.routes.filter((item) => item.meta.name === 'Frontpage')[0];
      },
      getLinksToShow() {
        return this.routes.filter((item) => !item.meta.hidden && item.meta.name !== 'Frontpage');
      },
      getLogo() {
        return this.websiteSettings.logoImage;
      },
      getLogoBgColor() {
        return this.websiteSettings.navColor;
      },
      activeRoute(): string {
        return this.$route.path as string;
      },
    },
    watch: {
      getLogo(newVal, oldVal) {
        this.oldLogo = oldVal;
        setTimeout(() => {
          this.oldLogo = newVal;
        }, 1000);
      },
      getLogoBgColor(newVal, oldVal) {
        this.oldBgColor = oldVal;
        setTimeout(() => {
          this.oldBgColor = newVal;
        }, 1000);
      },
      getLinksToShow(newVal, oldVal) {
        this.oldRoutes = oldVal;
        setTimeout(() => {
          this.oldRoutes = newVal;
        }, 1000);
      },
    },
    created() {
      this.oldLogo = this.websiteSettings.logoImage;
      this.oldRoutes = this.getLinksToShow;
    },
    mounted() {
      this.activeLink = this.$route.path as string;
    },
    methods: {
      // To be able to animate the new logo in and the old out,
      // we haveto save the old logo in another container until the animation is complete
      beforeEnterLogoOffset(el) {
        // Logo container
        gsap.set(el, {
          x: -230,
        });

        // Logo image
        gsap.set(el.children[0].children[0], {
          x: -258.8,
        });
      },
      enterLogoOffset(el, done) {
        // Logo container
        gsap.to(el, {
          x: 0,
          duration: 1,
          ease: 'power3.inOut',
        });

        // Logo image
        gsap.to(el.children[0].children[0], {
          x: 0,
          duration: 1,
          ease: 'power3.inOut',
          onComplete: done,
        });
      },
      beforeEnterLogo(el) {
        gsap.set(el, {
          x: 0,
        });
      },
      enterLogo(el, done) {
        gsap.to(el, {
          x: 230,
          duration: 1,
          ease: 'power3.inOut',
          onComplete: done,
        });
      },
      beforeEnterLinkOffset(el) {
        gsap.set(el, {
          x: -100,
          opacity: 0,
        });
      },
      enterLinkOffset(el, done) {
        gsap.to(el, {
          x: 0,
          opacity: 1,
          duration: 1,
          ease: 'power3.inOut',
          onComplete: done,
        });
      },
      beforeEnterLink(el) {
        gsap.set(el, {
          x: 0,
          opacity: 1,
        });
      },
      enterLink(el, done) {
        gsap.to(el, {
          x: 100,
          opacity: 0,
          duration: 1,
          ease: 'power3.inOut',
          onComplete: done,
        });
      },
      afterEnterLink(el) {
        const element = el;
        element.style.display = 'none';
      },
      isFrontPage(route: RouteRecord) {
        return isFrontPage(route);
      },
      getLogoPathIfFrontPage(route: RouteRecord): string {
        if (this.isFrontPage(route)) {
          return this.websiteSettings.logoImage;
        }
        return route.name as string;
      },
      onClickLink(route: RouteRecord) {
        this.$emit('onLinkClick', route);
      },
    },
  });
