
  import { defineComponent, PropType } from 'vue';
  import { Address, AddressPayload } from '@/models/address/address';
  import UserAddressCard from './UserAddressCard.vue';
  import UserAddressForm from './UserAddressForm.vue';

  interface UserAddressListState {
    editAddress: Address | null;
  }

  export default defineComponent({
    components: {
      UserAddressCard,
      UserAddressForm,
    },
    props: {
      /**
       * Addresses to render
       */
      addresses: {
        required: false,
        type: Array as PropType<Array<Address>>,
        default: () => [] as Address[],
      },
      /**
       * Determine if rendered addresses should have user controls for editing and deleting
       */
      controls: {
        required: false,
        type: Boolean,
        default: false,
      },
      /**
       * Determine if the edit address form is being submitted
       */
      editAddressSubmitting: {
        required: false,
        type: Boolean,
        default: false,
      },
    },
    emits: ['delete', 'update', 'make-default'],
    data() {
      return {
        editAddress: null,
      } as UserAddressListState;
    },
    methods: {
      onAddressDelete(address: Address) {
        this.$emit('delete', address);
      },
      onAddressEdit(address: Address) {
        this.editAddress = address;
      },
      onAddressMakeDefault(address: Address) {
        this.$emit('make-default', address);
      },
      onEditSubmit(id: string, address: AddressPayload) {
        this.$emit('update', id, address);
        this.editAddress = null;
      },
      onEditClose() {
        this.editAddress = null;
      },
    },
  });
