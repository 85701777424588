
  import { defineComponent } from 'vue';
  import { camelCase, get, has, invert } from 'lodash';
  import TabWrapper from '@/components/shared/TabWrapper.vue';
  import Tab from '@/components/shared/Tab.vue';
  import sizeGuideData from './config';
  import conf from '@/config';

  interface ImageConfig {
    kk: string;
    kvk: string;
    born: string;
  }

  interface Config {
    title: string;
    text: string;
    image: string | ImageConfig;
    categories: {
      [key: string]: string;
    };
    getCategories: (product) => { [key: string]: string };
  }

  interface TableData {
    [key: string]: {
      kk: Array<Array<string>>;
      kvk: Array<Array<string>>;
      born: Array<Array<string>>;
    };
  }

  export default defineComponent({
    components: {
      TabWrapper,
      Tab,
    },
    props: {
      product: {
        type: Object,
        required: true,
      },
    },
    emits: ['close'],
    data() {
      return {
        selectedCategory: '',
        selectedColumnIndex: null,
        readMore: false,
      };
    },
    computed: {
      getCategories(): { [key: string]: string } {
        return this.getSettings.getCategories(this.product);
      },
      getProductFamily(): string {
        return camelCase(this.product?.ProductFields?.ProductFamily?.Value[0]?.Value) || '';
      },
      getManufacturer(): string {
        return camelCase(this.product?.Manufacturer.Name) || '';
      },
      getTabTitles(): Array<string> {
        return Object.values(this.getSettings.getCategories(this.product));
      },
      getDefaultCategory(): string {
        return this.getCategories[Object.keys(this.getCategories)[0]] || this.getCategories.default;
      },
      getReadMoreOrLessText(): string {
        return this.readMore ? 'Lesa minna' : 'Lesa meira';
      },
      getTableData(): TableData {
        let tableData = {} as TableData;
        const productManufacturer = this.getManufacturer;
        if (has(sizeGuideData, `${this.getProductFamily}.data.${productManufacturer.toLowerCase()}`)) {
          tableData = get(sizeGuideData, `${this.getProductFamily}.data.${productManufacturer.toLowerCase()}`) as TableData;
        } else {
          tableData = get(sizeGuideData, `${this.getProductFamily}.data.default`) as TableData;
        }
        return tableData;
      },
      getSettings(): Config {
        return get(sizeGuideData, `${this.getProductFamily}.settings`) as Config;
      },
      getTitle(): string {
        return this.getSettings.title;
      },
      getText(): string {
        return this.getSettings.text;
      },
      getImage(): string {
        return typeof this.getSettings.image === 'string'
          ? `${conf.dwUrl}${this.getSettings.image}`
          : `${conf.dwUrl}${this.getSettings.image[this.selectedCategory || this.mapCategoryTitleToKey(this.getDefaultCategory)]}`;
      },
    },
    methods: {
      mapCategoryKeyToTitle(key: string): string {
        return this.getSettings.getCategories(this.product)[key];
      },
      mapCategoryTitleToKey(title: string): string {
        return invert(this.getSettings.getCategories(this.product))[title];
      },
      isDoubleAxis(data: Array<string>): boolean {
        return data[0] !== undefined && data[0][0] === '' && data[0][1] === '';
      },
      toggleParagraph(): void {
        this.readMore = !this.readMore;
      },
      onCloseModal(): void {
        this.$emit('close');
      },
      onTabClick(title: string): void {
        this.selectedCategory = this.mapCategoryTitleToKey(title);
      },
      setSelectedColumnIndex(index): void {
        if (index === 0 || index === this.selectedColumnIndex) {
          this.selectedColumnIndex = null;
        } else {
          this.selectedColumnIndex = index;
        }
      },
    },
  });
