import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import conf from '@/config';

export default class AdvaniaApi {
  protected baseUrl: string;

  protected http: AxiosInstance;

  constructor(path = '', baseUrl: Maybe<string> = null) {
    this.baseUrl = [baseUrl || `${conf.dwUrl}/api/advania`, path].join('/');
    this.http = axios.create({
      baseURL: this.baseUrl,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  }

  async get<T>(path: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.http.get(path, config);
  }

  async post<T>(path: string, data?: string | URLSearchParams, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.http.post(path, data, config);
  }
}
