import { Commit } from 'vuex';
import { GiftCardInfo, GiftCardResponse } from '@/models/cart/giftCardInfo';
import { PaymentPayload } from '@/models/checkout/paymentPayload';
import { CartState } from '@/models/store/cart';
import { isCartErrorResponse } from '@/utils/model_checkers/responses';
import CartAPI from '@/API/cartApi';
import SiminnPayApi from '@/API/siminnPayApi';
import { prepareCheckoutData } from '../helper';

export default {
  async setPaymentMethod(actionParams: { commit: Commit; state: CartState }, payload: PaymentPayload): Promise<void> {
    const { commit, state } = actionParams;
    commit('setPaymentMethod', payload);
  },
  async postCheckoutData(actionParams: { commit: Commit; state: CartState }, payload: PaymentPayload): Promise<void> {
    const { commit, state } = actionParams;
    const postCheckoutForm: FormData = prepareCheckoutData(state, payload);
    const response = await CartAPI.postCheckoutFormData(postCheckoutForm);
    if (isCartErrorResponse(response)) {
      console.error(`failed calling postCheckoutData ${JSON.stringify(payload)} from cart`, response.data);
    } else {
      commit('updateCartState', response.data);
    }
  },
  async setGiftCardInfo(actionParams: { commit: Commit; state: CartState }, payload: GiftCardInfo): Promise<GiftCardResponse> {
    const { commit, state } = actionParams;
    const formData = new FormData();
    formData.append('EcomCartPaymethodID', payload.paymentMethodId);
    formData.append('GiftCard', payload.giftCard);
    formData.append('GiftCardAmount', payload.giftCardAmount.toString());
    formData.append('CartV2.GotoStep0', '1');
    const response = await CartAPI.postCheckoutFormData(formData);
    if (isCartErrorResponse(response)) {
      console.error(`failed calling postCheckoutData ${JSON.stringify(payload)} from cart`, response.data);
      return {} as GiftCardResponse;
    }
    commit('setGiftCardInfo', response.data);
    return {
      GiftCardNumber: response.data.General.GiftCardNumber,
      GiftCardAmount: response.data.General.GiftCardAmount,
      NoGiftCardNumber: response.data.General.NoGiftCardNumber,
      GiftCardAmountIsInsufficient: response.data.General.GiftCardAmountIsInsufficient,
      OrderAmountToBePaid: response.data.General.OrderAmountToBePaid,
    } as GiftCardResponse;
  },
  async setSiminnPayLoanInfo(actionParams: { commit: Commit; state: CartState }): Promise<void> {
    const { commit, state } = actionParams;
    const response = await SiminnPayApi.getLoanInfo(state.cart.General.TotalPrice);
    commit('setSiminnPayLoanInfo', response);
  },
};
