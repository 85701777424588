import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7bfd895e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "logoRow" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["paragraph-container container", _ctx.getParagraphClasses]),
    style: _normalizeStyle(_ctx.getParagraphStyles)
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paragraphData.images, (image) => {
        return (_openBlock(), _createElementBlock("div", {
          key: image.image.Path,
          class: "logoRow_ImageWrapper"
        }, [
          _createElementVNode("img", {
            src: _ctx.imagePrefix + image.image.Path,
            class: "logoRow_Image"
          }, null, 8 /* PROPS */, _hoisted_2)
        ]))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ], 6 /* CLASS, STYLE */))
}