import { computed, ComputedRef, ref, Ref } from 'vue';
import { useRoute } from 'vue-router';
import { ProductListSortDropdownOption } from '@/components/products/ProductListSortDropdown.vue';

interface UseProductListSort {
  sortOptions: ProductListSortDropdownOption[];
  emptyOption: ProductListSortDropdownOption;
  sortBy: Ref<ProductListSortDropdownOption>;
  sortByQuery: ComputedRef<string>;
  setSortOption(option: ProductListSortDropdownOption): void;
  setSortFromQuery(): void;
}

export default function useProductListSort(): UseProductListSort {
  const route = useRoute();
  const emptyOption: ProductListSortDropdownOption = { name: 'Sjálfgefið' };

  const sortOptions: ProductListSortDropdownOption[] = [
    {
      name: 'Nýjast',
      value: 'new',
      sortBy: 'Created',
      sortOrder: 'DESC',
    },
    {
      name: 'Afslætti',
      value: 'afslætti',
      sortBy: 'CustomField_SyncedDiscountPercent',
      sortOrder: 'DESC',
    },
    {
      name: 'Vinsælast',
      value: 'popular',
      sortBy: 'CustomField_Popularity',
      sortOrder: 'DESC',
    },
    {
      name: 'Dýrast',
      value: 'max',
      sortBy: 'CustomField_SyncedLivePrice',
      sortOrder: 'DESC',
    },
    {
      name: 'Ódýrast',
      value: 'min',
      sortBy: 'CustomField_SyncedLivePrice',
      sortOrder: 'ASC',
    },
    emptyOption,
  ];

  const sortBy = ref<ProductListSortDropdownOption>({ ...emptyOption });
  const setSortOption = (option: ProductListSortDropdownOption) => {
    sortBy.value = option;
  };
  const sortByQuery = computed<string>(() => {
    if (!sortBy.value.sortBy) {
      return '';
    }

    return `SortBy=${sortBy.value.sortBy}${sortBy.value.sortOrder ? `&SortOrder=${sortBy.value.sortOrder}` : ''}`;
  });

  const setSortFromQuery = () => {
    if (route.query.SortBy) {
      const sortOption = sortOptions.find((opt) => route.query.SortBy === opt.sortBy && route.query.SortOrder === opt.sortOrder);

      if (sortOption) {
        sortBy.value = sortOption;
      }
    }
  };

  return {
    sortOptions,
    emptyOption,
    sortBy,
    sortByQuery,
    setSortOption,
    setSortFromQuery,
  };
}
