import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-097c4d60"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cartOverviewOrderLineModal" }
const _hoisted_2 = { class: "cartOverviewOrderLineModal_Product" }
const _hoisted_3 = { class: "cartOverviewOrderLineModal_Product_Manufacturer" }
const _hoisted_4 = { class: "cartOverviewOrderLineModal_Product_ProductName" }
const _hoisted_5 = { class: "cartOverviewOrderLineModal_Actions" }
const _hoisted_6 = { class: "cartOverviewOrderLineModal_Actions_Color hidden" }
const _hoisted_7 = ["value", "selected"]
const _hoisted_8 = { class: "cartOverviewOrderLineModal_Actions_Variant hidden" }
const _hoisted_9 = ["value", "selected", "disabled"]
const _hoisted_10 = { class: "cartOverviewOrderLineModal_Actions_Quantity" }
const _hoisted_11 = ["value", "selected"]
const _hoisted_12 = { class: "cartOverviewOrderLineModal_Actions_Delete" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.orderLine.Manufacturer), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.orderLine.Product.ProductName), 1 /* TEXT */)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("select", {
          name: "color",
          onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateColor($event)))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.colorOptions, (option) => {
            return (_openBlock(), _createElementBlock("option", {
              key: option.meta.product.ProductID,
              value: option.value,
              selected: _ctx.selectedColor === option.value
            }, _toDisplayString(option.value), 9 /* TEXT, PROPS */, _hoisted_7))
          }), 128 /* KEYED_FRAGMENT */))
        ], 32 /* HYDRATE_EVENTS */)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("select", {
          name: "variant",
          onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateVariant($event)))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.variantOptions, (option) => {
            return (_openBlock(), _createElementBlock("option", {
              key: option.meta.variant.VariantID,
              value: `${JSON.stringify(option.meta.variant)}`,
              selected: _ctx.selectedVariant.VariantID === option.meta.variant.VariantID,
              disabled: option.disabled
            }, _toDisplayString(option.value) + " " + _toDisplayString(option.notification), 9 /* TEXT, PROPS */, _hoisted_9))
          }), 128 /* KEYED_FRAGMENT */))
        ], 32 /* HYDRATE_EVENTS */)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("select", {
          name: "quantity",
          class: "mobileSelect bold",
          onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateQuantity($event)))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedVariant.Stock, (number) => {
            return (_openBlock(), _createElementBlock("option", {
              key: number,
              value: number,
              selected: number == _ctx.selectedQuantity
            }, _toDisplayString(number) + " STK. ", 9 /* TEXT, PROPS */, _hoisted_11))
          }), 128 /* KEYED_FRAGMENT */))
        ], 32 /* HYDRATE_EVENTS */)
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("button", {
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.deleteItem && _ctx.deleteItem(...args)))
        }, "EYÐA VÖRU")
      ])
    ])
  ]))
}