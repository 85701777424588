
  import { defineComponent, PropType, StyleValue } from 'vue';
  import { Field, Image, ImageDimensions } from '@/models/field';
  import { extractParagraphComponentDataFields, mapBaseComponentData } from '@/utils/dataMapper';
  import InformationBox from '@/components/itemTypes/InformationBox.vue';
  import { getBackgroundImageStyles } from '@/utils/imageHelper';
  import { hyphenate } from '@/utils/stringHelper';

  interface BoxAndImageBanner {
    image: Image;
    box: Field;
    imageTitle: string;
  }

  interface Options {
    boxWidth: string;
    invert: boolean;
    imageTitleColor: string;
    imageTitleWidth: string;
  }

  const optionKeys = ['boxWidth', 'invert', 'imageTitleWidth', 'imageTitleColor'];

  export default defineComponent({
    components: {
      InformationBox,
    },
    props: {
      banner: {
        type: Object as PropType<Field>,
        required: true,
      },
    },
    computed: {
      getData(): BoxAndImageBanner {
        const { banner } = this.$props;
        const data = mapBaseComponentData(banner) as BoxAndImageBanner;
        return data;
      },
      getBannerImageStyles(): StyleValue {
        const imageDimensions = { width: 1500, height: 700 } as ImageDimensions;
        return getBackgroundImageStyles(this.getData.image, imageDimensions);
      },
      getOptions(): Options {
        const data = this.getData;
        return extractParagraphComponentDataFields(data, optionKeys) as Options;
      },
      getStyles(): StyleValue {
        return {
          '--width': this.getOptions.boxWidth || '50%',
          '--boxOrder': this.getOptions.invert ? '1' : '0',
          '--imageTitleColor': this.getOptions.imageTitleColor || 'white',
          '--imageTitleWidth': this.getOptions.imageTitleWidth || '90%',
        } as StyleValue;
      },
      getTitle(): string {
        return hyphenate(this.getData.imageTitle || '');
      },
    },
  });
