import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-216552a6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container overview" }
const _hoisted_2 = { class: "overview__item" }
const _hoisted_3 = { class: "overview__text" }
const _hoisted_4 = {
  key: 0,
  class: "overview__item"
}
const _hoisted_5 = {
  key: 1,
  class: "overview__item"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserAddressCard = _resolveComponent("UserAddressCard")!
  const _component_UserOrderCard = _resolveComponent("UserOrderCard")!
  const _component_OutlineBox = _resolveComponent("OutlineBox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, [
        _renderSlot(_ctx.$slots, "infoText", {}, undefined, true)
      ])
    ]),
    (_ctx.userDefaultAddress)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_UserAddressCard, {
            address: _ctx.userDefaultAddress,
            underlined: ""
          }, null, 8 /* PROPS */, ["address"])
        ]))
      : _createCommentVNode("v-if", true),
    _createCommentVNode(" <div class=\"overview__item\">\r\n      <UserContentCard bg-color-class=\"grey\">\r\n        <div class=\"overview__credit-card\">\r\n          CreditCard Placeholder\r\n        </div>\r\n      </UserContentCard>\r\n    </div> "),
    (_ctx.userLastOrder)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_OutlineBox, { primary: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_UserOrderCard, { order: _ctx.userLastOrder }, {
                header: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "orderHeaderText", {}, undefined, true)
                ]),
                _: 3 /* FORWARDED */
              }, 8 /* PROPS */, ["order"])
            ]),
            _: 3 /* FORWARDED */
          })
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}