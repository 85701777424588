import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b11457c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VideoWithText = _resolveComponent("VideoWithText")!

  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(["container paragraph-container", _ctx.getParagraphClasses]),
    style: _normalizeStyle(_ctx.getParagraphStyles)
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getData, (card, index) => {
        return (_openBlock(), _createBlock(_component_VideoWithText, {
          key: index,
          video: card.youtubeId,
          title: card.title,
          text: card.text,
          invert: _ctx.isDarkMode
        }, null, 8 /* PROPS */, ["video", "title", "text", "invert"]))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ], 6 /* CLASS, STYLE */))
}