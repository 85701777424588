import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "options"
}
const _hoisted_2 = ["value", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["select postbox_Container", { open: _ctx.isOpen }]),
    tabindex: "0",
    onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
  }, [
    _createElementVNode("div", {
      class: "selected bold",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onSelectClick && _ctx.onSelectClick(...args)))
    }, _toDisplayString(_ctx.computedSelectedAgent ? _ctx.computedSelectedAgent.Name : 'Veljið afhendingarmáta'), 1 /* TEXT */),
    (_ctx.shippingAgents)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shippingAgents, (agent) => {
            return (_openBlock(), _createElementBlock("div", {
              key: agent.Code,
              value: agent.Code,
              class: "option",
              onClick: ($event: any) => (_ctx.select(agent))
            }, _toDisplayString(agent.Name), 9 /* TEXT, PROPS */, _hoisted_2))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true)
  ], 34 /* CLASS, HYDRATE_EVENTS */))
}