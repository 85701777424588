import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b27edd7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mobileNavigation" }
const _hoisted_2 = { class: "mobileNavigation_Header_Logo" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "mobileNavigation_Header_Icons" }
const _hoisted_5 = {
  ref: "body",
  class: "mobileNavigation_Body"
}
const _hoisted_6 = { class: "mobileNavigation_Body_Header" }
const _hoisted_7 = { class: "mobileNavigation_Body_Header_User" }
const _hoisted_8 = {
  ref: "pages",
  class: "mobileNavigation_Body_Pages"
}
const _hoisted_9 = { class: "mobileNavigation_Body_Pages_Page mobileNavigation_Body_Pages_Level1" }
const _hoisted_10 = { class: "mobileNavigation_Body_Pages_Page mobileNavigation_Body_Pages_Level2" }
const _hoisted_11 = { class: "mobileNavigation_Body_Pages_Page mobileNavigation_Body_Pages_Level3" }
const _hoisted_12 = {
  ref: "areaNavigation",
  class: "mobileNavigation_Body_Area"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Icons = _resolveComponent("Icons")!
  const _component_UserArea = _resolveComponent("UserArea")!
  const _component_PageNavigation = _resolveComponent("PageNavigation")!
  const _component_AreaNavigation = _resolveComponent("AreaNavigation")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["mobileNavigation_Header", { scrolling: _ctx.scrolling }])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.level1Routes, (route) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: route }, [
          (!route.children.length && _ctx.isFrontPage(route) && !route.meta.hidden)
            ? (_openBlock(), _createBlock(_component_router_link, {
                ref: `vue${route.path}`,
                key: route.path,
                class: _normalizeClass(["mobileNavigation_Header_FrontpageLink", { frontPageLink: _ctx.isFrontPage(route) }]),
                to: route.path
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("img", {
                      src: _ctx.websiteSettings.logoImage,
                      alt: ""
                    }, null, 8 /* PROPS */, _hoisted_3)
                  ])
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to", "class"]))
            : _createCommentVNode("v-if", true)
        ], 64 /* STABLE_FRAGMENT */))
      }), 128 /* KEYED_FRAGMENT */)),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_Icons, {
          scrolling: _ctx.scrolling,
          onPassBurgerRef: _cache[0] || (_cache[0] = ($event: any) => (_ctx.acceptBurgerRef($event))),
          onToggleBurger: _ctx.toggleBurger
        }, null, 8 /* PROPS */, ["scrolling", "onToggleBurger"])
      ])
    ], 2 /* CLASS */),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          (_ctx.hasUserArea)
            ? (_openBlock(), _createBlock(_component_UserArea, {
                key: 0,
                onOnLinkClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.navigation($event).area().linkClick()))
              }))
            : _createCommentVNode("v-if", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_PageNavigation, {
            routes: _ctx.level1Routes,
            onGoForward: _cache[2] || (_cache[2] = ($event: any) => (_ctx.navigation($event).page().forward().level2()))
          }, null, 8 /* PROPS */, ["routes"])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_PageNavigation, {
            routes: _ctx.level2Routes,
            onGoBack: _cache[3] || (_cache[3] = ($event: any) => (_ctx.navigation().page().back().level1())),
            onGoForward: _cache[4] || (_cache[4] = ($event: any) => (_ctx.navigation($event).page().forward().level3()))
          }, null, 8 /* PROPS */, ["routes"])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_PageNavigation, {
            routes: _ctx.level3Routes,
            "parent-name": _ctx.level3Parent,
            onGoBack: _cache[5] || (_cache[5] = ($event: any) => (_ctx.navigation().page().back().level2())),
            onGoForward: _cache[6] || (_cache[6] = ($event: any) => (_ctx.navigation().page().forward().end()))
          }, null, 8 /* PROPS */, ["routes", "parent-name"])
        ])
      ], 512 /* NEED_PATCH */),
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_component_AreaNavigation, {
          routes: _ctx.getAreaRoutes,
          onOnLinkClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.navigation($event).area().linkClick()))
        }, null, 8 /* PROPS */, ["routes"])
      ], 512 /* NEED_PATCH */)
    ], 512 /* NEED_PATCH */),
    _createElementVNode("div", {
      ref: "background",
      class: "mobileNavigation_Background background--hidden",
      onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.programmaticallyToggleBurger()))
    }, null, 512 /* NEED_PATCH */)
  ]))
}