
  import { defineComponent } from 'vue';
  import { mapState } from 'vuex';
  import { gsap } from 'gsap';
  import OrderLineCard from '@/components/cart/global_cart/OrderLineCard.vue';
  import { CartState } from '@/models/store/cart';

  export default defineComponent({
    components: {
      OrderLineCard,
    },
    props: {
      scrolling: {
        required: false,
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapState('cart', {
        addedToCart: (state) => (state as CartState).cartOverviewPageOptions.addedToCart,
        productIdAreaMap: (state) => (state as CartState).productIdAreaMap,
      }),
      isTablet() {
        return window.innerWidth < 781;
      },
    },
    methods: {
      beforeEnter(el) {
        if (!this.isTablet) {
          gsap.set('.cart-item__image', {
            opacity: 0,
          });
        }

        gsap.set(el, {
          maxHeight: '0px',
        });
      },
      enter(el, done) {
        if (!this.isTablet) {
          gsap.to('.globalOrderLineCard_Info', {
            x: 10,
            delay: 1.4,
            duration: 0.25,
          });

          gsap.to('.globalOrderLineCard_Info', {
            x: 0,
            delay: 1.65,
            duration: 0.25,
          });

          gsap.set('.cart-item__image', {
            opacity: 1,
            delay: 1.4,
          });
        }

        gsap.to(el, {
          maxHeight: '225px',
          duration: 0.5,
          onComplete: done,
        });
      },
      leave(el, done) {
        gsap.to(el, {
          maxHeight: '0px',
          duration: 0.5,
          onComplete: done,
        });
      },
    },
  });
