import { IProductIdAreaMap, OrderLine } from '@/models/cart/orderLine';
import { Postcode } from '@/models/cart/postcode';
import { CartState, MultiWrapOrderLine } from '@/models/store/cart';

import { ShippingMethod } from '@/models/cart/shippingMethod';
import initialCartState from './initialState';

export default (): CartState => ({
  cart: initialCartState,
  contactInfoPageOptions: {
    giftWrap: false,
    saveContactInfo: false,
    addToMailingList: false,
    useOtherAddress: false,
  },
  cartOverviewPageOptions: {
    originalOrderLines: [] as OrderLine[],
    addedToCart: [] as OrderLine[],
  },
  giftWrapProduct: null,
  giftWrapPage: {
    singleWrap: false,
    multiWrap: false,
    selectedGiftWrapCount: null,
    singleWrapMessage: '',
    multiWrapOrderLines: [] as MultiWrapOrderLine[],
  },
  shippingMethod: [] as ShippingMethod[],
  loading: false,
  postCodes: [] as Postcode[],
  siminnPayLoanInfo: {
    AvailableLoanOptions: 0,
    AveragePaymentAmount: 0,
    DisbursementAmount: 0,
    DisbursementFeeAmount: 0,
    DisbursementFeePercentage: 0,
    ErrorCode: '',
    ErrorMessage: '',
    HasError: false,
    HttpStatusCode: '',
    InterestRate: 0,
    LoanLengthInMonths: 0,
    PaymentFeePerPayment: 0,
    TotalFeeAmount: 0,
    TotalInterestAmount: 0,
    TotalPrincipalAmount: 0,
    TotalRepayment: 0,
    YearlyCostRatio: 0,
  },
  productIdAreaMap: {} as IProductIdAreaMap,
  receiptsBlackList: [],
});
