import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-20cef7ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "productList_MainContainer container" }
const _hoisted_2 = { class: "productList_Content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductListHeader = _resolveComponent("ProductListHeader")!
  const _component_ProductListFacets = _resolveComponent("ProductListFacets")!
  const _component_ProductCardList = _resolveComponent("ProductCardList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ProductListHeader, {
      "filters-open": _ctx.filtersOpen,
      "onUpdate:filters-open": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filtersOpen) = $event)),
      count: _ctx.productList?.TotalProductsCount || 0,
      "sort-by": _ctx.sortBy.value,
      "onUpdate:sortBy": _ctx.onSortUpdate
    }, null, 8 /* PROPS */, ["filters-open", "count", "sort-by", "onUpdate:sortBy"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ProductListFacets, {
        "selected-facets": _ctx.selectedFacets,
        facets: _ctx.facets,
        closed: !_ctx.filtersOpen,
        "onUpdate:selectedFacets": _ctx.onSelectedFacetsUpdate,
        onClose: _ctx.onFacetsClose,
        onClear: _ctx.onFacetsClear
      }, null, 8 /* PROPS */, ["selected-facets", "facets", "closed", "onUpdate:selectedFacets", "onClose", "onClear"]),
      _createVNode(_component_ProductCardList, {
        size: 'medium',
        loading: _ctx.productListLoader,
        "loading-next": _ctx.nextLoader,
        "product-list": _ctx.productListForCards,
        onNext: _ctx.onNextClick
      }, null, 8 /* PROPS */, ["loading", "loading-next", "product-list", "onNext"])
    ])
  ]))
}