import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7368c849"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "facet-group" }
const _hoisted_2 = { class: "facet-group__header" }
const _hoisted_3 = { class: "facet-group__title" }
const _hoisted_4 = {
  key: 0,
  class: "facet-group__body"
}
const _hoisted_5 = { class: "checkbox_Container" }
const _hoisted_6 = ["value"]
const _hoisted_7 = { class: "facetText" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.getFacetGroupTitle), 1 /* TEXT */),
      _createElementVNode("button", {
        class: _normalizeClass(['btn plusButton accordionToggler', { open: _ctx.isOpen }]),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleFacetGroup && _ctx.toggleFacetGroup(...args)))
      }, null, 2 /* CLASS */)
    ]),
    _createVNode(_Transition, {
      onBeforeEnter: _ctx.beforeEnter,
      onEnter: _ctx.enter,
      onLeave: _ctx.leave
    }, {
      default: _withCtx(() => [
        (_ctx.isOpen)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filters, (filter, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "facet-group__options"
                }, [
                  _createElementVNode("label", _hoisted_5, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedFilters) = $event)),
                      class: "hiddenCheckbox",
                      type: "checkbox",
                      value: _ctx.facet.QueryParameter + '=' + filter.Value
                    }, null, 8 /* PROPS */, _hoisted_6), [
                      [_vModelCheckbox, _ctx.selectedFilters]
                    ]),
                    _createElementVNode("span", {
                      class: "displayedCheckbox checkbox--black",
                      style: _normalizeStyle(_ctx.getCheckboxStyles(_ctx.facet, filter))
                    }, null, 4 /* STYLE */),
                    _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.getCheckboxLabel(_ctx.facet, filter)), 1 /* TEXT */)
                  ])
                ]))
              }), 128 /* KEYED_FRAGMENT */)),
              (!_ctx.isOpen)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "facet-group__see-more",
                    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.showAllOptions && _ctx.showAllOptions(...args)))
                  }, " + MEIRA "))
                : _createCommentVNode("v-if", true)
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["onBeforeEnter", "onEnter", "onLeave"])
  ]))
}