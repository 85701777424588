import { OrderLine } from '@/models/cart/orderLine';
import { CartState } from '@/models/store/cart';

export default {
  itemAdded(state: CartState, orderLine: OrderLine): void {
    if (!state.cartOverviewPageOptions.addedToCart.find((ol) => ol.Product.ProductID === orderLine.Product.ProductID)) {
      state.cartOverviewPageOptions.addedToCart = [...state.cartOverviewPageOptions.addedToCart, orderLine];
    }
  },
  itemAddedDone(state: CartState, orderLine: OrderLine): void {
    state.cartOverviewPageOptions.addedToCart = state.cartOverviewPageOptions.addedToCart.filter((ol) => ol.Product.ProductID !== orderLine.Product.ProductID);
  },
};
