
  /* eslint-disable @typescript-eslint/no-explicit-any */
  import { defineComponent, PropType } from 'vue';
  import { mapGetters } from 'vuex';
  import { ItemType } from '@/models/field';
  import { mapBaseComponentData } from '@/utils/dataMapper';
  import { convertIcelandicLettersInUrl } from '@/utils/routingHelper';
  import CustomButton from '@/components/shared/Button.vue';

  interface Button {
    text: string;
    link: string;
    type: string;
    buttonSize: string;
  }

  export default defineComponent({
    components: {
      CustomButton,
    },
    props: {
      button: {
        type: Object as PropType<ItemType>,
        required: true,
      },
    },
    computed: {
      ...mapGetters('website', {
        websiteSettings: 'getWebsiteSettings',
      }),
      getData(): any {
        const { button } = this.$props;
        const data = mapBaseComponentData(button) as Button;
        switch (data.type) {
          case 'btn--primary':
            data.type = 'primary';
            break;
          case 'btn--dark':
            data.type = 'dark';
            break;
          case 'btn-light':
            data.type = 'light-border';
            break;
          case 'btn--altLight':
            data.type = 'light';
            break;
          default:
            data.type = 'light-border';
            break;
        }

        switch (data.buttonSize) {
          case 'btn-s':
            data.buttonSize = 'small';
            break;
          case 'btn-m':
            data.buttonSize = 'medium';
            break;
          case 'btn-l':
            data.buttonSize = 'large';
            break;
          case 'btn-stretch':
            data.buttonSize = 'fill';
            break;
          case 'btn-hidden':
            data.buttonSize = 'hidden';
            break;
          default:
            data.buttonSize = 'fill';
            break;
        }
        return data;
      },
    },
    methods: {
      isInternalLink(link: { IsExternal: boolean }) {
        return !link.IsExternal;
      },
      linkUrl(link): string {
        return convertIcelandicLettersInUrl(link.Url);
      },
    },
  });
