
  import { defineComponent } from 'vue';
  import { mapActions, mapGetters, mapState } from 'vuex';

  import { OrderLine } from '@/models/cart/orderLine';
  import { CartState } from '@/models/store/cart';

  import Loader from '@/components/shared/Loader.vue';
  import OrderLineCard from '@/components/cart/global_cart/OrderLineCard.vue';
  import CustomButton from '@/components/shared/Button.vue';
  import useCart from '@/composables/useCart';

  export default defineComponent({
    components: {
      OrderLineCard,
      Loader,
      CustomButton,
    },
    props: {
      scrolling: {
        required: false,
        type: Boolean,
        default: false,
      },
    },
    emits: ['close'],
    setup() {
      const { formattedTotalAmountToBePaid } = useCart();

      return {
        formattedTotalAmountToBePaid,
      };
    },
    computed: {
      ...mapState('cart', {
        orderLines: (state) => (state as CartState).cart.OrderLines,
        productIdAreaMap: (state) => (state as CartState).productIdAreaMap,
      }),
      ...mapGetters('cart', {
        cartQuantity: 'getCartQuantity',
      }),
      deleteProductLoading(): boolean {
        return this.$store.state.cart.loading;
      },
    },
    mounted() {
      // Timeout so the event handler is added after current document click event (if there's a current click event)
      setTimeout(() => document.addEventListener('click', this.close), 0);
    },
    unmounted() {
      document.removeEventListener('click', this.close);
    },
    methods: {
      deleteItem(orderLine: OrderLine): void {
        if (this.orderLines.length === 1) {
          this.close();
        }
        this.deleteOrderLine(orderLine);
      },
      close() {
        this.$emit('close');
      },
      onButtonClick() {
        this.close();
      },
      onOrderLineNav() {
        this.close();
      },
      ...mapActions('cart', ['deleteOrderLine']),
    },
  });
