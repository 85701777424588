
  import { defineComponent } from 'vue';

  export default defineComponent({
    props: {
      value: {
        type: String,
        required: true,
      },
      radioValue: {
        type: String,
        required: true,
      },
      name: {
        type: String,
        required: true,
      },
    },
    emits: ['update:value'],
  });
