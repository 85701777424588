import { computed, ComputedRef } from 'vue';
import { useStore } from 'vuex';
import { toNumber } from 'lodash';
import { OrderLine } from '@/models/cart/orderLine';
import { CartState } from '@/models/store/cart';
import * as GETTER from '@/constants/store/checkout/getters';
import { formatAsPriceString } from '@/utils/stringHelper';
import { ShippingMethod, postboxPayload, Postbox } from '@/models/cart/shippingMethod';
import { CustomerInfo } from '@/models/cart/customerInfo';
import { PaymentMethod } from '@/models/cart/paymentMethod';
import { PaymentPayload } from '@/models/checkout/paymentPayload';
import { CartResponse } from '@/models/cart/cartResponseModels';

import { DeliveryInfo } from '@/models/cart/deliveryInfo';
import { SiminnPayLoanInfo } from '@/models/cart/siminnPay';
import { Cart } from '@/models/cart/cart';
import { Product } from '@/models/product/product';

interface ReturnValue {
  // Cart Step
  formattedTotalAmountToBePaid: ComputedRef<string>;
  totalAmountToBePaid: ComputedRef<number>;
  formattedOrderAmountToBePaid: ComputedRef<string>;
  totalQuantity: ComputedRef<number>;
  thereAreOrderLinesToBeUpdated: ComputedRef<boolean>;
  orderLines: ComputedRef<OrderLine[]>;
  getCouponCode: ComputedRef<string>;
  setCouponCode: (payload: string) => void;
  isLoading: ComputedRef<boolean>;
  getCouponOfferValid: ComputedRef<boolean>;
  // Information Step
  giftWrap: ComputedRef<boolean>;
  mailingList: ComputedRef<boolean>;
  useOtherAddress: ComputedRef<boolean>;
  toggleGiftWrap: () => void;
  toggleMailingList: () => void;
  toggleUseOtherAddress: () => void;
  postContactInfo: (data) => Promise<CartResponse>;
  setContactInfo: (payload: CustomerInfo) => void;
  getContactInfo: ComputedRef<CustomerInfo>;
  recalculateCart: () => Promise<void>;
  // Gift Wrap Step - Conditional, currently not in use.
  singleGiftWrap: ComputedRef<boolean>;
  multiGiftWrap: ComputedRef<boolean>;
  selectedGiftWrapCount: ComputedRef<number | null>;
  setSingleGiftWrap: () => void;
  setMultiGiftWrap: () => void;
  setSelectedGiftWrapCount: (value) => void;
  // Shipping Step
  shippingMethods: ComputedRef<ShippingMethod[]>;
  selectShippingMethod: (payload: string) => void;
  selectPostBox: (payload: postboxPayload) => void;
  selectedShippingMethod: ComputedRef<string>;
  selectedShippingMethodObject: ComputedRef<ShippingMethod>;
  selectedShippingID: ComputedRef<string>;
  selectedPostBox: ComputedRef<Postbox>;
  shippingFee: ComputedRef<string>;
  hasShippingFee: ComputedRef<boolean>;
  shippingFeeFormatted: ComputedRef<string>;
  postShippingMethod: (shippingMethod) => Promise<void>;
  // Delivery Info
  getDeliveryInfo: ComputedRef<DeliveryInfo>;
  setDeliveryInfo: (payload: DeliveryInfo) => Promise<void>;
  postDeliveryInfo: (payload: DeliveryInfo) => Promise<void>;
  // Customer info
  getCustomerInfo: ComputedRef<CustomerInfo>;
  // Payment Step
  paymentMethods: ComputedRef<PaymentMethod[]>;
  setPaymentMethod: (payload: PaymentMethod) => void;
  postCheckoutData: (payload: PaymentPayload) => Promise<void>;
  getSiminnPayLoanInfo: ComputedRef<SiminnPayLoanInfo>;
  setSiminnPayLoanInfo: () => Promise<void>;
  partialCartUpdate: (cart: Cart, fields: string[]) => void;
  giftWrapProduct: ComputedRef<Product>;
  giftWrapOrderLine: ComputedRef<OrderLine | undefined>;
  currencySymbol: ComputedRef<string>;
  totalCalculated: ComputedRef<number>;
  totalCalculatedFormatted: ComputedRef<string>;
}

// Populate VUEX Store Object
export default function useCart(): ReturnValue {
  // userSTore() used instead of this.$store.
  const store = useStore();
  const state = store.state.cart as CartState;
  const { getters } = store;

  const formattedTotalAmountToBePaid = computed(() =>
    formatAsPriceString(getters[`cart/${GETTER.GET_TOTAL_AMOUNT_TO_BE_PAID}`], state.cart.General.CurrencySymbol),
  );
  const totalAmountToBePaid = computed(() => getters[`cart/${GETTER.GET_TOTAL_AMOUNT_TO_BE_PAID}`]);
  const formattedOrderAmountToBePaid = computed(() => formatAsPriceString(state.cart.General.OrderAmountToBePaid, state.cart.General.CurrencySymbol));
  const totalQuantity = computed(() => getters[`cart/${GETTER.TOTAL_CART_QUANTITY}`]);
  const thereAreOrderLinesToBeUpdated = computed(() => getters[`cart/${GETTER.THERE_ARE_ORDERLINES_TO_BE_UPDATED}`]);
  const orderLines = computed(() => state.cart.OrderLines);
  const getCouponCode = computed(() => state.cart.General.CouponCode);

  const setCouponCode = (payload: string) => store.dispatch('cart/setCouponCode', payload);
  const recalculateCart = () => store.dispatch('cart/recalculateCart');
  const isLoading = computed(() => state.loading);
  const getCouponOfferValid = computed(() => state.cart.General.CouponOfferValid); // computed(() => true);

  const giftWrap = computed(() => state.contactInfoPageOptions.giftWrap);
  const mailingList = computed(() => state.contactInfoPageOptions.addToMailingList);
  const useOtherAddress = computed(() => state.contactInfoPageOptions.useOtherAddress);
  const toggleGiftWrap = () => store.dispatch('cart/toggleGiftWrap');
  const toggleMailingList = () => store.dispatch('cart/toggleMailingList');
  const toggleUseOtherAddress = () => store.dispatch('cart/toggleUseOtherAddress');
  const postContactInfo = (formData: FormData) => store.dispatch('cart/postContactInfo', formData);
  const setContactInfo = (payload: CustomerInfo) => store.dispatch('cart/setContactInfo', payload);
  const getContactInfo = computed(() => state.cart.CustomerInfo);
  const setDeliveryInfo = (payload: DeliveryInfo) => store.dispatch('cart/setDeliveryInfo', payload);
  const postDeliveryInfo = (payload: DeliveryInfo) => store.dispatch('cart/postDeliveryInfo', payload);
  const getDeliveryInfo = computed(() => state.cart.DeliveryInfo);

  const getCustomerInfo = computed(() => state.cart.CustomerInfo);

  const singleGiftWrap = computed(() => state.giftWrapPage.singleWrap);
  const multiGiftWrap = computed(() => state.giftWrapPage.multiWrap);
  const selectedGiftWrapCount = computed(() => state.giftWrapPage.selectedGiftWrapCount);
  const setSingleGiftWrap = () => store.dispatch('cart/setSingleGiftWrap');
  const setMultiGiftWrap = () => store.dispatch('cart/setMultiGiftWrap');
  const setSelectedGiftWrapCount = (value: number) => store.dispatch('cart/setSelectedGiftWrapCount', value);

  const shippingMethods = computed(() => state.cart.ShippingMethods);
  const selectShippingMethod = (payload: string) => store.commit('cart/selectShippingMethod', payload);
  const selectPostBox = (payload: postboxPayload) => store.commit('cart/selectPostBox', payload);
  const selectedShippingMethod = computed(() => state.cart.selectedShippingMethod);
  const selectedShippingMethodObject = computed(() => store.getters['cart/getSelectedShippingMethod']);
  const selectedShippingID = computed(() => state.cart.selectedShippingID);
  const selectedPostBox = computed(() => state.cart.selectedPostBox);
  const postShippingMethod = (shippingMethod) => store.dispatch('cart/postShippingMethod');

  const shippingFee = computed(() => state.cart.ShippingFee?.Price || '');
  const shippingFeeNumber = computed(() => (shippingFee.value?.length ? toNumber(shippingFee.value.split(',')[0].replaceAll('.', '')) : 0));
  const hasShippingFee = computed(() => !!shippingFee.value && shippingFee.value !== '0,00');
  const currencySymbol = computed(() => state.cart.General.CurrencySymbol);
  const shippingFeeFormatted = computed(() => formatAsPriceString(shippingFeeNumber.value, currencySymbol.value));

  const paymentMethods = computed(() => state.cart.PaymentMethods);
  const setPaymentMethod = (payload: PaymentMethod) => store.dispatch('cart/setPaymentMethod', payload);
  const postCheckoutData = (payload: PaymentPayload) => store.dispatch('cart/postCheckoutData', payload);
  const getSiminnPayLoanInfo = computed(() => state.siminnPayLoanInfo);
  const setSiminnPayLoanInfo = () => store.dispatch('cart/setSiminnPayLoanInfo');

  const partialCartUpdate = (cart: Cart, fields: string[]) => store.commit('cart/partialCartUpdate', { cart, fields });
  const giftWrapProduct = computed(() => getters[`cart/${GETTER.GIFT_WRAP_PRODUCT}`]);
  const giftWrapOrderLine = computed(() => getters[`cart/${GETTER.GIFT_WRAP_ORDER_LINE}`]);

  const totalCalculated = computed<number>(() => {
    const total = toNumber(totalAmountToBePaid.value);

    return (Number.isNaN(total) ? 0 : total) + shippingFeeNumber.value;
  });
  const totalCalculatedFormatted = computed<string>(() => formatAsPriceString(totalCalculated.value, currencySymbol.value));

  return {
    formattedTotalAmountToBePaid,
    totalAmountToBePaid,
    formattedOrderAmountToBePaid,
    totalQuantity,
    thereAreOrderLinesToBeUpdated,
    orderLines,
    getCouponCode,
    setCouponCode,
    getCouponOfferValid,
    giftWrap,
    mailingList,
    useOtherAddress,
    toggleGiftWrap,
    toggleMailingList,
    toggleUseOtherAddress,
    postContactInfo,
    getContactInfo,
    setContactInfo,
    setDeliveryInfo,
    postDeliveryInfo,
    getCustomerInfo,
    getDeliveryInfo,
    recalculateCart,
    singleGiftWrap,
    multiGiftWrap,
    selectedGiftWrapCount,
    setSingleGiftWrap,
    setMultiGiftWrap,
    setSelectedGiftWrapCount,
    shippingMethods,
    selectShippingMethod,
    selectPostBox,
    paymentMethods,
    setPaymentMethod,
    selectedPostBox,
    selectedShippingMethod,
    selectedShippingMethodObject,
    selectedShippingID,
    postShippingMethod,
    shippingFee,
    hasShippingFee,
    shippingFeeFormatted,
    postCheckoutData,
    getSiminnPayLoanInfo,
    setSiminnPayLoanInfo,
    isLoading,
    partialCartUpdate,
    giftWrapProduct,
    giftWrapOrderLine,
    currencySymbol,
    totalCalculated,
    totalCalculatedFormatted,
  };
}
