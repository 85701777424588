
  import { defineComponent } from 'vue';
  import UserAddressCard from '@/components/user/addresses/UserAddressCard.vue';
  // import UserContentCard from '@/components/user/UserContentCard.vue';
  import useManageUserAddresses from '@/composables/useManageUserAddresses';
  import OutlineBox from '@/components/shared/OutlineBox.vue';
  import UserOrderCard from '@/components/user/orders/UserOrderCard.vue';
  import useUserOrderHistory from '@/composables/useUserOrderHistory';

  export default defineComponent({
    components: {
      UserAddressCard,
      // UserContentCard,
      UserOrderCard,
      OutlineBox,
    },
    setup() {
      const { userDefaultAddress } = useManageUserAddresses();
      const { userLastOrder } = useUserOrderHistory();

      return { userDefaultAddress, userLastOrder };
    },
  });
