/* eslint-disable no-shadow */
import { find, get } from 'lodash';
import { Commit } from 'vuex';
import { WebsiteSettings, WebsiteState, RegisterWebsiteSettings } from '@/models/store/website';
import * as GETTER from '@/constants/store/website/getters';

const defaultSettings = {
  primaryColor: '#000000',
  phoneNumber: '',
  email: 's4s@s4s.is',
  logoImage: '',
  vatNumber: '',
  routePath: '/s4s',
  footerId: '-1',
  cartInfoText: '',
} as WebsiteSettings;

const state = (): WebsiteState => ({
  activeAreaId: null,
  settings: {},
});

const getters = {
  [GETTER.GET_WEBSITE_SETTINGS](state: WebsiteState): WebsiteSettings {
    const websiteSettings = state.activeAreaId !== null ? state.settings[state.activeAreaId].websiteSettings : defaultSettings;

    const computedSettings = {
      primaryColorLight: `${websiteSettings.primaryColor}80`,
      navColor: get(websiteSettings, 'navColor', websiteSettings.primaryColor),
      navColorLight: `${get(websiteSettings, 'navColor', websiteSettings.primaryColor)}1A`,
      navColorText: get(websiteSettings, 'navColorText', '#FFFFFF'),
      navColorTextHover: get(websiteSettings, 'navColorTextHover', '#000000'),
    };

    return { ...websiteSettings, ...computedSettings };
  },
  getAreaID(state: WebsiteState): number | null {
    return state.activeAreaId;
  },
  getWebsiteSettingsByName:
    (state: WebsiteState): unknown =>
    (name: string): WebsiteSettings | null => {
      const { websiteSettings } = find(state.settings, ({ areaName }) => areaName === name) || {};

      if (!websiteSettings) {
        return null;
      }

      const computedSettings = {
        primaryColorLight: `${websiteSettings.primaryColor}80`,
        navColor: get(websiteSettings, 'navColor', websiteSettings.primaryColor),
        navColorLight: `${get(websiteSettings, 'navColor', websiteSettings.primaryColor)}1A`,
        navColorText: get(websiteSettings, 'navColorText', '#FFFFFF'),
        navColorTextHover: get(websiteSettings, 'navColorTextHover', '#000000'),
      };

      return { ...websiteSettings, ...computedSettings };
    },
  getWebsiteSettingsByRoutePath:
    (state: WebsiteState): unknown =>
    (path: string): WebsiteSettings | null => {
      const { websiteSettings } = find(state.settings, ({ websiteSettings }) => websiteSettings?.routePath === path) || {};

      if (!websiteSettings) {
        return null;
      }

      const computedSettings = {
        primaryColorLight: `${websiteSettings.primaryColor}80`,
        navColor: get(websiteSettings, 'navColor', websiteSettings.primaryColor),
        navColorLight: `${get(websiteSettings, 'navColor', websiteSettings.primaryColor)}1A`,
        navColorText: get(websiteSettings, 'navColorText', '#FFFFFF'),
        navColorTextHover: get(websiteSettings, 'navColorTextHover', '#000000'),
      };

      return { ...websiteSettings, ...computedSettings };
    },
};

const mutations = {
  applySettings(state: WebsiteState, areaId: number): void {
    state.activeAreaId = areaId || null;
  },
  registerSettings(state: WebsiteState, { areaId, settings }: RegisterWebsiteSettings): void {
    state.settings = { ...state.settings, [areaId]: settings };
  },
};

const actions = {
  applySettings({ commit }: { commit: Commit }, areaId: number): void {
    commit('applySettings', areaId);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
