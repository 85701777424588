
  import { defineComponent, computed } from 'vue';

  import Loader from '@/components/shared/Loader.vue';
  import CustomButton from '@/components/shared/Button.vue';

  export default defineComponent({
    components: {
      Loader,
      CustomButton,
    },
    props: {
      loadingCondition: {
        type: Boolean,
        required: false,
        default: false,
      },
      goNextDisabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      nextButtonText: {
        type: String,
        required: false,
        default: 'NÆSTA SKREF',
      },
      prevButtonText: {
        type: String,
        required: false,
        default: 'TIL BAKA',
      },
      prevUseIcon: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    emits: ['clickBack', 'clickNext'],
    setup(props, { emit }) {
      const loading = computed(() => props.loadingCondition);
      const onClickBack = () => {
        emit('clickBack');
      };
      const onClickNext = (event) => {
        emit('clickNext', event);
      };
      return {
        loading,
        onClickBack,
        onClickNext,
      };
    },
  });
