
  import { defineComponent } from 'vue';
  import { get } from 'lodash';
  import tabwrapper from '@/components/shared/TabWrapper.vue';
  import tab from '@/components/shared/Tab.vue';
  import ProductCertificationIcons from '@/components/products/ProductCertificationIcons.vue';
  import accordionMixin from '../../mixins/accordion';
  export default defineComponent({
    components: {
      tabwrapper,
      tab,
      ProductCertificationIcons,
    },
    mixins: [accordionMixin],
    props: ['productInfo'],
    data() {
      return {
        tabTitles: ['UPPLÝSINGAR', 'TÆKNILEGIR EIGINLEIKAR'],
        defaultTab: 'TÆKNILEGIR EIGINLEIKAR',
      };
    },
    computed: {
      showTabs(): boolean {
        const showTabs =
          this.productInfo?.ProductCategories?.Clothing !== undefined ||
          this.productInfo?.ProductCategories?.Fourwheeler !== undefined ||
          this.productInfo?.ProductCategories?.Buggy_cars !== undefined ||
          this.productInfo?.ProductCategories?.Sexhjol !== undefined ||
          this.productInfo?.ProductCategories?.Snowmobile !== undefined ||
          this.productInfo?.ProductCategories?.bicycle !== undefined ||
          this.productInfo?.ProductCategories?.Saethotur !== undefined;
        return showTabs;
      },
      technicalInformationFields(): any {
        const clothingFields = get(this.productInfo || {}, 'ProductCategories.Clothing.Fields', {});
        const fourwheelerFields = get(this.productInfo || {}, 'ProductCategories.Fourwheeler.Fields', {});
        const buggyFields = get(this.productInfo || {}, 'ProductCategories.Buggy_cars.Fields', {});
        const sexhjolFields = get(this.productInfo || {}, 'ProductCategories.Sexhjol.Fields', {});
        const snowmobileFields = get(this.productInfo || {}, 'ProductCategories.Snowmobile.Fields', {});
        const bicycleFields = get(this.productInfo || {}, 'ProductCategories.bicycle.Fields', {});
        const saethoturFields = get(this.productInfo || {}, 'ProductCategories.Saethotur.Fields', {});
        const shoeFields = get(this.productInfo || {}, 'ProductCategories.Shoe.Fields', {});

        const fields = {
          ...clothingFields,
          ...fourwheelerFields,
          ...buggyFields,
          ...sexhjolFields,
          ...snowmobileFields,
          ...bicycleFields,
          ...saethoturFields,
          ...shoeFields,
        };

        return Object.values(fields);
      },
    },
    methods: {
      isValueArray(value) {
        return Array.isArray(value);
      },
    },
  });
