import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.getData.data && !_ctx.getOptions.disabled)
    ? (_openBlock(), _createBlock(_resolveDynamicComponent(`${_ctx.getData.component}`), {
        key: 0,
        class: "banner_Container",
        banner: _ctx.getData.data
      }, null, 8 /* PROPS */, ["banner"]))
    : _createCommentVNode("v-if", true)
}