import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "main-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loader = _resolveComponent("Loader")!
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_back_to_top = _resolveComponent("back-to-top")!
  const _component_FacebookChat = _resolveComponent("FacebookChat")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_Sprites = _resolveComponent("Sprites")!
  const _component_Login = _resolveComponent("Login")!

  return (!_ctx.routes.length)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        style: _normalizeStyle(_ctx.websiteStyles),
        class: "initializeSite_Container"
      }, [
        _createVNode(_component_Loader, {
          size: 100,
          thickness: 1
        })
      ], 4 /* STYLE */))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        style: _normalizeStyle(_ctx.websiteStyles),
        class: _normalizeClass([`theme-brp-ellingsen`, { 'toggle-darkmode': _ctx.$route.meta.toggleDarkMode }])
      }, [
        _createVNode(_component_Navigation),
        _createElementVNode("main", _hoisted_1, [
          _createVNode(_component_router_view, {
            key: _ctx.$route.path
          })
        ]),
        _createVNode(_component_back_to_top, {
          text: "EFST",
          visibleoffset: 1000
        }),
        (!_ctx.isDevEnv)
          ? (_openBlock(), _createBlock(_component_FacebookChat, { key: 0 }))
          : _createCommentVNode("v-if", true),
        _createVNode(_component_Footer),
        _createVNode(_component_Sprites),
        _createVNode(_Transition, { name: "slideInRight" }, {
          default: _withCtx(() => [
            (_ctx.displayLoginForm)
              ? (_openBlock(), _createBlock(_component_Login, {
                  key: 0,
                  onCancel: _ctx.onLoginCancel,
                  onLogin: _ctx.onLogin
                }, null, 8 /* PROPS */, ["onCancel", "onLogin"]))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        })
      ], 6 /* CLASS, STYLE */))
}