import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_opening_hours_banner_day = _resolveComponent("opening-hours-banner-day")!
  const _component_opening_hours_banner_date = _resolveComponent("opening-hours-banner-date")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["paragraph-container container", _ctx.getParagraphClasses]),
    style: _normalizeStyle(_ctx.getParagraphStyles)
  }, [
    (_ctx.storeListWeek.length)
      ? (_openBlock(), _createBlock(_component_opening_hours_banner_day, {
          key: 0,
          "banner-data": _ctx.bannerWeek
        }, null, 8 /* PROPS */, ["banner-data"]))
      : _createCommentVNode("v-if", true),
    (_ctx.storeListHolidays.length)
      ? (_openBlock(), _createBlock(_component_opening_hours_banner_date, {
          key: 1,
          "banner-data": _ctx.bannerHoliday
        }, null, 8 /* PROPS */, ["banner-data"]))
      : _createCommentVNode("v-if", true)
  ], 6 /* CLASS, STYLE */))
}