
  import { defineComponent, PropType } from 'vue';

  import { lowerCase } from 'lodash';

  import useParagraph from '@/composables/useParagraph';
  import { CoopAndGrantAPI, ICoopAndGrantFormPayload } from '@/API/coopAndGrantApi';
  import { minLength, validateEmail } from '@/utils/formValidators';

  import TextInput from '@/components/shared/inputs/TextInput.vue';
  import RadioButtonInput from '@/components/shared/inputs/RadioButtonInput.vue';
  import TextAreaInput from '@/components/shared/inputs/TextAreaInput.vue';
  import Loader from '@/components/shared/Loader.vue';
  import CustomButton from '@/components/shared/Button.vue';

  interface ICoopAndGrant {
    form: any;
  }

  export default defineComponent({
    components: {
      TextInput,
      RadioButtonInput,
      TextAreaInput,
      Loader,
      CustomButton,
    },
    props: {
      componentProps: {
        type: Object as PropType<ICoopAndGrant>,
        required: true,
      },
    },
    setup(props) {
      const { getParagraphStyles, getParagraphClasses } = useParagraph(props.componentProps);
      return {
        getParagraphStyles,
        getParagraphClasses,
      };
    },
    data() {
      return {
        formFields: [] as any,
        formLoader: false,
        formPosted: false,
        GrantApplication_Category: '',
        GrantApplication_Type: '',
        GrantApplication_Name: '',
        GrantApplication_Company: '',
        GrantApplication_Phone: '',
        GrantApplication_Email: '',
        GrantApplication_Message: '',
        GrantApplication_Country: '',
        emailValidator: [minLength(1, 'Netfang má ekki vera tómt'), validateEmail('Netfang ekki löglegt')],
        companyValidator: [minLength(1, 'Fyrirtæki má ekki vera tómt')],
        nameValidator: [minLength(1, 'Nafn má ekki vera tómt')],
        successMessage: 'Takk fyrir að hafa samband',
      };
    },
    computed: {
      getHiddenFormFields(): any {
        return this.formFields.hiddenFields;
      },
      getFormFields(): any {
        return this.formFields.formFields;
      },
      getRadioFormFields(): any {
        return this.getFormFields?.filter((element) => element.type === 'Radio');
      },
      getTextFormFields(): any {
        // Assume that there a no other none text/textarea types than radio
        return this.getFormFields?.filter((element) => element.type !== 'Radio');
      },
    },
    created() {
      this.fetchFormFields();
    },
    methods: {
      async fetchFormFields() {
        // fetch form fields
        const formFields = await CoopAndGrantAPI.getCoopAndGrantFields();
        this.formFields = formFields;
        return formFields;
      },
      async postForm() {
        // Create payload
        const payload = {
          category: this.GrantApplication_Category,
          type: this.GrantApplication_Type,
          name: this.GrantApplication_Name,
          company: this.GrantApplication_Company,
          phone: this.GrantApplication_Phone,
          email: this.GrantApplication_Email,
          message: this.GrantApplication_Message,
          country: this.GrantApplication_Country,
          hiddenFields: this.getHiddenFormFields,
        } as ICoopAndGrantFormPayload;
        // Start button loader
        this.formLoader = true;
        // Make API call
        await CoopAndGrantAPI.postCoopAndGrantForm(payload);
        this.formPosted = true;
        this.formLoader = false;
        // Return success/error message
      },
      createRefName(name: string) {
        return lowerCase(name.split('_').pop());
      },
      submitForm() {
        // Run local validation on every TextInput component
        Object.values(this.$refs).forEach((r: any) => {
          const { validate } = r;
          if (validate) {
            validate();
          }
        });

        // Check if all inputs are valid
        const allInputsValid = Object.values(this.$refs).every(
          (input: any) => input.validators.every((validator) => validator(input.value) === null) || input.validators.length === 0,
        );

        // Only post form if all inputs are valid
        if (allInputsValid) {
          this.postForm();
        }
      },
    },
  });
