
  import { defineComponent, PropType, StyleValue } from 'vue';
  import useParagraph from '@/composables/useParagraph';
  import { BaseParagraph } from '@/models/item-types/paragraph';
  import { extractParagraphComponentDataFields } from '@/utils/dataMapper';
  import useWebsiteSettings from '@/composables/useWebsiteSettings';
  import { colorIsDark } from '@/utils/colorHelper';

  interface TextParagraph extends BaseParagraph {
    text: string;
  }

  interface Options {
    backgroundColor: string;
    usePrimaryColor: boolean;
  }

  const optionKeys = ['backgroundColor', 'usePrimaryColor'];

  export default defineComponent({
    props: {
      componentProps: {
        type: Object as PropType<unknown>,
        required: true,
      },
    },
    setup(props) {
      const { data, getParagraphStyles, getParagraphClasses } = useParagraph(props.componentProps);

      const { websiteSettings } = useWebsiteSettings();

      const paragraphData = data.value as unknown as TextParagraph;
      return {
        paragraphData,
        getParagraphStyles,
        getParagraphClasses,
        websiteSettings,
      };
    },

    computed: {
      getOptions(): Options {
        const data = this.paragraphData;
        return extractParagraphComponentDataFields(data, optionKeys) as Options;
      },
      getStyles(): StyleValue {
        const backgroundColor = this.getOptions.usePrimaryColor ? this.websiteSettings.primaryColor : this.getOptions.backgroundColor || '#FFFFFF';
        return {
          '--paragraphBackgroundColor': backgroundColor,
          '--textColor': colorIsDark(backgroundColor) ? '#F8F8F8' : '#000000',
        } as StyleValue;
      },
    },
  });
