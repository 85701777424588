type S4SConfig = {
  env: 'DEV' | 'STAGING' | 'PROD';
  dwUrl: string;
  posturinnUrl: string;
  posturinnKey: string;
  logoutPageId: string;
  area: string;
  shop: string;
  orderContext: string;
};

const config: S4SConfig = {
  env: process.env.VUE_APP_ENVIRONMENT,
  dwUrl: process.env.VUE_APP_DW_URL,
  posturinnUrl: process.env.VUE_APP_POSTURINN_API_URL,
  posturinnKey: process.env.VUE_APP_POSTURINN_API_KEY,
  logoutPageId: process.env.VUE_APP_DW_LOGOUT_PAGE_ID,
  area: process.env.VUE_APP_AREA,
  shop: process.env.VUE_APP_SHOP,
  orderContext: process.env.VUE_APP_ORDER_CONTEXT_ID,
};

if (window.location.origin.includes('staging.')) {
  config.env = 'STAGING';
  config.dwUrl = 'https://stagingbackend.s4s.is';
  config.posturinnUrl = 'https://apitest.mappan.is/wscm/';
  config.posturinnKey = '4F/UEh52hA86NWTQyM6+ogYGEsOClgD19jfrwl4Ol2E=';
  config.logoutPageId = '2413';
} else if (window.location.origin.includes('demo01.lisa.is')) {
  config.env = 'DEV';
  config.dwUrl = 'https://s4sbackend.demo01.lisa.is';
  config.posturinnUrl = 'https://apitest.mappan.is/wscm/';
  config.posturinnKey = '4F/UEh52hA86NWTQyM6+ogYGEsOClgD19jfrwl4Ol2E=';
  config.logoutPageId = '3083';
} else if (!window.location.origin.includes('local.dynamicweb') && !window.location.origin.includes('localhost')) {
  config.env = 'PROD';
  config.dwUrl = 'https://backend.s4s.is';
  config.posturinnUrl = 'https://api.mappan.is/wscm/';
  config.posturinnKey = 'nuez0tWJGzATx4VCjOm5jbqRaEwAtCqikWxeX4Vz0bg=';
  config.logoutPageId = '3083';
}

export default config;
