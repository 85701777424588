import { hyphenateSync } from 'hyphen/is';

function cutStringIfNeeded(sentence: string, maxLength: number): string {
  if (sentence.length > maxLength) {
    return `${sentence.slice(0, maxLength)}...`;
  }
  return sentence;
}

function hyphenate(input: string): string {
  if (input.includes('-')) return input;
  return hyphenateSync(input);
}

function formatAsPriceString(input: string | number, currencyFormat: string): string {
  let sourceString = input;
  let string = '';
  let counter = 0;
  if (typeof sourceString === 'number') {
    sourceString = input.toString();
  }

  if (sourceString !== undefined) {
    sourceString = sourceString.replaceAll('.', '');
    for (let i = sourceString.length - 1; i >= 0; i -= 1) {
      string += sourceString.charAt(i);
      counter += 1;
      if (counter === 3 && i !== 0) {
        string += '.';
        counter = 0;
      }
    }
  }

  return `${string.split('').reverse().join('')} ${currencyFormat.toUpperCase()}`;
}

function extractYoutubeId(url: string): string {
  const regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[1].length === 11 ? match[1] : '';
}

export { cutStringIfNeeded, hyphenate, formatAsPriceString, extractYoutubeId };
