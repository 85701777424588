// https://api.siminnpay.is/api/v1/loan/calculate/simple/20000
import axios from 'axios';
import { SiminnPayLoanInfo } from '@/models/cart/siminnPay';

class SiminnPayApi {
  static getUrl(): string {
    return 'https://api.siminnpay.is/api/v1/loan/calculate/simple/';
  }

  static async getLoanInfo(amount: number): Promise<SiminnPayLoanInfo> {
    let response;
    try {
      response = await axios.get(`${this.getUrl()}${amount}`);
      return response.data;
    } catch (e) {
      console.error(e);
      const errorResponse = {
        ErrorCode: '',
        ErrorMessage: '',
        HasError: true,
        HttpStatusCode: '',
      } as SiminnPayLoanInfo;
      return errorResponse;
    }
  }
}

export default SiminnPayApi;
