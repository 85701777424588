
  /* eslint-disable @typescript-eslint/no-explicit-any */
  import { defineComponent, PropType } from 'vue';

  import useParagraph from '@/composables/useParagraph';
  import { ItemType } from '@/models/field';

  import { mapBaseComponentData } from '@/utils/dataMapper';

  import Anchor from '@/components/itemTypes/AnchorList/Anchor.vue';

  interface IAnchorList {
    anchorList: ItemType[];
  }

  export default defineComponent({
    components: {
      Anchor,
    },
    props: {
      componentProps: {
        type: Object as PropType<IAnchorList>,
        required: true,
      },
    },
    setup(props) {
      const { getParagraphStyles, getParagraphClasses } = useParagraph(props.componentProps);
      return {
        getParagraphStyles,
        getParagraphClasses,
      };
    },
    computed: {
      getData(): IAnchorList[] {
        const { anchorList } = this.componentProps;
        return anchorList.map((item) => mapBaseComponentData(item));
      },
    },
  });
