import axios from 'axios';
import conf from '@/config';

class NewsService {
  static getUrl(): string {
    return conf.dwUrl;
  }

  static async getNewsArticle(cardNumber: string): Promise<string> {
    const url = `${this.getUrl()}/api/GiftCard/${cardNumber}`;
    const response = await axios.get(url, { withCredentials: true });
    return response.data.Balance !== null ? response.data.Balance : 'error';
  }

  static async getNewsLinkPaths(areaID: string, pageID: string): Promise<any> {
    const url = `${this.getUrl()}/dwapi/frontend/navigations/${areaID}?ExpandMode=All&RootPageId=${pageID}`;
    const response = await axios.get(url, { withCredentials: true });
    return response.data?.Nodes;
  }

  static async getNewsList(queryParameters: string, areaID: string, pageID: string): Promise<any> {
    // Fetching pretty DW link through the DW api, this is because the templatetags in the feed
    // can only return DW non pretty link which wont map correctly to frontend navigation tree
    const newsLinkPaths = await NewsService.getNewsLinkPaths(areaID, pageID);

    const path = `${conf.area}/news-feed?${queryParameters}`;
    const url = `${this.getUrl()}${path}`;
    const response = await axios.get(url, { withCredentials: true });
    // Filter unused items from newsLinkPaths array
    const returnArr: string[] = [];
    newsLinkPaths.forEach((paths) => {
      const found = response.data.find((item) => item.PageId === paths.PageId);
      if (found) {
        found.link = paths.Link;
        returnArr.push(found);
      }
    });
    return returnArr;
  }
}

export default NewsService;
