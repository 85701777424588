import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5238a1b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "address-list" }
const _hoisted_2 = {
  key: 0,
  class: "address-list__item"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserAddressForm = _resolveComponent("UserAddressForm")!
  const _component_UserAddressCard = _resolveComponent("UserAddressCard")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.addresses, (address) => {
      return (_openBlock(), _createElementBlock("li", {
        key: address.Id,
        class: "address-list__item"
      }, [
        (_ctx.editAddress && _ctx.editAddress.Id === address.Id)
          ? (_openBlock(), _createBlock(_component_UserAddressForm, {
              key: 0,
              "old-value": _ctx.editAddress,
              submitting: _ctx.editAddressSubmitting,
              onSubmit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onEditSubmit(_ctx.editAddress.Id, $event))),
              onClose: _ctx.onEditClose
            }, null, 8 /* PROPS */, ["old-value", "submitting", "onClose"]))
          : (_openBlock(), _createBlock(_component_UserAddressCard, {
              key: 1,
              controls: _ctx.controls,
              address: address,
              onDelete: _ctx.onAddressDelete,
              onEdit: _ctx.onAddressEdit,
              onMakeDefault: _ctx.onAddressMakeDefault
            }, null, 8 /* PROPS */, ["controls", "address", "onDelete", "onEdit", "onMakeDefault"]))
      ]))
    }), 128 /* KEYED_FRAGMENT */)),
    (_ctx.$slots.append)
      ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
          _renderSlot(_ctx.$slots, "append", {}, undefined, true)
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}