
  /* eslint-disable @typescript-eslint/no-explicit-any */
  /* eslint-disable no-shadow */
  import { camelCase } from 'lodash';
  import { defineComponent, PropType } from 'vue';
  import { Field, ItemType, Color } from '@/models/field';
  import { cutStringIfNeeded } from '@/utils/stringHelper';
  import conf from '@/config';

  interface List {
    SystemName: 'IconList';
    Value: ItemType[];
  }

  interface Icon {
    SystemName: 'Icon';
    Path: string;
    Value: Array<{
      Path: string;
    }>;
  }

  interface IconWithText {
    icon: string;
    text: string;
    subtext: string;
  }

  interface IconsWithText {
    textColor: Color;
    backgroundColor: Color;
    list: IconWithText[];
  }

  export default defineComponent({
    props: {
      box: {
        type: Object as PropType<Field>,
        required: true,
      },
      boxCount: {
        type: Number,
        required: true,
      },
    },
    computed: {
      getData(): IconsWithText {
        const { box } = this.$props;
        const fields = (box.Value as ItemType).Fields as Field[];
        const data = fields.reduce((acc, curr) => {
          if (this.isIconList(curr)) {
            acc.list = this.reduceList(curr as List);
          } else {
            const key = camelCase(curr.SystemName) as keyof IconsWithText;
            acc[key] = curr.Value;
          }
          return acc;
        }, {} as any) as IconsWithText;
        return data;
      },
      getContainerStyles(): any {
        return {
          'background-color': this.getData.backgroundColor.Hex,
        };
      },
      getTextStyles(): any {
        return {
          color: this.getData.textColor.Hex,
        };
      },
    },
    methods: {
      cutSentenceIfNeeded(sentence: string, maxLength: number): string {
        return cutStringIfNeeded(sentence, maxLength);
      },
      reduceList(list: List) {
        return (list.Value as ItemType[]).reduce((acc, curr) => {
          const iconWithText = curr.Fields.reduce((acc, curr) => {
            if (this.isIcon(curr)) {
              acc.icon = `${conf.dwUrl}${curr.Value[0].Path}`;
            } else {
              const key = camelCase(curr.SystemName);
              acc[key] = curr.Value;
            }
            return acc;
          }, {} as any) as IconWithText;
          acc.push(iconWithText);
          return acc;
        }, [] as any[]) as IconWithText[];
      },
      isIconList(val: unknown): val is List[] {
        return (val as List).SystemName === 'IconList';
      },
      isIcon(val: unknown): val is Icon {
        const icon = val as Icon;
        return icon.SystemName === 'Icon' && icon.Value.length > 0;
      },
    },
  });
