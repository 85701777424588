
  import { defineComponent } from 'vue';
  import useAuthenticateUser from '@/composables/useAuthenticateUser';
  import TextInput from '@/components/shared/inputs/TextInput.vue';
  import Loader from '@/components/shared/Loader.vue';
  import CustomButton from '@/components/shared/Button.vue';

  export default defineComponent({
    components: {
      TextInput,
      Loader,
      CustomButton,
    },
    emits: ['back'],
    setup() {
      const { sendpasswordRecoveryEmail } = useAuthenticateUser();
      return { sendpasswordRecoveryEmail };
    },
    data() {
      return {
        formData: {
          email: '',
        },
        error: '',
        loading: false,
        emailSent: false,
      };
    },
    computed: {
      dwFormName() {
        return {
          username: () => 'username',
        };
      },
    },
    methods: {
      onSubmit() {
        this.error = '';
        if (this.formData.email === '') {
          this.error = 'Vinsamlegast sláðu inn netfang';
        } else {
          this.sendRecoveryEmail();
        }
      },
      async sendRecoveryEmail() {
        this.loading = true;
        const response = await this.sendpasswordRecoveryEmail(this.formData.email);
        if (response.data.RecoveryEmailSent) {
          this.emailSent = true;
        } else {
          this.error = 'Netfang fannst ekki.';
        }
        this.loading = false;
      },
      goBack() {
        this.$emit('back');
      },
    },
  });
