import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ba2aef68"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_Loader = _resolveComponent("Loader")!

  return (_openBlock(), _createElementBlock("form", {
    class: "address-form",
    onSubmit: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
  }, [
    _createCommentVNode(" <TextInput\r\n      ref=\"nameRef\"\r\n      v-model:value=\"formData.name\"\r\n      class=\"address-form__input\"\r\n      label=\"Nafn\"\r\n      :width=\"'100%'\"\r\n      :validators=\"validators.name\"\r\n    /> "),
    _createVNode(_component_TextInput, {
      ref: "addressRef",
      value: _ctx.formData.address,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.address) = $event)),
      class: "address-form__input",
      label: "Heimilisfang",
      width: '100%',
      validators: _ctx.validators.address
    }, null, 8 /* PROPS */, ["value", "validators"]),
    _createVNode(_component_TextInput, {
      ref: "zipRef",
      value: _ctx.formData.zip,
      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.zip) = $event)),
      class: "address-form__input",
      label: "Póstnúmer",
      width: '100%',
      validators: _ctx.validators.zip
    }, null, 8 /* PROPS */, ["value", "validators"]),
    _createVNode(_component_TextInput, {
      ref: "cityRef",
      value: _ctx.formData.city,
      "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.city) = $event)),
      class: "address-form__input",
      label: "Staður",
      width: '100%',
      validators: _ctx.validators.city
    }, null, 8 /* PROPS */, ["value", "validators"]),
    _createCommentVNode(" <TextInput\r\n      v-model:value=\"formData.country\"\r\n      class=\"address-form__input\"\r\n      label=\"Land\"\r\n      :width=\"'100%'\"\r\n    /> "),
    _createVNode(_component_TextInput, {
      ref: "phoneNumberRef",
      value: _ctx.formData.phoneNumber,
      "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.phoneNumber) = $event)),
      class: "address-form__input",
      label: "Símanúmer",
      width: '100%',
      validators: _ctx.validators.phoneNumber
    }, null, 8 /* PROPS */, ["value", "validators"]),
    _createElementVNode("button", {
      class: "address-form__close btn closeButton",
      type: "button",
      role: "button",
      onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onCloseClick && _ctx.onCloseClick(...args)))
    }),
    _createElementVNode("button", {
      class: "address-form__submit btn btn--primary",
      type: "submit",
      disabled: _ctx.submitting
    }, [
      (!_ctx.submitting)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Staðfesta"))
        : (_openBlock(), _createBlock(_component_Loader, {
            key: 1,
            color: "#FFFFFF",
            size: 24,
            thickness: 5
          }))
    ], 8 /* PROPS */, _hoisted_1)
  ], 32 /* HYDRATE_EVENTS */))
}