
  /* eslint-disable @typescript-eslint/no-explicit-any */
  import { defineComponent, PropType } from 'vue';

  interface Option {
    value: string;
    notification: string;
    disabled: boolean;
    meta: any;
  }

  export default defineComponent({
    props: {
      options: {
        type: Object as PropType<Option[]>,
        required: true,
      },
      selectedOption: {
        type: String,
        required: false,
        default: '',
      },
      label: {
        type: String,
        required: false,
        default: '',
      },
      height: {
        type: String,
        required: false,
        default: '48px',
      },
      width: {
        type: String,
        required: false,
        default: '100%',
      },
      backgroundColor: {
        type: String,
        required: false,
        default: 'none',
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      openAbove: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    emits: ['onSelectOption', 'update:selectedOption'],
    data() {
      return {
        open: false,
        optionsZindex: '1',
      };
    },
    computed: {
      // We need to set and unset the Z index so that it will not conflict with other dropdowns when used in a list and later rendered dropdowns will be rendered on top.
      getStyles(): unknown {
        return {
          '--options-z-index': this.optionsZindex,
          '--width': this.$props.width,
          '--height': this.$props.height,
          '--background-color': this.$props.backgroundColor,
        };
      },
    },
    methods: {
      onSelectOption(option: Option): void {
        const { value, disabled } = option;
        if (!disabled) {
          this.$emit('update:selectedOption', value);
          this.$emit('onSelectOption', option);
          this.open = false;
        }
      },
      onClickSelected() {
        if (this.$props.disabled) return;
        if (this.open) {
          this.optionsZindex = '2';
        } else {
          this.optionsZindex = '1';
        }
      },
    },
  });
