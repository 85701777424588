import { Commit } from 'vuex';
import CartAPI from '@/API/cartApi';
import { isCartErrorResponse } from '@/utils/model_checkers/responses';

export default {
  async getCart({ commit }: { commit: Commit }, payload: FormData): Promise<void> {
    commit('loading');
    try {
      const response = await CartAPI.getCart(payload);
      if (isCartErrorResponse(response)) {
        console.error(`failed posting contact info ${JSON.stringify(payload)} from cart`, response.data);
      } else {
        commit('updateCartState', response.data);
      }
    } catch (e) {
      console.error(e);
    } finally {
      commit('loadingDone');
    }
  },
  setCouponCode({ commit }: { commit: Commit }, payload: string): void {
    commit('setCouponCode', payload);
  },
};
