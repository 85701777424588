import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f5cddeb4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "employee-list container" }
const _hoisted_2 = { class: "employee-card__name" }
const _hoisted_3 = {
  key: 0,
  class: "employee-card__job-title"
}
const _hoisted_4 = {
  key: 1,
  class: "employee-card__mail"
}
const _hoisted_5 = {
  key: 2,
  class: "employee-card__phone"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getData, (employee, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "employee-card"
      }, [
        _createElementVNode("div", {
          class: "employee-card__image",
          style: _normalizeStyle({
          backgroundImage: 'url(' + _ctx.imagePath(employee.image.Path, 350, 350) + ')',
          backgroundPositionX: employee.image.FocalPositionFromLeft + '%',
          backgroundPositionY: employee.image.FocalPositionFromTop + '%',
        })
        }, null, 4 /* STYLE */),
        _createElementVNode("div", _hoisted_2, _toDisplayString(employee.name), 1 /* TEXT */),
        (employee.jobTitle)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(employee.jobTitle), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        (employee.eMail)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(employee.eMail), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        (employee.phoneNumber)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(employee.phoneNumber), 1 /* TEXT */))
          : _createCommentVNode("v-if", true)
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}