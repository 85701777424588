import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3341c25c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (!$props.href)
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        class: _normalizeClass(`t-btn t-btn--${$props.btnType} t-btn--${$props.btnHeight} t-btn--${$props.btnSize}`),
        disabled: $props.disabled,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('btn-click')))
      }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 10 /* CLASS, PROPS */, _hoisted_1))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        (!$props.isExternal)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: $setup.linkUrl(),
              class: _normalizeClass(`t-btn t-btn--${$props.btnType} t-btn--${$props.btnHeight} t-btn--${$props.btnSize}`),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('btn-click')))
            }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ]),
              _: 3 /* FORWARDED */
            }, 8 /* PROPS */, ["to", "class"]))
          : (_openBlock(), _createElementBlock("a", {
              key: 1,
              href: $props.href,
              class: _normalizeClass(`t-btn t-btn--${$props.btnType} t-btn--${$props.btnHeight} t-btn--${$props.btnSize}`)
            }, [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ], 10 /* CLASS, PROPS */, _hoisted_2))
      ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}