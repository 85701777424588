
  import { defineComponent, PropType } from 'vue';

  export default defineComponent({
    props: {
      errors: {
        type: Array as PropType<Array<string | null>>,
        required: true,
      },
      limit: {
        type: Number,
        required: false,
        default: null,
      },
    },
    computed: {
      errorsToDisplay(): Array<string | null> {
        if (this.limit === null) {
          return this.errors;
        }

        return this.errors.filter((error) => error).slice(0, this.limit);
      },
    },
  });
