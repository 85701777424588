import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-712473a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_Loader = _resolveComponent("Loader")!

  return (_openBlock(), _createElementBlock("form", {
    class: "info-form",
    onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
  }, [
    _createVNode(_component_TextInput, {
      ref: "nameRef",
      value: _ctx.formData.name,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.name) = $event)),
      class: "info-form__input",
      label: "Nafn",
      width: "100%",
      icon: "edit",
      validators: _ctx.validators.name,
      onInput: _ctx.onInput
    }, null, 8 /* PROPS */, ["value", "validators", "onInput"]),
    _createVNode(_component_TextInput, {
      ref: "ssnRef",
      value: _ctx.formData.ssn,
      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.ssn) = $event)),
      class: "info-form__input",
      label: "Kennitala",
      width: "100%",
      icon: "edit",
      validators: _ctx.validators.ssn,
      onInput: _ctx.onInput
    }, null, 8 /* PROPS */, ["value", "validators", "onInput"]),
    _createVNode(_component_TextInput, {
      ref: "emailRef",
      value: _ctx.formData.email,
      disabled: true,
      class: "info-form__input",
      label: "Netfang",
      width: "100%",
      icon: "edit"
    }, null, 8 /* PROPS */, ["value"]),
    _createVNode(_component_TextInput, {
      ref: "phoneNumberRef",
      value: _ctx.formData.phoneNumber,
      "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.phoneNumber) = $event)),
      class: "info-form__input",
      label: "Símanúmer",
      width: "100%",
      icon: "edit",
      validators: _ctx.validators.phoneNumber,
      onInput: _ctx.onInput
    }, null, 8 /* PROPS */, ["value", "validators", "onInput"]),
    _createVNode(_component_TextInput, {
      ref: "passwordInput",
      label: "Lykilorð",
      class: "info-form__input",
      value: "****",
      width: "100%",
      icon: "edit",
      onFocus: _ctx.onPasswordFocus,
      onClick: _ctx.onPasswordClick
    }, null, 8 /* PROPS */, ["onFocus", "onClick"]),
    _createElementVNode("button", {
      disabled: !_ctx.hasChange || _ctx.submitting,
      type: "submit",
      role: "button",
      class: "info-form__submit btn btn--primary"
    }, [
      (!_ctx.submitting)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, "vista breytingar"))
        : (_openBlock(), _createBlock(_component_Loader, {
            key: 1,
            color: "#FFFFFF",
            size: 24,
            thickness: 5
          }))
    ], 8 /* PROPS */, _hoisted_1)
  ], 32 /* HYDRATE_EVENTS */))
}