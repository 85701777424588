
  import { defineComponent, PropType } from 'vue';

  import useParagraph from '@/composables/useParagraph';

  import { ArticleTypeOne } from '@/models/item-types/paragraph';

  import CustomButton from '@/components/shared/Button.vue';
  import conf from '@/config';

  export default defineComponent({
    components: {
      CustomButton,
    },
    props: {
      componentProps: {
        type: Object as PropType<unknown>,
        required: true,
      },
    },
    setup(props) {
      const { data, getParagraphStyles, getParagraphClasses } = useParagraph(props.componentProps);
      const paragraphData = data.value as unknown as ArticleTypeOne;
      const imagePrefix = conf.dwUrl;
      return {
        paragraphData,
        getParagraphStyles,
        getParagraphClasses,
        imagePrefix,
      };
    },
  });
