import { computed, ComputedRef } from 'vue';
import { Field } from '@/models/field';
import { findSelectedComponentAndData } from '@/utils/dataHelper';
import { InformationBox } from '@/models/item-types/base/boxes';
import { isInformationBox } from '@/utils/model_checkers/boxes';

interface InformationBoxData {
  getData: ComputedRef<InformationBox>;
  hide: ComputedRef<boolean>;
}

export default function useInformationBox(props): InformationBoxData {
  const getData = computed(() => {
    const { box } = props;
    const fields = box.Fields as Field[];
    const componentAndData = findSelectedComponentAndData(fields) as InformationBox;
    return componentAndData;
  });

  const hide = computed(() => {
    const data = getData.value;
    if (isInformationBox(data)) {
      const now = new Date();
      const enabledFrom = new Date(data.options.enabledFrom);
      const enabledTo = new Date(data.options.enabledTo);
      return data.options.disabled || now < enabledFrom || now > enabledTo;
    }
    return false;
  });

  return {
    getData,
    hide,
  };
}
