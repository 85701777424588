import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-16dc4071"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "image-and-button-box__button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomButton = _resolveComponent("CustomButton")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.getStyles),
    class: "image-and-button-box"
  }, [
    _createElementVNode("img", {
      src: _ctx.boxImageUrl,
      class: "image-and-button-box__image"
    }, null, 8 /* PROPS */, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.buttonData.text !== '')
        ? (_openBlock(), _createBlock(_component_CustomButton, {
            key: 0,
            button: _ctx.boxData.button,
            "button-classes": [_ctx.buttonData.buttonSize, _ctx.buttonData.type]
          }, null, 8 /* PROPS */, ["button", "button-classes"]))
        : _createCommentVNode("v-if", true)
    ])
  ], 4 /* STYLE */))
}