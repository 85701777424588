
  /* eslint-disable no-new-wrappers */
  import { defineComponent } from 'vue';

  export default defineComponent({
    props: {
      quantity: {
        type: Number,
        required: true,
      },
      minValue: {
        type: Number,
        required: false,
        default: 1,
      },
      maxValue: {
        type: Number,
        required: false,
        default: 100,
      },
    },
    emits: ['update:quantity', 'onIncrement', 'onDecrement', 'onChange'],
    methods: {
      onIncrement() {
        const number = new Number(this.$props.quantity < this.maxValue ? this.$props.quantity + 1 : this.$props.quantity);
        this.$emit('update:quantity', number);
        this.$emit('onIncrement', number);
      },
      onDecrement() {
        const number = new Number(this.$props.quantity > this.minValue ? this.$props.quantity - 1 : this.$props.quantity);
        this.$emit('update:quantity', number);
        this.$emit('onDecrement', number);
      },
      onChange(event: InputEvent) {
        event.preventDefault();
        const { value } = event.target as HTMLInputElement;
        const number = new Number(value);
        if (number < this.minValue || number > this.maxValue) {
          this.$emit('update:quantity', new Number(this.$props.quantity));
          this.$emit('onChange', new Number(this.$props.quantity));
        } else {
          this.$emit('update:quantity', number);
          this.$emit('onChange', number);
        }
      },
    },
  });
