
  /* eslint-disable @typescript-eslint/no-explicit-any */
  import { defineComponent, PropType } from 'vue';
  import { ItemType } from '@/models/field';
  import { mapBaseComponentData } from '@/utils/dataMapper';

  interface TitleAndSubtitle {
    title: string;
    subtitle: string;
  }

  export default defineComponent({
    props: {
      titleAndSubtitle: {
        type: Object as PropType<ItemType>,
        required: true,
      },
    },
    computed: {
      getData(): any {
        const { titleAndSubtitle } = this.$props;
        const data = mapBaseComponentData(titleAndSubtitle) as TitleAndSubtitle;
        return data;
      },
    },
  });
