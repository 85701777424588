import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6ba3ab96"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "masterComponent" }
const _hoisted_2 = {
  key: 0,
  class: "loaderContainer masterComponent"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loader = _resolveComponent("Loader")!
  const _component_PageNotFound = _resolveComponent("PageNotFound")!
  const _component_CookiePopup = _resolveComponent("CookiePopup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_Loader, {
            size: 100,
            thickness: 1
          })
        ]))
      : _createCommentVNode("v-if", true),
    _createVNode(_Transition, {
      onBeforeEnter: _ctx.beforeEnter,
      onEnter: _ctx.enter
    }, {
      default: _withCtx(() => [
        (!_ctx.loading && _ctx.paragraphs && _ctx.paragraphs.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paragraphs, (paragraph, index) => {
                return (_openBlock(), _createBlock(_resolveDynamicComponent(`${paragraph.component}`), {
                  key: index,
                  "component-props": paragraph
                }, null, 8 /* PROPS */, ["component-props"]))
              }), 128 /* KEYED_FRAGMENT */))
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["onBeforeEnter", "onEnter"]),
    (!_ctx.loading && !_ctx.paragraphs)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_PageNotFound)
        ]))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_CookiePopup)
  ]))
}