import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InformationBox = _resolveComponent("InformationBox")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_swiper, {
      pagination: true,
      autoplay: _ctx.autoplay
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.boxes, (box, index) => {
          return (_openBlock(), _createBlock(_component_swiper_slide, { key: index }, {
            default: _withCtx(() => [
              _createVNode(_component_InformationBox, {
                box: box,
                "box-count": 1
              }, null, 8 /* PROPS */, ["box"])
            ]),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["autoplay"])
  ]))
}