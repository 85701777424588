
  import { defineComponent } from 'vue';
  import { mapGetters } from 'vuex';

  import DesktopNavigation from '@/global_components/navigation/desktop/DesktopNavigation.vue';
  import MobileNavigation from '@/global_components/navigation/mobile_tablet/MobileNavigation.vue';

  export default defineComponent({
    components: {
      DesktopNavigation,
      MobileNavigation,
    },
    data() {
      return {
        isDesktop: true,
      };
    },
    computed: {
      ...mapGetters('website', {
        websiteSettings: 'getWebsiteSettings',
      }),
    },
    created() {
      this.onResize();
      window.addEventListener('resize', this.onResize);
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.onResize);
    },
    methods: {
      onResize() {
        if (window.innerWidth > 1366) {
          this.isDesktop = true;
        } else {
          this.isDesktop = false;
        }
      },
    },
  });
