/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export default {
  async getDroppLocation() {
    let locationObject = {};
    // eslint-disable-next-line no-undef
    await chooseDroppLocation()
      .then((location) => {
        // window.localStorage.setItem("selectedDroppBox", JSON.stringify(location));
        locationObject = location;
      })
      .catch((error) => {
        console.log(error);
      });

    return locationObject;
  },
};
