import { useStore } from 'vuex';
import { CartState } from '@/models/store/cart';
import { formatAsPriceString } from '@/utils/stringHelper';
import { GiftCardInfo } from '@/models/cart/giftCardInfo';
import { computed } from 'vue';

export const useGiftCard = () => {
  const store = useStore();
  const state = store.state.cart as CartState;

  const giftCardNumber = computed(() => state.cart.General.GiftCardNumber);
  const giftCardAmount = computed(() => state.cart.General.GiftCardAmount);
  const orderAmountToBePaid = computed(() => state.cart.General.OrderAmountToBePaid);
  const giftCardAmountFormatted = computed(() => formatAsPriceString(giftCardAmount.value, state.cart.General.CurrencySymbol));
  const setGiftCardInfo = (payload: GiftCardInfo) => store.dispatch('cart/setGiftCardInfo', payload);

  return {
    giftCardNumber,
    giftCardAmount,
    giftCardAmountFormatted,
    orderAmountToBePaid,
    setGiftCardInfo,
  };
};
