interface Accordion {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toggleAccordion: (dataID: string) => void;
  resizeAccordion: (dataID: string) => void;
}

export default function useAccordion(): Accordion {
  const toggleAccordion = (dataID: string): void => {
    const accordionToggler = document.querySelector<HTMLElement>(`.accordionToggler[data-id='${dataID}']`) || ({} as HTMLElement);
    const accordionContent = document.querySelector<HTMLElement>(`.accordionContent[data-id='${dataID}']`) || ({} as HTMLElement);

    if (accordionContent.style.maxHeight || accordionToggler.classList.contains('startOpen')) {
      if (accordionToggler.classList.contains('startOpen')) {
        accordionToggler.classList.remove('startOpen');
        accordionContent.style.maxHeight = `${accordionContent.scrollHeight}px`;
      }
      accordionContent.style.maxHeight = '';
      // Close Accordion
      accordionToggler.classList.remove('open');
      accordionToggler.classList.add('closed');
      accordionContent.classList.remove('open');
    } else {
      accordionContent.style.maxHeight = `${accordionContent.scrollHeight}px`;
      // Open Accordion
      accordionToggler.classList.add('open');
      accordionToggler.classList.remove('closed');
      accordionContent.classList.add('open');
    }
  };

  const resizeAccordion = (dataID: string): void => {
    const accordionContent = document.querySelector<HTMLElement>(`.accordionContent[data-id='${dataID}']`) || ({} as HTMLElement);

    if (accordionContent?.classList?.contains('open')) {
      accordionContent.style.maxHeight = `${accordionContent.scrollHeight}px`;
    }
  };

  return {
    toggleAccordion,
    resizeAccordion,
  } as Accordion;
}
