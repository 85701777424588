
  /* eslint-disable @typescript-eslint/no-explicit-any */
  import { defineComponent } from 'vue';

  export default defineComponent({
    props: {
      title: {
        type: String || null,
        default: null,
      },
      label: {
        type: String || null,
        default: null,
      },
      text: {
        type: String || null,
        default: null,
      },
      image: {
        type: String || null,
        default: null,
      },
      video: {
        type: String || null,
        default: null,
      },
    },
  });
