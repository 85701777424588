import { createApp } from 'vue';
import AppS4S from '@/App.vue';
import AppBRP from '@/AppBRP.vue';
import router, { routes } from '@/router';
import store from '@/store';
import '@/sass/main.scss';
import FacebookPixel from '@/plugins/facebook-pixel/index';
import i18n from '@/i18n';
import config from '@/config';

let app;

// If area is shared, load s4s app
if (config.area === 'shared') {
  app = createApp(AppS4S);
  routes.map(r => ({ ...r, meta: { brp: false } })).forEach(router.addRoute);
} else {
  app = createApp(AppBRP);
  routes.map(r => ({ ...r, meta: { brp: true } })).forEach(router.addRoute);
}

app.use(router);
app.use(store);

app.use(i18n);

if (config.env === 'PROD') {
  app.use(FacebookPixel, { id: '500520933839822' });
}

app.mount('#app');
