import { computed, ComputedRef, ref, Ref } from 'vue';
import { Facet, FacetOption } from '@/models/product/facet';
import { createFilterParameterUrl } from '@/utils/requestHelper';

interface UseProductListFacets {
  filtersOpen: Ref<boolean>;
  toggleFiltersOpen(): void;

  selectedFacets: Ref<string[]>;
  showMoreSelected: Ref<string[]>;

  setSelectedFacets(facets: Facet[]): void;
  facetOptionValue(facet: Facet, opt: FacetOption): string;

  filterQuery: ComputedRef<string>;
}

export default function useProductListFacets(): UseProductListFacets {
  const filtersOpen = ref<boolean>(true);
  const toggleFiltersOpen = () => {
    filtersOpen.value = !filtersOpen.value;
  };

  const selectedFacets = ref<string[]>([]);
  const showMoreSelected = ref<string[]>([]);

  const facetOptionValue = (facet: Facet, opt: FacetOption): string => `${facet.QueryParameter}_${opt.Value}`;
  const setSelectedFacets = (facets: Facet[]) => {
    facets.forEach((facet) => {
      facet.Options.forEach((option) => {
        if (option.Selected) {
          selectedFacets.value.push(facetOptionValue(facet, option));
        }
      });
    });
  };

  const filterQuery = computed<string>(() => createFilterParameterUrl(selectedFacets.value));

  return {
    filtersOpen,
    toggleFiltersOpen,
    selectedFacets,
    showMoreSelected,
    facetOptionValue,
    setSelectedFacets,
    filterQuery,
  };
}
