/* eslint-disable prefer-destructuring */
/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-rest-params */
/* eslint-disable prefer-spread */
/* eslint-disable no-multi-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable no-unused-expressions */

export default {
  install: (app, options) => {
    const noscript = document.createElement('noscript');
    const pixel = document.createElement('img');
    pixel.setAttribute('height', 1);
    pixel.setAttribute('width', 1);
    pixel.setAttribute('src', `https://www.facebook.com/tr?id=${options.id}&ev=PageView&noscript=1`);
    noscript.appendChild(pixel);
    document.getElementsByTagName('head')[0].appendChild(noscript);

    !(function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

    window.fbq('init', options.id);
    window.fbq('track', 'PageView');

    app.config.globalProperties.$analytics = {
      ...app.config.globalProperties.$analytics,
      fbEvent: (event, opts) => window.fbq('track', event, opts),
    };
    app.provide('fbEvent', options);
  },
};
