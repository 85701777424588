/* eslint-disable no-shadow */
import useVuelidate, { Validation, ValidationArgs } from '@vuelidate/core';
import { keys } from 'lodash';
import { computed, ComputedRef, Ref } from 'vue';

interface FormValidation {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  v$: Ref<Validation<any, unknown>>;
  touch: (key: string) => void;
  formIsValid: ComputedRef<boolean>;
  hasErrors: (key: string) => boolean;
  getErrorMessages: (key: string) => string[];
  clearForm: () => void;
}
export default function useFormValidation(rules: ValidationArgs | Ref<ValidationArgs>, state: any): FormValidation {
  const v$ = useVuelidate(rules, state);
  const touch = (key: string) => v$.value[key].$touch();
  const formIsValid = computed(() => v$.value.$silentErrors.length === 0);
  const hasErrors = (key: string) => v$.value[key].$errors.length > 0;
  const getErrorMessages = (key: string) => v$.value[key].$errors.map((error) => error.$message as string);
  const clearForm = () => {
    keys(v$.value)
      .filter((key) => !key.includes('$'))
      .forEach((key) => {
        v$.value[key].$model = '';
      });
    v$.value.$reset();
  };
  return {
    v$,
    touch,
    formIsValid,
    hasErrors,
    getErrorMessages,
    clearForm,
  };
}
