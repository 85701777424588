
  import { defineComponent, PropType } from 'vue';
  import CustomButton from '@/components/itemTypes/base/Button.vue';
  import { Field } from '@/models/field';
  import useImageAndButtonBox from '@/composables/useImageAndButtonBox';

  export default defineComponent({
    components: {
      CustomButton,
    },
    props: {
      box: {
        type: Object as PropType<Field>,
        required: true,
      },
      boxCount: {
        type: Number,
        required: true,
      },
    },
    setup(props) {
      const { boxData, buttonData, getTitle, getStyles, boxImageUrl } = useImageAndButtonBox(props);

      return {
        boxData,
        buttonData,
        getTitle,
        getStyles,
        boxImageUrl,
      };
    },
  });
