import { Commit } from 'vuex';
import { cloneDeep } from 'lodash';
import { DeleteOrderLinePayLoad, EmptyCartPayload, SetOrAddMultiPayload } from '@/models/cart/cartRequestPayloadModels';
import { OrderLine } from '@/models/cart/orderLine';
import CartAPI from '@/API/cartApi';
import { Product as CartProduct } from '@/models/cart/product';
import { Product } from '@/models/product/product';
import { Variant } from '@/models/cart/variant';
import { CartState } from '@/models/store/cart';
import { isCartErrorResponse } from '@/utils/model_checkers/responses';
import { mapProductToInitialOrderLine } from '@/utils/dataMapper';
import { getOrderLinesToBeUpdated, orderLineHasBeenAddedAndIsPendingToBeUpdated, syncOtherOrderLineValues } from '@/store/modules/cart/helper';
import ProductAPI from '@/API/productApi';
import {
  addToCartWhenProductHasBeenAddedAndIsPendingToBeUpdated,
  addToCartWhenProductHasNotBeenAddedBefore,
  triggerItemAdded,
  updateCartStateAndPreserveToBeUpdated,
} from './delegates';
import { GA4E } from '@/ga4ecommerce/GA4E';

export default {
  async addToCart(actionParams: { commit: Commit; state: CartState }, product: Product): Promise<void> {
    const { state, commit } = actionParams;
    // TODO: Fix, cannot issue many add to cart commands in a row, they must wait for the previous one to finish or else there is a 500 error.
    // Queue them maybe
    // -- Natan: Or cancel and send new request, like how spamming filters is handled in product list.
    const productAsOrderline = mapProductToInitialOrderLine(product);
    commit('loading');
    triggerItemAdded(productAsOrderline, actionParams);
    if (orderLineHasBeenAddedAndIsPendingToBeUpdated(state, productAsOrderline)) {
      await addToCartWhenProductHasBeenAddedAndIsPendingToBeUpdated(product, actionParams);
    } else {
      await addToCartWhenProductHasNotBeenAddedBefore(product, actionParams);
    }
    commit('loadingDone');

    GA4E.addToCart(product);
  },

  async deleteOrderLine(actionParams: { commit: Commit; state: CartState }, orderLine: OrderLine): Promise<void> {
    const { commit, state } = actionParams;
    commit('loading');
    const payload = {
      data: {
        Step: 'CartV2.GotoStep0',
      },
      params: {
        cartcmd: 'DelOrderLine',
        OrderLineID: orderLine.OrderLineID,
      },
    } as DeleteOrderLinePayLoad;
    commit('deleteOrderLine', orderLine);
    const response = await CartAPI.deleteOrderLine(payload);
    if (isCartErrorResponse(response)) {
      // TODO: Commit error message info
      commit('updateCartState', state);
      console.error(`failed deleting orderline ${JSON.stringify(orderLine)} from cart`, response.data);
    } else {
      updateCartStateAndPreserveToBeUpdated(response.data, actionParams);
    }
    commit('loadingDone');

    GA4E.removeFromCart(orderLine);
  },

  updateOrderLineProduct({ commit }: { commit: Commit }, payload: { orderLine: OrderLine; product: CartProduct }): void {
    const { orderLine, product } = payload;
    let orderLineToBeUpdated = cloneDeep(orderLine);
    orderLineToBeUpdated.RelatedProducts = [
      ...orderLineToBeUpdated.RelatedProducts.filter((p: CartProduct) => p.ProductID !== product.ProductID),
      orderLineToBeUpdated.Product,
    ];
    orderLineToBeUpdated.Product = product;
    const variant = product.Variants.find((v: Variant) => v.Selected) as Variant;
    orderLineToBeUpdated.Quantity = orderLine.Quantity > variant.Stock ? variant.Stock : orderLine.Quantity;
    orderLineToBeUpdated.OrderLinePrice = orderLineToBeUpdated.Product.ProductPrice;
    orderLineToBeUpdated.OriginalOrderLinePrice = orderLineToBeUpdated.Product.ProductPrice;
    orderLineToBeUpdated = syncOtherOrderLineValues(orderLineToBeUpdated);
    commit('updateOrderLine', orderLineToBeUpdated);
  },

  updateOrderLineQuantity({ commit }: { commit: Commit }, payload: { orderLine: OrderLine; quantity: number }): void {
    const { orderLine, quantity } = payload;
    let orderLineToBeUpdated = cloneDeep(orderLine);
    orderLineToBeUpdated.Quantity = quantity;
    orderLineToBeUpdated = syncOtherOrderLineValues(orderLineToBeUpdated);
    commit('updateOrderLine', orderLineToBeUpdated);
  },

  updateOrderLineVariant({ commit }: { commit: Commit }, payload: { orderLine: OrderLine; variant: Variant }): void {
    const { orderLine, variant } = payload;
    let orderLineToBeUpdated = cloneDeep(orderLine);
    orderLineToBeUpdated.Product.Variants = [
      ...orderLineToBeUpdated.Product.Variants.map((v: Variant) => {
        let Selected = false;
        if (v.VariantID === variant.VariantID) {
          Selected = true;
        }
        return {
          ...v,
          Selected,
        };
      }),
    ];
    orderLineToBeUpdated.Quantity = orderLine.Quantity > variant.Stock ? variant.Stock : orderLine.Quantity;
    orderLineToBeUpdated = syncOtherOrderLineValues(orderLineToBeUpdated);
    commit('updateOrderLine', orderLineToBeUpdated);
  },

  async populateOrderLineProductColor({ commit }: { commit: Commit }, productId: string): Promise<void> {
    const response = await ProductAPI.getProductFields(productId);
    if (isCartErrorResponse(response)) {
      console.error(`failed calling populateOrderLineProductColor ${JSON.stringify(productId)} from cart`, response?.data);
    } else {
      commit('setOrderLineProductColor', response?.data);
    }
  },

  async recalculateCart(actionParams: { commit: Commit; state: CartState }): Promise<void> {
    const { commit, state } = actionParams;
    const payload = {
      data: {
        Step: 'CartV2.GotoStep0',
      },
      params: {
        cartcmd: 'SetMulti',
        items: getOrderLinesToBeUpdated(state).map((ol) => ({
          ProductID: ol.Product.ProductID,
          VariantID: ol.Product.Variants.find((v: Variant) => v.Selected)?.VariantID,
          Quantity: ol.Quantity,
        })),
      },
      couponCode: state.cart.General.CouponCode,
    } as SetOrAddMultiPayload;
    const response = await CartAPI.setOrAddMulti(payload);
    if (isCartErrorResponse(response)) {
      console.error(`failed calling set multi ${JSON.stringify(payload)} from cart`, response.data);
    } else {
      commit('updateCartState', response.data);
    }
  },

  async emptyCart({ commit }: { commit: Commit }): Promise<void> {
    const payload = {
      data: {
        Step: 'CartV2.GotoStep0',
      },
      params: {
        cartcmd: 'EmptyCart',
      },
    } as EmptyCartPayload;
    commit('emptyCart');
    // TODO: try catch and revert if call fails
    await CartAPI.emptyCart(payload);
  },
};
