
  import { defineComponent, PropType } from 'vue';
  import { RouteRecordRaw } from 'vue-router';

  export default defineComponent({
    props: {
      routes: {
        type: Object as PropType<RouteRecordRaw[]>,
        required: true,
      },
    },
    emits: ['onClickLink'],
    computed: {
      setStyles(): unknown {
        return {
          '--columns': this.$props.routes.length - 1,
        };
      },
    },
    methods: {
      onClickLink() {
        this.$emit('onClickLink');
      },
    },
  });
