
  import { defineComponent, onMounted, PropType } from 'vue';
  import { OrderLine } from '@/models/cart/orderLine';
  import NumberInput from '@/components/shared/inputs/NumberInput.vue';
  import { Product } from '@/models/cart/product';
  import { Variant } from '@/models/cart/variant';
  import useOrderLine from '@/composables/useOrderLine';
  import conf from '@/config';

  export default defineComponent({
    components: {
      NumberInput,
    },
    props: {
      orderLine: {
        type: Object as PropType<OrderLine>,
        required: true,
      },
      area: {
        type: String,
        required: false,
        default: null,
      },
    },
    emits: ['onClickDots'],
    setup(props, { emit }) {
      const {
        formattedOrderLinePrice,
        formattedProductPrice,
        orderLineHasDiscount,
        orderLineStyles,
        productImagePath,
        selectedQuantity,
        selectedColor,
        colorOptions,
        selectedVariant,
        variantOptions,
        updateOrderLineQuantity,
        updateOrderLineProduct,
        updateOrderLineVariant,
        deleteOrderLine,
        populateOrderLineProductColor,
      } = useOrderLine(props);

      const onViewDetails = () => {
        emit('onClickDots', props.orderLine);
      };

      const updateQuantity = (quantity: number) => {
        updateOrderLineQuantity({ orderLine: props.orderLine, quantity });
      };

      const updateColor = (option: { meta: { product: Product } }) => {
        const { product } = option.meta;
        if (props.orderLine.Product.ProductID !== product.ProductID) {
          updateOrderLineProduct({ orderLine: props.orderLine, product });
        }
      };

      const updateVariant = (option: { meta: { variant: Variant } }) => {
        const { variant } = option.meta;
        updateOrderLineVariant({ orderLine: props.orderLine, variant });
      };

      const deleteItem = () => {
        deleteOrderLine(props.orderLine);
      };

      onMounted(() => {
        populateOrderLineProductColor(props.orderLine.Product.ProductID);
      });

      return {
        formattedOrderLinePrice,
        formattedProductPrice,
        orderLineHasDiscount,
        orderLineStyles,
        productImagePath,
        selectedQuantity,
        selectedColor,
        colorOptions,
        selectedVariant,
        variantOptions,
        onViewDetails,
        updateQuantity,
        updateColor,
        updateVariant,
        deleteItem,
      };
    },
    data() {
      return {
        imagePrefix: conf.dwUrl,
      };
    },
    computed: {
      hasProductLink(): boolean {
        return this.area !== null;
      },
      productLink(): string {
        const lastChar = this.area?.charAt(this.area.length - 1);
        if (lastChar === '/') {
          return `${this.area}vara?ProductId=${this.orderLine.Product.ProductID}`;
        }
        return `${this.area}/vara?ProductId=${this.orderLine.Product.ProductID}`;
      },
    },
  });
