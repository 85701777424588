import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6cc9878d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "order" }
const _hoisted_2 = { class: "order__state" }
const _hoisted_3 = { class: "order__number" }
const _hoisted_4 = { class: "order__date" }
const _hoisted_5 = { class: "order__shipping" }
const _hoisted_6 = { class: "order__total" }
const _hoisted_7 = { class: "order__invoice" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserContentCard = _resolveComponent("UserContentCard")!

  return (_openBlock(), _createBlock(_component_UserContentCard, {
    underlined: !!_ctx.$slots.header
  }, _createSlots({
    default: _withCtx(() => [
      _createElementVNode("ul", _hoisted_1, [
        _createElementVNode("li", _hoisted_2, _toDisplayString(_ctx.order.OrderState.Name), 1 /* TEXT */),
        _createElementVNode("li", _hoisted_3, _toDisplayString(_ctx.order.Number), 1 /* TEXT */),
        _createElementVNode("li", _hoisted_4, _toDisplayString(_ctx.orderDate), 1 /* TEXT */),
        _createElementVNode("li", _hoisted_5, _toDisplayString(_ctx.order.ShippingMethod), 1 /* TEXT */),
        _createElementVNode("li", _hoisted_6, _toDisplayString(_ctx.orderTotal), 1 /* TEXT */),
        _createElementVNode("li", _hoisted_7, [
          _createElementVNode("a", {
            class: "invoice disabled",
            onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
          }, " Skoða reikning ")
        ])
      ])
    ]),
    _: 2 /* DYNAMIC */
  }, [
    (_ctx.$slots.header)
      ? {
          name: "header",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "header", {}, undefined, true)
          ])
        }
      : undefined
  ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["underlined"]))
}