import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0f42816e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "download-file" }
const _hoisted_2 = { class: "download-file__section flex flex--align-center" }
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "download-file__section" }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(["paragraph-container container", _ctx.getParagraphClasses]),
    style: _normalizeStyle(_ctx.getParagraphStyles)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("a", {
          class: "download-file__button",
          href: _ctx.paragraphData.fileToDownload[0].Path,
          download: ""
        }, [
          _createVNode(_component_Icon, {
            icon: "pdf",
            size: "60"
          }),
          _createElementVNode("span", null, _toDisplayString(_ctx.paragraphData.downloadButtonTitle), 1 /* TEXT */)
        ], 8 /* PROPS */, _hoisted_3)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: "download-file__description",
          innerHTML: _ctx.paragraphData.description
        }, null, 8 /* PROPS */, _hoisted_5)
      ])
    ])
  ], 6 /* CLASS, STYLE */))
}