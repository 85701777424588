
  import { computed, defineComponent, PropType } from 'vue';
  import { Field } from '@/models/field';
  import { cutStringIfNeeded } from '@/utils/stringHelper';
  import useBaseBoxComponent from '@/composables/useBaseBoxComponent';
  import { IconTitleAndText } from '@/models/item-types/base/boxes';
  import conf from '@/config';

  export default defineComponent({
    props: {
      box: {
        type: Object as PropType<Field>,
        required: true,
      },
      boxCount: {
        type: Number,
        required: true,
      },
    },
    setup(props) {
      const { data, getTitle, getStyles } = useBaseBoxComponent(props.box);
      const boxData = data.value as IconTitleAndText;
      const getIconPath = computed(() => `${conf.dwUrl}${boxData.icon.Path}`);
      return {
        boxData,
        getTitle,
        getStyles,
        getIconPath,
      };
    },
    methods: {
      cutSentenceIfNeeded(sentence: string, maxLength: number): string {
        return cutStringIfNeeded(sentence, maxLength);
      },
    },
  });
