/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Commit } from 'vuex';
import ProductAPI from '@/API/productApi';
import { NO_CONTENT } from '@/constants/requests';
import { Facet, FacetOption } from '@/models/product/facet';
import { ProductList } from '@/models/productList/productList';
import { ProductIsFavoritePayload, ProductListLiveDataPayload, ProductListPayload } from '@/models/productList/productListPayloadModels';
import { getOnlyActiveFacets } from '@/utils/facetsHelper';

interface ProductListState {
  productList: ProductList;
  subGroupFacetOptions: FacetOption[];
}

const initialState = {
  products: [] as any[],
  facets: [] as Facet[],
  productCount: 0,
  currentPage: 0,
  pageCount: 0,
  group: {
    Id: '',
  },
  liveDataLoaded: false,
} as ProductList;

const state = (): ProductListState => ({
  productList: initialState as any,
  subGroupFacetOptions: [] as FacetOption[],
});

const getters = {
  getProductList(state: ProductListState): any {
    return state.productList;
  },
  getSubGroupFacetOptions(state: ProductListState): FacetOption[] {
    return state.subGroupFacetOptions;
  },
};

const mutations = {
  setProductList(state: ProductListState, data: any): void {
    state.productList = {
      products: data.CurrentPage > 1 ? state.productList.products.concat(data.Products) : data.Products,
      facets: getOnlyActiveFacets(data.FacetGroups[0]?.Facets),
      productCount: data.TotalProductsCount,
      currentPage: data.CurrentPage,
      pageCount: data.PageCount,
      group: data.Group,
      routePageId: data.routePageId,
    } as ProductList;
  },
  setLiveData(state: ProductListState, data: any): void {
    state.productList.products = state.productList.products.map((product) => {
      const productWithLiveData = data.Products?.find((p) => p.Id === product.Id) || {};
      return {
        ...product,
        ...productWithLiveData,
      };
    });

    state.productList.liveDataLoaded = true;
  },
  toggleFacetOption(state: ProductListState, { facet, facetOption }): Facet[] {
    const foundFacet = state.productList.facets.find((item) => item.QueryParameter === facet.QueryParameter);
    const foundFacetOption = foundFacet?.Options.find((item) => item.Value === facetOption.Value);
    if (foundFacetOption !== undefined) {
      foundFacetOption.Selected = !foundFacetOption?.Selected;
    }

    return state.productList.facets;
  },
  toggleIsFavorite(state: ProductListState, productId): void {
    state.productList.products.forEach((product) => {
      if (product.Id === productId) {
        // eslint-disable-next-line no-param-reassign
        product.IsFavorite = !product.IsFavorite;
      }
    });
  },
  clearProductList(state: ProductListState): void {
    state.productList = initialState;
  },
  clearProducts(state: ProductListState, groupId: string): void {
    if (state.productList.group.Id !== groupId) {
      state.productList.facets = [] as Facet[];
    }
    state.productList.products = [];
    state.productList.productCount = 0;
    state.productList.currentPage = 1;
    state.productList.pageCount = 1;
    state.productList.group.Id = groupId;
  },
  removeIsFavoriteFromProducts(state: ProductListState, productIds: string[]) {
    state.productList.products = state.productList.products.map((product) => ({
      ...product,
      IsFavorite: productIds.includes(product.Id) ? false : product.IsFavorite,
    }));
  },
};

const actions = {
  async setProductList({ commit }: { commit: Commit }, payload: ProductListPayload): Promise<any> {
    const response = await ProductAPI.getProductList(payload);

    if (response?.status === NO_CONTENT) {
      commit('clearProducts', payload.params.groupID);
      return { cancelled: false };
    }
    if (Object.keys(response).length === 0) {
      return { cancelled: true };
    }

    response.data.Group = {
      Id: payload.params.groupID,
    };
    response.data.routePageId = payload.params.routePageId;
    commit('setProductList', response.data);
    return response.data;
  },
  async setLiveData({ commit }: { commit: Commit }, payload: ProductListLiveDataPayload): Promise<boolean> {
    const response = await ProductAPI.getProductListLiveData(payload);

    if (response?.status === NO_CONTENT) {
      return true;
    }

    if (response === undefined) {
      return false;
    }

    if (Object.keys(response).length === 0) {
      return false;
    }

    commit('setLiveData', response.data);
    return true;
  },
  async toggleFacetOption({ commit }: { commit: Commit }, { facet, facetOption }): Promise<void> {
    commit('toggleFacetOption', { facet, facetOption });
  },
  async toggleIsFavorite({ commit }: { commit: Commit }, payload: ProductIsFavoritePayload): Promise<boolean> {
    commit('toggleIsFavorite', payload.productId);
    return true;
  },
  async removeIsFavoriteFromProducts({ commit }: { commit: Commit }, productIds: string[]): Promise<boolean> {
    commit('removeIsFavoriteFromProducts', productIds);
    return true;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
