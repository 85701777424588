
  import { computed, defineComponent, PropType } from 'vue';
  import { SearchResultsProductsByShop } from '@/models/search/searchModels';
  import SearchResultsProductSection from './SearchResultsProductSection.vue';

  export default defineComponent({
    components: {
      SearchResultsProductSection,
    },
    props: {
      sections: {
        type: Object as PropType<SearchResultsProductsByShop>,
        required: false,
        default: () => ({}),
      },
    },
    setup(props) {
      const sectionCount = computed(() => Object.keys(props.sections).length);

      return { sectionCount };
    },
  });
