import { pick } from 'lodash';
import { Cart } from '@/models/cart/cart';
import { IProductIdAreaMap, OrderLine } from '@/models/cart/orderLine';
import { CartState, IProductIdAreaMapPayload, PartialUpdatePayload } from '@/models/store/cart';
import { combineOrderLines, mapOrderLinesToMultiWrapOrderLines, orderLinesHaveSameColorAndVariant } from '@/store/modules/cart/helper';
import initialCartState from '../initialState';

export default {
  updateCartState(state: CartState, cart: Cart): void {
    state.cart = cart;
    state.cartOverviewPageOptions.originalOrderLines = cart.OrderLines;
    state.giftWrapPage.multiWrapOrderLines = mapOrderLinesToMultiWrapOrderLines(cart.OrderLines);
  },
  deleteOrderLine(state: CartState, orderLine: OrderLine): void {
    state.cart = {
      ...state.cart,
      OrderLines: state.cart.OrderLines.filter((ol) => ol.OrderLineID !== orderLine.OrderLineID),
    };
  },
  updateOrderLine(state: CartState, orderLine: OrderLine): void {
    const existing = state.cart.OrderLines.find(
      (ol: OrderLine) => ol.OrderLineID !== orderLine.OrderLineID && orderLinesHaveSameColorAndVariant(ol, orderLine),
    );
    if (existing !== undefined) {
      const combined = combineOrderLines(existing, orderLine);
      state.cart.OrderLines = [
        ...state.cart.OrderLines.filter((ol: OrderLine) => ol.OrderLineID !== orderLine.OrderLineID && ol.OrderLineID !== combined.OrderLineID),
        combined,
      ];
    } else {
      state.cart.OrderLines = [...state.cart.OrderLines.filter((ol: OrderLine) => ol.OrderLineID !== orderLine.OrderLineID), orderLine];
    }
  },
  setOrderLineProductColor(state: CartState, product: any): void {
    if (product?.Id === undefined || state?.cart?.OrderLines === undefined) {
      return;
    }

    const orderLines = state.cart.OrderLines?.filter((ol: OrderLine) => ol.Product?.ProductID === product?.Id);

    if (orderLines !== undefined) {
      orderLines.forEach((orderLine) => {
        const updatedOrderLine = orderLine;
        const colorName = product?.ProductFields?.BaseColor?.Value[0]?.Name;
        const productColor = colorName?.split('#')[0]?.trim();
        updatedOrderLine.Product.Colors = productColor;
        state.cart.OrderLines = [...state.cart.OrderLines.filter((ol: OrderLine) => ol.OrderLineID !== updatedOrderLine.OrderLineID), updatedOrderLine];
      });
    }
  },
  emptyCart(state: CartState): void {
    state.cart = initialCartState;
    state.cartOverviewPageOptions.originalOrderLines = [];
  },
  setCouponCode: (state: CartState, payload: string): void => {
    state.cart.General.CouponCode = payload;
  },
  loading(state: CartState): void {
    state.loading = true;
  },
  loadingDone(state: CartState): void {
    state.loading = false;
  },
  partialCartUpdate(state: CartState, payload: PartialUpdatePayload): void {
    state.cart = { ...state.cart, ...pick(payload.cart, payload.fields) };
  },
  setProductIdArea(state: CartState, payload: IProductIdAreaMapPayload): void {
    state.productIdAreaMap = {
      ...state.productIdAreaMap,
      [payload.productId]: payload.area,
    } as IProductIdAreaMap;
  },
};
