import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-11dc31ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  id: "desktopNavigation",
  class: "navigation-desktop"
}
const _hoisted_2 = {
  key: 1,
  id: "mobileNavigation"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DesktopNavigation = _resolveComponent("DesktopNavigation")!
  const _component_MobileNavigation = _resolveComponent("MobileNavigation")!

  return (_ctx.isDesktop)
    ? (_openBlock(), _createElementBlock("nav", _hoisted_1, [
        _createVNode(_component_DesktopNavigation)
      ]))
    : (!_ctx.isDesktop)
      ? (_openBlock(), _createElementBlock("nav", _hoisted_2, [
          _createVNode(_component_MobileNavigation)
        ]))
      : _createCommentVNode("v-if", true)
}