
  import { defineComponent } from 'vue';
  import TextInput from '@/components/shared/inputs/TextInput.vue';
  import useCreditCard from '@/composables/paymentMethods/useCreditCard';

  export default defineComponent({
    components: {
      TextInput,
    },
    setup() {
      const { creditCardState } = useCreditCard();

      return {
        creditCardState,
      };
    },
  });
