
  import { defineComponent } from 'vue';
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import 'swiper/components/navigation/navigation.scss';
  import 'swiper/components/pagination/pagination.scss';
  import Loader from '@/components/shared/Loader.vue';
  import useParagraph from '@/composables/useParagraph';
  import { ProductListLiveDataPayload, ProductListPayload } from '@/models/productList/productListPayloadModels';
  import { ProductList } from '@/models/productList/productList';
  import ProductCard from '@/components/products/ProductCard.vue';
  import useWebsiteSettings from '@/composables/useWebsiteSettings';

  export default defineComponent({
    components: {
      Swiper,
      SwiperSlide,
      Loader,
      ProductCard,
    },
    props: {
      componentProps: {
        type: Object,
        default: null,
      },
    },
    setup(props) {
      const { getParagraphStyles, getParagraphClasses } = useParagraph(props.componentProps);

      const { websiteSettings } = useWebsiteSettings();

      return {
        getParagraphStyles,
        getParagraphClasses,
        websiteSettings,
      };
    },
    data() {
      return {
        // General
        initialLoad: true,
        GroupID: this.componentProps.groupId,
        ShowOnlyOffers: this.componentProps.showOnlyOffers,
        BrandName: this.componentProps.brandName,
        ProductIds: this.componentProps.featuredProduct.map((item) => item.Id).join(','),
        title: this.componentProps.name,
        buttonText: this.componentProps.featuredButtonText,
        buttonUrl: this.convertIcelandicLettersInUrl(this.componentProps.featuredProductListLink.Url),

        // Product
        productCount: 0,
        pageSize: 12,
        currentPage: 1,
        pageCount: 1,

        // Swiper
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        swiperOption: {
          breakpoints: {
            320: {
              slidesPerView: 1,
              spaceBetween: 10,
              pagination: {
                el: '.swiper-pagination',
              },
              navigation: true,
            },
            480: {
              slidesPerView: 2,
              spaceBetween: 10,
              navigation: true,
            },
            998: {
              slidesPerView: 3,
              spaceBetween: 24,
              pagination: false,
              navigation: true,
            },
            1280: {
              slidesPerView: 4,
              spaceBetween: 24,
              pagination: false,
              navigation: false,
            },
          },
        },
        thumbsOption: {
          breakpoints: {
            320: {
              direction: 'horizontal',
            },
            780: {
              direction: 'vertical',
            },
          },
        },
      };
    },
    computed: {
      productList(): ProductList {
        return this.$store.getters['productList/getProductList'] as ProductList;
      },
    },
    mounted() {
      // this.setQueryParameters();
      this.initializePage();
    },
    methods: {
      async initializePage() {
        const cacheProductList = this.productList;

        const useCache =
          cacheProductList.group.Id === this.GroupID &&
          cacheProductList.products.length > 0 &&
          this.GroupID !== '' &&
          Object.keys(this.$route.query).length === 0;

        if (useCache) {
          this.initialLoad = false;
        } else {
          const params = new URLSearchParams(this.$route.query as Record<string, string>);
          await this.getProductList(this.pageSize, this.currentPage, `&${params.toString()}`);
        }
      },
      async getProductList(paraPageSize, paraPageNumber, parafilterQuery = ''): Promise<unknown> {
        const payload = {
          params: {
            repositoryName: this.websiteSettings.repositoryName,
            queryName: this.websiteSettings.queryName,
            facetName: this.websiteSettings.facetName,
            groupID: this.GroupID,
            showOnlyOffers: this.ShowOnlyOffers,
            brandName: this.BrandName,
            filterQuery: parafilterQuery,
            pageNumber: paraPageNumber,
            pageSize: paraPageSize,
            productIds: this.ProductIds,
          },
        } as ProductListPayload;

        return this.$store
          .dispatch('productList/setProductList', payload)
          .then((data) => {
            if (data?.cancelled === undefined || data.cancelled !== true) {
              this.getLiveData(data);
            }
          })
          .finally(() => {
            this.initialLoad = false;
          });
      },
      getLiveData(data: any) {
        if (data?.Products?.length > 0) {
          const productIds = data.Products?.map((a) => a.Id)?.join(',');
          const liveDataPayload = {
            productIds,
          } as ProductListLiveDataPayload;
          this.$store.dispatch('productList/setLiveData', liveDataPayload);
        }
      },
      convertIcelandicLettersInUrl(url: string): string {
        return url.replaceAll('ð', 'd').replaceAll('Ð', 'D');
      },
    },
  });
