import { sortBy, get } from 'lodash';
import { CartState, MultiWrapOrderLine } from '@/models/store/cart';
import * as GETTER from '@/constants/store/checkout/getters';
import { Product } from '@/models/product/product';
import { OrderLine } from '@/models/cart/orderLine';
import { getOrderLinesToBeUpdated } from './helper';
import { ShippingMethod } from '@/models/cart/shippingMethod';

export default {
  [GETTER.GET_CART_LOADING](state: CartState): boolean {
    return state.loading;
  },

  [GETTER.TOTAL_CART_QUANTITY](state: CartState): number {
    return state.cart.OrderLines.reduce((acc, curr) => {
      // eslint-disable-next-line no-param-reassign
      acc += curr.Quantity;
      return acc;
    }, 0);
  },

  [GETTER.GET_TOTAL_AMOUNT_TO_BE_PAID](state: CartState): number {
    return state.cart.OrderLines.reduce((acc, curr) => {
      // eslint-disable-next-line no-param-reassign
      acc += curr.OrderLinePrice;
      return acc;
    }, 0);
  },

  [GETTER.THERE_ARE_ORDERLINES_TO_BE_UPDATED](state: CartState): boolean {
    return getOrderLinesToBeUpdated(state).length > 0;
  },

  [GETTER.GET_MULTI_WRAP_ORDER_LINES](state: CartState): MultiWrapOrderLine[] {
    return sortBy(state.giftWrapPage.multiWrapOrderLines, (ol: MultiWrapOrderLine) => ol.MultiWrapOrderLineID);
  },
  [GETTER.GIFT_WRAP_PRODUCT]({ giftWrapProduct }: CartState): Product | null {
    if (!giftWrapProduct) {
      return null;
    }

    return {
      ProductID: giftWrapProduct.Id,
      VariantID: '',
      Manufacturer: '',
      ProductName: giftWrapProduct.Name, // giftWrapProduct?.Name,
      Price: get(giftWrapProduct, 'Price.PriceWithVat', 0),
      OriginalPrice: get(giftWrapProduct, 'PriceBeforeDiscount.PriceWithVat', 0),
      CurrencySymbol: 'Kr',
      ImagePath: get(giftWrapProduct, 'ImagePatternImages.0.Value', ''),
      Webshops: '',
      BaseColor: '',
      Quantity: 1,
    };
  },
  [GETTER.GIFT_WRAP_ORDER_LINE]({ cart }: CartState): OrderLine | undefined {
    return cart.OrderLines.find((ol) => get(ol, 'Product.ProductID') === 'GJAFAINNPÖKKUN');
  },
  [GETTER.GET_SELECTED_SHIPPING_METHOD]({ cart }: CartState): ShippingMethod | null {
    return cart.ShippingMethods.find(({ DWGeneratedShippingMethodID }) => DWGeneratedShippingMethodID === cart.selectedShippingMethod) || null;
  },
};
