import state from './authState';
import actions from './authActions';
import getters from './authGetters';
import mutations from './authMutations';

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
