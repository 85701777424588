import { differenceBy } from 'lodash';
import { Commit } from 'vuex';
import { Cart } from '@/models/cart/cart';
import { AddToCartPayload, SetOrAddMultiPayload } from '@/models/cart/cartRequestPayloadModels';
import { OrderLine } from '@/models/cart/orderLine';
import { Product } from '@/models/product/product';
import { CartState } from '@/models/store/cart';
import CartAPI from '@/API/cartApi';
import { isCartErrorResponse } from '@/utils/model_checkers/responses';
import { Variant } from '@/models/cart/variant';
import { mapProductToInitialOrderLine } from '@/utils/dataMapper';
import { getOrderLinesToBeUpdated, mapOrderLineToSetAddMultiPayloadItem } from '@/store/modules/cart/helper';

export function triggerItemAdded(orderLine: OrderLine, actionParams: { commit: Commit; state: CartState }): void {
  const { commit } = actionParams;
  commit('itemAdded', orderLine);
  setTimeout(() => {
    commit('itemAddedDone', orderLine);
  }, 3000);
}

export function updateCartStateAndPreserveToBeUpdated(cart: Cart, actionParams: { commit: Commit; state: CartState }): void {
  const { commit, state } = actionParams;
  const preservedCart = { ...cart };
  const toBeUpdated = getOrderLinesToBeUpdated(state).filter((ol: OrderLine) => ol.Quantity !== 0);
  preservedCart.OrderLines = [...differenceBy(preservedCart.OrderLines, toBeUpdated, (ol: OrderLine) => ol.OrderLineID), ...toBeUpdated];
  commit('updateCartState', preservedCart);
}

export async function addToCartWhenProductHasNotBeenAddedBefore(product: Product, actionParams: { commit: Commit; state: CartState }): Promise<void> {
  const payload = {
    params: {
      Product: product,
      Quantity: product.Quantity,
      cartcmd: 'add',
    },
    data: {
      Step: 'CartV2.GotoStep0',
    },
  } as AddToCartPayload;
  const response = await CartAPI.addToCart(payload);
  if (isCartErrorResponse(response)) {
    // Todo: commit error message
    console.error(`failed adding product ${JSON.stringify(product)} to cart`, response.data);
  } else {
    updateCartStateAndPreserveToBeUpdated(response.data, actionParams);
  }
}

export async function addToCartWhenProductHasBeenAddedAndIsPendingToBeUpdated(
  product: Product,
  actionParams: { commit: Commit; state: CartState },
): Promise<void> {
  const productAsOrderline = mapProductToInitialOrderLine(product);
  const { commit, state } = actionParams;
  const payload = {
    data: {
      Step: 'CartV2.GotoStep0',
    },
    params: {
      cartcmd: 'SetMulti',
      items: [
        ...getOrderLinesToBeUpdated(state)
          .filter((ol: OrderLine) => ol.Quantity !== 0)
          .map(mapOrderLineToSetAddMultiPayloadItem),
        {
          ProductID: productAsOrderline.Product.ProductID,
          VariantID: productAsOrderline.Product.Variants.find((v: Variant) => v.Selected)?.VariantID,
          Quantity: productAsOrderline.Quantity,
        },
      ],
    },
  } as SetOrAddMultiPayload;
  const response = await CartAPI.setOrAddMulti(payload);
  if (isCartErrorResponse(response)) {
    // Todo: commit error message
    console.error(`failed adding product ${JSON.stringify(product)} to cart`, response.data);
  } else {
    commit('updateCartState', response.data);
  }
}
