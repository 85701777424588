
  import { computed, defineComponent, provide, reactive, ref, watchEffect } from 'vue';
  import { email, helpers, required } from '@vuelidate/validators';
  import { ValidationArgs } from '@vuelidate/core';
  import { difference, every, keys, keysIn, pickBy, reduce, values, valuesIn } from 'lodash';
  import format from 'date-fns/format';
  import addDays from 'date-fns/addDays';
  import WorkshopRegistrationForm from './WorkshopRegistrationForm.vue';
  import * as CONST from './constants';
  import SimplyBookMeapi from '@/API/simplyBookMeApi';
  import { AdditionalField, Booking, BookingRequest, Inputs, AdditionalFieldRequest } from './models';
  import Loader from '@/components/shared/Loader.vue';

  const DEFAULT_TIME_SLOT = {
    [CONST.TIME_SLOT['10_TO_13']]: true,
    [CONST.TIME_SLOT['13_TO_16']]: true,
  };

  export default defineComponent({
    components: {
      WorkshopRegistrationForm,
      Loader,
    },
    setup() {
      const status = ref(CONST.STATUS.LOADING);
      const loading = computed(() => status.value === CONST.STATUS.LOADING);
      const error = computed(() => status.value === CONST.STATUS.ERROR);
      const success = computed(() => status.value === CONST.STATUS.POST_SUCCESS);

      const availableTimeSlots = ref(DEFAULT_TIME_SLOT);

      const inputs = reactive({
        date: '',
        time: '',
        name: '',
        email: '',
        phone: '',
        comment: '',
        newsletter: false,
      } as Inputs);

      const rules = {
        date: { required: helpers.withMessage('Dagsetning má ekki vera tóm', required) },
        time: { required: helpers.withMessage('Nauðsynlegt er að velja annan hvorn tímarammann', required) },
        name: { required: helpers.withMessage('Nafn má ekki vera tómt', required) },
        email: { required: helpers.withMessage('Netfang má ekki vera tómt', required), email: helpers.withMessage('Netfang ekki gilt', email) },
        phone: { required: helpers.withMessage('Símanúmer má ekki vera tómt', required) },
        comment: {},
        newsletter: {},
      } as ValidationArgs;
      provide('inputs', inputs);
      provide('validations', rules);

      const additionalFields = ref([] as AdditionalField[]);
      const additionalFieldsFromInputs = computed(() => pickBy(inputs, (_, key) => !['time', 'date'].includes(key)));
      const additionalFieldsForRequest = computed(() =>
        reduce(
          additionalFields.value,
          (acc, curr: AdditionalField) => {
            acc.push({ field: curr.name, value: inputs[curr.field_name].toString() });
            return acc;
          },
          [] as AdditionalFieldRequest[],
        ),
      );
      const additionalFieldsAreValid = computed(() => {
        if (additionalFields.value.length === 0) return false;
        const additionalFieldNamesFromInput = keysIn(additionalFieldsFromInputs.value);
        const additionalFieldNamesFromApi = values(additionalFields.value.map((field: AdditionalField) => field.field_name));
        return difference(additionalFieldNamesFromApi, additionalFieldNamesFromInput).length === 0;
      });

      const bookings = ref([] as Booking[]);
      const timeByDateBookingStatus = computed(() => {
        const value = reduce(
          bookings.value,
          (acc: any, curr: Booking) => {
            const date = curr.start_datetime.split(' ')[0] ?? '';
            if (date === '') return acc;
            if (!acc[date]) {
              acc[date] = {
                [CONST.TIME_SLOT['10_TO_13']]: true,
                [CONST.TIME_SLOT['13_TO_16']]: true,
              };
            }
            const startTime = curr.start_datetime.split(' ')[1].slice(0, -3);
            const endTime = curr.end_datetime.split(' ')[1].slice(0, -3);
            const currentTimeSlot = `${startTime}-${endTime}`;
            if (currentTimeSlot === CONST.TIME_SLOT['10_TO_13']) {
              acc[date][CONST.TIME_SLOT['10_TO_13']] = false;
            } else {
              acc[date][CONST.TIME_SLOT['13_TO_16']] = false;
            }
            return acc;
          },
          {},
        );
        return value;
      });

      const unavailableDates = computed(() => keys(pickBy(timeByDateBookingStatus.value, (slot) => every(valuesIn(slot), (v) => !v))).map((k) => new Date(k)));
      const timeByDateIsUnavailable = (time: string, date: string) => !timeByDateBookingStatus.value[date][time];

      watchEffect(() => {
        if (inputs.date !== '') {
          const selectedDate = format(new Date(inputs.date), 'y-MM-dd');
          if (timeByDateBookingStatus.value[selectedDate]) {
            availableTimeSlots.value = timeByDateBookingStatus.value[selectedDate];
            if (timeByDateIsUnavailable(inputs.time, selectedDate)) {
              inputs.time = '';
            }
          } else {
            availableTimeSlots.value = DEFAULT_TIME_SLOT;
          }
        }
      });

      const getTimeString = (index: number) => {
        if (inputs.time && inputs.date) {
          const time = inputs.time.split('-')[index] ?? '';
          const startDate = new Date(inputs.date);
          startDate.setHours(Number.parseInt(time, 10));
          return format(startDate, 'y-MM-dd H:00');
        }
        return '';
      };
      const startTime = computed(() => getTimeString(0));
      const endTime = computed(() => getTimeString(1));

      const maxDate = computed(() => addDays(new Date(), 60));
      provide('maxDate', maxDate.value);

      return {
        status,
        loading,
        error,
        success,
        availableTimeSlots,
        inputs,
        additionalFields,
        additionalFieldsForRequest,
        additionalFieldsAreValid,
        bookings,
        timeByDateBookingStatus,
        unavailableDates,
        startTime,
        endTime,
        maxDate,
      };
    },
    async created() {
      this.status = CONST.STATUS.LOADING;
      try {
        await this.getAdditionalFields();
        await this.getbookings();
        if (this.additionalFieldsAreValid) {
          this.status = CONST.STATUS.LOADING_SUCCESS;
        } else {
          this.status = CONST.STATUS.ERROR;
        }
      } catch (error) {
        this.status = CONST.STATUS.ERROR;
      }
    },
    methods: {
      async submitForm() {
        if (this.inputs.newsletter) {
          // sign up for newsletter with Mailchimp
        }
        const request = this.createRequest();
        try {
          await SimplyBookMeapi.createBooking(request);
          this.status = CONST.STATUS.POST_SUCCESS;
        } catch (error) {
          this.status = CONST.STATUS.ERROR;
        }
      },
      async getAdditionalFields(): Promise<void> {
        const additionalFields: AdditionalField[] = await SimplyBookMeapi.getAdditionalFields();
        this.additionalFields = additionalFields;
      },
      async getbookings(): Promise<void> {
        const startDate = format(new Date(), 'y-MM-dd');
        const endDate = format(this.maxDate, 'y-MM-dd');
        const query = `?filter=[services]=${process.env.VUE_APP_SIMPLYBOOKME_SERVICE_ID}&filter[status]=confirmed&filter[start_date]=${startDate}&filter[end_date]=${endDate}&on_page=120`;
        const bookings: Booking[] = await SimplyBookMeapi.getBookings(query);
        this.bookings = bookings;
      },

      createRequest(): BookingRequest {
        const request: BookingRequest = {
          service_id: process.env.VUE_APP_SIMPLYBOOKME_SERVICE_ID ?? '',
          provider_id: process.env.VUE_APP_SIMPLYBOOKME_PROVIDER_ID ?? '',
          count: 1,
          start_datetime: this.startTime,
          end_datetime: this.endTime,
          additional_fields: this.additionalFieldsForRequest,
        };
        return request;
      },
    },
  });
