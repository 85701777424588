import config from '@/config';
import { OrderLine } from '@/models/cart/orderLine';

export interface SideInfoItem {
  title: string;
  description: string;
  order: number;
  subtitle?: string;
  isOutlet?: boolean;
  iconXLink: string;
}

export function getSideInfoItems(orderLines: OrderLine[]) {
  const isShared = config.area === 'shared';

  let sideInfoItems: SideInfoItem[] = [
    {
      title: 'GREIÐSLUMÁTAR',
      description: 'Boðið er upp á að greiða með greiðslukortum frá Visa, Eurocard/Mastercard eða Amex og Netgíró',
      order: 4,
      iconXLink: '#icon-wallet',
    },
  ];

  const refund: SideInfoItem = {
    title: '14 DAGA ENDURGREIÐSLU FRESTUR',
    description: 'Varan skal vera ónotuð, henni sé skilað í góðu lagi, í upprunalegum umbúðum og að greiðslukvittun fylgi með.',
    order: 1,
    iconXLink: '#icon-clock',
  };

  const noRefund: SideInfoItem = {
    title: '14 DAGA SKIPTIFRESTUR',
    description: 'Ekki er hægt að skila Outlet vöru en hægt er að skipta vörunni í S4S Premium Outlet.',
    order: 300,
    isOutlet: true,
    iconXLink: '#icon-clock',
  };

  const sharedDelivery: SideInfoItem = {
    title: 'FRÍ HEIMSENDING',
    description:
      'Frí sending um allt land fyrir pantanir yfir 18.000 kr. Gildir ekki fyrir ferðatæki, fánastangir og aukahluti þeim tengdum. Afhendingartími miðast við 0-1 daga frá pöntun.',
    order: 3,
    iconXLink: '#icon-truck',
  };

  const brpDelivery: SideInfoItem = {
    title: 'HEIMSENDING',
    description: 'Burðargjöld greiðist á pósthúsi / póstboxi.',
    order: 100,
    iconXLink: '#icon-truck',
  };

  if (orderLines?.length) {
    orderLines.forEach((ol) => {
      const isOutlet = ol.Webshops.toLowerCase().includes('outlet');
      if (isOutlet) {
        refund.subtitle = 'Á ekki við um Outlet vörur';
        refund.order = 200;
      }

      sideInfoItems.push(isOutlet ? noRefund : refund);
    });

    sideInfoItems = Array.from(new Set(sideInfoItems));
  } else {
    sideInfoItems.push(refund);
  }

  sideInfoItems.push(isShared ? sharedDelivery : brpDelivery);
  sideInfoItems.sort((a, b) => a.order - b.order);

  return sideInfoItems;
}
