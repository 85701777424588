import RelatedGroupsApi, { RelatedGroupAPI, RelatedGroupRaw, RelatedGroupResponse } from '@/API/relatedGroupsApi';
import ProductAPI, { ProductsRelatedGroupsResponse } from '@/API/productApi';
import { GroupRequest } from '@/models/group/group';
import useLiveProductPrices, { LiveProductPrices } from '@/composables/useLiveProductPrices';
import { Commit } from 'vuex';
import { ProductRelatedGroup, RelatedGroup, RelatedGroups, RelatedGroupsState } from '@/models/relatedGroup/relatedGroup';
import { ref } from 'vue';

const { getLivePricesForProducts } = useLiveProductPrices();

const state = (): RelatedGroupsState => ({
  relatedProducts: [],
});

export interface relationGroupPayload {
  productID: string;
  whatAboutTheseProducts?: string[];
  shopID?: string;
}
function removeVariants(input: RelatedGroupResponse): RelatedGroupRaw {
  const cleanedResponse = ref<RelatedGroupRaw>({
    RelatedGroups: [],
  });

  input.data.RelatedGroups.forEach((group: RelatedGroupAPI) => {
    if (group.Products) {
      const productsWithEmptyVariantId = group.Products.filter((product) => product.VariantId === '');

      if (productsWithEmptyVariantId.length > 0) {
        cleanedResponse.value.RelatedGroups.push({
          ...group,
          Products: productsWithEmptyVariantId,
        });
      }
    }
  });

  return cleanedResponse.value;
}
const actions = {
  async relationGroups({ commit }: { commit: Commit }, payload: relationGroupPayload): Promise<RelatedGroups | undefined> {
    try {
      const shopID = payload.shopID || 'SHOP1';
      const params: GroupRequest = createGroupRequest(shopID);

      const relatedGroupResponse: RelatedGroupResponse = await fetchRelatedGroups(payload.productID, params);

      if (!hasTengdarVorurGroup(relatedGroupResponse) && payload.whatAboutTheseProducts && payload.whatAboutTheseProducts.length > 0) {
        const fallbackGroupResponse : RelatedGroupAPI | undefined = await handleMissingTengdarVorurGroup(payload.whatAboutTheseProducts);
        if (fallbackGroupResponse) {
          relatedGroupResponse.data.RelatedGroups.push(fallbackGroupResponse);
        }
      }

      const cleanedRelatedGroupResponse = cleanRelatedGroupResponse(relatedGroupResponse);
      const productIds = extractProductIds(cleanedRelatedGroupResponse.RelatedGroups);

      if (productIds.length > 0) {
        const products = await fetchProductsAndPrices(productIds, shopID);
        const result = createRelatedGroupProducts(payload.productID, products.productsData, cleanedRelatedGroupResponse.RelatedGroups, products.livePrices);
        commit('setRelatedProduct', result);
        return result;
      }

      return undefined;
    } catch (error) {
      console.error('Error fetching related groups:', error);
      throw error;
    }
  },
};

async function fetchRelatedGroups(productID: string, params: GroupRequest): Promise<RelatedGroupResponse> {
  return await RelatedGroupsApi.getRelatedGroups(productID, params);
}

function createGroupRequest(shopID: string): GroupRequest {
  return {
    ShopId: shopID,
    CountryCode: 'IS',
    CurrencyCode: 'ISK',
    FilledProperties: 'RelatedGroups',
  };
}

function hasTengdarVorurGroup(response: RelatedGroupResponse): boolean {
  return response.data.RelatedGroups.some((item) => item.Name === 'Tengdar vörur');
}

async function handleMissingTengdarVorurGroup(whatAboutTheseProducts: string[]): Promise<RelatedGroupAPI | undefined> {

    const response: ProductsRelatedGroupsResponse = await ProductAPI.getProductsById(whatAboutTheseProducts, 'SHOP1');
    if (response.data) {
      return {
        Id: 'WHATABOUTTHESEPRODUCT',
        Name: 'Tengdar vörur',
        Products: response.data.Products.map(({ Id, VariantId, Price }: ProductRelatedGroup) => ({
          ProductId: Id,
          VariantId,
          Price,
        })),
      } as RelatedGroupAPI;
    }
}

function cleanRelatedGroupResponse(response: RelatedGroupResponse): RelatedGroupRaw {
  return removeVariants(response);
}

async function fetchProductsAndPrices(productIds: string[], shopID: string) {
  const productsResponse = await ProductAPI.getProductsById(productIds, shopID);
  const livePrices = await getLivePricesForProducts(productIds);

  return {
    productsData: productsResponse.data.Products,
    livePrices,
  };
}
function extractProductIds(relatedGroup: RelatedGroupAPI[]): string[] {
  const productIds: string[] = [];
  if (Array.isArray(relatedGroup)) {
    relatedGroup.forEach((group: RelatedGroupAPI) => {
      if (Array.isArray(group.Products)) {
        group.Products.forEach((product) => {
          productIds.push(product.ProductId);
        });
      }
    });
  }
  return productIds;
}

function createRelatedGroupProducts(
  productId: string,
  productResponse: ProductRelatedGroup[],
  relatedGroupsResponse: RelatedGroupAPI[],
  livePrices: LiveProductPrices,
): RelatedGroups {
  const products = Array.isArray(productResponse) ? productResponse : [];

  // Map products back to their respective groups with live prices
  const relatedGroups = relatedGroupsResponse.map((group) => {
    const enrichedProducts: ProductRelatedGroup[] = products
      .filter((product) => group.Products?.some((p) => p.ProductId === product.Id))
      .map((product) => {
        const livePrice = livePrices[product.Id];
        return {
          ...product,
          Price: livePrice?.Price,
          PriceBeforeDiscount: livePrice?.PriceBeforeDiscount,
        };
      });

    return {
      GroupID: group.Id,
      Name: group.Name,
      Products: enrichedProducts,
    };
  });

  const result: RelatedGroups = {
    productID: productId,
    relatedProducts: relatedGroups,
  };
  return result;
}

const mutations = {
  setRelatedProduct(state: RelatedGroupsState, relatedData: RelatedGroups): void {
    const existingIndex = state.relatedProducts.findIndex((rp) => rp.productID === relatedData.productID);
    if (existingIndex !== -1) {
      state.relatedProducts[existingIndex] = relatedData;
    } else {
      state.relatedProducts.push(relatedData);
    }
  },
};

const getters = {
  getRelatedGroups(state: RelatedGroupsState): RelatedGroups[] {
    return state.relatedProducts;
  },

  getRelatedGroupByName:
    (state: RelatedGroupsState) =>
    (productId: string, groupName: string): RelatedGroup | undefined => {
      for (const relatedGroup of state.relatedProducts) {
        if (relatedGroup.productID === productId) {
          const foundGroup = relatedGroup.relatedProducts.find((group) => group.Name === groupName);
          if (foundGroup) {
            return foundGroup;
          }
        }
      }
      return undefined;
    },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
