import axios from 'axios';
import { Postcode } from '@/models/cart/postcode';
import conf from '@/config';

class PosturinnAPI {
  static getApiKey(): string {
    return conf.posturinnKey;
  }

  static getUrl(): string {
    return conf.posturinnUrl;
  }

  static getAuthenticationHeaders(): { 'x-api-key': string } {
    return {
      'x-api-key': this.getApiKey(),
    };
  }

  static async getPostCodes(): Promise<Postcode[]> {
    let response;
    try {
      const options = {
        headers: {
          ...this.getAuthenticationHeaders(),
        },
      };
      response = await axios.get(`${this.getUrl()}v1/postcodes`, options);
      return response.data.postcodes;
    } catch (e) {
      console.error(e);
    }
    return [];
  }
}

export default PosturinnAPI;
