
  import * as Cookie from 'tiny-cookie';
  import { defineComponent } from 'vue';
  import { mapGetters } from 'vuex';

  import CustomButton from '@/components/shared/Button.vue';

  export default defineComponent({
    components: {
      CustomButton,
    },
    data() {
      return {
        cookieName: 'Dynamicweb.CookieOptInLevel',
        cookieContent: '2',
        accepted: false,
      };
    },
    computed: {
      ...mapGetters('website', {
        websiteSettings: 'getWebsiteSettings',
      }),
      getOtherLinksColumnCount(): number {
        return 3;
      },
    },
    mounted() {
      this.accepted = this.isAccepted();
    },
    methods: {
      setAccepted() {
        Cookie.set(this.cookieName, this.cookieContent, { expires: '1Y' });
        this.accepted = this.isAccepted();
      },
      isAccepted(): boolean {
        let accepted = false;
        const cookie = Cookie.get(this.cookieName);

        if (typeof cookie === 'string') {
          accepted = cookie === this.cookieContent;
        }

        return accepted;
      },
    },
  });
