
  import { defineComponent } from 'vue';

  import TextInput from '@/components/shared/inputs/TextInput.vue';
  import useCart from '@/composables/useCart';
  import CartSideOverviewOrderLine from './CartSideOverviewOrderLine.vue';
  import { useGiftCard } from '@/features/giftCard/useGiftCard';

  export default defineComponent({
    components: {
      CartSideOverviewOrderLine,
      TextInput,
    },
    setup() {
      const { formattedTotalAmountToBePaid, totalCalculatedFormatted, totalQuantity, orderLines, getCouponCode, shippingFeeFormatted, hasShippingFee } =
        useCart();

      const { giftCardAmount, giftCardAmountFormatted } = useGiftCard();

      return {
        formattedTotalAmountToBePaid,
        totalCalculatedFormatted,
        totalQuantity,
        orderLines,
        getCouponCode,
        giftCardAmount,
        giftCardAmountFormatted,
        shippingFeeFormatted,
        hasShippingFee,
      };
    },
  });
