import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fd596fea"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "name", "type", "value", "checked", "disabled"]
const _hoisted_2 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.getStyles),
    class: _normalizeClass(["checkboxInput_Container", { disabled: _ctx.disabled }])
  }, [
    _createElementVNode("input", {
      id: _ctx.id,
      name: _ctx.name,
      type: _ctx.type,
      value: _ctx.value,
      checked: _ctx.checked,
      class: _normalizeClass({ 'has-error': _ctx.error }),
      disabled: _ctx.disabled,
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
    }, null, 10 /* CLASS, PROPS */, _hoisted_1),
    _createElementVNode("label", {
      class: _normalizeClass({ 'has-error': _ctx.error }),
      for: _ctx.id
    }, [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createTextVNode(_toDisplayString(_ctx.label), 1 /* TEXT */)
      ], true)
    ], 10 /* CLASS, PROPS */, _hoisted_2)
  ], 6 /* CLASS, STYLE */))
}