
  /* eslint-disable @typescript-eslint/no-explicit-any */
  /* eslint-disable no-shadow */
  import { camelCase } from 'lodash';
  import axios, { AxiosResponse } from 'axios';
  import { defineComponent } from 'vue';

  import gsap from 'gsap';
  import ProductList from '@/components/products/ProductList.vue';
  import ProductDetail from '@/components/products/ProductDetail.vue';
  import ProductCarousel from '@/components/products/ProductCarousel.vue';
  import ProductFeatured from '@/components/products/ProductFeatured.vue';
  import InformationBoxes from '@/components/itemTypes/paragraphs/InformationBoxes.vue';
  import Banner from '@/components/itemTypes/paragraphs/BannerParagraph.vue';
  import TextParagraph from '@/components/itemTypes/paragraphs/TextParagraph.vue';
  import TextAndImage from '@/components/itemTypes/paragraphs/TextAndImage.vue';
  import ShopInfoParagraph from '@/components/itemTypes/paragraphs/ShopInfoParagraph.vue';
  import ArticleTypeOne from '@/components/itemTypes/paragraphs/ArticleTypeOne.vue';
  import LogoRow from '@/components/itemTypes/paragraphs/LogoRow.vue';
  import Loader from '@/components/shared/Loader.vue';
  import PageNotFound from '@/global_components/PageNotFound.vue';
  import CookiePopup from '@/global_components/CookiePopup.vue';
  import MetaTags from '@/components/itemTypes/MetaTags.vue';
  import IframeParagraph from '@/components/itemTypes/IframeParagraph.vue';
  import MailchimpSignupForm from '@/components/itemTypes/MailchimpSignupForm.vue';
  import OpeningHoursBanner from '@/components/itemTypes/OpeningHoursBanner/OpeningHoursBanner.vue';
  import GiftCardCredit from '@/components/itemTypes/paragraphs/GiftCardCredit.vue';
  import AccordionList from '@/components/itemTypes/AccordionList/AccordionList.vue';
  import EmployeeList from '@/components/itemTypes/EmployeeList/EmployeeList.vue';
  import NewsArticle from '@/components/itemTypes/NewsArticle/NewsArticle.vue';
  import NewsArticleList from '@/components/itemTypes/NewsArticle/NewsArticleList.vue';
  import NewsArticleCarousel from '@/components/itemTypes/NewsArticle/NewsArticleCarousel.vue';
  import LatestNewsArticles from '@/components/itemTypes/paragraphs/LatestNewsArticles.vue';
  import TabSlider from '@/components/itemTypes/TabSlider/TabSlider.vue';
  import TabSliderItem from '@/components/itemTypes/TabSlider/TabSliderItem.vue';
  import Anchor from '@/components/itemTypes/AnchorList/Anchor.vue';
  import AnchorList from '@/components/itemTypes/AnchorList/AnchorList.vue';
  import DropshadowButtonList from '@/components/itemTypes/DropshadowButton/DropshadowButtonList.vue';
  import DropshadowButton from '@/components/itemTypes/DropshadowButton/DropshadowButton.vue';
  import ThumbnailVideoAndText from '@/components/itemTypes/ThumbnailVideoAndText.vue';
  import VideoWithText from '@/components/itemTypes/VideoWithText/VideoWithText.vue';
  import VideoWithTextList from '@/components/itemTypes/VideoWithText/VideoWithTextList.vue';
  import SearchItemType from '@/components/itemTypes/SearchItemType.vue';
  import FiveImageAndButtonBoxes from '@/components/itemTypes/paragraphs/FiveImageAndButtonBoxes.vue';
  import DownloadAndDescription from '@/components/itemTypes/paragraphs/DownloadAndDescription.vue';
  import { getAreaForPage } from '@/utils/routingHelper';
  import CoopAndGrantForm from '@/components/itemTypes/paragraphs/CoopAndGrantForm.vue';
  import ContactUsForm from '@/components/itemTypes/paragraphs/ContactUsForm.vue';
  import WorkshopRegistration from '@/components/itemTypes/paragraphs/WorkshopRegistrationForm/WorkshopRegistration.vue';
  import * as authItemTypes from '@/components/itemTypes/user/index';
  import useMetaTags from '@/composables/useMetaTags';
  import conf from '@/config';
  import useSetPageMetaTags from '@/composables/useSetPageMetaTags';
  import useGetPageInfo from '@/composables/useGetPageInfo';

  // List of all components that paragraphs can use to render their content
  const components = {
    InformationBoxes,
    ProductList,
    ProductDetail,
    ProductCarousel,
    ProductFeatured,
    Banner,
    LatestNewsArticles,
    TextParagraph,
    TextAndImage,
    ShopInfoParagraph,
    ArticleTypeOne,
    LogoRow,
    Loader,
    PageNotFound,
    CookiePopup,
    MetaTags,
    IframeParagraph,
    MailchimpSignupForm,
    OpeningHoursBanner,
    GiftCardCredit,
    AccordionList,
    EmployeeList,
    NewsArticle,
    NewsArticleList,
    NewsArticleCarousel,
    TabSlider,
    TabSliderItem,
    Anchor,
    AnchorList,
    DropshadowButtonList,
    DropshadowButton,
    ThumbnailVideoAndText,
    VideoWithText,
    VideoWithTextList,
    SearchItemType,
    FiveImageAndButtonBoxes,
    CoopAndGrantForm,
    DownloadAndDescription,
    ContactUsForm,
    WorkshopRegistration,
    ...authItemTypes,
  };

  export default defineComponent({
    components,
    setup() {
      const { getPageInfo } = useGetPageInfo();
      const { setMetaTags, setOpenGraphMetaTags, setTwitterMetaTags, setCanonicalUrl } = useMetaTags();
      const { setPageMetaTags } = useSetPageMetaTags();

      return {
        setMetaTags,
        setOpenGraphMetaTags,
        setTwitterMetaTags,
        setCanonicalUrl,
        getPageInfo,
        setPageMetaTags,
      };
    },
    data() {
      return {
        loading: false,
        route: {},
        paragraphs: [],
        pageParagraph: false,
      };
    },
    computed: {
      isFrontpage(): boolean {
        const routePath = this.$route.path;
        const areas = ['/ellingsen', '/skor', '/air', '/s4s', '/brp', '/rafhjolasetrid'];
        return areas.includes(routePath);
      },
    },
    mounted() {
      this.loadRoute();
    },
    updated() {
      this.route = this.$router.currentRoute as any;
    },
    methods: {
      async loadRoute() {
        this.loading = true;
        const { pageID } = this.$route.meta;
        const pageInfo = await this.getPageInfo(pageID as string);

        if (pageInfo) {
          this.setPageTitle(pageInfo);
          this.pageParagraph = pageInfo.IsPageParagraph;

          const currentArea = getAreaForPage(this.$router);
          this.setPageMetaTags(pageInfo, currentArea?.name?.toString() ?? '');
        }

        // Fetch paragraph from page if certain thing is selected.
        let paragraphPromise;
        if (this.pageParagraph) {
          paragraphPromise = this.setParagraphs(pageInfo, true);
        } else {
          paragraphPromise = this.getParagraphs();
        }
        const setFaviconPromise = this.setFavicon();

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const result = [await paragraphPromise, await setFaviconPromise];
        this.loading = false;
      },
      async getParagraphs() {
        const { areaID } = this.$route.meta;
        const { pageID } = this.$route.meta;
        if (!areaID || !pageID || pageID === -1) {
          return;
        }

        const response = await axios.get(`${conf.dwUrl}/dwapi/content/paragraphs?AreaId=${areaID}&PageId=${pageID}`);
        this.setParagraphs(response, false);
      },
      setParagraphs(response, paragraphPage: boolean): any {
        // eslint-disable-next-line
        let data: any[] = [];
        if (paragraphPage) {
          data.push(response);
        } else {
          data = response.data;
        }
        this.paragraphs = data.reduce((acc: any, curr: any) => {
          if (curr.Item !== undefined && curr.Item.Fields !== undefined) {
            const paragraph = {
              name: curr.Name,
              paragraphId: curr.ID,
              hasComponent: false,
            } as any;
            acc.push(
              curr.Item.Fields.reduce((acc: any, curr: any) => {
                if (curr.Name === 'Component') {
                  acc.component = curr.Value.SelectedValue;
                } else {
                  acc[camelCase(curr.Name)] = curr.Value;
                }
                return acc;
              }, paragraph),
            );
          }
          return acc;
        }, []);
      },
      async setFavicon() {
        const currentArea = getAreaForPage(this.$router);
        const areaPath = currentArea?.path;
        const favicon = document.getElementById('favicon') as HTMLAnchorElement;

        if (favicon !== null) {
          switch (areaPath) {
            case '/ellingsen':
              favicon.href = '/ellingsen_ico/favicon.ico';
              break;
            case '/skor':
              favicon.href = '/skor_ico/favicon.ico';
              break;
            case '/air':
              favicon.href = '/skor_ico/favicon.ico';
              break;
            default:
              favicon.href = '/favicon.ico';
          }
        }
      },
      async setPageTitle(pageInfo) {
        const { Name, Title } = pageInfo;
        if (Title.length > 0) {
          document.title = Title;
        } else {
          document.title = Name;
        }
      },
      beforeEnter(el) {
        if (this.isFrontpage) {
          gsap.set(el, {
            opacity: 0,
            y: 50,
          });
        }
      },
      enter(el, done) {
        if (this.isFrontpage) {
          gsap.to(el, {
            opacity: 1,
            y: 0,
            duration: 1,
            onComplete: done,
          });
        }
      },
    },
  });
