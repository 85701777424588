
  import { defineComponent, PropType, ref } from 'vue';
  import TextInput from '@/components/shared/inputs/TextInput.vue';
  import { Address, AddressPayload } from '@/models/address/address';
  import Loader from '@/components/shared/Loader.vue';
  import { minLength, validatePhoneNumber } from '@/utils/formValidators';

  interface UserAddressFormState {
    formData: AddressPayload;
    validators: any;
  }

  export default defineComponent({
    components: {
      TextInput,
      Loader,
    },
    props: {
      /**
       * Populate form with an existing address
       */
      oldValue: {
        required: false,
        type: Object as PropType<Address>,
        default: null,
      },
      /**
       * Indicate the form is being submitted
       */
      submitting: {
        required: false,
        type: Boolean,
        default: false,
      },
    },
    emits: ['submit', 'close'],
    data() {
      return {
        formData: {
          name: '',
          address: '',
          city: '',
          zip: '',
          phoneNumber: '',
        } as AddressPayload,
        validators: {
          name: [minLength(1, 'Nafn má ekki vera tómt')],
          address: [minLength(1, 'Heimilisfang má ekki vera tómt')],
          city: [minLength(1, 'Staður má ekki vera tómt')],
          zip: [minLength(1, 'Póstnúmer má ekki vera tómt')],
          phoneNumber: [validatePhoneNumber('Símanúmer ekki löglegt')],
        },
      } as UserAddressFormState;
    },
    created() {
      if (this.oldValue) {
        this.formData = {
          name: this.oldValue.Name,
          address: this.oldValue.Address,
          zip: this.oldValue.Zip,
          country: this.oldValue.Country,
          countryCode: this.oldValue.CountryCode,
          email: this.oldValue.Email,
          city: this.oldValue.City,
          phoneNumber: this.oldValue.PhoneNumber,
          default: this.oldValue.Default,
        };
      }
    },
    methods: {
      onSubmit() {
        if (this.formValid()) {
          this.$emit('submit', this.formData);
        }
      },
      onCloseClick() {
        this.$emit('close');
      },
      formValid() {
        [this.$refs.addressRef, this.$refs.cityRef, this.$refs.zipRef, this.$refs.phoneNumberRef].forEach((r: any) => {
          if (r !== undefined) {
            if (r?.validate) {
              r.validate();
            }
          }
        });

        // if (!this.validators.name.every((validator) => validator(this.formData.name) === null) && this.validators.name.length > 0) return false;
        if (!this.validators.address.every((validator) => validator(this.formData.address) === null) && this.validators.address.length > 0) return false;
        if (!this.validators.city.every((validator) => validator(this.formData.city) === null) && this.validators.city.length > 0) return false;
        if (!this.validators.zip.every((validator) => validator(this.formData.zip) === null) && this.validators.zip.length > 0) return false;
        if (!this.validators.phoneNumber.every((validator) => validator(this.formData.phoneNumber) === null) && this.validators.phoneNumber.length > 0)
          return false;
        return true;
      },
    },
  });
