import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-84005692"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  id: "desktopNavigation",
  class: "navigation-desktop"
}
const _hoisted_2 = {
  key: 1,
  id: "mobileNavigation"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.isDesktop)
    ? (_openBlock(), _createElementBlock("nav", _hoisted_1, [
        _createVNode($setup["DesktopNavigation"])
      ]))
    : (!$setup.isDesktop)
      ? (_openBlock(), _createElementBlock("nav", _hoisted_2, [
          _createVNode($setup["MobileNavigation"])
        ]))
      : _createCommentVNode("v-if", true)
}