
  import { defineComponent, PropType } from 'vue';
  import { RouteRecord } from 'vue-router';
  import { isFrontPage } from '@/utils/routingHelper';

  export default defineComponent({
    props: {
      routes: {
        type: Object as PropType<RouteRecord[]>,
        required: true,
      },
      parentName: {
        required: false,
        type: String,
        default: 'Allt',
      },
    },
    emits: ['goForward', 'goBack'],
    computed: {
      isNotLevel1Pages(): boolean {
        return this.$props.routes.find((r) => r.meta.level === 2 || r.meta.level === 3) !== undefined;
      },
      getRouteName(): string {
        return (this.$route.name as string)?.split(']')[1] ?? '';
      },
    },
    methods: {
      onClickLink(route: RouteRecord) {
        this.$emit('goForward', route);
      },
      isFrontPage(route: RouteRecord) {
        return isFrontPage(route);
      },
      goBack() {
        this.$router.back();
        this.$emit('goBack');
      },
    },
  });
