
  import { computed, defineComponent } from 'vue';
  import { useStore } from 'vuex';
  import MultiGiftWrapOrderLine from '@/components/cart/checkout/gift-wrap/MultiGiftWrapOrderLine.vue';
  import * as GETTER from '@/constants/store/checkout/getters';

  export default defineComponent({
    components: {
      MultiGiftWrapOrderLine,
    },
    setup() {
      const store = useStore();
      const { getters } = store;
      const multiGiftWrapOrderLines = computed(() => getters[`cart/${GETTER.GET_MULTI_WRAP_ORDER_LINES}`]);
      return {
        multiGiftWrapOrderLines,
      };
    },
  });
