/* eslint-disable @typescript-eslint/no-explicit-any */

import { camelCase } from 'lodash';
import { Field, ItemType } from '@/models/field';
import { isColor, isComponentField, isDropdownField, isField, isFileField } from '@/utils/modelChecker';
import { Product } from '@/models/product/product';
import { OrderLine } from '@/models/cart/orderLine';
import { Product as OrderLineProduct } from '@/models/cart/product';
import { Variant } from '@/models/cart/variant';

function mapBaseComponentData<T>(arg: Field | ItemType): T {
  let fields;
  if (isField(arg)) {
    fields = (arg.Value as ItemType).Fields as Field[];
  } else {
    fields = (arg as ItemType).Fields as Field[];
  }
  const data = fields.reduce((acc, curr) => {
    const key = camelCase(curr.SystemName) as keyof T;
    if (isFileField(curr)) {
      // eslint-disable-next-line prefer-destructuring
      acc[key] = curr.Value[0];
    } else if (isDropdownField(curr)) {
      acc[key] = curr.Value.SelectedValue;
    } else {
      acc[key] = curr.Value;
    }
    return acc;
  }, {} as any) as T;
  return data;
}

function mapParagraphComponentData<T>(data: unknown): T {
  const result = Object.entries(data as any).reduce((acc, [key, curr]) => {
    acc[camelCase(key)] = curr;
    return acc;
  }, {} as any) as T;
  return result;
}

function mapIntermediateComponentOptions(fields: Field[], selectedComponent: string): any {
  const options = fields.reduce((acc, curr) => {
    if (!isComponentField(curr) && curr.SystemName !== selectedComponent) {
      acc[camelCase(curr.SystemName)] = curr.Value;
    }
    return acc;
  }, {} as any);
  return options;
}

/**
 * Function to extract values from paragraph component data.
 *
 * Feel free to extend this method for other cases of non primitive data like the color.
 * Create a model checker for your type and add a case for it.
 *
 * @argument data the paragraph component data
 * @argument keys the keys to extract
 */
function extractParagraphComponentDataFields<T>(data: any, keys: string[]): T {
  return Object.keys(data).reduce((acc, key) => {
    if (keys.includes(key) && data[key] !== undefined) {
      if (isColor(data[key])) {
        acc[key] = data[key].Hex;
      } else {
        acc[key] = data[key];
      }
    }
    return acc;
  }, {} as T);
}

function mapProductToInitialOrderLine(product: Product): OrderLine {
  return Object.entries(product).reduce(
    (acc, [key, value]) => {
      if (key === 'Price') {
        acc.OrderLinePrice = value;
        acc.OriginalOrderLinePrice = value;
      } else if (key === 'OriginalPrice') {
        acc.Product.ProductPrice = value;
      } else if (key === 'ProductID') {
        acc.Product.ProductID = value;
      } else if (key === 'ProductName') {
        acc.Product.ProductName = value;
      } else if (key === 'VariantID') {
        acc.Product.Variants = [{ VariantID: value, Selected: true } as Variant];
      } else if (key === 'ImagePath') {
        acc.Product.ImagePath = value;
      } else if (key === 'Quantity') {
        acc.Quantity = value;
      } else {
        acc[key] = value;
      }
      return acc;
    },
    { Product: { Variants: [] as Variant[] } as OrderLineProduct } as OrderLine,
  );
}

interface IButtonItemType {
  text: string;
  link: {
    PageId?: number | undefined;
    IsExternal?: boolean | undefined;
    Url: string;
  };
  type: string;
}

interface IButtonCustom {
  text: string;
  href: string;
  type: string;
  isExternal: boolean;
}
/**
 * Map Button ItemType data to Button component props
 *
 * @param ButtonItemTypeData
 */
function mapButtonItemTypeData(ButtonItemTypeData: Field | ItemType): IButtonCustom {
  const data: IButtonItemType = mapBaseComponentData(ButtonItemTypeData);
  const mappedData = {
    text: data.text,
    href: data.link.Url,
    type: data.type,
    isExternal: data.link.IsExternal ? data.link.IsExternal : false,
  };

  return mappedData;
}

export {
  mapParagraphComponentData,
  mapBaseComponentData,
  mapIntermediateComponentOptions,
  mapProductToInitialOrderLine,
  extractParagraphComponentDataFields,
  mapButtonItemTypeData,
};
