// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/icons/checkmark-white.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "/* Font families */\n/* Colors */\n/* S4S - Grey 200 */\n/* S4S - Grey 300 */\n/* S4S - Grey 600 */\n/* General */\n/* Device Sizes */\n/* Device specific sizes */\n/* Front Page Container Variables */\n/* Navigation */\n/* Checkout */\n.checkboxInput_Container[data-v-fd596fea] {\n  display: flex;\n  align-items: center;\n}\n.checkboxInput_Container label[data-v-fd596fea] {\n  text-transform: uppercase;\n  font-weight: 600;\n  margin-left: 10px;\n  cursor: pointer;\n}\n.checkboxInput_Container label.has-error[data-v-fd596fea] {\n  color: #f32b2b;\n}\n.checkboxInput_Container input[data-v-fd596fea] {\n  border-radius: var(--border-radius);\n  -webkit-appearance: none;\n     -moz-appearance: none;\n          appearance: none;\n  background: white;\n  border: 1px solid black;\n  height: 14px;\n  width: 14px;\n  min-width: 14px;\n  min-height: 14px;\n  box-sizing: content-box;\n  cursor: pointer;\n}\n.checkboxInput_Container input.has-error[data-v-fd596fea] {\n  border-color: #f32b2b;\n}\n.checkboxInput_Container input[data-v-fd596fea]:checked {\n  position: relative;\n  background: black;\n  border: none;\n  height: 16px;\n  width: 16px;\n  min-width: 16px;\n  min-height: 16px;\n}\n.checkboxInput_Container input[data-v-fd596fea]:checked::before {\n  position: absolute;\n  content: \"\";\n  width: 18px;\n  height: 18px;\n  border-radius: var(--border-radius);\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center;\n  background-size: contain;\n  top: -1px;\n  left: -1px;\n}\n.checkboxInput_Container.disabled label[data-v-fd596fea] {\n  opacity: 0.6;\n}\n.checkboxInput_Container.disabled input[data-v-fd596fea] {\n  opacity: 0.6;\n}", ""]);
// Exports
module.exports = exports;
