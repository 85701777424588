import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-33d9f5f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "product"
}
const _hoisted_2 = { class: "product__image" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "product__info" }
const _hoisted_5 = { class: "product__manufacturer" }
const _hoisted_6 = { class: "product__product-name" }
const _hoisted_7 = { class: "product__price" }
const _hoisted_8 = {
  key: 0,
  class: "without-discount"
}
const _hoisted_9 = { class: "product__trash-cell" }
const _hoisted_10 = { class: "product__menu-cell" }
const _hoisted_11 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_MenuDots = _resolveComponent("MenuDots")!
  const _component_DropDown = _resolveComponent("DropDown")!

  return (_ctx.product)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: _ctx.productImagePath,
            class: "image"
          }, null, 8 /* PROPS */, _hoisted_3)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            class: "product__webshop",
            style: _normalizeStyle(_ctx.webshopColor)
          }, _toDisplayString(_ctx.product.Webshop), 5 /* TEXT, STYLE */),
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.product.Manufacturer), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.product.Name), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", {
              class: _normalizeClass(["with-discount", { 'has-discount': _ctx.hasDiscount }])
            }, _toDisplayString(_ctx.formattedPrice), 3 /* TEXT, CLASS */),
            (_ctx.hasDiscount)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.formattedPriceBeforeDiscount), 1 /* TEXT */))
              : _createCommentVNode("v-if", true)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_Icon, {
              class: "product__trash",
              icon: "trash",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onWishlistProductDelete(_ctx.product)))
            })
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_MenuDots, {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showMenu(_ctx.product)))
            })
          ])
        ]),
        _createVNode(_component_DropDown, {
          "selected-option": _ctx.selectedColor,
          options: _ctx.colorOptions,
          disabled: _ctx.colorDropdownDisabled,
          "background-color": "white",
          width: "204px",
          class: "product__dropdown",
          onOnSelectOption: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateColor($event)))
        }, null, 8 /* PROPS */, ["selected-option", "options", "disabled"]),
        _createVNode(_component_DropDown, {
          "selected-option": _ctx.selectedSize,
          options: _ctx.variantOptions,
          disabled: _ctx.variantDropdownDisabled,
          "background-color": "white",
          width: "204px",
          class: "product__dropdown",
          onOnSelectOption: _cache[3] || (_cache[3] = ($event: any) => (_ctx.updateSize($event)))
        }, null, 8 /* PROPS */, ["selected-option", "options", "disabled"]),
        _createElementVNode("button", {
          class: "btn btn--primary product__buy",
          disabled: _ctx.buyButtonDisabled,
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onWishlistProductAddToCart()))
        }, " BÆTA Í KÖRFU ", 8 /* PROPS */, _hoisted_11)
      ]))
    : _createCommentVNode("v-if", true)
}