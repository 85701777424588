export const SET_DISPLAY_LOGIN_FORM = 'setDisplayLoginForm';
export const SET_AUTHENTICATED_USER = 'setAuthenticatedUser';
export const CLEAR_AUTHENTICATED_USER = 'clearAuthenticatedUser';
export const SET_USER_ADDRESSES = 'setUserAddresses';
export const SET_USER_ORDERS = 'setUserOrders';
export const SET_USER_WISHLIST_ID = 'setUserWishlistId';
export const SET_USER_WISHLIST = 'setUserWishlist';
export const SET_WISHLIST_LIVE_DATA = 'setWishlistLiveData';
export const DELETE_USER_WISHLIST = 'deleteUserWishlist';
export const DELETE_PRODUCT_FROM_WISHLIST = 'deleteProductFromWishlist';
export const UPDATE_USER = 'updateUser';
