import { Commit, Dispatch } from 'vuex';
import { get } from 'lodash';
import CartAPI from '@/API/cartApi';
import { isCartErrorResponse } from '@/utils/model_checkers/responses';
import { CustomerInfo } from '@/models/cart/customerInfo';
import { CartResponse } from '@/models/cart/cartResponseModels';
import { CartState } from '@/models/store/cart';

export default {
  updateContactInfo({ commit }: { commit: Commit }, payload: any): void {
    commit('updateContactInfo', payload);
  },

  updateDeliveryInfo({ commit }: { commit: Commit }, payload: any): void {
    commit('updateDeliveryInfo', payload);
  },

  async postContactInfo({ commit }: { commit: Commit }, payload: FormData): Promise<CartResponse> {
    const response = await CartAPI.postFormData(payload);
    if (isCartErrorResponse(response)) {
      console.error(`failed posting contact info ${JSON.stringify(payload)} from cart`, response.data);
    } else {
      commit('setContactInfo', response.data);
    }
    return response;
  },

  setContactInfo({ commit }: { commit: Commit }, payload: CustomerInfo): void {
    commit('setContactInfo', payload);
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async toggleGiftWrap({ commit, dispatch, getters }: { commit: Commit; dispatch: Dispatch; getters: any }): Promise<void> {
    commit('toggleGiftWrap');
    if (getters.getGiftWrapProduct !== null) {
      if (getters.getGiftWrapOrderLine) {
        await dispatch('deleteOrderLine', getters.getGiftWrapOrderLine);
      } else {
        await dispatch('addToCart', getters.getGiftWrapProduct);
      }
    }
    commit('loadingDone');
  },

  toggleMailingList({ commit }: { commit: Commit }): void {
    commit('toggleMailingList');
  },

  toggleUseOtherAddress({ commit }: { commit: Commit }): void {
    commit('toggleUseOtherAddress');
  },
};
