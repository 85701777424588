
  import { defineComponent, PropType } from 'vue';
  import useParagraph from '@/composables/useParagraph';
  import { LogoRow } from '@/models/item-types/paragraph';
  import { mapBaseComponentData } from '@/utils/dataMapper';
  import conf from '@/config';

  export default defineComponent({
    props: {
      componentProps: {
        type: Object as PropType<unknown>,
        required: true,
      },
    },
    setup(props) {
      const { data, getParagraphStyles, getParagraphClasses } = useParagraph(props.componentProps);

      const paragraphData = data.value as unknown as LogoRow;

      const imagesData = [] as any[];
      paragraphData.images.forEach((image) => {
        const imageData = mapBaseComponentData(image);
        imagesData.push(imageData);
      });
      paragraphData.images = imagesData;

      const imagePrefix = conf.dwUrl;
      return {
        paragraphData,
        getParagraphStyles,
        getParagraphClasses,
        imagePrefix,
      };
    },
  });
