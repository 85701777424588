import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7e101205"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "areaNavigation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routes, (route) => {
      return (_openBlock(), _createBlock(_component_router_link, {
        key: route.path,
        class: "areaNavigation_Link",
        to: route.path,
        onClick: ($event: any) => (_ctx.onLinkClick(route))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(route.name), 1 /* TEXT */)
        ]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to", "onClick"]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}