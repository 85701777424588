import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_banner = _resolveComponent("banner")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["paragraph-container container", _ctx.getParagraphClasses]),
    style: _normalizeStyle(_ctx.getParagraphStyles)
  }, [
    (_ctx.paragraphData.banner)
      ? (_openBlock(), _createBlock(_component_banner, {
          key: 0,
          banner: _ctx.paragraphData.banner
        }, null, 8 /* PROPS */, ["banner"]))
      : _createCommentVNode("v-if", true)
  ], 6 /* CLASS, STYLE */))
}