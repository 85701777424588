
  import { defineComponent } from 'vue';
  import { mapGetters } from 'vuex';
  import { colorIsDark } from '@/utils/colorHelper';
  import CustomButton from '@/components/shared/Button.vue';

  export default defineComponent({
    components: {
      CustomButton,
    },
    data() {
      return {};
    },
    computed: {
      ...mapGetters('website', {
        websiteSettings: 'getWebsiteSettings',
      }),
      getFontStyles(): unknown {
        return {
          '--text-color': colorIsDark(this.websiteSettings.primaryColor) ? 'white' : 'black',
        };
      },
      getOtherLinksColumnCount(): number {
        return 3;
      },
    },
    mounted() {
      document.title = '404';
    },
    methods: {
      goBack() {
        return this.$router.go(-1);
      },
    },
  });
