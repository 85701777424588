
  import { defineComponent } from 'vue';

  export default defineComponent({
    props: {
      active: {
        required: false,
        type: Boolean,
        default: false,
      },
    },
    emits: ['close'],
    watch: {
      active(active) {
        if (active) {
          this.blockScrollOnBackgroundContent();
        } else {
          this.unblockScrollOnBackgroundContent();
        }
      },
    },
    unmounted() {
      this.unblockScrollOnBackgroundContent();
    },
    methods: {
      handleOutsideContentClick(event): void {
        if (event.target.classList.contains('modal')) {
          this.close();
        }
      },
      close(): void {
        this.unblockScrollOnBackgroundContent();
        this.$emit('close');
      },
      blockScrollOnBackgroundContent(): void {
        const body = document.getElementsByTagName('body')[0];
        body.classList.add('overflow--hidden');
      },
      unblockScrollOnBackgroundContent(): void {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('overflow--hidden');
      },
    },
  });
