import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_button = _resolveComponent("custom-button")!

  return (_ctx.getData.link)
    ? (_openBlock(), _createBlock(_component_custom_button, {
        key: 0,
        href: _ctx.getData.link.Url,
        "is-external": _ctx.getData.link.IsExternal,
        "btn-type": _ctx.getData.type,
        "btn-size": _ctx.getData.buttonSize
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.getData.text), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["href", "is-external", "btn-type", "btn-size"]))
    : (_openBlock(), _createBlock(_component_custom_button, {
        key: 1,
        "btn-type": _ctx.getData.type,
        "btn-size": _ctx.getData.buttonSize
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.getData.text), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["btn-type", "btn-size"]))
}