
  import { defineComponent } from 'vue';
  import UserOrderTable from '@/components/user/orders/UserOrderTable.vue';
  import useUserOrderHistory from '@/composables/useUserOrderHistory';

  export default defineComponent({
    components: {
      UserOrderTable,
    },
    setup() {
      const { userOrders, getUserOrders } = useUserOrderHistory();

      return { userOrders, getUserOrders };
    },
    created() {
      this.getUserOrders();
    },
  });
