import { computed, ComputedRef } from 'vue';
import { useStore } from 'vuex';
import { IProductIdAreaMap, OrderLine } from '@/models/cart/orderLine';
import { Product } from '@/models/cart/product';
import { Variant } from '@/models/cart/variant';
import { ImageHandler } from '@/utils/imageHelper';
import { formatAsPriceString } from '@/utils/stringHelper';
import conf from '@/config';

interface ReturnValue {
  formattedOrderLinePrice: ComputedRef<string>;
  formattedProductPrice: ComputedRef<string>;
  orderLineHasDiscount: ComputedRef<boolean>;
  orderLineStyles: ComputedRef<{
    '--price-color': string;
    '--old-price-decoration': string;
  }>;
  productImagePath: ComputedRef<string>;
  selectedQuantity: ComputedRef<number>;
  selectedColor: ComputedRef<string>;
  productIdAreaMap: ComputedRef<IProductIdAreaMap>;
  colorOptions: ComputedRef<
    {
      value: string;
      notification: string;
      disabled: boolean;
      meta: {
        product: Product;
      };
    }[]
  >;
  selectedVariant: ComputedRef<Variant | undefined>;
  variantOptions: ComputedRef<
    {
      value: string;
      notification: string;
      disabled: boolean;
      meta: {
        variant: Variant;
      };
    }[]
  >;
  updateOrderLineQuantity: (payload) => void;
  updateOrderLineProduct: (payload) => void;
  updateOrderLineVariant: (payload) => void;
  deleteOrderLine: (payload) => void;
  populateOrderLineProductColor: (productId) => void;
}

export default function useOrderLine(props: { orderLine: OrderLine }): ReturnValue {
  const store = useStore();

  const formattedOrderLinePrice = computed(() => formatAsPriceString(props.orderLine.OrderLinePrice, props.orderLine.CurrencySymbol));
  const formattedProductPrice = computed(() =>
    formatAsPriceString(props.orderLine.Product.ProductPrice * props.orderLine.Quantity, props.orderLine.CurrencySymbol),
  );
  const orderLineHasDiscount = computed(() => props.orderLine.OrderLinePrice !== props.orderLine.Product.ProductPrice * props.orderLine.Quantity);
  const productImagePath = computed(() => `${conf.dwUrl}${ImageHandler(props.orderLine.Product.ImagePath || '/Files/Images/Icons/photo.svg', 200, 200)}`); // =Refactor= No hardcoded path, use DW settings
  const productIdAreaMap = computed(() => store.state.cart.productIdAreaMap);

  const orderLineStyles = computed(() => ({
    '--price-color': props.orderLine.OrderLinePrice < (props.orderLine.Product.ProductPrice *props.orderLine.Quantity) ? 'red' : 'black',
    '--old-price-decoration': props.orderLine.OrderLinePrice < (props.orderLine.Product.ProductPrice *props.orderLine.Quantity) ? 'line-through' : 'initial',
  }));


  const selectedQuantity = computed(() => props.orderLine.Quantity);
  const selectedColor = computed(() => props.orderLine.Product.Colors || '');
  const colorOptions = computed(() => {
    const relatedProducts = [...props.orderLine.RelatedProducts.filter((related: Product) => related.Variants.some((variant: Variant) => variant.Stock > 0))];
    return [...relatedProducts, { ...props.orderLine.Product }].map((product: Product) => ({
      value: product.Colors !== '' ? product.Colors : 'NO COLOR',
      notification: product.ProductID, // TODO: set only product ID when color is missing
      disabled: false,
      meta: {
        product,
      },
    }));
  });
  const selectedVariant = computed(() => props.orderLine.Product.Variants.find((variant: Variant) => variant.Selected)) || ({} as Variant);
  const variantOptions = computed(() =>
    props.orderLine.Product.Variants.map((variant: Variant) => ({
      value: variant.VariantText,
      notification: (() => {
        if (variant.Stock === 0) {
          return 'UPPSELT';
        }
        if (variant.Stock < 5) {
          return 'FÁ EINTÖK EFTIR';
        }
        return '';
      })(),
      disabled: variant.Stock === 0,
      meta: {
        variant,
      },
    })),
  );

  const updateOrderLineQuantity = (payload) => store.dispatch('cart/updateOrderLineQuantity', payload);
  const updateOrderLineProduct = (payload) => store.dispatch('cart/updateOrderLineProduct', payload);
  const updateOrderLineVariant = (payload) => store.dispatch('cart/updateOrderLineVariant', payload);
  const deleteOrderLine = (payload) => store.dispatch('cart/deleteOrderLine', payload);
  const populateOrderLineProductColor = (productId) => store.dispatch('cart/populateOrderLineProductColor', productId);

  return {
    formattedOrderLinePrice,
    formattedProductPrice,
    orderLineHasDiscount,
    orderLineStyles,
    productImagePath,
    selectedQuantity,
    selectedColor,
    colorOptions,
    selectedVariant,
    variantOptions,
    updateOrderLineQuantity,
    updateOrderLineProduct,
    updateOrderLineVariant,
    deleteOrderLine,
    populateOrderLineProductColor,
    productIdAreaMap,
  };
}
