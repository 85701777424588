
  import { defineComponent, PropType } from 'vue';

  type ColorClass = 'white' | 'grey' | 'lightGrey';

  /**
   * Generic card component for user resources
   */
  export default defineComponent({
    props: {
      /**
       * Add underline styling to card header
       */
      underlined: {
        required: false,
        type: Boolean,
        default: false,
      },
      /**
       * Set background color class
       * @values white, grey, lightGrey
       */
      bgColorClass: {
        required: false,
        type: String as PropType<ColorClass>,
        default: 'white',
      },
    },
    computed: {
      bgColorClassComputed(): string {
        return `card--${this.bgColorClass}`;
      },
    },
  });
