
  import { defineComponent } from 'vue';

  export default defineComponent({
    props: {
      initiallyChecked: {
        type: Boolean,
        required: false,
      },
      disableTransition: {
        type: Boolean,
        required: false,
      },
    },
    emits: ['passBurgerRef', 'toggleBurger'],
    mounted() {
      this.$emit('passBurgerRef', this.$refs.burger);
    },
    methods: {
      toggleBurger() {
        this.$emit('toggleBurger');
      },
    },
  });
