
  import { defineComponent } from 'vue';
  import { RouteRecord, RouteRecordRaw } from 'vue-router';
  import { mapGetters } from 'vuex';
  import AreaNavigation from '@/global_components/navigation/mobile_tablet/MobileTabletAreaNavigation.vue';
  import UserArea from '@/global_components/navigation/mobile_tablet/MobileTabletUserArea.vue';
  import PageNavigation from '@/global_components/navigation/mobile_tablet/MobileTabletPageNavigation.vue';
  import Icons from '@/global_components/navigation/mobile_tablet/MobileTabletIcons.vue';

  import { getAreaForPage, getFrontPageChild, getLevel0Routes, getUserArea, isFrontPage } from '@/utils/routingHelper';
  import useWebsiteSettings from '@/composables/useWebsiteSettings';
  import navBus from '@/utils/navBus';

  export default defineComponent({
    components: {
      Icons,
      UserArea,
      PageNavigation,
      AreaNavigation,
    },
    setup() {
      const { websiteSettings } = useWebsiteSettings();

      return { websiteSettings };
    },
    data() {
      return {
        burgerRef: {} as HTMLInputElement,
        level1Routes: [] as RouteRecordRaw[],
        level2Routes: [] as RouteRecordRaw[],
        level3Routes: [] as RouteRecordRaw[],
        level3Parent: '',
        scrolling: false,
      };
    },
    computed: {
      getAreaRoutes(): RouteRecordRaw[] {
        return getLevel0Routes(this.$router);
      },
      hasUserArea(): boolean {
        return getUserArea(this.$router) !== undefined;
      },
      ...mapGetters('auth', ['hasAuthenticatedUser']),
    },
    created() {
      navBus.addHandler((areaId: number) => {
        this.level1Routes = getLevel0Routes(this.$router, true).find(({ meta }) => meta?.areaID === areaId)?.children || [];
      });
    },
    mounted() {
      this.level1Routes = getAreaForPage(this.$router).children || this.getAreaRoutes[0]?.children || [];
      window.addEventListener('scroll', this.onScroll);
    },
    unmounted() {
      window.removeEventListener('scroll', this.onScroll);
    },
    methods: {
      onScroll() {
        this.scrolling = window.scrollY > 100;
      },
      navigation(route: RouteRecord) {
        const pages = this.$refs.pages as HTMLElement;
        const area = this.$refs.areaNavigation as HTMLElement;
        return {
          area: () => ({
            linkClick: () => {
              pages.scrollTo(0, 0);
              setTimeout(() => {
                this.level1Routes = route.children || [];
              }, 500);
              setTimeout(() => {
                pages.classList.add('open-pages');
                area.classList.add('move-area-down');
                this.clearPagesTransitionClasses();
                pages.classList.remove('close-pages');
                area.classList.remove('move-area-up');
              }, 500);
              pages.classList.remove('open-pages');
              area.classList.remove('move-area-down');
              pages.classList.add('close-pages');
              area.classList.add('move-area-up');
              this.goToFrontPage(route);
            },
          }),
          page: () => ({
            back: () => ({
              level1: () => {
                pages.scrollTo(0, 0);
                this.clearPagesTransitionClasses();
                pages.classList.add('level2-backwards-to-level1');
              },
              level2: () => {
                pages.scrollTo(0, 0);
                this.clearPagesTransitionClasses();
                pages.classList.add('level3-backwards-to-level2');
              },
            }),
            forward: () => ({
              level2: () => {
                this.level3Parent = route.meta.name as string;
                if (route.children?.length) {
                  pages.scrollTo(0, 0);
                  pages.classList.remove('open-pages');
                  this.clearPagesTransitionClasses();
                  pages.classList.add('level1-forward-to-level2');
                  this.level2Routes = route.children || [];
                } else {
                  this.programmaticallyToggleBurger();
                }
              },
              level3: () => {
                if (route.children?.length) {
                  pages.scrollTo(0, 0);
                  this.clearPagesTransitionClasses();
                  pages.classList.add('level2-forward-to-level3');
                  this.level3Routes = route.children;
                } else {
                  this.programmaticallyToggleBurger();
                }
              },
              end: () => {
                this.programmaticallyToggleBurger();
              },
            }),
          }),
        };
      },
      clearPagesTransitionClasses() {
        const pages = this.$refs.pages as HTMLElement;
        pages.classList.remove('level1-forward-to-level2');
        pages.classList.remove('level2-backwards-to-level1');
        pages.classList.remove('level2-forward-to-level3');
        pages.classList.remove('level3-backwards-to-level2');
      },
      programmaticallyToggleBurger() {
        const burger = this.burgerRef as HTMLInputElement;
        burger.checked = !burger.checked;
        this.toggleBurger();
      },
      toggleBurger() {
        const body = document.getElementsByTagName('body')[0];
        const menuBody = this.$refs.body as HTMLElement;
        const background = this.$refs.background as HTMLElement;
        const burger = this.burgerRef as HTMLInputElement;
        if (burger.checked) {
          background.classList.remove('background--hidden');
          menuBody.classList.remove('close-nav');
          menuBody.classList.add('open-nav');
          body.classList.add('overflow--hidden');
        } else {
          body.classList.remove('overflow--hidden');
          menuBody.classList.remove('open-nav');
          menuBody.classList.add('close-nav');
          background.classList.add('background--hidden');
        }
      },
      acceptBurgerRef(burgerRef: HTMLInputElement) {
        this.burgerRef = burgerRef;
      },
      goToFrontPage(route: RouteRecord) {
        this.$router.push(getFrontPageChild(route));
      },
      isFrontPage(route: RouteRecord) {
        return isFrontPage(route);
      },
    },
  });
