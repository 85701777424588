import { createRouter, createWebHistory, RouteLocationNormalized } from 'vue-router';
import applyWebsiteSettings from './middlewares/applyWebsiteSettings';
import checkRequiresAuth from './middlewares/checkRequiresAuth';
import applyGoogleTagPageviewEvent from './middlewares/applyGoogleTagPageviewEvent';

import { isRouteBRP } from './isRouteBrp';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { left: 0, top: 0 };
  },
});

router.beforeEach((to, from) => {
  if (isRouteBRP(to)) {
    window.open('https://brp.is');
    return from;
  } else {
    return true;
  }
});
router.beforeEach(checkRequiresAuth);
router.afterEach(applyWebsiteSettings);
router.beforeEach(applyGoogleTagPageviewEvent);

export { default as routes } from './routes';
export default router;
