import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "product-flag blue"
}
const _hoisted_3 = {
  key: 1,
  class: "product-flag red"
}
const _hoisted_4 = {
  key: 2,
  class: "product-flag yellow"
}
const _hoisted_5 = {
  key: 3,
  class: "product-flag green"
}
const _hoisted_6 = {
  key: 4,
  class: "product-flag orange"
}
const _hoisted_7 = {
  key: 5,
  class: "product-flag turqoise"
}
const _hoisted_8 = {
  key: 6,
  class: "product-flag red"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.product !== null)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.displayIsNew)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t('Nýtt')), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        (_ctx.displayHasDiscount)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t(_ctx.discountLabel)), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        (_ctx.displayOffer)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t('OUTLET')), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        (_ctx.displayPopular)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t('VINSÆLT')), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        (_ctx.displaySpecialOrder)
          ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t('SÉRPÖNTUN')), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        (_ctx.displayComingSoon)
          ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t('VÆNTANLEGT')), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        (_ctx.displaySoldOut)
          ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t('UPPSELT')), 1 /* TEXT */))
          : _createCommentVNode("v-if", true)
      ]))
    : _createCommentVNode("v-if", true)
}