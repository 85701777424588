
  import { defineComponent, inject, computed } from 'vue';
  import { keys, get, merge, mapValues, flatten, values, uniqBy, pick, toNumber } from 'lodash';
  import { mapGetters } from 'vuex';
  import useProductSearch from '@/composables/useProductSearch';
  import useLiveProductPrices from '@/composables/useLiveProductPrices';
  import Loader from '@/components/shared/Loader.vue';
  import { SearchResultsProductsByShop, SearchResultsContent, SearchResultsProducts } from '@/models/search/searchModels';
  import SearchForm from './SearchForm.vue';
  import SearchPopularTerms from './SearchPopularTerms.vue';
  import SearchResults from './SearchResults.vue';
  import { SearchForMoreKey, SearchConfigKey, SearchValueKey, SearchQueryKey, SearchQueryChangeKey } from './SearchSymbols';

  interface SearchViewState {
    loading: boolean;
    searchValue: string;
    productSearchResults: SearchResultsProductsByShop | null;
    contentSearchResults: SearchResultsContent | null;
    productLivePrices: {
      [key: string]: {
        Price: string;
        PriceBeforeDiscount: string;
        SoldOut: boolean;
      };
    };
    query: string;
    hasNotSearched: boolean;
  }

  export default defineComponent({
    components: {
      SearchForm,
      SearchPopularTerms,
      SearchResults,
      Loader,
    },
    provide() {
      return {
        [SearchForMoreKey as symbol]: async (store: string): Promise<SearchResultsProducts | null> => {
          const pageSize = get(this.searchConfig.shops, `${store}.pageSize`, this.defaultPageSize) as string;
          const pageNumber = toNumber(get(this.productSearchResults || {}, `${store}.CurrentPage`, 1)) + 1;
          const shopResults = await this.searchForProductsByStore(store, pageSize, pageNumber.toString());

          this.updateProductLivePrices(shopResults);

          return shopResults[store];
        },
        [SearchValueKey as symbol]: computed(() => this.searchValue),
        [SearchQueryKey as symbol]: computed(() => this.query),
        [SearchQueryChangeKey as symbol]: async (query: string): Promise<void> => {
          this.query = query;

          const routeQuery = { [this.searchConfig.queryKey]: this.searchValue };
          query.split('&').forEach((param) => {
            const parts = param.split('=');
            const [q, v] = parts;
            routeQuery[q] = v;
          });
          this.$router.push({ path: this.$route.path, query: routeQuery });
          await this.performProductSearch();
        },
      };
    },
    props: {
      defaultPageSize: {
        required: false,
        type: String,
        default: '12',
      },
      searchesForContent: {
        required: false,
        type: Boolean,
        default: true,
      },
      popularSearchTerms: {
        required: false,
        type: Array,
        default: () => ['Skór', 'Nike', 'Hjólafatnaður', 'Buggy', 'Crossfit', 'Útivist', 'Ecco', 'Trespass', 'Jakki', 'Arena', 'Columbia', 'Skechers'],
      },
    },
    setup() {
      const searchConfig = inject(SearchConfigKey);

      if (searchConfig === undefined) {
        throw new Error('SearchConfig provider must be defined');
      }

      const { searchForProducts } = useProductSearch(searchConfig.queryKey);

      const { getLivePricesForProducts } = useLiveProductPrices();

      return {
        searchForProducts,
        getLivePricesForProducts,
        searchConfig,
      };
    },
    data(): SearchViewState {
      return {
        loading: false,
        searchValue: '',
        productSearchResults: null,
        contentSearchResults: null,
        productLivePrices: {},
        query: '',
        hasNotSearched: true,
      } as SearchViewState;
    },
    computed: {
      ...mapGetters({
        areaId: 'website/getAreaID',
      }),
      productResultsWithLivePrices(): SearchResultsProductsByShop {
        if (this.productSearchResults === null) {
          return {};
        }

        return mapValues(this.productSearchResults, (shopResults) => ({
          ...shopResults,
          Products: (shopResults?.Products || []).map((product) => ({
            ...product,
            ...pick(get(this.productLivePrices, product?.Id || '', {}), ['Price', 'PriceBeforeDiscount', 'SoldOut']),
          })),
        }));
      },
    },
    created() {
      const query = get(this.$route, `query.${this.searchConfig.queryKey}`, null);

      if (query !== null && query.length > 0) {
        this.searchValue = query;
        this.query = window.location.search;
      }
    },
    mounted() {
      if (this.searchValue.length > 0) {
        this.performSearch().then(() => {
          const scroll = get(window, 'history.state.scroll');

          if (scroll) {
            window.scrollTo(window.history.state.scroll);
          }
        });
      }
    },
    methods: {
      onSearchSubmit(value: string): void {
        this.searchValue = value;
        const query = { ...this.$route.query };
        query[`${this.searchConfig.queryKey}`] = value;

        this.$router.push({ path: this.$route.path, query });

        this.performSearch();
      },
      onSearchCancel(): void {
        this.$router.back();
      },
      async performSearch(): Promise<void> {
        this.loading = true;

        const productSearch = this.performProductSearch();
        if (this.searchesForContent) {
          this.contentSearchResults = await this.performContentSearch();
        }
        await productSearch;

        this.loading = false;
        this.hasNotSearched = false;
      },
      async performProductSearch(): Promise<void> {
        await this.searchForProductsByAllConfiguredStores();
      },
      async searchForProductsByAllConfiguredStores(): Promise<void> {
        const shopKeys = keys(this.searchConfig.shops);
        for (let i = 0; i < shopKeys.length; i += 1) {
          const key = shopKeys[i];
          // eslint-disable-next-line no-await-in-loop
          const results = await this.searchForProductsByStore(key, get(this.searchConfig.shops, `${key}.pageSize`, this.defaultPageSize) as string, '1', false);
          this.productSearchResults = { ...this.productSearchResults, ...results };
        }
        if (this.productSearchResults !== null) {
          this.updateProductLivePrices(this.productSearchResults);
        }
      },
      async searchForProductsByStore(shop: string, pageSize: string, pageNumber: string, mergeResults = true): Promise<SearchResultsProductsByShop> {
        const results = await this.searchForProducts(this.searchValue, merge({}, this.searchConfig.shops[shop], { pageSize, pageNumber }), this.query);

        const Products = get(this.productSearchResults || ({} as SearchResultsProducts), `${shop}.Products`, [])
          .concat(results?.Products || [])
          .filter((p) => p && Object.keys(p).length > 0);
        this.productSearchResults = {
          ...(this.productSearchResults || {}),
          [shop]: { ...results, Products: mergeResults ? Products : results?.Products || [] },
        };

        return { [shop]: results };
      },
      async performContentSearch(): Promise<SearchResultsContent> {
        const contentSearchResults = {} as SearchResultsContent;

        this.contentSearchResults = contentSearchResults;

        return contentSearchResults;
      },
      async updateProductLivePrices(searchResults: SearchResultsProductsByShop) {
        const results = values(searchResults).filter((res) => res !== null) as SearchResultsProducts[];
        const ids = uniqBy(flatten(results.map(({ Products }) => Products || {})), 'Id').map(({ Id }) => Id || '') as string[];

        this.productLivePrices = { ...this.productLivePrices, ...(await this.getLivePricesForProducts(ids)) };
      },
    },
  });
