
  import { defineComponent, PropType } from 'vue';
  import { mapGetters } from 'vuex';

  export default defineComponent({
    props: {
      product: {
        type: Object as PropType<any>, // =REFACTOR= when new product model is ready
        required: true,
      },
    },
    computed: {
      ...mapGetters('website', {
        websiteSettings: 'getWebsiteSettings',
      }),
      discountLabel() {
        return this.websiteSettings.discountLabel !== undefined ? this.websiteSettings.discountLabel : 'Útsala';
      },
      isSoldOut(): boolean {
        return this.isGetOffer ? false : this.product?.SoldOut;
      },
      // check if product has fields
      isNew(): boolean {
        if (this.product?.Created !== undefined) {
          const createdDate = new Date(this.product.Created);
          const tenDaysAgo = new Date();
          tenDaysAgo.setDate(new Date().getDate() - 10);
          return tenDaysAgo < createdDate;
        }
        return false;
      },
      hasDiscount(): boolean {
        return this.product?.Price?.PriceWithVat < this.product?.PriceBeforeDiscount?.PriceWithVat;
      },
      isPopular(): boolean {
        return this.product?.ProductFields?.IsPopular?.Value;
      },
      isSpecialOrder(): boolean {
        return this.product?.ProductFields?.IsSerpontun?.Value;
      },
      isComingSoon(): boolean {
        return this.product?.ProductFields?.IsVaentanlegt?.Value;
      },
      isNeverOutOfStock(): boolean {
        return this.product?.NeverOutOfstock === true;
      },
      isToppskor(): boolean {
        return this.product?.ProductFields?.Toppskor?.Value?.toLowerCase() === 'yes';
      },
      isGetOffer(): boolean {
        return this.product?.ProductFields?.Fatilbod?.Value;
      },
      // display conditions
      displayIsNew(): boolean {
        return this.isNew;
      },
      displayHasDiscount(): boolean {
        return this.hasDiscount && !this.isToppskor;
      },
      displayPopular(): boolean {
        return this.isPopular;
      },
      displaySpecialOrder(): boolean {
        return this.isSpecialOrder;
      },
      displayComingSoon(): boolean {
        return this.isComingSoon;
      },
      displaySoldOut(): boolean {
        return this.isSoldOut && !this.isSpecialOrder && !this.isComingSoon;
      },
      displayOffer(): boolean {
        return this.isToppskor && this.hasDiscount;
      },
    },
  });
