import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-85dd1fbc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "multiGiftWrapOrderLines" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiGiftWrapOrderLine = _resolveComponent("MultiGiftWrapOrderLine")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.multiGiftWrapOrderLines, (orderLine) => {
      return (_openBlock(), _createBlock(_component_MultiGiftWrapOrderLine, {
        key: orderLine.MultiWrapOrderLineID,
        "order-line": orderLine
      }, null, 8 /* PROPS */, ["order-line"]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}