import AdvaniaApi from './advaniaApi';

export default new (class LoggingApi extends AdvaniaApi {
  constructor() {
    super('logs');
  }

  async postError(message: string) {
    const log = new URLSearchParams();
    log.append('message', message);
    this.post('error', log);
  }

  async postEmergency(message: string) {
    const log = new URLSearchParams();
    log.append('message', message);
    this.post('emergency', log);
  }

  async postDebug(message: string) {
    const log = new URLSearchParams();
    log.append('message', message);
    this.post('debug', log);
  }
})();
