import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-130e98ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "box-and-image-banner__image-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_information_box = _resolveComponent("information-box")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.getStyles),
    class: "box-and-image-banner"
  }, [
    _createVNode(_component_information_box, {
      class: "box-and-image-banner__box box_InBanner",
      box: _ctx.getData.box,
      "box-count": 1
    }, null, 8 /* PROPS */, ["box"]),
    _createElementVNode("div", {
      class: "box-and-image-banner__image",
      style: _normalizeStyle(_ctx.getBannerImageStyles)
    }, [
      _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.getTitle), 1 /* TEXT */)
    ], 4 /* STYLE */)
  ], 4 /* STYLE */))
}