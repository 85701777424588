import { Commit } from 'vuex';
import { DeliveryInfo } from '@/models/cart/deliveryInfo';
import { isCartErrorResponse } from '@/utils/model_checkers/responses';
import CartAPI from '@/API/cartApi';
import { CartState } from '@/models/store/cart';

export default {
  async setDeliveryInfo(actionParams: { commit: Commit; state: CartState }, payload: DeliveryInfo): Promise<void> {
    const { commit, state } = actionParams;
    commit('setDeliveryInfo', payload);
  },
  async postDeliveryInfo(actionParams: { commit: Commit; state: CartState }, payload: DeliveryInfo): Promise<void> {
    const { commit, state } = actionParams;
    const formData = new FormData();
    formData.append('EcomOrderDeliveryName', payload.DeliveryName);
    formData.append('EcomOrderDeliveryAddress', payload.DeliveryAddress);
    formData.append('EcomOrderDeliveryZip', payload.DeliveryPostcode);
    formData.append('EcomOrderDeliveryCity', payload.DeliveryCity);
    formData.append('EcomOrderDeliveryPhone', payload.DeliveryPhone);
    formData.append('EcomOrderDeliveryEmail', payload.DeliveryEmail);
    formData.append('EcomOrderDeliveryComment', payload.DeliveryComment);
    formData.append('DroppLocationID', payload.DroppLocationID);
    formData.append('DroppLocationName', payload.DroppLocationName);
    formData.append('EcomCartShippingMethodID', state.cart.selectedShippingMethod);
    const response = await CartAPI.postFormData(formData);
    if (isCartErrorResponse(response)) {
      console.error(`failed calling setDeliveryInfo ${JSON.stringify(payload)} from cart`, response.data);
    } else {
      // commit('setDeliveryInfo', response.data);
      commit('partialCartUpdate', {
        cart: response.data,
        fields: ['DeliveryInfo', 'ShippingFee', 'General'],
      });
    }
  },
};
