import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "creditCardContainer" }
const _hoisted_2 = {
  key: 0,
  class: "modal modal--error"
}
const _hoisted_3 = { class: "nestedCardInformation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextInput = _resolveComponent("TextInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.creditCardState.errorMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.creditCardState.errorMessage), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_TextInput, {
      value: _ctx.creditCardState.cardHolderName,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.creditCardState.cardHolderName) = $event)),
      label: "Korthafi",
      name: "card-holder-name",
      width: "100%",
      height: "48",
      class: "checkoutForm_Item"
    }, null, 8 /* PROPS */, ["value"]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_TextInput, {
        value: _ctx.creditCardState.cardPan,
        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.creditCardState.cardPan) = $event)),
        label: "Kortanúmer",
        name: "card-pan",
        width: "100%",
        height: "48",
        class: "checkoutForm_Item cardNumber"
      }, null, 8 /* PROPS */, ["value"]),
      _createVNode(_component_TextInput, {
        value: _ctx.creditCardState.cardExpMonth,
        "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.creditCardState.cardExpMonth) = $event)),
        label: "Mánuður",
        name: "card-exp-month",
        width: "100%",
        height: "48",
        class: "checkoutForm_Item"
      }, null, 8 /* PROPS */, ["value"]),
      _createVNode(_component_TextInput, {
        value: _ctx.creditCardState.cardExpYear,
        "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.creditCardState.cardExpYear) = $event)),
        label: "Ár (18-99)",
        name: "card-exp-year",
        width: "100%",
        height: "48",
        class: "checkoutForm_Item",
        "input-handler": (v) => v.substr(-2)
      }, null, 8 /* PROPS */, ["value", "input-handler"]),
      _createVNode(_component_TextInput, {
        value: _ctx.creditCardState.cardCVC,
        "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.creditCardState.cardCVC) = $event)),
        label: "CVC",
        name: "card-cvc",
        width: "100%",
        height: "48",
        class: "checkoutForm_Item",
        onBlur: _cache[5] || (_cache[5] = ($event: any) => (_ctx.creditCardState.cardCVC = _ctx.creditCardState.cardCVC.value))
      }, null, 8 /* PROPS */, ["value"])
    ])
  ]))
}