/* eslint-disable no-param-reassign */
import { Facet } from '@/models/product/facet';

function getOnlyActiveFacets(facets: Facet[]): Facet[] {
  facets.forEach((facet: Facet) => {
    facet.Options = facet.Options.filter((option) => option.Count !== undefined);
  });
  return facets;
}

export { getOnlyActiveFacets };
