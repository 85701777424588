import axios from 'axios';
import { mapKeys } from 'lodash';
import {
  GetUserResponse,
  PasswordRecoverRequestResponse,
  UserCreatedResponse,
  UserLoginResponse,
  UserLogoutResponse,
  UserUpdatedResponse,
} from '@/models/user/responseModels';
import * as LOGIN from '@/constants/user_management/UserManagementLogin';
import { CreateUserPayload, UserFormData } from '@/models/user/user';
import DynamicWebApi from './dwApi';
import conf from '@/config';

class UserManagement {
  static getUrl(): string {
    return `${conf.dwUrl}/${conf.area}/usermanagement`;
  }

  static async loginUser(formData: FormData): Promise<UserLoginResponse> {
    const res = await axios.post(`${this.getUrl()}/login`, formData, { withCredentials: true, headers: { 'Content-Type': 'multipart/form-data' } });
    return res;
  }

  static async getUser(): Promise<GetUserResponse> {
    const res = await axios.get(`${this.getUrl()}/get-user`, { withCredentials: true });
    return res;
  }

  static async logoutUser(): Promise<UserLogoutResponse> {
    const LOGOUT_PAGE_ID = conf.logoutPageId;
    const res = await axios.get(`${conf.dwUrl}/Admin/Public/ExtranetLogoff.aspx?ID=${LOGOUT_PAGE_ID}`, { withCredentials: true });
    return res;
  }

  static async isAuthenticated(): Promise<boolean> {
    const res = await axios.get(`${this.getUrl()}/login`, { withCredentials: true });
    return res.data?.Authenticated;
  }

  /**
   * UserManagement_Form_EmailAllowed and UserManagement_Form_Email cannot have empty values if they are sent as part of the form data
   * @param formData
   * @returns
   */
  static async updateUser(formData: UserFormData): Promise<UserUpdatedResponse> {
    return DynamicWebApi.postFeed(`${conf.area}/usermanagement/update-user`, {
      UserManagementForm: '1',
      ...mapKeys(formData, (_, key) => `UserManagement_Form_${key}`),
    });
  }

  static async createUser(formData: CreateUserPayload): Promise<UserCreatedResponse> {
    // The reason we must create the user, log him in and then fetch him, is that the post request returns a 302 redirect code making the browser perform a
    // GET request to the feed page but the formData is stripped and not included in the get request
    const response = await DynamicWebApi.postFeed(`${conf.area}/usermanagement/create-user`, {
      UserManagementForm: '1',
      ...mapKeys(formData.User, (_, key) => `UserManagement_Form_${key}`),
      ...mapKeys(formData.Password, (_, key) => `UserManagement_Form_${key}`),
    });
    const errors = response?.data?.Errors;
    if (errors?.length) {
      return response;
    }
    return {} as UserCreatedResponse;
  }

  static async requestToRecoverPassword(formData: FormData): Promise<PasswordRecoverRequestResponse> {
    formData.append('LoginAction', 'Recovery');
    const additionalData = await axios.post(`${this.getUrl()}/request-password-recovery`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
    formData.append(LOGIN.EMAIL, formData.get('username') || '');
    formData.append(LOGIN.PASSWORD_RECOVERY_TEMPLATE, additionalData.data.MailTemplate);
    formData.append(LOGIN.PASSWORD_RECOVERY_SENDER_EMAIL, additionalData.data.SenderEmail);
    formData.append(LOGIN.PASSWORD_RECOVERY_EMAIL_SUBJECT, additionalData.data.MailSubject);
    formData.append('RecoveryLink', `${window.location.origin}/password-recovery`);
    const res = await axios.post(`${this.getUrl()}/request-password-recovery`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
    return res;
  }

  static async changePassword(formData: FormData): Promise<PasswordRecoverRequestResponse> {
    formData.append('LoginAction', 'ChangePassword');
    const res = await axios.post(`${this.getUrl()}/request-password-recovery`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
    return res;
  }

  static async passwordRecoveryInfo(token: string): Promise<PasswordRecoverRequestResponse> {
    const res = await axios.get(`${this.getUrl()}/request-password-recovery?recoverytoken=${token}`);
    return res;
  }
}

export default UserManagement;
