import { omit } from 'lodash';

export type MetaTags = { [key: string]: Element };
type MetaTagsState = { metaTags: MetaTags };

const initialState = { metaTags: {} } as MetaTagsState;

const mutations = {
  saveMetaTags(state: MetaTagsState, metaTags: MetaTags): void {
    state.metaTags = { ...state.metaTags, ...metaTags };
  },
  clearMetaTags(state: MetaTagsState, metaTagKeys: string[]): void {
    state.metaTags = omit(state.metaTags, metaTagKeys);
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
};
