
  import { filter, get, sumBy } from 'lodash';
  import { computed, defineComponent, PropType } from 'vue';
  import { SearchResultsContent, SearchResultsProducts, SearchResultsProductsByShop } from '@/models/search/searchModels';
  import SearchResultsProductSections from './SearchResultsProductSections.vue';
  import SearchResultsContentList from './SearchResultsContentList.vue';
  import SearchProductList from './SearchProductList.vue';

  export default defineComponent({
    components: {
      SearchResultsProductSections,
      SearchResultsContentList,
      SearchProductList,
    },
    props: {
      productResults: {
        type: Object as PropType<SearchResultsProductsByShop>,
        required: false,
        default: null,
      },
      contentResults: {
        type: Object as PropType<SearchResultsContent>,
        required: false,
        default: null,
      },
    },
    setup(props) {
      const sectionsWithResults = computed<Array<SearchResultsProducts>>(() => filter(props.productResults) as Array<SearchResultsProducts>);
      const totalProductCount = computed<number>(() => sumBy(sectionsWithResults.value, ({ TotalProductsCount }) => TotalProductsCount || 0));
      const totalContentCount = computed<number>(() => get(props.contentResults, 'ResultCount', 0) as number);

      const activeSection = computed<SearchResultsProducts | null>(() => (sectionsWithResults.value.length > 0 ? sectionsWithResults.value[0] : null));
      const activeSectionShop = computed<string | null>(() => get(Object.keys(props.productResults), '0', null));

      return {
        sectionsWithResults,
        totalProductCount,
        totalContentCount,

        activeSection,
        activeSectionShop,
      };
    },
  });
