
  import { defineComponent, PropType } from 'vue';
  import UserOverviewView from '@/components/user/UserOverviewView.vue';

  interface DwComponentProps {
    informationText: string;
    orderCardHeaderText?: string;
  }

  export default defineComponent({
    components: {
      UserOverviewView,
    },
    props: {
      /**
       * Component props loaded from DynamicWeb
       */
      componentProps: {
        type: Object as PropType<DwComponentProps>,
        required: true,
      },
    },
    computed: {
      orderHeaderText(): string {
        return this.componentProps.orderCardHeaderText || 'Síðasta pöntunin þín';
      },
    },
  });
