/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { forEach } from 'lodash';
import conf from '@/config';

export default class DynamicWebApi {
  static url(relative?: string): string {
    return [conf.dwUrl, 'dwapi', relative].join('/');
  }

  static feedUrl(relative?: string): string {
    return [conf.dwUrl, relative].join('/');
  }

  static async get(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<any>> {
    return axios.get(DynamicWebApi.url(url), config);
  }

  static async post(url: string, data?: string | Record<string, any>, config?: AxiosRequestConfig): Promise<AxiosResponse<any>> {
    return axios.post(DynamicWebApi.url(url), data, config);
  }

  static async ping(): Promise<boolean> {
    try {
      const response = await DynamicWebApi.get('/feeds/VerifyConnection');
      return response?.data === 'true';
    } catch (e) {
      if (!axios.isCancel(e)) {
        console.error(e);
      }
    }
    return false;
  }

  static async getFeed(url: string, data?: Record<string, string>, config?: AxiosRequestConfig): Promise<AxiosResponse<any>> {
    const queryUrl = `${url}${data ? `?${new URLSearchParams(data).toString()}` : ''}`;
    return axios.get(DynamicWebApi.feedUrl(queryUrl), { withCredentials: true, ...(config || {}) } as AxiosRequestConfig);
  }

  static async postFeed(url: string, data?: Record<string, any>, config?: AxiosRequestConfig): Promise<AxiosResponse<any>> {
    const defaultConfig = {
      withCredentials: true,
      headers: { 'Content-Type': 'multipart/form-data' },
    } as AxiosRequestConfig;

    const formParams = new FormData();

    forEach(data || {}, (value, key) => formParams.append(key, value));

    return axios.post(DynamicWebApi.feedUrl(url), formParams, { ...defaultConfig, ...(config || {}) });
  }
}
