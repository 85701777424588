
  import { defineComponent, PropType, computed } from 'vue';
  import { get, keyBy } from 'lodash';
  import SearchView from '@/components/search/SearchView.vue';
  import { ItemType } from '@/models/field';
  import { SearchShopConfig, SearchConfig } from '@/models/search/searchModels';
  import { SearchConfigKey } from '@/components/search/SearchSymbols';
  import conf from '@/config';

  interface ComponentProps {
    includeContentSearch?: boolean;
    linkToMasterSearch?: string;
    searchQueryKey?: string;
    searchHref?: string;
    searchAreas: ItemType[];
    maxDisplaySize?: number | null;
    showLoadMore?: boolean;
    formPlaceholderText?: string;
    popularSearchTerms: {
      SelectedValues: string[];
    };
  }

  export default defineComponent({
    components: {
      SearchView,
    },
    provide() {
      return {
        [SearchConfigKey as symbol]: computed(() => this.searchConfig),
      };
    },
    props: {
      componentProps: {
        required: true,
        type: Object as PropType<ComponentProps>,
      },
    },
    computed: {
      searchConfig(): SearchConfig {
        const shops = get(this.componentProps, 'searchAreas', [] as ItemType[]).reduce((acc, { Fields }) => {
          const fieldObj = keyBy(Fields, 'SystemName');
          const configKey = get(fieldObj, 'Shop.Value.SelectedValue', '');
          const repositoryValues = (get(fieldObj, 'Repository_Name.Value.SelectedValue', '') as string).split('_', 2);
          const config = {
            shopName: get(fieldObj, 'Shop.Value.SelectedName', ''),
            routePath: `/${get(fieldObj, 'Shop.Value.SelectedValue')}`,
            repositoryName: get(fieldObj, 'Repository_Name.Value.SelectedName', ''),
            queryName: get(repositoryValues, '0', ''),
            facetName: `${get(repositoryValues, '1', undefined)}${conf.env !== 'PROD' ? '.facets' : ''}`,
            showLoadMore: get(fieldObj, 'Show_Load_More.Value', false),
            maxDisplaySize: get(fieldObj, 'Max_Display_Size.Value', 0),
          } as SearchShopConfig;
          return { ...acc, [configKey as string]: config };
        }, {});

        return {
          shops,
          queryKey: this.componentProps.searchQueryKey || 'Leit',
          href: this.componentProps.searchHref || 'leit',
          masterSearch: this.componentProps.linkToMasterSearch,
          formPlaceholderText: this.componentProps.formPlaceholderText || 'Leita á vefverslunum S4S',
          popularSearchTerms: this.componentProps.popularSearchTerms?.SelectedValues ? this.componentProps.popularSearchTerms.SelectedValues : [],
        };
      },
    },
  });
