
  import { defineComponent } from 'vue';
  import Breadcrumb from '@/global_components/Breadcrumb.vue';
  import ProductListSortDropdown from './ProductListSortDropdown.vue';
  import useProductListSort from '@/composables/useProductListSort';

  export default defineComponent({
    components: {
      Breadcrumb,
      ProductListSortDropdown,
    },
    props: {
      count: {
        required: true,
        type: Number,
      },
      filtersOpen: {
        type: Boolean,
        required: false,
        default: false,
      },
      sortBy: {
        type: String,
        required: false,
        default: undefined,
      },
    },
    emits: ['update:filtersOpen', 'update:sortBy'],
    setup(props, { emit }) {
      const toggleFilterMenu = () => emit('update:filtersOpen', !props.filtersOpen);

      const { sortOptions, emptyOption } = useProductListSort();

      const onSortUpdate = (value?: string) => {
        emit('update:sortBy', sortOptions.find((item) => item.value === value) || emptyOption);
      };

      return {
        toggleFilterMenu,
        sortOptions,
        onSortUpdate,
      };
    },
  });
