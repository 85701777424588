import { ActionContext, ActionTree } from 'vuex';
import { get, isArray } from 'lodash';
import * as ACTIONS from '@/constants/store/auth/authActionTypes';
import { AuthState, LoginPayload } from '@/models/store/auth';
import UserApi from '@/API/userApi';
import * as MUTATIONS from '@/constants/store/auth/authMutationTypes';
import { CreateUserPayload, PasswordPayload, User, UserFormData, UserPayload } from '@/models/user/user';
import { Address, AddressesResponse, AddressKeyedPayload, AddressPayload } from '@/models/address/address';
import AddressApi from '@/API/addressApi';
import { OrderPagination } from '@/models/order/order';
import OrderApi from '@/API/orderApi';
import { UserCreatedResponse } from '@/models/user/responseModels';
import WishlistAPI from '@/API/wishlistApi';
import { WishlistProduct, WishlistResponse } from '@/models/wishlist/wishlist';
import { ProductIsFavoritePayload, ProductListPayload } from '@/models/productList/productListPayloadModels';
import ProductAPI from '@/API/productApi';
import { getMainWebshop } from '@/utils/dataHelper';

type Context = ActionContext<AuthState, unknown>;

const mapAddressesResponse = (state: AuthState, response: AddressesResponse): Address[] =>
  (isArray(response?.data) ? response.data : []).map((address) => ({
    ...address,
    UserId: get(state, 'user.information.Id', ''),
    Name: get(state, 'user.information.Name', ''),
    Email: get(state, 'user.information.Email', ''),
  }));

const getAuthUserId = (state: AuthState): string => get(state, 'user.information.Id', '');

export default {
  async [ACTIONS.AUTH_LOGIN]({ dispatch }: Context, { username, password }: LoginPayload): Promise<unknown> {
    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    const login = await UserApi.loginUser(formData);

    if (!login.data.Authenticated) {
      throw new Error('Unauthenticated.');
    }

    return dispatch(ACTIONS.GET_AUTH_USER_DATA);
  },
  async [ACTIONS.AUTH_LOGOUT]({ commit }: Context): Promise<void> {
    const logout = await UserApi.logoutUser();
    if (logout.data.LoggedOut) {
      commit(MUTATIONS.CLEAR_AUTHENTICATED_USER);
    }
  },
  async [ACTIONS.PASSWORD_RECOVERY](_, username: string): Promise<unknown> {
    const formData = new FormData();
    formData.append('username', username);
    const result = await UserApi.requestToRecoverPassword(formData);
    return result;
  },
  async [ACTIONS.CHANGE_PASSWORD](_, formFields): Promise<unknown> {
    const { password, confirmPassword, token } = formFields;
    const formData = new FormData();
    formData.append('UserManagement_Form_NewPassword', password);
    formData.append('UserManagement_Form_NewPasswordConfirm', confirmPassword);
    formData.append('RecoveryToken', token);
    const result = await UserApi.changePassword(formData);
    return result;
  },
  async [ACTIONS.PASSWORD_RECOVERY_INFO](_, token: string): Promise<unknown> {
    const result = await UserApi.passwordRecoveryInfo(token);
    return result;
  },
  async [ACTIONS.CREATE_USER]({ dispatch }: Context, formFields: CreateUserPayload): Promise<UserCreatedResponse> {
    const result = await UserApi.createUser(formFields);

    if (result?.data?.Errors?.length) {
      return result;
    }

    const username = formFields.User.Email || '';
    const password = formFields.Password.newPassword || '';
    dispatch(ACTIONS.AUTH_LOGIN, { username, password });

    return result;
  },
  async [ACTIONS.GET_AUTH_USER]({ commit }: Context): Promise<User | null> {
    const user = await UserApi.getUser();
    if (get(user, 'data.UserName', '') === '') {
      commit(MUTATIONS.CLEAR_AUTHENTICATED_USER);
    } else {
      commit(MUTATIONS.SET_AUTHENTICATED_USER, user.data);
    }

    return user.data;
  },
  async [ACTIONS.GET_AUTH_USER_DATA]({ dispatch }: Context): Promise<unknown> {
    const user = await dispatch(ACTIONS.GET_AUTH_USER);

    if (get(user, 'UserName', '') !== '') {
      return Promise.all([dispatch(ACTIONS.GET_USER_ADDRESSES), dispatch(ACTIONS.GET_USER_ORDERS)]);
    }

    return null;
  },
  async [ACTIONS.GET_USER_ADDRESSES]({ commit, state }: Context): Promise<Address[]> {
    const response = await AddressApi.getUserAddresses();
    const addresses = mapAddressesResponse(state, response);
    commit(MUTATIONS.SET_USER_ADDRESSES, addresses);
    return addresses;
  },
  async [ACTIONS.CREATE_USER_ADDRESS]({ commit, state }: Context, address: AddressPayload): Promise<Address[]> {
    // = Refactor = Handle if the response is bad
    const response = await AddressApi.createUserAddress(getAuthUserId(state), address);
    const addresses = mapAddressesResponse(state, response);
    commit(MUTATIONS.SET_USER_ADDRESSES, addresses);
    return addresses;
  },
  async [ACTIONS.DELETE_USER_ADDRESS]({ commit, state }: Context, addressId: string): Promise<Address[]> {
    const response = await AddressApi.deleteUserAddress(getAuthUserId(state), addressId);
    const addresses = mapAddressesResponse(state, response);
    commit(MUTATIONS.SET_USER_ADDRESSES, addresses);
    return addresses;
  },
  async [ACTIONS.UPDATE_USER_ADDRESS]({ state, dispatch }: Context, { id, address }: AddressKeyedPayload): Promise<Address[]> {
    await AddressApi.updateUserAddress(getAuthUserId(state), id, address);
    return dispatch(ACTIONS.GET_USER_ADDRESSES);
  },
  async [ACTIONS.MAKE_ADDRESS_DEFAULT]({ commit, state }: Context, addressId: string): Promise<Address[]> {
    const response = await AddressApi.makeUserAddressDefault(getAuthUserId(state), addressId);
    const addresses = mapAddressesResponse(state, response);
    commit(MUTATIONS.SET_USER_ADDRESSES, addresses);
    return addresses;
  },
  async [ACTIONS.GET_USER_ORDERS]({ commit }: Context): Promise<OrderPagination> {
    const response = await OrderApi.getUserOrders();
    const orders = response.data;
    commit(MUTATIONS.SET_USER_ORDERS, orders);
    return orders;
  },
  async [ACTIONS.UPDATE_USER_PROFILE]({ commit }: Context, user: UserPayload): Promise<User | null> {
    const response = await UserApi.updateUser({
      EmailAllowed: '1',
      Name: user.name,
      Phone: user.phoneNumber,
      ExternalId: user.ssn,
    } as UserFormData);

    if (!response.data && response.data !== '') {
      return null;
    }

    commit(MUTATIONS.UPDATE_USER, response.data);

    return response.data;
  },
  async [ACTIONS.UPDATE_USER_PASSWORD](_, passwordPayload: PasswordPayload): Promise<User | null> {
    const response = await UserApi.updateUser({
      OldPassword: passwordPayload.oldPassword,
      NewPassword: passwordPayload.newPassword,
      NewPasswordConfirm: passwordPayload.newPasswordConfirm,
    } as UserFormData);

    if (!response.data && response.data !== '') {
      return null;
    }

    return response.data;
  },
  async [ACTIONS.IS_AUTHENTICATED]({ commit }: Context): Promise<void> {
    const isAuthenticated = await UserApi.isAuthenticated();
    if (!isAuthenticated) {
      commit(MUTATIONS.CLEAR_AUTHENTICATED_USER);
    }
  },
  async [ACTIONS.GET_USER_WISHLIST]({ commit }: Context): Promise<WishlistProduct[]> {
    const wishlist = await WishlistAPI.getWishlist();
    commit(MUTATIONS.SET_USER_WISHLIST_ID, wishlist.data.Id);
    return wishlist.data.WishlistProducts as WishlistProduct[];
  },
  async [ACTIONS.TOGGLE_PRODUCT_IN_WISHLIST]({ commit, state }, product: ProductIsFavoritePayload): Promise<WishlistResponse> {
    const wishListId = state.user?.wishlistId;
    let response = {} as WishlistResponse;
    if (product.isFavorite) {
      if (typeof wishListId === 'number') {
        response = await WishlistAPI.removeFromFavorites(product.productId, wishListId);
      }
    } else {
      response = await WishlistAPI.addToFavorites(product.productId);
      if (wishListId === null) {
        commit(MUTATIONS.SET_USER_WISHLIST_ID, response.data.Id);
      }
    }
    return response;
  },
  async [ACTIONS.DELETE_PRODUCT_FROM_WISHLIST]({ commit, state, dispatch }: Context, id: string): Promise<void> {
    if (typeof state.user?.wishlistId === 'number') {
      WishlistAPI.removeFromFavorites(id, state.user?.wishlistId);
      dispatch('productList/removeIsFavoriteFromProducts', [id], { root: true });
      commit(MUTATIONS.DELETE_PRODUCT_FROM_WISHLIST, id);
    }
  },
  async [ACTIONS.DELETE_USER_WISHLIST]({ commit, state, dispatch }: Context, wishlistId: number): Promise<boolean> {
    await WishlistAPI.deleteWishlist(wishlistId);
    const productIds = ((state.user?.wishlist as WishlistProduct[]) || []).map((product) => product.Id);
    dispatch('productList/removeIsFavoriteFromProducts', productIds, { root: true });
    commit(MUTATIONS.DELETE_USER_WISHLIST);
    commit(MUTATIONS.SET_USER_WISHLIST_ID, null);
    return true;
  },
  async [ACTIONS.GET_WISHLIST_PRODUCTS]({ commit }: Context, payload: ProductListPayload): Promise<WishlistProduct[]> {
    const response = await ProductAPI.getProductList(payload);

    const wishlistProducts = response?.data?.Products?.map(
      (product) =>
        ({
          Id: product.Id,
          Name: product.Name,
          Manufacturer: product.Manufacturer?.Name,
          Webshop: getMainWebshop(product.ProductFields.WebShops.Value),
          Image: product.DefaultImage?.Value,
        }) as WishlistProduct,
    );

    return wishlistProducts;
  },
} as ActionTree<AuthState, unknown>;
