import kennitala from 'kennitala';
import { Postcode } from '@/models/cart/postcode';

export type Validator = (input: string) => string | null;

// Naive regexes
const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const PHONE_REGEX = /^[6-8]\d{2}-?\d{4}$/;
const GIFTCARD_REGEX = /^\d{8,13}$/;
const NUMBER_REGEX = /^\d+$/;

// Ingi Þór bað um að kennitölur fyrirtækja væru líka teknar gildar
const isSsnLegal = (input: string) => kennitala.isPerson(input) || kennitala.isCompany(input);

export const minLength =
  (min: number, errorMessage?: string): ((input: string) => string | null) =>
  (input: string) =>
    input?.length < min ? errorMessage || `Gildi þarf að vera að minnsta kosti ${min} stafir` : null;
export const exactLength =
  (length: number, errorMessage?: string): ((input: string) => string | null) =>
  (input: string) =>
    input?.length !== length ? errorMessage || `Gildi þarf að vera ${length} stafir` : null;
export const validatePostCode =
  (list: Postcode[], errorMessage: string): ((input: string) => string | null) =>
  (input: string) =>
    list?.length > 0 && !list.map((postCode: Postcode) => postCode.postcode).includes(input) ? errorMessage : null;
export const validateSSN =
  (errorMessage: string): ((input: string) => string | null) =>
  (input: string) =>
    !isSsnLegal(input) ? errorMessage : null;
export const validateSSNOptional =
  (errorMessage: string): ((input: string) => string | null) =>
  (input: string) =>
    input?.length > 0 && !isSsnLegal(input) ? errorMessage : null;
export const validatePhoneNumber = (errorMessage: string) => {
  return (input: string) => {
    const result = PHONE_REGEX.test(input) ? null : errorMessage;
    return result;
  };
};

export const validateEmail =
  (errorMessage: string): ((input: string) => string | null) =>
  (input: string) =>
    EMAIL_REGEX.test(input) ? null : errorMessage;
export const validateGiftCard =
  (errorMessage: string): ((input: string) => string | null) =>
  (input: string) =>
    GIFTCARD_REGEX.test(input) ? null : errorMessage;
export const validateNumber =
  (errorMessage: string): ((input: string) => string | null) =>
  (input: string) =>
    NUMBER_REGEX.test(input) ? null : errorMessage;
