
  import { defineComponent, PropType } from 'vue';
  import { Address } from '@/models/address/address';
  import UserContentCard from '@/components/user/UserContentCard.vue';
  import Icon from '@/components/shared/Icon.vue';

  export default defineComponent({
    components: {
      UserContentCard,
      Icon,
    },
    props: {
      /**
       * Address model to render
       */
      address: {
        required: true,
        type: Object as PropType<Address>,
      },
      /**
       * Determine if user controls should be rendered or not
       */
      controls: {
        required: false,
        type: Boolean,
        default: false,
      },
      /**
       * Determine if a header underline should be added
       */
      underlined: {
        required: false,
        type: Boolean,
        default: false,
      },
    },
    emits: ['delete', 'edit', 'make-default'],
    computed: {
      canMakeAddressDefault(): boolean {
        return this.controls && !this.address.Default;
      },
    },
    methods: {
      onDeleteClick() {
        this.$emit('delete', this.address);
      },
      onEditClick() {
        this.$emit('edit', this.address);
      },
      onIconClick() {
        if (this.canMakeAddressDefault) {
          this.$emit('make-default', this.address);
        }
      },
    },
  });
