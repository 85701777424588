
  import { computed, defineComponent } from 'vue';

  export default defineComponent({
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      label: {
        type: String,
        required: false,
        default: '',
      },
      isCircle: {
        type: Boolean,
        required: false,
        default: false,
      },
      id: {
        type: String,
        required: true,
      },
      type: {
        type: String,
        required: false,
        default: 'checkbox',
      },
      name: {
        type: String,
        required: false,
        default: null,
      },
      checked: {
        type: Boolean,
        required: false,
        default: false,
      },
      error: {
        type: Boolean,
        required: false,
        default: false,
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    emits: ['update:value', 'click'],
    setup(props, { emit }) {
      const onClick = (event: Event) => {
        const { checked } = event.target as HTMLInputElement;
        emit('click', checked);
        emit('update:value', checked);
      };

      const getStyles = computed(() => ({
        '--border-radius': props.isCircle ? '50%' : 'initial',
      }));

      return {
        onClick,
        getStyles,
      };
    },
  });
