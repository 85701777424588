import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-02dd41f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "iconsWithText_Wrapper" }
const _hoisted_2 = { class: "iconsWithText_Wrapper_IconListItem_Icon" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "iconsWithText_Wrapper_IconListItem_Text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.getContainerStyles),
    class: _normalizeClass(["iconsWithText", `iconsWithText_${_ctx.boxCount}boxes`])
  }, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getData.list, (icon, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "iconsWithText_Wrapper_IconListItem"
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("img", {
                class: "box_Icon icon-l",
                src: icon.icon,
                alt: "icon"
              }, null, 8 /* PROPS */, _hoisted_3)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", null, [
                _createElementVNode("p", {
                  class: "box_Text",
                  style: _normalizeStyle(_ctx.getTextStyles)
                }, _toDisplayString(_ctx.cutSentenceIfNeeded(icon.text, 50)), 5 /* TEXT, STYLE */)
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("p", {
                  class: "box_Text",
                  style: _normalizeStyle(_ctx.getTextStyles)
                }, _toDisplayString(_ctx.cutSentenceIfNeeded(icon.subtext, 50)), 5 /* TEXT, STYLE */)
              ])
            ])
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ])
  ], 6 /* CLASS, STYLE */))
}