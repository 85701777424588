import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-57d41d9e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "article-card" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["href"]
const _hoisted_4 = {
  key: 0,
  class: "article-card__date"
}
const _hoisted_5 = { class: "article-card__text" }
const _hoisted_6 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      class: "article-card__image",
      src: _ctx.imagePath(_ctx.article.image)
    }, null, 8 /* PROPS */, _hoisted_2),
    _createElementVNode("a", {
      class: "article-card__title",
      href: _ctx.article.link
    }, _toDisplayString(_ctx.article.title), 9 /* TEXT, PROPS */, _hoisted_3),
    (_ctx.article.publishDate != '')
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.formatDate(_ctx.article.publishDate)), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.article.snippet), 1 /* TEXT */),
    _createElementVNode("a", {
      class: "article-card__read-more",
      href: _ctx.article.link
    }, " Lesa meira ", 8 /* PROPS */, _hoisted_6)
  ]))
}