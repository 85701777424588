
  import { computed, defineComponent, onMounted, PropType, ref } from 'vue';
  import { mapGetters } from 'vuex';
  import useInputValidator from '@/composables/useInputValidator';
  import RenderErrors from '@/components/shared/inputs/RenderErrors.vue';
  import Icon from '@/components/shared/Icon.vue';

  /**
   * Text input utility wrapper
   *
   * @deprecated Nota TextInputNew í staðinn og nota useFormValidator composable í form parent
   */
  export default defineComponent({
    components: {
      RenderErrors,
      Icon,
    },
    props: {
      /**
       * V-model target (Set in created if initializing from data)
       *
       * @model
       */
      value: {
        type: [String, Number],
        required: true,
      },
      /**
       * Maps to input name
       */
      name: {
        type: String,
        required: false,
        default: '',
      },
      /**
       * Text for the label element
       */
      label: {
        type: String,
        required: false,
        default: '',
      },
      id: {
        type: String,
        required: false,
        default: null,
      },
      height: {
        type: String,
        required: false,
        default: '48px',
      },
      /**
       * Specify CSS width of the input
       */
      width: {
        type: String,
        required: false,
        default: '180px',
      },
      /**
       * Input placeholder
       */
      placeholder: {
        type: String,
        required: false,
        default: '-',
      },
      /**
       * Add validators to be run on input events
       */
      validators: {
        type: Object as PropType<Array<(input: string) => string | null>>,
        required: false,
        default: () => [] as Array<(input: string) => string | null>,
      },
      /**
       * Set a limit for the number of error elements that can be displayed
       */
      errorLimit: {
        type: Number,
        required: false,
        default: null,
      },
      /**
       * Maps to input type
       */
      type: {
        type: String,
        required: false,
        default: 'text',
      },
      /**
       * Hide label element
       */
      hasLabel: {
        type: Boolean,
        required: false,
        default: true,
      },
      /**
       * Add autofocus to the input element
       */
      autofocus: {
        type: Boolean,
        required: false,
        default: false,
      },
      /**
       * Add required to the input element
       */
      required: {
        type: Boolean,
        required: false,
        default: false,
      },
      /**
       * Add autocomplete to the input element
       */
      autocomplete: {
        type: String,
        required: false,
        default: 'off',
      },
      /**
       * Append an icon to the input.
       * @values See Sprites.vue for sprite IDs
       */
      icon: {
        required: false,
        type: String,
        default: null,
      },
      maxlength: {
        type: Number,
        required: false,
        default: null,
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      inputHandler: {
        type: Function as PropType<(value: string) => string>,
        required: false,
        default: null,
      },
    },
    emits: ['update:value', 'focus'],
    setup(props, { emit, expose }) {
      const { input, errors, validate } = useInputValidator(props.value.toString(), props.validators, (value) => emit('update:value', value));

      const onInput = (event: Event) => {
        if (props.validators.length === 0) {
          const { value } = event.target as HTMLInputElement;
          input.value = props.inputHandler ? props.inputHandler(value) : value;
        }
      };

      const onChange = (event: Event) => {
        if (props.validators.length > 0) {
          const { value } = event.target as HTMLInputElement;
          input.value = value;
        }
      };

      const onFocus = (event: Event) => {
        emit('focus', event);
      };

      const inputRef = ref<HTMLInputElement>();

      const hasErrors = computed(() => errors.value.length > 0 && errors.value.some((e) => e !== null));

      expose({ hasErrors });

      onMounted(() => {
        if (props.autofocus && inputRef) {
          (inputRef.value as HTMLInputElement).focus();
        }
      });

      return {
        onInput,
        onChange,
        onFocus,
        input,
        errors,
        hasErrors,
        validate,
        inputRef,
      };
    },
    computed: {
      ...mapGetters('website', {
        websiteSettings: 'getWebsiteSettings',
      }),
      getStyles(): any {
        return {
          '--width': this.$props.width,
          '--height': this.$props.height,
        };
      },
      hasIcon(): boolean {
        return this.icon !== null || !!this.$slots.icon;
      },
    },
  });
