import { Commit } from 'vuex';

import PosturinnAPI from '@/API/posturinnAPI';

export default {
  async setPostCodes({ commit }: { commit: Commit }): Promise<void> {
    const postCodes = await PosturinnAPI.getPostCodes();
    commit('setPostCodes', postCodes);
  },
};
