import EventEmitter from 'events';

export interface AreaHandler {
  (areaId: number): void;
}

/**
 * Class to handle publishing events when nav areas or pages change
 */
export default new (class NavBus extends EventEmitter {
  protected handlers = [] as AreaHandler[];

  /**
   * Add a handler to react to nav changes.
   *
   * @param handler
   */
  addHandler(handler: AreaHandler) {
    this.handlers.push(handler);
  }

  /**
   * Remove a handler
   *
   * @param handler
   */
  clearHandler(handler: AreaHandler) {
    const idx = this.handlers.findIndex((h: AreaHandler) => h === handler);
    if (idx !== -1) {
      this.handlers.splice(idx, 1);
    }
  }

  /**
   * Trigger area update event
   *
   * @param route
   */
  updateArea(areaId: number) {
    this.handlers.forEach((handler) => handler(areaId));
  }
})();
