import { useStore } from 'vuex';
import { ref, Ref } from 'vue';
import { ShippingAgent, ShippingMethod } from '@/models/cart/shippingMethod';
import useCart from './useCart';

interface IAdvaniaIntegratedShippingMethod {
  selectedShippingMethodAgent: Ref<ShippingAgent | undefined>;
  selectAdvaniaShippingAgent(shipping: ShippingMethod, selectedAgent?: ShippingAgent): Promise<unknown>;
}

export default function useAdvaniaIntegratedShippingMethod(): IAdvaniaIntegratedShippingMethod {
  const store = useStore();
  const { selectedShippingMethodObject } = useCart();

  const selectAdvaniaShippingAgent = async (shipping: ShippingMethod, selectedAgent?: ShippingAgent) => {
    const formData: FormData = new FormData();

    formData.append('EcomCartShippingMethodID', shipping.DWGeneratedShippingMethodID);
    formData.append('AdvaniaShippingAgentService', shipping.ShippingMethodName);
    formData.append('AdvaniaShippingAgentServiceCode', selectedAgent?.Code || '');
    formData.append('AdvaniaShippingAgentServiceDescription', selectedAgent?.Name || '');

    // Auka reitir, til að fitta inn með eldri útfærslu á sendingarmátum
    formData.append('DroppLocationID', selectedAgent?.Code || '');
    formData.append('DroppLocationName', selectedAgent?.Name || '');
    formData.append('DroppBarcode', selectedAgent?.Code || '');
    formData.append('EcomOrderDeliveryAddress', selectedAgent?.Name?.substring(0, 50) || '');
    formData.append('EcomOrderDeliveryAddress2', selectedAgent?.Code?.substring(0, 50) || '');

    return store.dispatch('cart/getCart', formData);
  };

  const selectedShippingMethodAgent = ref<ShippingAgent | undefined>(
    selectedShippingMethodObject.value?.ShippingAgents.find(({ Selected }) => Selected) || undefined,
  );

  return { selectAdvaniaShippingAgent, selectedShippingMethodAgent };
}
