import { AxiosResponse } from 'axios';
import DynamicWebApi from './dwApi';

export type DwTranslation = {
  Key: string;
  DefaultValue: string;
  Value: string;
}[];
type TranslationsResponse = AxiosResponse<DwTranslation | undefined | null>;

export default class TranslationsApi {
  static async getTranslations(areaId: number | string): Promise<TranslationsResponse> {
    return DynamicWebApi.get(`translations/area/${areaId}`);
  }
}
