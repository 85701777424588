/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ref, watch } from 'vue';

/**
 *
 * @deprecated Instead use the useFormValidation composable that uses Vuelidate to validate the form
 */
export default function useInputValidator(initialValue: string, validators: Array<(input: string) => string | null>, onEmit: (value: any) => void) {
  const input = ref(initialValue);
  const errors = ref([] as Array<string | null>);
  watch(
    () => input.value,
    (value) => {
      errors.value = validators.map((validator) => validator(value));
      onEmit(value);
    },
  );
  const validate = () => {
    errors.value = validators.map((validator) => validator(input.value));
    onEmit(input.value);
  };
  return {
    input,
    errors,
    validate,
  };
}
