import { CustomerInfo } from '@/models/cart/customerInfo';
import { validateEmail, validatePhoneNumber } from '@/utils/formValidators';

export function CheckoutStepValidation() {
  const isContactInfoValid = (contactInfo: CustomerInfo): boolean => 
    contactInfo.CustomerAddress?.length >0 &&
    validateEmail(contactInfo.CustomerEmail) != null&&
    contactInfo.CustomerName?.length > 0 &&
    validatePhoneNumber(contactInfo.CustomerPhone) != null;
  return {
    isContactInfoValid,
  };
}
