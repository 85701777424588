import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4c8a9b31"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "imageTitleAndButton_Title" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "imageTitleAndButton_Button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_button = _resolveComponent("custom-button")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.wrapperAttribute), {
    href: _ctx.linkUrl,
    style: _normalizeStyle({ ..._ctx.getStyles, ..._ctx.getComponentSpecificStyles }),
    class: _normalizeClass(["imageTitleAndButton box_Image", `imageTitleAndButton_${_ctx.boxCount}boxes`])
  }, {
    default: _withCtx(() => [
      _createElementVNode("img", {
        class: "imageTitleAndButton_Image",
        src: _ctx.imageSrc
      }, null, 8 /* PROPS */, _hoisted_1),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.getTitle), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_custom_button, {
          button: _ctx.boxData.button
        }, null, 8 /* PROPS */, ["button"])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["href", "style", "class"]))
}