
  import { defineComponent, PropType } from 'vue';
  import { WishlistProduct } from '@/models/wishlist/wishlist';
  import DropDown from '@/components/shared/inputs/DropDown.vue';
  import Icon from '@/components/shared/Icon.vue';
  import MenuDots from '@/components/shared/MenuDots.vue';
  import useWishlistProduct from '@/composables/useWishlistProduct';

  interface Option {
    value: string;
    notification: string;
    disabled: boolean;
    meta: any;
  }

  export default defineComponent({
    components: {
      DropDown,
      Icon,
      MenuDots,
    },
    props: {
      product: {
        required: true,
        type: Object as PropType<WishlistProduct>,
      },
    },
    emits: ['delete', 'cart-add', 'show-menu'],
    setup(props) {
      const { productImagePath, colorOptions, getVariantOptions, formattedPrice, formattedPriceBeforeDiscount } = useWishlistProduct(props);

      return {
        productImagePath,
        colorOptions,
        getVariantOptions,
        formattedPrice,
        formattedPriceBeforeDiscount,
      };
    },
    data() {
      return {
        selectedSize: 'VELDU STÆRÐ',
        selectedVariant: {},
        selectedProduct: this.product as WishlistProduct,
      };
    },
    computed: {
      selectedColor(): string {
        if (this.product?.RelatedProducts?.length === 0) {
          return this.product.BaseColor;
        }
        if (this.selectedProduct?.BaseColor) {
          return this.selectedProduct.BaseColor;
        }
        return 'VELDU LIT';
      },
      variantOptions(): Option[] {
        return this.getVariantOptions(this.product, this.selectedProduct);
      },
      colorDropdownDisabled(): boolean {
        if (this.product?.RelatedProducts === undefined || this.product.RelatedProducts.length === 0) {
          return true;
        }
        return false;
      },
      variantDropdownDisabled(): boolean {
        if (this.product?.VariantInfo?.VariantInfo === undefined || this.product.VariantInfo.VariantInfo.length === 0) {
          return true;
        }
        return false;
      },
      buyButtonDisabled(): boolean {
        return this.selectedColor === 'VELDU LIT' || this.selectedSize === 'VELDU STÆRÐ';
      },
      webshopColor() {
        let color = 'black';
        if (this.product.Webshop === 'AIR') color = '#000000';
        if (this.product.Webshop === 'SKOR') color = '#16425b';
        if (this.product.Webshop === 'ELLINGSEN') color = '#00ADEE';
        if (this.product.Webshop === 'BRP') color = '#f2b823';
        return {
          color,
        };
      },
      hasDiscount(): boolean {
        return this.product?.Price?.PriceWithVat !== this.product?.PriceBeforeDiscount?.PriceWithVat;
      },
    },
    methods: {
      onWishlistProductDelete(product: WishlistProduct) {
        this.$emit('delete', product);
      },
      onWishlistProductAddToCart() {
        if (!this.buyButtonDisabled) {
          const product = this.product?.RelatedProducts?.length === 0 ? this.product : this.selectedProduct;
          const variant = this.selectedVariant;
          this.$emit('cart-add', { product, variant });
        }
      },
      showMenu(product: WishlistProduct) {
        this.$emit('show-menu', product);
      },
      updateColor(event) {
        this.selectedColor = event.value;
        this.selectedProduct = event.meta.product;
        this.selectedSize = 'VELDU STÆRÐ';
      },
      updateSize(event) {
        this.selectedSize = event.value;
        this.selectedVariant = event.meta.variant;
      },
    },
  });
