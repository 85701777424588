import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2e46730a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card__content" }
const _hoisted_2 = {
  key: 1,
  class: "card__footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`card ${_ctx.bgColorClassComputed}`)
  }, [
    (_ctx.$slots.header)
      ? (_openBlock(), _createElementBlock("h4", {
          key: 0,
          class: _normalizeClass(["card__header", { hasUnderline: _ctx.underlined }])
        }, [
          _renderSlot(_ctx.$slots, "header", {}, undefined, true)
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true),
    _createElementVNode("article", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    (_ctx.$slots.footer)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
        ]))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}