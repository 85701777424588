
  import { computed, defineComponent, PropType } from 'vue';
  import { useStore } from 'vuex';
  import { cloneDeep } from 'lodash';
  import DropDown from '@/components/shared/inputs/DropDown.vue';
  import useOrderLine from '@/composables/useOrderLine';
  import Checkbox from '@/components/shared/inputs/CheckboxInput.vue';
  import { MultiWrapOrderLine } from '@/models/store/cart';
  import useCart from '@/composables/useCart';
  import conf from '@/config';

  export default defineComponent({
    components: {
      DropDown,
      Checkbox,
    },
    props: {
      orderLine: {
        type: Object as PropType<MultiWrapOrderLine>,
        required: true,
      },
    },
    setup(props) {
      const store = useStore();
      const updateMultiGiftWrapOrderLine = (payload) => store.dispatch('cart/updateMultiGiftWrapOrderLine', payload);

      const { selectedGiftWrapCount } = useCart();
      const { productImagePath } = useOrderLine(props);

      const getOptions = computed(() =>
        Array.from({ length: selectedGiftWrapCount.value || 0 }, (_, index) => ({ value: `PAKKI ${index + 1}`, meta: { packageNumber: index + 1 } })),
      );
      const selectedOption = computed(() =>
        props.orderLine.PackageNumber !== null ? `PAKKI ${props.orderLine.PackageNumber}` : props.orderLine.PackageNumber,
      );

      const updateOrderLineGiftWrapStatus = (checked: boolean) => {
        const orderLine = cloneDeep(props.orderLine);
        orderLine.IsGiftWrapped = checked;
        updateMultiGiftWrapOrderLine(orderLine);
      };

      const updateOrderLineGiftWrapPackage = (option: { meta: { packageNumber } }) => {
        const orderLine = cloneDeep(props.orderLine);
        orderLine.PackageNumber = option.meta.packageNumber;
        updateMultiGiftWrapOrderLine(orderLine);
      };

      return {
        productImagePath,
        getOptions,
        updateOrderLineGiftWrapStatus,
        updateOrderLineGiftWrapPackage,
        selectedOption,
      };
    },
    data() {
      return {
        imagePrefix: conf.dwUrl,
      };
    },
  });
