
  import { defineComponent } from 'vue';
  import CartSideOverviewOrderLines from '@/components/cart/checkout/shared/CartSideOverviewOrderLines.vue';
  import CartContactInfoForm from '@/components/cart/checkout/contact-info/CartContactInfoForm.vue';

  export default defineComponent({
    components: {
      CartSideOverviewOrderLines,
      CartContactInfoForm,
    },
  });
