
  /* eslint-disable @typescript-eslint/no-explicit-any */
  import { defineComponent, PropType } from 'vue';
  import Accordion from '@/components/shared/Accordion.vue';
  import Icon from '@/components/shared/Icon.vue';

  interface IAccordionItem {
    title: string;
    subtitle: string;
  }

  export default defineComponent({
    components: {
      Accordion,
      Icon,
    },
    props: {
      showBody: {
        type: Boolean,
        default: false,
      },
      item: {
        required: true,
        type: Object as PropType<IAccordionItem>,
      },
      iconSize: {
        type: String,
        default: '24',
      },
    },
    data() {
      return {
        bodyVisible: false,
      };
    },
    watch: {
      showBody(value) {
        this.bodyVisible = value;
      },
    },
    methods: {
      toggle(): void {
        this.bodyVisible = !this.bodyVisible;
      },
    },
  });
