
  /* eslint-disable @typescript-eslint/no-explicit-any */
  import { defineComponent } from 'vue';
  import gsap from 'gsap';

  import { mapGetters } from 'vuex';
  import { Facet, FacetFilter, FacetOption } from '@/models/product/facet';
  import { ImageHandler } from '@/utils/imageHelper';
  import useParagraph from '@/composables/useParagraph';
  import NewsService from '@/API/newsApi';
  import accordionMixin from '../../../mixins/accordion';
  import FilterSidebar from '@/global_components/FilterSidebar/FilterSidebar.vue';
  import Loader from '@/components/shared/Loader.vue';
  import ArticleCard from '@/components/itemTypes/NewsArticle/ArticleCard.vue';
  import { extractParagraphComponentDataFields } from '@/utils/dataMapper';

  interface NewsArticle {
    image: string;
    title: string;
    published: string;
    snippet: string;
    link: string;
    publishDate: string;
  }

  interface Options {
    newsArticlePageId: string;
  }

  export default defineComponent({
    components: {
      Loader,
      ArticleCard,
      FilterSidebar,
    },
    mixins: [accordionMixin],
    props: {
      componentProps: {
        type: Object,
        default: null,
      },
    },
    setup(props) {
      const { data, getParagraphStyles, getParagraphClasses } = useParagraph(props.componentProps);
      return {
        data,
        getParagraphStyles,
        getParagraphClasses,
      };
    },
    data() {
      return {
        // News
        newsListLoader: false,
        newsList: [] as NewsArticle[],

        // Facets
        facetList: [
          // FacetGroups:
          {
            Name: 'TEGUND PÓSTS',
            QueryParameter: 'ArticleType',
            RenderType: 'Select',
            Options: [
              {
                Name: 'FRÉTT',
                Label: 'FRÉTT',
                Value: 'Frétt',
                Selected: false,
              },
              {
                Name: 'FRÓÐLEIKUR',
                Label: 'FRÓÐLEIKUR',
                Value: 'Fróðleikur',
                Selected: false,
              },
              {
                Name: 'ANNAÐ',
                Label: 'ANNAÐ',
                Value: 'Annað',
                Selected: false,
              },
            ] as FacetOption[],
            ShowMore: false,
          },
          {
            Name: 'ÁR',
            QueryParameter: 'Publish.from',
            RenderType: 'Select',
            Options: [
              {
                Name: '2022',
                Label: '2022',
                Value: '2022-01-01&Publish.to=2022-12-31',
                Selected: false,
              },
              {
                Name: '2021',
                Label: '2021',
                Value: '2021-01-01&Publish.to=2021-12-31',
                Selected: false,
              },
              {
                Name: 'ELDRA',
                Label: 'ELDRA',
                Value: '2021-01-01&Publish.op=lt',
                Selected: false,
              },
            ] as FacetOption[],
            ShowMore: false,
          },
          {
            Name: 'VERSLUN',
            QueryParameter: 'Shop',
            RenderType: 'Select',
            Options: [
              {
                Name: 'AIR',
                Label: 'AIR',
                Value: 'Air',
                Selected: false,
              },
              {
                Name: 'ECCO',
                Label: 'ECCO',
                Value: 'Ecco',
                Selected: false,
              },
              {
                Name: 'ELLINGSEN',
                Label: 'ELLINGSEN',
                Value: 'Ellingsen',
                Selected: false,
              },
            ] as FacetOption[],
            ShowMore: false,
          },
        ] as Facet[],
        facetFilter: [] as FacetFilter[],
        selectedOptions: [] as string[],
        showMoreSelected: [] as string[],

        // Other
        isFilterOpen: true,
      };
    },
    computed: {
      ...mapGetters('website', {
        areaId: 'getAreaID',
      }),
      filterText() {
        if (this.isFilterOpen) {
          return 'FELA SÍU';
        }

        return 'OPNA SÍU';
      },
      getOptions(): Options {
        return extractParagraphComponentDataFields(this.data, ['newsArticlePageId']) as Options;
      },
    },
    mounted() {
      this.getNewsList('');
    },
    methods: {
      async getNewsList(queryParameters) {
        // Start loader
        this.newsListLoader = true;
        // Call api
        this.newsList = await NewsService.getNewsList(queryParameters, this.areaId, this.getOptions.newsArticlePageId);
        // Stop loader
        this.newsListLoader = false;
      },
      filterNewsList(val) {
        const filterQuery = this.createFilterParameterUrl(val);
        window.history.replaceState(window.history.state, '', `${window.location.pathname}?${filterQuery}`);
        this.getNewsList(filterQuery);
      },
      createFilterParameterUrl(facetOption) {
        let filterString = '';
        facetOption.forEach((option) => {
          filterString += `${option}&`;
        });
        return filterString;
      },
      clearFilters() {
        this.selectedOptions = [];
        this.getNewsList('');
      },
      toggleFilter() {
        this.isFilterOpen = !this.isFilterOpen;
      },
      beforeEnter(el) {
        gsap.set(el, {
          width: 0,
        });
      },
      enter(el, done) {
        gsap.to(el, {
          width: 'calc(204px + 10%)',
          duration: 0.3,
          onComplete: done,
        });
      },
      leave(el, done) {
        gsap.to(el, {
          width: 0,
          duration: 0.2,
          onComplete: done,
        });
      },
    },
  });
