import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-880d8434"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.setStyles),
    class: "level2PageNavigation"
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routes, (route, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: route.path
      }, [
        (route.meta.isExternal)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              class: _normalizeClass(["level2PageNavigation_Link nav_Link", { featured: index === 0 }]),
              href: route.path,
              target: "_blank",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClickLink()))
            }, [
              _createElementVNode("span", null, _toDisplayString(route.meta.name), 1 /* TEXT */)
            ], 10 /* CLASS, PROPS */, _hoisted_1))
          : (_openBlock(), _createBlock(_component_router_link, {
              key: 1,
              class: _normalizeClass(["level2PageNavigation_Link nav_Link", { featured: index === 0 }]),
              to: route.path,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onClickLink()))
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(route.meta.name), 1 /* TEXT */)
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to", "class"]))
      ], 64 /* STABLE_FRAGMENT */))
    }), 128 /* KEYED_FRAGMENT */))
  ], 4 /* STYLE */))
}