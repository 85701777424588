
  import { defineComponent, PropType } from 'vue';
  import { WishlistProduct } from '@/models/wishlist/wishlist';
  import UserWishlistTableRow from './UserWishlistTableRow.vue';

  export default defineComponent({
    components: {
      UserWishlistTableRow,
    },
    props: {
      wishlist: {
        type: Array as PropType<Array<WishlistProduct>>,
        required: false,
        default: () => [] as WishlistProduct[],
      },
    },
    emits: ['delete', 'cart-add', 'show-menu'],
    methods: {
      onWishlistProductDelete(product: WishlistProduct) {
        this.$emit('delete', product);
      },
      onWishlistProductAddToCart({ product, variant }) {
        this.$emit('cart-add', product, variant);
      },
      onShowMenu(product: WishlistProduct) {
        this.$emit('show-menu', product);
      },
    },
  });
