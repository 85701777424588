
  import { defineComponent } from 'vue';

  import useAuthenticateUser from '@/composables/useAuthenticateUser';
  import useNavigation from '@/composables/useNavigation';

  import { getUserArea } from '@/utils/routingHelper';
  import { exactLength, minLength, validateEmail, validatePhoneNumber } from '@/utils/formValidators';

  import { CreateUserPayload, PasswordPayload, User } from '@/models/user/user';

  import TextInput from '@/components/shared/inputs/TextInput.vue';
  import CheckboxInput from '@/components/shared/inputs/CheckboxInput.vue';
  import Loader from '@/components/shared/Loader.vue';
  import CustomButton from '@/components/shared/Button.vue';

  export default defineComponent({
    components: {
      TextInput,
      Loader,
      CheckboxInput,
      CustomButton,
    },
    emits: ['back'],
    setup() {
      const { createUser } = useAuthenticateUser();
      const { navigateToArea } = useNavigation();
      return { createUser, navigateToArea };
    },
    data() {
      return {
        formData: {
          User: {
            Name: '',
            Email: '',
            UserName: '',
            Phone: '',
            Address: '',
            Zip: '',
            City: '',
            EmailAllowed: false,
          } as User,
          Password: {
            newPassword: '',
            newPasswordConfirm: '',
          } as PasswordPayload,
        } as CreateUserPayload,
        validators: {
          User: {
            Name: [minLength(1, 'Nafn má ekki vera tómt')],
            Email: [minLength(1, 'Netfang má ekki vera tómt'), validateEmail('Netfang ekki löglegt')],
            Phone: [minLength(1, 'Símanúmer má ekki vera tómt'), validatePhoneNumber('Símanúmer ekki löglegt')],
            Address: [minLength(1, 'Heimilisfang má ekki vera tómt')],
            Zip: [exactLength(3, 'Ógilt póstnúmer')],
            City: [minLength(1, 'Staður má ekki vera tómt')],
          },
        },
        errors: [] as Array<string>,
        initLoading: true,
        loading: false,
        userCreated: '',
      };
    },
    computed: {
      dwFormName() {
        return {
          name: () => 'UserManagement_Form_Name',
          email: () => 'UserManagement_Form_Email',
          phone: () => 'UserManagement_Form_Phone',
          address: () => 'UserManagement_Form_Address',
          city: () => 'UserManagement_Form_City',
          zip: () => 'UserManagement_Form_Zip',
          password: () => 'UserManagement_Form_NewPassword',
          confirmPassword: () => 'UserManagement_Form_NewPasswordConfirm',
        };
      },
    },
    methods: {
      onSubmit() {
        if (this.formValid()) {
          this.createUserAttempt();
        }
      },
      async createUserAttempt() {
        this.loading = true;
        const response = await this.createUser(this.formData);

        if (!response?.data?.Errors?.length) {
          const userArea = getUserArea(this.$router);
          if (userArea !== undefined) {
            this.navigateToArea(userArea);
          }
        } else {
          const errors = response?.data?.Errors;
          errors.forEach((error) => {
            this.errors.push(error.Message);
          });
        }

        this.loading = false;
      },
      formValid() {
        [this.$refs.nameRef, this.$refs.emailRef, this.$refs.phoneRef, this.$refs.addressRef, this.$refs.cityRef, this.$refs.zipRef].forEach((r: any) => {
          if (r !== undefined) {
            if (r?.validate) {
              r.validate();
            }
          }
        });

        if (!this.validators.User.Name.every((validator) => validator(this.formData.User.Name) === null) && this.validators.User.Name.length > 0) return false;
        if (!this.validators.User.Email.every((validator) => validator(this.formData.User.Email) === null) && this.validators.User.Email.length > 0)
          return false;
        if (!this.validators.User.Phone.every((validator) => validator(this.formData.User.Phone) === null) && this.validators.User.Phone.length > 0)
          return false;
        if (!this.validators.User.Address.every((validator) => validator(this.formData.User.Address) === null) && this.validators.User.Address.length > 0)
          return false;
        if (!this.validators.User.City.every((validator) => validator(this.formData.User.City) === null) && this.validators.User.City.length > 0) return false;
        if (!this.validators.User.Zip.every((validator) => validator(this.formData.User.Zip) === null) && this.validators.User.Zip.length > 0) return false;

        this.errors = [] as Array<string>;
        if (this.formData.Password.newPassword === '' || this.formData.Password.newPasswordConfirm === '') {
          this.errors.push('Fylla þarf út lykilorð.');
          return false;
        }
        if (this.formData.Password.newPasswordConfirm !== this.formData.Password.newPassword) {
          this.errors.push('Lykilorð passa ekki saman.');
          return false;
        }

        return true;
      },
    },
  });
