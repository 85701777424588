// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/icons/minus.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/icons/plus.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "/* Font families */\n/* Colors */\n/* S4S - Grey 200 */\n/* S4S - Grey 300 */\n/* S4S - Grey 600 */\n/* General */\n/* Device Sizes */\n/* Device specific sizes */\n/* Front Page Container Variables */\n/* Navigation */\n/* Checkout */\n.numberInput_Container[data-v-86970b76] {\n  position: relative;\n  display: inline-block;\n}\n.numberInput_Container .numberInput[data-v-86970b76] {\n  text-align: center;\n  height: 100%;\n  width: 100%;\n}\n.numberInput_Container .minus[data-v-86970b76],\n.numberInput_Container .plus[data-v-86970b76] {\n  height: 20px;\n  width: 20px;\n  position: absolute;\n  background: transparent;\n  background-size: 10px;\n  background-repeat: no-repeat;\n  background-position: 50% 50%;\n}\n.numberInput_Container .minus[data-v-86970b76] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  top: calc(50% - 10px);\n  left: 8px;\n}\n.numberInput_Container .plus[data-v-86970b76] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  top: calc(50% - 10px);\n  right: 8px;\n}", ""]);
// Exports
module.exports = exports;
