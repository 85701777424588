
import { GroupRequest } from '@/models/group/group';
import { AxiosResponse } from 'axios';
import DynamicWebApi from './dwApi';

export interface RelatedGroupsProductsAPI {
  ProductId:string;
  VariantId: string;
}
export interface RelatedGroupAPI {
  Id: string;
  Name: string;
  Products?: RelatedGroupsProductsAPI[];
}

export interface RelatedGroupRaw {
  RelatedGroups: RelatedGroupAPI[]
}

export default class RelatedGroupsApi {
  static async getRelatedGroups(productId: string, params?: GroupRequest): Promise<RelatedGroupResponse> {
    return DynamicWebApi.get(`ecommerce/products/${productId}`, { params })
  }
}

export type RelatedGroupResponse = AxiosResponse<RelatedGroupRaw>;
