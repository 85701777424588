
  import { defineComponent, PropType } from 'vue';

  type IconType = 'sm' | 'm' | 'l' | '16' | '24' | '32' | '48' | '60';

  /**
   * Icon utility wrapper for registered SVG icons
   *
   * @see @/global_components/Sprites.vue
   */
  export default defineComponent({
    props: {
      /**
       * Sprite ID from Sprites global component. (Without icon- prefix)
       */
      icon: {
        required: true,
        type: String,
      },
      /**
       * Icon size
       * @values sm, m, l, 16, 24, 32, 48
       */
      size: {
        required: false,
        type: String as PropType<IconType>,
        default: '24',
      },
      /**
       * Add icon thickness, only works if icons are defined with stroke width in Sprites.vue
       */
      thick: {
        required: false,
        type: Boolean,
        default: false,
      },
      /**
       * Add Primary color styling
       */
      primary: {
        required: false,
        type: Boolean,
        default: false,
      },
    },
  });
