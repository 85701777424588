
  import { computed, defineComponent, inject, PropType, ref } from 'vue';
  import { get } from 'lodash';
  import { useRoute } from 'vue-router';
  import { SearchResultsProducts } from '@/models/search/searchModels';
  import ProductListHeader from '@/components/products/ProductListHeader.vue';
  import ProductCardList, { ProductCardList as ProductCardListType } from '@/components/products/ProductCardList.vue';
  import ProductListFacets from '@/components/products/ProductListFacets.vue';
  import useProductListSort from '@/composables/useProductListSort';
  import useProductListFacets from '@/composables/useProductListFacets';
  import { ProductListSortDropdownOption } from '@/components/products/ProductListSortDropdown.vue';
  import { getOnlyActiveFacets } from '@/utils/facetsHelper';
  import { SearchForMoreKey, SearchQueryChangeKey, SearchQueryKey } from './SearchSymbols';

  export default defineComponent({
    components: {
      ProductListHeader,
      ProductListFacets,
      ProductCardList,
    },
    props: {
      productList: {
        type: Object as PropType<SearchResultsProducts>,
        required: true,
      },
      shop: {
        required: true,
        type: String,
      },
    },
    setup(props) {
      const query = inject(SearchQueryKey);
      const changeQuery = inject(SearchQueryChangeKey);
      const searchForMore = inject(SearchForMoreKey);

      if (query === undefined || changeQuery === undefined || searchForMore === undefined) {
        throw new Error('Failed to provide search items');
      }

      const productListForCards = computed<ProductCardListType>(() => ({
        products: props.productList.Products,
        currentPage: (props.productList?.CurrentPage || 0) as number,
        pageCount: (props.productList?.PageCount || 0) as number,
      }));

      const nextLoader = ref<boolean>(false);
      const productListLoader = ref<boolean>(false);
      const facets = computed(() => getOnlyActiveFacets(get(props.productList, 'FacetGroups.0.Facets', [])));

      const { filtersOpen, selectedFacets, filterQuery, setSelectedFacets } = useProductListFacets();
      const { sortBy, sortByQuery, setSortOption, setSortFromQuery } = useProductListSort();

      setSortFromQuery();
      setSelectedFacets(facets.value);

      const createQuery = () => [sortByQuery.value, filterQuery.value].filter((v) => v).join('');

      const onSelectedFacetsUpdate = (v: string[]) => {
        selectedFacets.value = v;
        productListLoader.value = true;
        changeQuery(createQuery()).then(() => {
          productListLoader.value = false;
        });
      };
      const onFacetsClear = () => {
        selectedFacets.value = [];
        productListLoader.value = true;
        changeQuery(createQuery()).then(() => {
          productListLoader.value = false;
        });
      };
      const onFacetsClose = () => {
        filtersOpen.value = !filtersOpen.value;
      };

      const onSortUpdate = (opt: ProductListSortDropdownOption) => {
        const lastOption = sortBy.value;
        setSortOption(opt);

        if (lastOption !== sortBy.value) {
          productListLoader.value = true;
          changeQuery(createQuery()).then(() => {
            productListLoader.value = false;
          });
        }
      };

      const onNextClick = () => {
        nextLoader.value = true;
        searchForMore(props.shop).then(() => {
          nextLoader.value = false;
        });
      };

      return {
        productListForCards,
        onNextClick,

        query,
        changeQuery,
        createQuery,

        facets,
        selectedFacets,
        filtersOpen,
        onSelectedFacetsUpdate,
        onFacetsClear,
        onFacetsClose,

        sortBy,
        onSortUpdate,

        productListLoader,
        nextLoader,
      };
    },
  });
