import { OrderLine } from '@/models/cart/orderLine';
import { Variant } from '@/models/cart/variant';
import { PaymentPayload, PaymentType } from '@/models/checkout/paymentPayload';
import { CartState, MultiWrapOrderLine } from '@/models/store/cart';

export function convertToOrderLineToBeRemoved(orderLine: OrderLine): OrderLine {
  const orderLineToBeRemoved = { ...orderLine };
  orderLineToBeRemoved.Quantity = 0;
  return orderLineToBeRemoved;
}

export function orderLinesAreEqual(orderLine: OrderLine, orderLine2: OrderLine): boolean {
  return (
    orderLine.Product.ProductID === orderLine2.Product.ProductID &&
    orderLine.Product.Variants.find((v: Variant) => v.Selected)?.VariantID === orderLine2.Product.Variants.find((v: Variant) => v.Selected)?.VariantID &&
    orderLine.Quantity === orderLine2.Quantity
  );
}

export function orderLinesHaveSameColorAndVariant(orderLine: OrderLine, orderLine2: OrderLine): boolean {
  return (
    orderLine.Product.ProductID === orderLine2.Product.ProductID &&
    orderLine.Product.Variants.find((v: Variant) => v.Selected)?.VariantID === orderLine2.Product.Variants.find((v: Variant) => v.Selected)?.VariantID
  );
}

export function orderLinesHaveSameColorAndDifferentVariant(orderLine: OrderLine, orderLine2: OrderLine): boolean {
  return (
    orderLine.Product.ProductID === orderLine2.Product.ProductID &&
    orderLine.Product.Variants.find((v: Variant) => v.Selected)?.VariantID !== orderLine2.Product.Variants.find((v: Variant) => v.Selected)?.VariantID
  );
}

export function combineOrderLines(source: OrderLine, target: OrderLine): OrderLine {
  return {
    ...source,
    Quantity: source.Quantity + target.Quantity,
    OrderLinePrice: source.OrderLinePrice + target.OrderLinePrice,
  };
}

export function syncOtherOrderLineValues(orderLine: OrderLine): OrderLine {
  const orderLineSynced = { ...orderLine };
  orderLineSynced.OrderLinePrice = orderLineSynced.OriginalOrderLinePrice * orderLineSynced.Quantity;
  return orderLineSynced;
}

export function getOrderLinesToBeUpdated(state: CartState): OrderLine[] {
  const toUpdate = [] as OrderLine[];
  state.cart.OrderLines.forEach((orderLine: OrderLine) => {
    const originalOrderLine = state.cartOverviewPageOptions.originalOrderLines.find((ol: OrderLine) => ol.OrderLineID === orderLine.OrderLineID);
    if (originalOrderLine !== undefined && !orderLinesAreEqual(orderLine, originalOrderLine)) {
      toUpdate.push(orderLine);
      if (!orderLinesHaveSameColorAndVariant(orderLine, originalOrderLine)) {
        toUpdate.push(convertToOrderLineToBeRemoved(originalOrderLine));
      }
    }
  });
  return toUpdate;
}

export function orderLineHasBeenAddedAndIsPendingToBeUpdated(state: CartState, orderLine: OrderLine): boolean {
  return (
    state.cartOverviewPageOptions.originalOrderLines.some((ol: OrderLine) => orderLinesHaveSameColorAndVariant(ol, orderLine)) &&
    state.cart.OrderLines.some((ol: OrderLine) => orderLinesHaveSameColorAndDifferentVariant(ol, orderLine))
  );
}

export function mapOrderLineToSetAddMultiPayloadItem(orderLine: OrderLine): { ProductID: string; VariantID: string | undefined; Quantity: number } {
  return {
    ProductID: orderLine.Product.ProductID,
    VariantID: orderLine.Product.Variants.find((v: Variant) => v.Selected)?.VariantID,
    Quantity: orderLine.Quantity,
  };
}

export function mapOrderLinesToMultiWrapOrderLines(orderLines: OrderLine[]): MultiWrapOrderLine[] {
  return orderLines
    .map((orderLine: OrderLine) =>
      Array.from({ length: orderLine.Quantity }, (_, index) => {
        const multiWrapOrderLine = {
          ...orderLine,
          MultiWrapOrderLineID: `${orderLine.OrderLineID}${index}`,
          PackageNumber: null,
          IsGiftWrapped: false,
        } as MultiWrapOrderLine;
        return multiWrapOrderLine;
      }),
    )
    .flat(1);
}

export function prepareCheckoutData(state: CartState, payload: PaymentPayload): FormData {
  const formData = new FormData();
  // Populate form data

  // Card payment fields

  // if (payload.paymentType === PaymentType.Borgun) {
  //   formData.append("Type", "30");
  //   formData.append("InlineCheckout", "True");
  //   formData.append("pan", payload.borgun.pan);
  //   formData.append("expMonth", payload.borgun.expMonth);
  //   formData.append("expYear", payload.borgun.expYear);
  //   formData.append("BorgunOneTimeToken", payload.borgun.oneTimeToken);
  // }

  // // Gift Card
  // formData.append("GiftCard", payload.giftCard);
  // formData.append("giftCardAmount", payload.giftCardAmount);
  if (payload.paymentType === PaymentType.Giftcard || payload.paymentType === PaymentType.Borgun) {
    formData.append('GiftCard', state.cart?.General?.GiftCardNumber);
    formData.append('GiftCardAmount', state.cart?.General?.GiftCardAmount?.toString());
  }

  // Selected Payment
  formData.append('EcomCartPaymethodID', state.cart.selectedPaymentMethod);
  // Customer Info
  formData.append('EcomOrderCustomerName', state.cart.CustomerInfo.CustomerName);
  formData.append('EcomOrderCustomerAddress', state.cart.CustomerInfo.CustomerAddress);
  formData.append('EcomOrderCustomerZip', state.cart.CustomerInfo.CustomerPostcode);
  formData.append('EcomOrderCustomerCity', state.cart.CustomerInfo.CustomerCity);
  formData.append('EcomOrderCustomerPhone', state.cart.CustomerInfo.CustomerPhone);
  formData.append('EcomOrderCustomerEmail', state.cart.CustomerInfo.CustomerEmail);
  formData.append('EcomOrderCustomerComment', state.cart.CustomerInfo.CustomerComment);
  // Shipping Method
  formData.append('EcomCartShippingMethodID', state.cart.selectedShippingMethod);
  formData.append('EcomOrderDeliveryName', state.cart.DeliveryInfo.DeliveryName);
  formData.append('EcomOrderDeliveryAddress', state.cart.DeliveryInfo.DeliveryAddress);
  formData.append('EcomOrderDeliveryZip', state.cart.DeliveryInfo.DeliveryPostcode);
  formData.append('EcomOrderDeliveryCity', state.cart.DeliveryInfo.DeliveryCity);
  formData.append('EcomOrderDeliveryEmail', '');
  formData.append('EcomOrderDeliveryPhone', state.cart.DeliveryInfo.DeliveryPhone);
  // Waiting implementation
  formData.append('Comment', '');
  formData.append('DroppLocationID', state.cart.DeliveryInfo.DroppLocationID);
  formData.append('DroppLocationName', state.cart.DeliveryInfo.DroppLocationName);
  // DW Step Specific
  formData.append('InlineCheckout', 'true');
  // formData.append("EcomOrderCustomerAccepted", "1");
  formData.append('skilmalar', 'on');
  formData.append('CartV2.GotoStep0', '1');
  return formData;
}
