<template>
  <div class="row">
    <!-- <div class="row__cell">
      <slot name="state" />
    </div> -->
    <div class="row__cell">
      <slot name="date" />
    </div>
    <div class="row__cell">
      <slot name="id" />
    </div>
    <div class="row__cell">
      <slot name="shipping" />
    </div>
    <div class="row__cell">
      <slot name="total" />
    </div>
    <div class="row__cell">
      <slot name="end" />
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .row {
    display: flex;
    justify-content: space-between;
    gap: 24px;

    @include tablet-h {
      gap: 10px;
    }

    .row__cell {
      font-size: 14px;
      text-transform: uppercase;

      &:first-child {
        flex-basis: 100px;

        @include tablet-h {
          flex-basis: 85px;
        }
      }

      &:nth-child(2) {
        flex-basis: 125px;

        @include tablet-h {
          flex-basis: 100px;
        }
      }

      &:nth-child(3) {
        flex-grow: 1;

        @include tablet-h {
          display: none;
        }
      }

      &:nth-child(4) {
        font-weight: 600;
        flex-basis: auto;
      }

      &:nth-child(4),
      &:last-child {
        text-align: right;
      }

      &:last-child {
        flex-basis: 15%;
        flex-shrink: 1;
      }

      @include tablet-h {
      }
    }
  }
</style>
