/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { CancelToken } from 'axios';
import conf from '@/config';

interface hiddenFields {
  name: string;
  value: string;
  type: string;
}

export interface ICoopAndGrantFormPayload {
  category: string;
  type: string;
  name: string;
  company: string;
  phone: string;
  email: string;
  message: string;
  country: string;
  hiddenFields: hiddenFields[];
  cancelToken: CancelToken;
}

export class CoopAndGrantAPI {
  static getConfig(cancelToken: CancelToken): any {
    return {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      cancelToken,
    };
  }

  static async getCoopAndGrantFields(): Promise<ICoopAndGrantFormPayload> {
    let response;
    const url = `${conf.dwUrl}/${conf.area}/coop-and-grant`;

    try {
      response = await axios.get(url);
      response = {
        hiddenFormFields: response?.data?.hiddenFormFields,
        formFields: response?.data?.formFields,
      };
    } catch (e) {
      if (!axios.isCancel(e)) {
        console.error(e);
      }
    }
    return response;
  }

  static async postCoopAndGrantForm(payload: ICoopAndGrantFormPayload): Promise<any> {
    let response;

    try {
      const config = this.getConfig(payload.cancelToken);
      const formData = new FormData();
      payload.hiddenFields.forEach((hiddenField) => formData.append(hiddenField.name, hiddenField.value));
      formData.append('GrantApplication_Category', payload.category);
      formData.append('GrantApplication_Type', payload.type);
      formData.append('GrantApplication_Name', payload.name);
      formData.append('GrantApplication_Company', payload.company);
      formData.append('GrantApplication_Phone', payload.phone);
      formData.append('GrantApplication_Email', payload.email);
      formData.append('GrantApplication_Message', payload.message);
      formData.append('GrantApplication_Country', payload.country);

      const url = `${conf.dwUrl}/${conf.area}/coop-and-grant`;
      await axios.post(url, formData, config);
    } catch (e) {
      if (!axios.isCancel(e)) {
        console.error(e);
      }
    }

    return response;
  }
}
