import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserWishlistTableRow = _resolveComponent("UserWishlistTableRow")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.wishlist, (product) => {
    return (_openBlock(), _createBlock(_component_UserWishlistTableRow, {
      key: product.Id,
      product: product,
      onDelete: _ctx.onWishlistProductDelete,
      onCartAdd: _ctx.onWishlistProductAddToCart,
      onShowMenu: _ctx.onShowMenu
    }, null, 8 /* PROPS */, ["product", "onDelete", "onCartAdd", "onShowMenu"]))
  }), 128 /* KEYED_FRAGMENT */))
}