
  /* eslint-disable @typescript-eslint/no-explicit-any */
  import { defineComponent, PropType } from 'vue';
  import { mapBaseComponentData } from '@/utils/dataMapper';
  import conf from '@/config';

  interface MetaTags {
    title: string;
    description: string;
    link: any;
    image: any;
  }

  export default defineComponent({
    props: {
      componentProps: {
        type: Object as PropType<any>,
        required: true,
      },
    },
    setup(props) {
      const { componentProps } = props as any;
      const data = mapBaseComponentData(componentProps.metaTags) as MetaTags;

      const metaTitle = document.createElement('meta');
      metaTitle.setAttribute('property', 'og:title');
      metaTitle.setAttribute('content', data.title);

      const metaDescription = document.createElement('meta');
      metaDescription.setAttribute('property', 'og:description');
      metaDescription.setAttribute('content', data.description);

      const metaLink = document.createElement('meta');
      metaLink.setAttribute('property', 'og:url');
      metaLink.setAttribute('content', data.link.Url);

      const metaImage = document.createElement('meta');
      metaImage.setAttribute('property', 'og:image');
      metaImage.setAttribute('content', conf.dwUrl + data.image.Path);

      document.head.appendChild(metaTitle);
      document.head.appendChild(metaDescription);
      document.head.appendChild(metaLink);
      document.head.appendChild(metaImage);
    },
  });
