
  /* eslint-disable @typescript-eslint/no-explicit-any */
  import { computed, defineComponent, onUpdated, PropType, reactive, ref, toRefs, watch } from 'vue';
  import { onBeforeRouteLeave } from 'vue-router';
  import { isEqual } from 'lodash';
  import InformationBox from '@/components/itemTypes/InformationBox.vue';
  import { Field, ItemType } from '@/models/field';
  import { isDisabledField, isEnabledFrom, isEnabledTo } from '@/utils/modelChecker';
  import InformationBoxesCarousel from '@/components/itemTypes/InformationBoxesCarousel.vue';
  import useParagraph, { UseParagraph } from '@/composables/useParagraph';
  import { InformationBoxesParagraph } from '@/models/item-types/paragraph';

  export default defineComponent({
    components: {
      InformationBox,
      InformationBoxesCarousel,
    },
    props: {
      componentProps: {
        type: Object as PropType<unknown>,
        required: true,
      },
    },
    setup(props) {
      const { data, getParagraphStyles, getParagraphClasses } = useParagraph(props.componentProps);
      const paragraphData = data.value as InformationBoxesParagraph;

      return {
        paragraphData,
        getParagraphStyles,
        getParagraphClasses,
      };
    },
    computed: {
      getEnabledBoxCount(): number {
        const { boxes } = this.$props.componentProps as any;
        const count = boxes.reduce((acc: number, curr: ItemType) => {
          const fields = curr.Fields as Field[];
          const disabled = fields.find((field) => isDisabledField(field))?.Value;
          const enabledTo = fields.find((field) => isEnabledTo(field))?.Value as string;
          const enabledFrom = fields.find((field) => isEnabledFrom(field))?.Value as string;
          const now = new Date();
          if (disabled || (enabledFrom && now < new Date(enabledFrom)) || (enabledTo && now > new Date(enabledTo))) {
            // eslint-disable-next-line no-param-reassign
            acc -= 1;
          }
          return acc;
        }, boxes.length as number);
        return count;
      },
      getInformationSpecificParagraphStyles(): unknown {
        return {
          '--boxes': this.getEnabledBoxCount,
          '--disable-column-gap': this.paragraphData.disableColumnGap ? 'normal' : '',
        };
      },
      getInformationBoxClasses(): unknown {
        return {
          boxes_WrapInMobile: this.paragraphData.wrapInMobile,
          boxes_WrapInTablet: this.paragraphData.wrapInTablet,
          boxes_CarouselInMobile: this.paragraphData.mobileCarousel,
          boxes_DisableColumnGap: this.paragraphData.disableColumnGap,
        };
      },
    },
  });
