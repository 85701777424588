import { CartState } from '@/models/store/cart';

export default {
  didAddReceiptsBlackList(state: CartState, orderSecret: string): void {
    if (orderSecret && !state.receiptsBlackList.includes(orderSecret)) {
      state.receiptsBlackList = [...state.receiptsBlackList, orderSecret];
    }
  },
  didRemoveReceiptsBlackList(state: CartState, orderSecret: string): void {
    state.receiptsBlackList = state.receiptsBlackList.filter((os) => os === orderSecret);
  },
};
