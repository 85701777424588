
  import { defineComponent, PropType } from 'vue';

  import useParagraph from '@/composables/useParagraph';

  import { mapBaseComponentData } from '@/utils/dataMapper';

  import { ShopInfoParagraph } from '@/models/item-types/paragraph';

  import CustomButton from '@/components/shared/Button.vue';
  import conf from '@/config';

  export default defineComponent({
    components: {
      CustomButton,
    },
    props: {
      componentProps: {
        type: Object as PropType<unknown>,
        required: true,
      },
    },
    setup(props) {
      const { data, getParagraphStyles, getParagraphClasses } = useParagraph(props.componentProps);

      const paragraphData = data.value as unknown as ShopInfoParagraph;
      const imagePrefix = conf.dwUrl;

      let shopInfo = {} as any;
      shopInfo = mapBaseComponentData(paragraphData.shopInfo);
      if (shopInfo.openingHours !== undefined) {
        const shopOpeningHours = [] as any[];
        shopInfo.openingHours.forEach((item) => {
          const shopData = mapBaseComponentData(item);
          shopOpeningHours.push(shopData);
        });
        shopInfo.openingHours = shopOpeningHours;
      }

      paragraphData.shopInfo = shopInfo;

      return {
        paragraphData,
        getParagraphStyles,
        imagePrefix,
        getParagraphClasses,
      };
    },
  });
