import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bfba7e7e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user-management-area" }
const _hoisted_2 = {
  key: 1,
  class: "user-management-area__my-pages"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.hasAuthenticatedUser)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "user-management-area__logged-in-as",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onUsernameClick && _ctx.onUsernameClick(...args)))
        }, _toDisplayString(_ctx.authenticatedUser?.Name || 'Notandi'), 1 /* TEXT */))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("a", {
            onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onMyPagesClick && _ctx.onMyPagesClick(...args)), ["prevent"]))
          }, "Mínar síður")
        ]))
  ]))
}