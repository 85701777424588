
  import { defineComponent } from 'vue';
  import useManageUserAddresses from '@/composables/useManageUserAddresses';
  import OutlineButton from '@/components/shared/OutlineButton.vue';
  import { Address, AddressPayload } from '@/models/address/address';
  import UserAddressForm from './UserAddressForm.vue';
  import UserAddressList from './UserAddressList.vue';

  interface UserAddressesState {
    showForm: boolean;
    addAddressSubmitting: boolean;
    editAddressSubmitting: boolean;
  }

  export default defineComponent({
    components: {
      UserAddressForm,
      UserAddressList,
      OutlineButton,
    },
    setup() {
      const { userAddresses, getUserAddresses, createUserAddress, deleteUserAddress, updateUserAddress, makeUserAddressDefault } = useManageUserAddresses();

      return {
        userAddresses,
        getUserAddresses,
        createUserAddress,
        deleteUserAddress,
        updateUserAddress,
        makeUserAddressDefault,
      };
    },
    data() {
      return {
        showForm: false,
        addAddressSubmitting: false,
        editAddressSubmitting: false,
      } as UserAddressesState;
    },
    created() {
      this.getUserAddresses();
    },
    methods: {
      onAddClick() {
        this.showForm = true;
      },
      onAddClose() {
        this.showForm = false;
      },
      onAddSubmit(address: AddressPayload) {
        this.performAddressCreate(address);
      },
      onAddressDelete({ Id }: Address) {
        this.deleteUserAddress(Id);
      },
      onAddressUpdate(id: string, address: AddressPayload) {
        this.performAddressUpdate(id, address);
      },
      onAddressMakeDefault({ Id }: Address) {
        this.makeUserAddressDefault(Id);
      },
      async performAddressCreate(address: AddressPayload) {
        this.addAddressSubmitting = true;
        await this.createUserAddress(address);
        this.addAddressSubmitting = false;
        this.showForm = false;
      },
      async performAddressUpdate(id: string, address: AddressPayload) {
        this.editAddressSubmitting = true;
        await this.updateUserAddress(id, address);
        this.editAddressSubmitting = false;
      },
    },
  });
