
  import { defineComponent, PropType } from 'vue';
  import useParagraph, { UseParagraph } from '@/composables/useParagraph';
  import { IframeParagraph } from '@/models/item-types/paragraph';

  export default defineComponent({
    props: {
      componentProps: {
        type: Object as PropType<unknown>,
        required: true,
      },
    },
    setup(props) {
      const { data, getParagraphStyles, getParagraphClasses } = useParagraph(props.componentProps);

      const paragraphData = data.value as unknown as IframeParagraph;
      const iframeSource = paragraphData.source;
      return {
        paragraphData,
        iframeSource,
        getParagraphStyles,
        getParagraphClasses,
      };
    },
  });
