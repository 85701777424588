import { resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.getData.data && !_ctx.hide)
    ? (_openBlock(), _createBlock(_resolveDynamicComponent(`${_ctx.getData.component}`), {
        key: 0,
        class: _normalizeClass(["informationBox_Container", `informationBox_Container_${_ctx.boxCount}boxes`]),
        box: _ctx.getData.data,
        options: _ctx.getData.options,
        "box-count": _ctx.boxCount
      }, null, 8 /* PROPS */, ["class", "box", "options", "box-count"]))
    : _createCommentVNode("v-if", true)
}