
  import { defineComponent, reactive, ref } from 'vue';

  import useAuthenticateUser from '@/composables/useAuthenticateUser';

  import { minLength } from '@/utils/formValidators';

  import TextInput from '@/components/shared/inputs/TextInput.vue';
  import Loader from '@/components/shared/Loader.vue';
  import Icon from '@/components/shared/Icon.vue';
  import ForgottenPassword from '@/components/user/ForgottenPassword.vue';
  import CustomButton from '@/components/shared/Button.vue';

  export default defineComponent({
    components: {
      ForgottenPassword,
      TextInput,
      Loader,
      Icon,
      CustomButton,
    },
    emits: ['cancel', 'login'],
    setup(props, { emit }) {
      const { loginUser } = useAuthenticateUser();

      const isLoading = ref<boolean>();
      const usernameRef = ref<typeof TextInput>();
      const passwordRef = ref<typeof TextInput>();
      const invalidLogin = ref<boolean>(false);

      const inputs = reactive({
        username: {
          value: '',
          validators: [minLength(1, 'Vinsamlegast sláðu inn netfang')],
        },
        password: {
          value: '',
          validators: [minLength(1, 'Vinsamlegast sláðu inn lykilorð')],
        },
      });

      const validateInputs = (): boolean => {
        [usernameRef, passwordRef].forEach((r) => {
          const { value } = r;
          if (value?.validate) {
            value.validate();
          }
        });

        return Object.values(inputs).every((input) => input.validators.every((validator) => validator(input.value) === null) || input.validators.length === 0);
      };

      const onSubmit = async () => {
        isLoading.value = true;
        invalidLogin.value = false;
        if (validateInputs()) {
          try {
            await loginUser(inputs.username.value, inputs.password.value);
            emit('login');
            inputs.username.value = '';
            inputs.password.value = '';
          } catch (e) {
            invalidLogin.value = true;
          }
        }
        isLoading.value = false;
      };

      return {
        loginUser,
        usernameRef,
        passwordRef,
        onSubmit,
        inputs,
        isLoading,
        invalidLogin,
      };
    },
    data() {
      return {
        showForgottenPassword: false,
        formData: {
          username: '',
          password: '',
        },
        validators: {
          usernameValidator: [minLength(1, 'Vinsamlegast sláðu inn netfang')],
          passwordValitor: [minLength(1, 'Vinsamlegast sláðu inn lykilorð')],
        },
        errors: {} as any,
        loading: false,
      };
    },
    computed: {
      dwFormName() {
        return {
          username: () => 'username',
          password: () => 'password',
        };
      },
    },
    mounted() {
      setTimeout(() => document.addEventListener('mousedown', this.onDocumentClick), 0);
    },
    unmounted() {
      document.removeEventListener('mousedown', this.onDocumentClick);
      const body = document.getElementsByTagName('body')[0];
      body.classList.remove('overflow--hidden');
    },
    methods: {
      async performLogin() {
        await this.loginUser(this.formData.username, this.formData.password);
        this.formData = { username: '', password: '' };
        this.$emit('login');
      },
      onDocumentClick() {
        const body = document.getElementsByTagName('body')[0];
        this.$emit('cancel');
        body.classList.remove('overflow--hidden');
      },
      onBack() {
        this.showForgottenPassword = false;
      },
    },
  });
