import { ComponentDataAndOptions } from '@/models/componentModels';
import { ComponentField, Field } from '@/models/field';
import { InformationBox } from '@/models/item-types/base/boxes';
import { mapIntermediateComponentOptions } from '@/utils/dataMapper';
import { isComponentField } from '@/utils/modelChecker';

function findSelectedComponentAndData(fields: Field[]): ComponentDataAndOptions | InformationBox {
  const component = (fields.find((elem) => isComponentField(elem)) as ComponentField)?.Value.SelectedValue;
  const data = fields.find((elem) => elem.SystemName === component) as Field;
  const options = mapIntermediateComponentOptions(fields, component);
  return {
    component,
    data,
    options,
  };
}

function getMainWebshop(webshops: string): string {
  const webshopsUppercase = webshops.toUpperCase();
  if (webshopsUppercase.startsWith('AIR')) return 'AIR';
  if (webshopsUppercase.startsWith('SKOR')) return 'SKOR';
  if (webshopsUppercase.startsWith('ELLINGSEN')) return 'ELLINGSEN';
  if (webshopsUppercase.startsWith('BRP')) return 'BRP';

  return 'S4S';
}

export { findSelectedComponentAndData, getMainWebshop };
