import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-61b8b008"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "userManagementArea userManagementArea_MyPages" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.hasAuthenticatedUser)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onUsernameClick && _ctx.onUsernameClick(...args)), ["prevent"]))
        }, _toDisplayString(_ctx.authenticatedUser?.Name || 'Notandi'), 1 /* TEXT */))
      : (_openBlock(), _createElementBlock("a", {
          key: 1,
          onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onMyPagesClick && _ctx.onMyPagesClick(...args)), ["prevent"]))
        }, " Mínar síður "))
  ]))
}