import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7f6d8618"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "desktopNavigation container" }
const _hoisted_2 = { class: "desktopNavigation_AreaAndUser" }
const _hoisted_3 = { class: "desktopNavigation_AreaAndUser_Area" }
const _hoisted_4 = { class: "desktopNavigation_AreaAndUser_User" }
const _hoisted_5 = { class: "desktopNavigation_IconsAndLevel1_Wrapper" }
const _hoisted_6 = { class: "desktopNavigation_IconsAndLevel1" }
const _hoisted_7 = { class: "desktopNavigation_IconsAndLevel1_Icons" }
const _hoisted_8 = { class: "desktopNavigation_Level2And3_Level2" }
const _hoisted_9 = { class: "desktopNavigation_Level2And3_Level3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AreaNavigation = _resolveComponent("AreaNavigation")!
  const _component_UserArea = _resolveComponent("UserArea")!
  const _component_IconsArea = _resolveComponent("IconsArea")!
  const _component_Level1Navigation = _resolveComponent("Level1Navigation")!
  const _component_Level2Navigation = _resolveComponent("Level2Navigation")!
  const _component_Level3Navigation = _resolveComponent("Level3Navigation")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_AreaNavigation, {
          routes: _ctx.getAreaRoutes,
          onOnLinkClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigation().area().linkClick($event)))
        }, null, 8 /* PROPS */, ["routes"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.hasUserArea)
          ? (_openBlock(), _createBlock(_component_UserArea, {
              key: 0,
              onOnLinkClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.navigation().area().linkClick($event)))
            }))
          : _createCommentVNode("v-if", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_IconsArea)
        ]),
        _createElementVNode("div", {
          class: "desktopNavigation_IconsAndLevel1_Level1",
          onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"]))
        }, [
          _createVNode(_component_Level1Navigation, {
            routes: _ctx.level1Routes,
            "show-next-level": _ctx.showLevel2And3Navigation,
            onOnLinkClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.navigation().page().level1($event)))
          }, null, 8 /* PROPS */, ["routes", "show-next-level"])
        ])
      ])
    ]),
    _createVNode(_Transition, {
      onBeforeEnter: _ctx.beforeEnter,
      onEnter: _ctx.enter
    }, {
      default: _withCtx(() => [
        (_ctx.showLevel2And3Navigation)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "desktopNavigation_Level2And3",
              onClick: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["stop"]))
            }, [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_Level2Navigation, {
                  routes: _ctx.level2Routes,
                  onOnClickLink: _cache[4] || (_cache[4] = ($event: any) => (_ctx.closeLevel2And3Navigation()))
                }, null, 8 /* PROPS */, ["routes"])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_Level3Navigation, {
                  "routes-array": _ctx.level3Routes,
                  onOnClickLink: _cache[5] || (_cache[5] = ($event: any) => (_ctx.closeLevel2And3Navigation()))
                }, null, 8 /* PROPS */, ["routes-array"])
              ])
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["onBeforeEnter", "onEnter"])
  ]))
}