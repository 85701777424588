
  import { defineComponent, PropType } from 'vue';
  import { get } from 'lodash';
  import ImageAndButtonIndependent from '@/components/itemTypes/base/boxes/ImageAndButtonIndependent.vue';
  import useParagraph from '@/composables/useParagraph';
  import { InformationBoxesParagraph } from '@/models/item-types/paragraph';

  export default defineComponent({
    components: {
      ImageAndButtonIndependent,
    },
    props: {
      componentProps: {
        type: Object as PropType<unknown>,
        required: true,
      },
    },
    setup(props) {
      const { data, getParagraphStyles } = useParagraph(props.componentProps);
      const paragraphData = data.value as InformationBoxesParagraph;
      return {
        paragraphData,
        getParagraphStyles,
      };
    },
    computed: {
      getStyleClass(): string {
        return get(this.paragraphData, 'layout.SelectedValue', '');
      },
    },
  });
