
  import { defineComponent, PropType } from 'vue';
  import CustomButton from '@/components/itemTypes/base/Button.vue';
  import { Field } from '@/models/field';
  import useImageAndButtonBox from '@/composables/useImageAndButtonBox';
  import { mapBaseComponentData } from '@/utils/dataMapper';

  interface Button {
    text: string;
    link: {
      IsExternal: boolean;
      Url: string;
    };
    type: string;
    buttonSize: string;
  }

  export default defineComponent({
    components: {
      CustomButton,
    },
    props: {
      box: {
        type: Object as PropType<Field>,
        required: true,
      },
      boxCount: {
        type: Number,
        required: true,
      },
      options: {
        type: Object,
        required: true,
      },
    },
    setup(props) {
      const { boxData, buttonData, getTitle, getStyles, boxImageUrl } = useImageAndButtonBox(props);

      const linkUrl = (mapBaseComponentData(boxData.button) as Button).link?.Url;

      return {
        boxData,
        buttonData,
        getTitle,
        getStyles,
        linkUrl,
        boxImageUrl,
      };
    },
    computed: {
      wrapperAttribute() {
        return this.options.entireFrameClickable ? 'a' : 'div';
      },
    },
  });
