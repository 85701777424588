import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InformationBoxesCarousel = _resolveComponent("InformationBoxesCarousel")!
  const _component_InformationBox = _resolveComponent("InformationBox")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["paragraph-container container", _ctx.getParagraphClasses]),
    style: _normalizeStyle({ ..._ctx.getParagraphStyles, ..._ctx.getInformationSpecificParagraphStyles })
  }, [
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass(["informationBoxes_Container informationBoxes_Carousel", `informationBoxes_Container_${1}boxes`])
    }, [
      _createVNode(_component_InformationBoxesCarousel, {
        boxes: _ctx.paragraphData.boxes
      }, null, 8 /* PROPS */, ["boxes"])
    ], 512 /* NEED_PATCH */), [
      [_vShow, _ctx.paragraphData.mobileCarousel]
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["informationBoxes_Container", [_ctx.getInformationBoxClasses, `informationBoxes_Container_${_ctx.getEnabledBoxCount}boxes`]])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paragraphData.boxes, (box, index) => {
        return (_openBlock(), _createBlock(_component_InformationBox, {
          key: index,
          box: box,
          "box-count": _ctx.getEnabledBoxCount
        }, null, 8 /* PROPS */, ["box", "box-count"]))
      }), 128 /* KEYED_FRAGMENT */))
    ], 2 /* CLASS */)
  ], 6 /* CLASS, STYLE */))
}