import { ShippingMethod, postboxPayload } from '@/models/cart/shippingMethod';
import { CartState } from '@/models/store/cart';

export default {
  setShippingMethod(state: CartState, shippingMethod: ShippingMethod[]): void {
    state.shippingMethod = shippingMethod;
  },
  selectShippingMethod(state: CartState, payload: string): void {
    // Set selected to true for Shipping method with specific ID
    // =Refactoring needed=
    state.cart.ShippingMethods.forEach((shipping, index) => {
      if (shipping.DWGeneratedShippingMethodID === payload) {
        state.cart.ShippingMethods[index].Selected = true;
        state.cart.selectedShippingMethod = shipping.DWGeneratedShippingMethodID;
        state.cart.selectedShippingID = shipping.DWShippingMethodID;
      } else {
        // Reset all other selected to false
        state.cart.ShippingMethods[index].Selected = false;
      }
    });
  },
  selectPostBox(state: CartState, payload: postboxPayload): void {
    state.cart.ShippingMethods.forEach((shipping, index) => {
      if (shipping.DWGeneratedShippingMethodID === payload.shippingID) {
        shipping.Postboxes.forEach((postbox, nestedIndex) => {
          if (postbox.Name === payload.postBoxID) {
            state.cart.ShippingMethods[index].Postboxes[nestedIndex].Selected = true;
            state.cart.selectedPostBox = postbox;
          } else {
            // Reset all other selected to false
            state.cart.ShippingMethods[index].Postboxes[nestedIndex].Selected = false;
          }
        });
      }
    });
  },
};
