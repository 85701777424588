
  import { defineComponent, PropType, ref } from 'vue';
  import { get } from 'lodash';
  import { Facet, FacetOption } from '@/models/product/facet';
  import accordionMixin from '@/mixins/accordion';

  export default defineComponent({
    mixins: [accordionMixin],
    props: {
      facets: {
        required: false,
        type: Array as PropType<Facet[]>,
        default: () => [],
      },
      closed: {
        required: false,
        type: Boolean,
        default: false,
      },
      selectedFacets: {
        required: false,
        type: Array as PropType<string[]>,
        default: () => [],
      },
    },
    emits: ['close', 'clear', 'update:selectedFacets'],
    setup(props, { emit }) {
      const getFacetTitle = (facet: Facet): string =>
        get(
          {
            Size: 'Stærð',
            Manufacturer: 'Vörumerki',
            BaseColor: 'Litur',
            Fabric: 'Efni',
            ProductCategory: 'Flokkur',
            Gender: 'Kyn',
          },
          facet.QueryParameter,
          facet.QueryParameter,
        );

      const showMoreSelected = ref<string[]>([]);
      const showAllFacetOptions = (facet: Facet): boolean => facet.Options.length <= 8 || showMoreSelected.value.indexOf(facet.QueryParameter) > -1;
      const getFacetOptions = (facet: Facet) => {
        if (showAllFacetOptions(facet)) {
          return facet.Options;
        }
        return facet.Options.slice(0, 8);
      };
      const facetOptionValue = (facet: Facet, opt: FacetOption): string => `${facet.QueryParameter}_${opt.Value}`;
      const facetOptionIsSelected = (facet: Facet, opt: FacetOption): boolean =>
        props.selectedFacets.findIndex((v) => v === facetOptionValue(facet, opt)) !== -1;

      const onClearClick = () => emit('clear');
      const onCloseClick = () => emit('close');
      const onOptionChange = (e: Event) => {
        const { value } = e.target as HTMLInputElement;
        const idx = props.selectedFacets.findIndex((v) => v === value);

        if (idx !== -1) {
          emit('update:selectedFacets', [...props.selectedFacets.slice(0, idx), ...props.selectedFacets.slice(idx + 1)]);
        } else {
          emit('update:selectedFacets', [...props.selectedFacets, value]);
        }
      };

      const getCheckboxStyles = (facet: Facet, option: FacetOption): string => {
        let style = '';
        if (facet.QueryParameter === 'BaseColor') {
          const color = option.Label.split(' ')[1];
          style = `background-color: ${color}`;
        }
        return style;
      };
      const getCheckboxLabel = (facet: Facet, option: FacetOption): string => {
        let label = option.Label;
        if (facet.QueryParameter === 'BaseColor') {
          const [name] = option.Label.split(' ');
          label = name;
        }
        return label;
      };

      return {
        getFacetTitle,
        getFacetOptions,
        showAllFacetOptions,
        showMoreSelected,
        facetOptionValue,
        facetOptionIsSelected,

        onClearClick,
        onCloseClick,
        onOptionChange,

        getCheckboxStyles,
        getCheckboxLabel,
      };
    },
    watch: {
      facets: {
        immediate: true,
        handler() {
          this.$nextTick(() => {
            this.facets.forEach((facet) => {
              this.resizeAccordion(facet.QueryParameter);
            });
          });
        },
      },
    },
    methods: {
      onFacetOptionShowMore(facet: Facet) {
        this.showMoreSelected.push(facet.QueryParameter);
        this.$nextTick(() => {
          this.resizeAccordion(facet.QueryParameter);
        });
      },
    },
  });
