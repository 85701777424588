import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0de78be1"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "name", "value", "type", "placeholder", "required", "autocomplete", "maxlength", "disabled"]
const _hoisted_3 = {
  key: 1,
  class: "text-input__icon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_RenderErrors = _resolveComponent("RenderErrors")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.getStyles),
    class: _normalizeClass(['text-input', { 'text-input--error': _ctx.hasErrors }])
  }, [
    (_ctx.hasLabel)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: _normalizeClass(["text-input__label", { error: _ctx.hasErrors }]),
          for: _ctx.name
        }, _toDisplayString(_ctx.label), 11 /* TEXT, CLASS, PROPS */, _hoisted_1))
      : _createCommentVNode("v-if", true),
    _createElementVNode("input", {
      id: _ctx.id,
      ref: "inputRef",
      name: _ctx.name,
      class: _normalizeClass([
        'text-input__input',
        {
          'text-input__input--error': _ctx.hasErrors,
          'text-input__input--icon': _ctx.hasIcon,
        },
      ]),
      value: _ctx.input,
      type: _ctx.type,
      placeholder: _ctx.placeholder,
      required: _ctx.required,
      autocomplete: _ctx.autocomplete,
      maxlength: _ctx.maxlength,
      disabled: _ctx.disabled,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args))),
      onFocus: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args)))
    }, null, 42 /* CLASS, PROPS, HYDRATE_EVENTS */, _hoisted_2),
    (_ctx.hasIcon)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
          _renderSlot(_ctx.$slots, "icon", {}, () => [
            _createVNode(_component_Icon, { icon: _ctx.icon }, null, 8 /* PROPS */, ["icon"])
          ], true)
        ]))
      : _createCommentVNode("v-if", true),
    (_ctx.hasErrors)
      ? (_openBlock(), _createBlock(_component_RenderErrors, {
          key: 2,
          limit: _ctx.errorLimit,
          errors: _ctx.errors
        }, null, 8 /* PROPS */, ["limit", "errors"]))
      : _createCommentVNode("v-if", true)
  ], 6 /* CLASS, STYLE */))
}