import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/pdf.svg'


const _withScopeId = n => (_pushScopeId("data-v-a18444a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cartBackAndNextButtons" }
const _hoisted_2 = {
  key: 0,
  src: _imports_0,
  class: "prevIcon"
}
const _hoisted_3 = { class: "" }
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_Loader = _resolveComponent("Loader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_custom_button, {
      "btn-type": "light-border",
      "btn-height": "48",
      onBtnClick: _ctx.onClickBack,
      disabled: _ctx.loading
    }, {
      default: _withCtx(() => [
        (_ctx.prevUseIcon)
          ? (_openBlock(), _createElementBlock("img", _hoisted_2))
          : _createCommentVNode("v-if", true),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.prevButtonText) + " " + _toDisplayString(_ctx.prevButtonText.useIcon), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["onBtnClick", "disabled"]),
    _createVNode(_component_custom_button, {
      "btn-type": "dark",
      "btn-height": "48",
      disabled: _ctx.goNextDisabled || _ctx.loading,
      type: "submit",
      onBtnClick: _ctx.onClickNext
    }, {
      default: _withCtx(() => [
        (!_ctx.loading)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.nextButtonText), 1 /* TEXT */))
          : (_openBlock(), _createBlock(_component_Loader, {
              key: 1,
              size: 15,
              thickness: 3
            }))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["disabled", "onBtnClick"])
  ]))
}