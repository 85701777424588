import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c8b12920"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "menu__header" }
const _hoisted_2 = { class: "menu__title" }
const _hoisted_3 = { class: "menu__subtitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "mobile-modal-menu",
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
  }, [
    _createElementVNode("div", {
      class: "menu",
      onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.subtitle), 1 /* TEXT */)
      ]),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}