
  /* eslint-disable @typescript-eslint/no-explicit-any */
  import { defineComponent } from 'vue';
  import useParagraph from '@/composables/useParagraph';
  import { ImageHandler } from '@/utils/imageHelper';
  import { mapBaseComponentData } from '@/utils/dataMapper';
  import conf from '@/config';

  export default defineComponent({
    props: {
      componentProps: {
        type: Object,
        default: null,
      },
    },
    setup(props) {
      const { getParagraphStyles, getParagraphClasses } = useParagraph(props.componentProps);
      return {
        getParagraphStyles,
        getParagraphClasses,
      };
    },
    data() {
      return {
        imagePrefix: conf.dwUrl,
      };
    },
    computed: {
      newsArticle(): any {
        return this.componentProps;
      },
      bannerImage(): string {
        if (this.newsArticle.bannerImage) {
          return `url('${this.imagePath(this.newsArticle.bannerImage[0].Path, 1500, 1500)}')`;
        }
        return `url('${this.imagePath(this.newsArticle.image[0].Path, 1500, 1500)}')`;
      },
      getContentData(): any {
        const { articleContent } = this.componentProps;
        return articleContent.map((block) => mapBaseComponentData(block));
      },
    },
    methods: {
      imagePath(image: string, width: number, height: number) {
        return this.imagePrefix + ImageHandler(image, width, height);
      },
      convertLayoutToClass(layout: string): string {
        switch (layout) {
          case 'Horizontal-TextFirst':
            return 'layout__horizontal layout__horizontal--text-first';
          case 'Horizontal-ImageFirst':
            return 'layout__horizontal layout__horizontal--img-first';
          case 'Horizontal-DoubleText':
            return 'layout__horizontal';
          case 'Vertical':
            return 'layout__vertical';
          default:
            return 'layout__vertical';
        }
      },
    },
  });
