
  import { computed, defineComponent, onMounted, onUnmounted, PropType, ref } from 'vue';

  export interface ProductListSortDropdownOption {
    value?: string;
    name: string;
    sortBy?: string;
    sortOrder?: 'ASC' | 'DESC';
  }

  export default defineComponent({
    props: {
      options: {
        required: false,
        type: Array as PropType<ProductListSortDropdownOption[]>,
        default: () => [],
      },
      modelValue: {
        required: false,
        type: String,
        default: '',
      },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
      const showSortOptions = ref<boolean>(false);
      const sortToggle = () => {
        showSortOptions.value = !showSortOptions.value;
      };
      const onSortClick = () => {
        sortToggle();
      };

      const onOptionClick = (opt: ProductListSortDropdownOption) => {
        emit('update:modelValue', opt.value);
      };
      const selectedOption = computed<ProductListSortDropdownOption | undefined>(() =>
        props.options.find(({ value }) => props.modelValue && value === props.modelValue),
      );

      const onDocumentClick = () => {
        showSortOptions.value = false;
      };
      onMounted(() => {
        document.addEventListener('click', onDocumentClick);
      });
      onUnmounted(() => {
        document.removeEventListener('click', onDocumentClick);
      });
      return {
        showSortOptions,
        sortToggle,
        onSortClick,

        onOptionClick,
        selectedOption,
      };
    },
  });
