import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2cad00c6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    href: _ctx.link,
    class: _normalizeClass(['shadow-button', { 'shadow-button--invert': _ctx.invert }])
  }, [
    _createElementVNode("button", {
      class: _normalizeClass(['shadow-button__button', `shadow-button__button--${_ctx.color}`]),
      style: {}
    }, _toDisplayString(_ctx.text), 3 /* TEXT, CLASS */)
  ], 10 /* CLASS, PROPS */, _hoisted_1))
}