import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-036b80a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "news-swiper" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loader = _resolveComponent("Loader")!
  const _component_article_card = _resolveComponent("article-card")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["container", _ctx.getParagraphClasses]),
    style: _normalizeStyle(_ctx.getParagraphStyles)
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.newsCarouselLoader)
        ? (_openBlock(), _createBlock(_component_Loader, {
            key: 0,
            size: 100,
            thickness: 2
          }))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_swiper, {
              "space-between": 16,
              navigation: false,
              pagination: false,
              "slides-per-view": 'auto',
              breakpoints: _ctx.swiperOption.breakpoints
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newsList, (news, index) => {
                  return (_openBlock(), _createBlock(_component_swiper_slide, { key: index }, {
                    default: _withCtx(() => [
                      _createVNode(_component_article_card, { article: news }, null, 8 /* PROPS */, ["article"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */))
                }), 128 /* KEYED_FRAGMENT */))
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["breakpoints"]),
            _createElementVNode("a", {
              class: "news-card__see-all btn btn--dark btn-s",
              href: _ctx.allNewsLink
            }, " ALLAR FRÉTTIR ", 8 /* PROPS */, _hoisted_3)
          ]))
    ])
  ], 6 /* CLASS, STYLE */))
}