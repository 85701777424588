import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserOrderTableHeader = _resolveComponent("UserOrderTableHeader")!
  const _component_UserOrderTableRow = _resolveComponent("UserOrderTableRow")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_UserOrderTableHeader),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orders, (order) => {
      return (_openBlock(), _createBlock(_component_UserOrderTableRow, {
        key: order.Id,
        order: order
      }, null, 8 /* PROPS */, ["order"]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}