
  import { defineComponent } from 'vue';
  import { mapActions, mapGetters } from 'vuex';
  import Burger from '@/global_components/navigation/mobile_tablet/MobileTabletBurger.vue';
  import AddToCartNotification from '@/components/cart/global_cart/AddToCartNotification.vue';
  import GlobalPopupCart from '@/components/cart/global_cart/PopupCart.vue';
  import { colorIsDark } from '@/utils/colorHelper';
  import Loader from '@/components/shared/Loader.vue';

  export default defineComponent({
    components: {
      AddToCartNotification,
      GlobalPopupCart,
      Burger,
      Loader,
    },
    props: {
      scrolling: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    emits: ['passBurgerRef', 'toggleBurger'],
    data() {
      return {
        cartOpen: false,
      };
    },
    computed: {
      ...mapGetters('cart', {
        cartQuantity: 'getCartQuantity',
      }),
      ...mapGetters('website', {
        websiteSettings: 'getWebsiteSettings',
      }),
      areaHasSearch(): boolean {
        return this.$router.resolve({ path: this.areaSearchPath }).name !== '404';
      },
      areaSearchPath(): string {
        // = Refactor = Should not have /leit hard-coded here
        const lastChar = this.websiteSettings.routePath.charAt(this.websiteSettings.routePath.length - 1);
        if (lastChar === '/') {
          return `${this.websiteSettings.routePath}leit`;
        }
        return `${this.websiteSettings.routePath}/leit`;
      },
      getFontStyles(): unknown {
        return {
          '--text-color': colorIsDark(this.websiteSettings.navColor) ? 'white' : 'black',
        };
      },
      cartLoading(): boolean {
        return this.$store.state.cart.loading;
      },
    },
    methods: {
      ...mapActions('cart', ['addToCart']),
      acceptBurgerRef(burger: HTMLInputElement) {
        this.$emit('passBurgerRef', burger);
      },
      toggleBurger() {
        this.$emit('toggleBurger');
      },
      toggleCart() {
        this.cartOpen = !this.cartOpen;
      },
    },
  });
