import { CartState } from '@/models/store/cart';
import { Cart } from '@/models/cart/cart';

export default {
  toggleGiftWrap: (state: CartState): void => {
    state.contactInfoPageOptions.giftWrap = !state.contactInfoPageOptions.giftWrap;
  },

  toggleMailingList: (state: CartState): void => {
    state.contactInfoPageOptions.addToMailingList = !state.contactInfoPageOptions.addToMailingList;
  },

  toggleUseOtherAddress: (state: CartState): void => {
    state.contactInfoPageOptions.useOtherAddress = !state.contactInfoPageOptions.useOtherAddress;
  },

  setContactInfo: (state: CartState, cart: Cart): void => {
    state.cart.CustomerInfo = cart.CustomerInfo;
  },
};
