
  /* eslint-disable @typescript-eslint/no-explicit-any */
  import { defineComponent, PropType } from 'vue';
  import { ItemType } from '@/models/field';
  import { mapBaseComponentData } from '@/utils/dataMapper';

  import useParagraph from '@/composables/useParagraph';

  import VideoWithText from '@/components/itemTypes/VideoWithText/VideoWithText.vue';

  interface IVideoWithTextList {
    videoWithTextList: ItemType[];
  }

  export default defineComponent({
    components: {
      VideoWithText,
    },
    props: {
      componentProps: {
        type: Object as PropType<IVideoWithTextList>,
        required: true,
      },
    },
    setup(props) {
      const { getParagraphStyles, getParagraphClasses } = useParagraph(props.componentProps);

      return {
        getParagraphStyles,
        getParagraphClasses,
      };
    },
    data() {
      return {
        showBodyAll: false,
        buttons: [] as IVideoWithTextList[],
      };
    },
    computed: {
      getData(): IVideoWithTextList[] {
        const { videoWithTextList } = this.componentProps;
        return videoWithTextList.map((item) => mapBaseComponentData(item));
      },
      isDarkMode(): boolean {
        return this.$route.meta.toggleDarkMode as boolean;
      },
    },
  });
