import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), {
    class: _normalizeClass(["outline-box", { primary: _ctx.primary, clickable: _ctx.clickable, closable: _ctx.closable, textPrimary: _ctx.textPrimary }]),
    onClick: _ctx.onClick
  }, {
    default: _withCtx(() => [
      (_ctx.closable)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "outline-box__close btn closeButton",
            type: "button",
            role: "button",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onCloseClick && _ctx.onCloseClick(...args)))
          }))
        : _createCommentVNode("v-if", true),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["class", "onClick"]))
}