
  import { defineComponent, PropType } from 'vue';
  import Banner from '@/components/itemTypes/Banner.vue';
  import useParagraph from '@/composables/useParagraph';
  import { BannerParagraph } from '@/models/item-types/paragraph';

  export default defineComponent({
    components: {
      Banner,
    },
    props: {
      componentProps: {
        type: Object as PropType<unknown>,
        required: true,
      },
    },
    setup(props) {
      const { data, getParagraphStyles, getParagraphClasses } = useParagraph(props.componentProps);
      const paragraphData = data.value as BannerParagraph;
      return {
        paragraphData,
        getParagraphStyles,
        getParagraphClasses,
      };
    },
  });
