import { Cart } from '@/models/cart/cart';
import { PaymentMethod } from '@/models/cart/paymentMethod';
import { SiminnPayLoanInfo } from '@/models/cart/siminnPay';
import { CartState } from '@/models/store/cart';

export default {
  setPaymentMethod(state: CartState, payload: PaymentMethod): void {
    state.cart.PaymentMethods.forEach((paymentMethod, index) => {
      if (paymentMethod.PaymentMethodID === payload.PaymentMethodID) {
        state.cart.PaymentMethods[index].Selected = true;
        state.cart.selectedPaymentMethod = paymentMethod.PaymentMethodID;
      } else {
        state.cart.PaymentMethods[index].Selected = false;
      }
    });
  },
  setSiminnPayLoanInfo(state: CartState, payload: SiminnPayLoanInfo): void {
    state.siminnPayLoanInfo = payload;
  },
  setGiftCardInfo(state: CartState, cart: Cart): void {
    state.cart.General.GiftCardNumber = cart.General.GiftCardNumber;
    state.cart.General.GiftCardAmount = cart.General.GiftCardAmount;
    state.cart.General.GiftCardAmountIsInsufficient = cart.General.GiftCardAmountIsInsufficient;
    state.cart.General.NoGiftCardNumber = cart.General.NoGiftCardNumber;
    state.cart.General.OrderAmountToBePaid = cart.General.OrderAmountToBePaid;
    state.cart.General.TotalPrice = cart.General.TotalPrice;
  },
};
