/* eslint-disable no-shadow */
import { CartState, MultiWrapOrderLine } from '@/models/store/cart';

export default {
  setSingleGiftWrap(state: CartState): void {
    state.giftWrapPage.singleWrap = true;
    state.giftWrapPage.multiWrap = false;
  },

  setMultiGiftWrap(state: CartState): void {
    state.giftWrapPage.multiWrap = true;
    state.giftWrapPage.singleWrap = false;
  },

  setSelectedGiftWrapCount(state: CartState, payload: number): void {
    state.giftWrapPage.selectedGiftWrapCount = payload;
    state.giftWrapPage.multiWrapOrderLines = [
      ...state.giftWrapPage.multiWrapOrderLines.map((ol: MultiWrapOrderLine) => {
        const multiWrapOrderLine = { ...ol };
        if (multiWrapOrderLine.PackageNumber && multiWrapOrderLine.PackageNumber > payload) {
          multiWrapOrderLine.PackageNumber = payload;
        }
        return multiWrapOrderLine;
      }),
    ];
  },

  updateMultiGiftWrapOrderLine(state: CartState, payload: MultiWrapOrderLine): void {
    const multiWrapOrderLine = { ...payload };
    if (!multiWrapOrderLine.IsGiftWrapped) {
      multiWrapOrderLine.PackageNumber = null;
    }
    state.giftWrapPage.multiWrapOrderLines = [
      ...state.giftWrapPage.multiWrapOrderLines.filter((ol: MultiWrapOrderLine) => ol.MultiWrapOrderLineID !== multiWrapOrderLine.MultiWrapOrderLineID),
      multiWrapOrderLine,
    ];
  },

  setGiftWrapProduct(state: CartState, payload: unknown): void {
    state.giftWrapProduct = payload;
  },
};
