
  import { computed, defineComponent, PropType } from 'vue';
  import { Field } from '@/models/field';
  import { TitleAndRichText } from '@/models/item-types/base/boxes';
  import useBaseBoxComponent from '@/composables/useBaseBoxComponent';

  export default defineComponent({
    props: {
      box: {
        type: Object as PropType<Field>,
        required: true,
      },
      boxCount: {
        type: Number,
        required: true,
      },
    },
    setup(props) {
      const { data, getStyles, getTitle } = useBaseBoxComponent(props.box);
      const boxData = data.value as TitleAndRichText;
      const getComponentSpecificStyles = computed(() => ({ '--rich-text-transform': boxData.uppercaseText ? 'uppercase' : 'none' }));
      return {
        boxData,
        getTitle,
        getStyles,
        getComponentSpecificStyles,
      };
    },
  });
