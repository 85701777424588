export const TIME_SLOT = {
  '10_TO_13': '10:00-13:00',
  '13_TO_16': '13:00-16:00',
};

export const STATUS = {
  LOADING: 'LOADING',
  LOADING_SUCCESS: 'LOADING_SUCCESS',
  ERROR: 'ERROR',
  POST_SUCCESS: 'POST_SUCCESS',
};
