
  /* eslint-disable @typescript-eslint/no-explicit-any */
  import { defineComponent, PropType } from 'vue';
  import { ItemType } from '@/models/field';
  import { mapBaseComponentData } from '@/utils/dataMapper';
  import { ImageHandler } from '@/utils/imageHelper';
  import conf from '@/config';

  interface IEmployeeList {
    employeeList: ItemType[];
  }

  export default defineComponent({
    props: {
      componentProps: {
        type: Object as PropType<IEmployeeList>,
        required: true,
      },
    },
    data() {
      return {
        imagePrefix: conf.dwUrl,
      };
    },
    computed: {
      getData(): any {
        const { employeeList } = this.componentProps;
        return employeeList.map((employee) => mapBaseComponentData(employee));
      },
    },
    methods: {
      imagePath(image: string, width: number, height: number) {
        return this.imagePrefix + ImageHandler(image, width, height);
      },
    },
  });
