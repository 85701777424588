import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d52ba52"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cartSideOverviewOrderLine" }
const _hoisted_2 = { class: "cartSideOverviewOrderLine_Image" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "cartSideOverviewOrderLine_Text" }
const _hoisted_5 = { class: "cartSideOverviewOrderLine_Text_Manufacturer" }
const _hoisted_6 = { class: "cartSideOverviewOrderLine_Text_ProductName" }
const _hoisted_7 = { class: "cartSideOverviewOrderLine_Price" }
const _hoisted_8 = { class: "cartSideOverviewOrderLine_Price--WithDiscount" }
const _hoisted_9 = {
  key: 0,
  class: "cartSideOverviewOrderLine_Price--WithoutDiscount"
}
const _hoisted_10 = { class: "cartSideOverviewOrderLine_Info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.productImagePath,
        alt: "product image"
      }, null, 8 /* PROPS */, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.orderLine.Manufacturer), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.orderLine.Product.ProductName), 1 /* TEXT */)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.formattedOrderLinePrice), 1 /* TEXT */),
      (_ctx.orderLineHasDiscount)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.formattedProductPrice), 1 /* TEXT */))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", null, _toDisplayString(_ctx.selectedQuantity) + " STK", 1 /* TEXT */),
      _createElementVNode("div", null, _toDisplayString(_ctx.selectedColor), 1 /* TEXT */),
      _createElementVNode("div", null, _toDisplayString(_ctx.selectedVariant.VariantText), 1 /* TEXT */)
    ])
  ]))
}