
  import { defineComponent, PropType, ref } from 'vue';
  import TextInput from '@/components/shared/inputs/TextInput.vue';
  import { User, UserPayload } from '@/models/user/user';
  import Loader from '@/components/shared/Loader.vue';
  import { minLength, validateEmail, validatePhoneNumber, validateSSNOptional } from '@/utils/formValidators';

  interface UserInformationFormState {
    formData: UserPayload;
    validators: any;
    hasChange: boolean;
  }

  export default defineComponent({
    components: {
      TextInput,
      Loader,
    },
    props: {
      /**
       * Populate form with existing User model
       */
      oldValue: {
        required: false,
        type: Object as PropType<User>,
        default: null,
      },
      /**
       * Indicate the form is being submitted
       */
      submitting: {
        required: false,
        type: Boolean,
        default: false,
      },
    },
    emits: ['password', 'submit'],
    data() {
      return {
        formData: {
          name: '',
          ssn: '',
          email: '',
          phoneNumber: '',
        },
        validators: {
          name: [minLength(1, 'Nafn má ekki vera tómt')],
          ssn: [validateSSNOptional('Kennitala ekki lögleg')],
          phoneNumber: [validatePhoneNumber('Símanúmer ekki löglegt')],
        },
        hasChange: false,
      } as UserInformationFormState;
    },
    created() {
      if (this.oldValue) {
        this.formData = {
          name: this.oldValue.Name,
          ssn: this.oldValue.ExternalId,
          email: this.oldValue.Email,
          phoneNumber: this.oldValue.Phone,
        };
      }
    },
    methods: {
      onPasswordFocus() {
        this.$emit('password', true);
      },
      onPasswordClick() {
        this.$emit('password', false);
      },
      onSubmit() {
        if (this.formValid()) {
          this.$emit('submit', this.formData);
        }
      },
      onInput() {
        this.hasChange = true;
      },
      formValid() {
        [this.$refs.nameRef, this.$refs.ssnRef, this.$refs.phoneNumberRef].forEach((r: any) => {
          if (r !== undefined) {
            if (r?.validate) {
              r.validate();
            }
          }
        });

        if (!this.validators.name.every((validator) => validator(this.formData.name) === null) && this.validators.name.length > 0) return false;
        if (!this.validators.ssn.every((validator) => validator(this.formData.ssn) === null) && this.validators.ssn.length > 0) return false;
        if (!this.validators.phoneNumber.every((validator) => validator(this.formData.phoneNumber) === null) && this.validators.phoneNumber.length > 0)
          return false;
        return true;
      },
    },
  });
