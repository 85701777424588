
  /* eslint-disable @typescript-eslint/no-explicit-any */
  import { defineComponent } from 'vue';

  export default defineComponent({
    props: {
      text: {
        type: String,
        required: true,
      },
      link: {
        type: String,
        required: true,
      },
      invert: {
        type: Boolean,
        default: false,
      },
      color: {
        type: String,
        required: true,
      },
    },
  });
