import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-14687e22"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "titleTextAndButton_TitleAndText" }
const _hoisted_2 = { class: "titleTextAndButton_TitleAndText_Title" }
const _hoisted_3 = { class: "box_Title box_DesktopTitle" }
const _hoisted_4 = { class: "box_Title box_MobileTitle" }
const _hoisted_5 = { class: "titleTextAndButton_TitleAndText_Text" }
const _hoisted_6 = { class: "box_Text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_button = _resolveComponent("custom-button")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.wrapperAttribute), {
    href: _ctx.linkUrl,
    style: _normalizeStyle(_ctx.getStyles),
    class: _normalizeClass(["titleTextAndButton", `titleTextAndButton_${_ctx.boxCount}boxes`])
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.getTitle), 1 /* TEXT */),
          _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.getMobileTitle), 1 /* TEXT */)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.cutSentenceIfNeeded(_ctx.boxData.text, 190)), 1 /* TEXT */)
        ])
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(['titleTextAndButton_Button', `titleTextAndButton_Button--${_ctx.boxData.buttonPosition}`])
      }, [
        _createVNode(_component_custom_button, {
          button: _ctx.boxData.button
        }, null, 8 /* PROPS */, ["button"])
      ], 2 /* CLASS */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["href", "style", "class"]))
}