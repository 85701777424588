
  /* eslint-disable @typescript-eslint/no-explicit-any */
  import { defineComponent, PropType } from 'vue';
  import { Field, Image, Color, ImageDimensions, ItemType, LinkField } from '@/models/field';
  import { mapBaseComponentData } from '@/utils/dataMapper';
  import { getBackgroundImageStyles, getBackgroundImageValue } from '@/utils/imageHelper';
  import CustomButton from '@/components/itemTypes/base/Button.vue';

  interface ImageAndTitle {
    image: Image;
    title: string;
    subtitle: string;
    titleColor: Color;
    button: ItemType;
    entireFrameClickable?: boolean;
  }

  interface ButtonData {
    buttonSize: string;
    link: LinkField;
    text: string;
    type: string;
  }

  export default defineComponent({
    components: {
      CustomButton,
    },
    props: {
      banner: {
        type: Object as PropType<Field>,
        required: true,
      },
    },
    computed: {
      href(): string {
        try {
          return this.getButtonData.link.Url;
        } catch (e) {
          return '';
        }
      },
      isExternal(): boolean {
        try {
          return this.getButtonData.link.IsExternal;
        } catch (e) {
          return false;
        }
      },
      isEntireFrameClickable(): boolean {
        return !!this.getData.entireFrameClickable;
      },
      componentTag(): string {
        if (!this.isEntireFrameClickable) {
          return 'div';
        }

        return this.isExternal ? 'a' : 'router-link';
      },
      getData(): ImageAndTitle {
        const { banner } = this.$props;
        const data = mapBaseComponentData(banner) as ImageAndTitle;
        return data;
      },
      getButtonData(): ButtonData {
        const { banner } = this.$props;
        const data = mapBaseComponentData(banner) as ImageAndTitle;
        const buttonData = mapBaseComponentData(data.button) as ButtonData;
        return buttonData;
      },
      getContainerStyles(): unknown {
        const imageDimensions = { width: 1500, height: 700 } as ImageDimensions;
        return getBackgroundImageStyles(this.getData.image, imageDimensions);
      },
      getTitleStyles(): unknown {
        return {
          color: this.getData.titleColor.Hex,
        };
      },
      imageSrc(): string {
        return getBackgroundImageValue(this.getData.image, { width: 1500, height: 700 } as ImageDimensions);
      },
    },
  });
