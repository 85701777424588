
  import { defineComponent, PropType } from 'vue';
  import { WishlistProduct } from '@/models/wishlist/wishlist';
  import MobileModalMenu from '@/components/shared/MobileModalMenu.vue';
  import useWishlistProduct from '@/composables/useWishlistProduct';
  import DropDown from '@/components/shared/inputs/DropDown.vue';

  interface Option {
    value: string;
    notification: string;
    disabled: boolean;
    meta: any;
  }

  export default defineComponent({
    components: {
      MobileModalMenu,
      DropDown,
    },
    props: {
      product: {
        required: true,
        type: Object as PropType<WishlistProduct>,
      },
    },
    emits: ['close', 'cart-add', 'delete'],
    setup(props) {
      const { colorOptions, getVariantOptions } = useWishlistProduct(props);

      return {
        colorOptions,
        getVariantOptions,
      };
    },
    data() {
      return {
        selectedSize: 'VELDU STÆRÐ',
        selectedVariant: {},
        selectedProduct: this.product as WishlistProduct,
      };
    },
    computed: {
      selectedColor(): string {
        if (this.product?.RelatedProducts?.length === 0) {
          return this.product.BaseColor;
        }
        if (this.selectedProduct?.BaseColor) {
          return this.selectedProduct.BaseColor;
        }
        return 'VELDU LIT';
      },
      variantOptions(): Option[] {
        return this.getVariantOptions(this.product, this.selectedProduct);
      },
      colorDropdownDisabled(): boolean {
        if (this.product?.RelatedProducts === undefined) {
          return true;
        }
        if (this.product.RelatedProducts.length === 0) {
          return true;
        }
        return false;
      },
      variantDropdownDisabled(): boolean {
        if (this.product?.VariantInfo?.VariantInfo === undefined) {
          return true;
        }
        if (this.product.VariantInfo.VariantInfo.length === 0) {
          return true;
        }
        return false;
      },
      buyButtonDisabled(): boolean {
        return this.selectedColor === 'VELDU LIT' || this.selectedSize === 'VELDU STÆRÐ';
      },
    },
    methods: {
      onWishlistProductDelete(product: WishlistProduct) {
        this.$emit('delete', product);
      },
      onWishlistProductAddToCart() {
        if (!this.buyButtonDisabled) {
          const product = this.product?.RelatedProducts?.length === 0 ? this.product : this.selectedProduct;
          const variant = this.selectedVariant;
          this.$emit('cart-add', product, variant);
        }
      },
      onCloseMenu() {
        this.$emit('close');
      },
      updateColor(event) {
        this.selectedColor = event.value;
        this.selectedProduct = event.meta.product;
        this.selectedSize = 'VELDU STÆRÐ';
      },
      updateSize(event) {
        this.selectedSize = event.value;
        this.selectedVariant = event.meta.variant;
      },
    },
  });
