import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-596ee737"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "coop-form__categories" }
const _hoisted_2 = { class: "coop-form__category-title" }
const _hoisted_3 = { class: "coop-form__group" }
const _hoisted_4 = { class: "coop-form__inputs" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 1,
  class: "coop-form__success"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RadioButtonInput = _resolveComponent("RadioButtonInput")!
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_TextAreaInput = _resolveComponent("TextAreaInput")!
  const _component_Loader = _resolveComponent("Loader")!
  const _component_custom_button = _resolveComponent("custom-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["container container--small paragraph-container", _ctx.getParagraphClasses]),
    style: _normalizeStyle(_ctx.getParagraphStyles)
  }, [
    (!_ctx.formPosted)
      ? (_openBlock(), _createElementBlock("form", {
          key: 0,
          class: "coop-form",
          onSubmit: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getRadioFormFields, (cat, catInd) => {
              return (_openBlock(), _createElementBlock("div", {
                key: catInd,
                class: "coop-form__category"
              }, [
                _createElementVNode("div", _hoisted_2, _toDisplayString(cat.name), 1 /* TEXT */),
                _createElementVNode("div", _hoisted_3, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(cat.fieldOptions, (option, optInd) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: optInd,
                      class: "radio-item"
                    }, [
                      _createVNode(_component_RadioButtonInput, {
                        value: _ctx.$data[cat.systemName],
                        "onUpdate:value": ($event: any) => ((_ctx.$data[cat.systemName]) = $event),
                        "radio-value": option.value,
                        name: cat.name
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(option.text), 1 /* TEXT */)
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "onUpdate:value", "radio-value", "name"])
                    ]))
                  }), 128 /* KEYED_FRAGMENT */))
                ])
              ]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTextFormFields, (item, itemInd) => {
              return (_openBlock(), _createElementBlock("div", {
                key: itemInd,
                class: "input-item"
              }, [
                _createCommentVNode(" {{ `${createRefName(item.systemName)}Validator` }} "),
                (item.type === 'TextInput')
                  ? (_openBlock(), _createBlock(_component_TextInput, {
                      key: 0,
                      ref: `${_ctx.createRefName(item.systemName)}`,
                      value: _ctx.$data[item.systemName],
                      "onUpdate:value": ($event: any) => ((_ctx.$data[item.systemName]) = $event),
                      label: item.name,
                      width: "100%",
                      required: item.required,
                      type: item.validation,
                      "error-limit": 1,
                      validators: _ctx.$data[`${_ctx.createRefName(item.systemName)}Validator`]
                    }, null, 8 /* PROPS */, ["value", "onUpdate:value", "label", "required", "type", "validators"]))
                  : (_openBlock(), _createBlock(_component_TextAreaInput, {
                      key: 1,
                      id: item.systemName,
                      ref: `${_ctx.createRefName(item.systemName)}`,
                      value: _ctx.$data[item.systemName],
                      "onUpdate:value": ($event: any) => ((_ctx.$data[item.systemName]) = $event),
                      label: item.name,
                      width: "100%",
                      height: "144px"
                    }, null, 8 /* PROPS */, ["id", "value", "onUpdate:value", "label"]))
              ]))
            }), 128 /* KEYED_FRAGMENT */)),
            _createVNode(_component_custom_button, {
              "btn-type": "primary",
              "btn-height": "48",
              type: "submit"
            }, {
              default: _withCtx(() => [
                (!_ctx.formLoader)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, "SENDA"))
                  : (_openBlock(), _createBlock(_component_Loader, {
                      key: 1,
                      size: 28,
                      thickness: 3,
                      color: '#f4f4f4'
                    }))
              ]),
              _: 1 /* STABLE */
            })
          ])
        ], 32 /* HYDRATE_EVENTS */))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.successMessage), 1 /* TEXT */))
  ], 6 /* CLASS, STYLE */))
}