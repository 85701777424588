import { useStore } from 'vuex';
import { computed, ComputedRef } from 'vue';
import { useRouter } from 'vue-router';
import { WebsiteSettings, WebsiteStateSettings } from '@/models/store/website';
import * as GETTER from '@/constants/store/website/getters';
import { colorIsDark } from '@/utils/colorHelper';
import { getAreaForPage, getPrimaryArea } from '@/utils/routingHelper';

interface ReturnValue {
  websiteSettings: ComputedRef<WebsiteSettings>;
  websiteStyles: ComputedRef<{
    '--primary-color': string;
  }>;
  updateWebsiteSettings: () => Promise<void>;
  registerAreaSettings: (areaId: number, settings: WebsiteStateSettings) => void;
  getAvailableAreas: ComputedRef<Array<string>>;
  getSettingsByAreaName: (name: string) => WebsiteSettings | null;
  getSettingsByRoutePath: (path: string) => WebsiteSettings | null;
}

const getWebsiteStyles = (websiteSettings: WebsiteSettings) => ({
  '--primary-color': websiteSettings.primaryColor,
  '--primary-color-light': websiteSettings.primaryColorLight,
  '--text-color': colorIsDark(websiteSettings.primaryColor) ? 'white' : 'black',
  '--nav-color': websiteSettings.navColor,
  '--nav-color-light': websiteSettings.navColorLight,
  '--nav-color-text': websiteSettings.navColorText,
  '--nav-color-text-hover': websiteSettings.navColorTextHover,
});

export default function useWebsiteSettings(): ReturnValue {
  const store = useStore();
  const router = useRouter();
  const { getters, commit } = store;
  const websiteSettings = computed(() => getters[`website/${GETTER.GET_WEBSITE_SETTINGS}`] as WebsiteSettings);
  const websiteStyles = computed(() => getWebsiteStyles(websiteSettings.value));
  const updateWebsiteSettings = async (): Promise<void> => {
    let area = getAreaForPage(router);
    if (!Object.keys(area).length) {
      area = getPrimaryArea(router);
    }
    return store.dispatch('website/applySettings', area.meta.areaID);
  };
  const registerAreaSettings = (areaId: number, settings: WebsiteStateSettings) => {
    commit('website/registerSettings', { areaId, settings });
  };
  const getAvailableAreas = computed(() => Object.values(store.state.website.settings as WebsiteStateSettings[]).map(({ areaName }) => areaName));
  const getSettingsByAreaName = (name: string): WebsiteSettings | null => getters['website/getWebsiteSettingsByName'](name) || null;
  const getSettingsByRoutePath = (path: string): WebsiteSettings | null => getters['website/getWebisteSettingsByRoutePath'](path) || null;

  return {
    websiteSettings,
    websiteStyles,
    updateWebsiteSettings,
    registerAreaSettings,
    getAvailableAreas,
    getSettingsByAreaName,
    getSettingsByRoutePath,
  };
}
