
  import { defineComponent, PropType } from 'vue';
  import { RouteRecord } from 'vue-router';

  export default defineComponent({
    props: {
      route: {
        type: Object as PropType<RouteRecord>,
        required: false,
        default: () => ({}),
      },
      activeRoute: {
        type: String,
        required: false,
        default: () => '',
      },
    },
    emits: ['onLinkClick'],
    data() {
      return {
        dropdownOpen: false,
      };
    },
    computed: {
      routeName() {
        return this.route.path;
      },
      isActive() {
        return this.activeRoute.includes(this.routeName);
      },
    },
    methods: {
      onClickLink(route): void {
        this.$emit('onLinkClick', route);
      },
      onOpenDropdown(route): void {
        this.$emit('onLinkClick', route);
        if (!this.isActive) {
          this.dropdownOpen = !this.dropdownOpen;
        }
      },
    },
  });
