import { Cart } from '@/models/cart/cart';
import { CustomerInfo } from '@/models/cart/customerInfo';
import { DeliveryInfo } from '@/models/cart/deliveryInfo';
import { General } from '@/models/cart/general';
import { OrderLine } from '@/models/cart/orderLine';
import { PaymentMethod } from '@/models/cart/paymentMethod';
import { ShippingFee } from '@/models/cart/shippingFee';
import { Postbox, ShippingMethod } from '@/models/cart/shippingMethod';

export default {
  General: {
    CurrencySymbol: 'ISK',
    OrderAmountToBePaid: 0,
    TotalPrice: 0,
    CouponCode: '',
  } as General,
  CustomerInfo: {
    CustomerName: '',
    CustomerAddress: '',
    CustomerCity: '',
    CustomerComment: '',
    CustomerCompany: '',
    CustomerEmail: '',
    CustomerPhone: '',
    CustomerPostcode: '',
    CustomerSSN: '',
  } as CustomerInfo,
  DeliveryInfo: {} as DeliveryInfo,
  OrderLines: [] as OrderLine[],
  ShippingFee: {} as ShippingFee,
  ShippingMethods: [] as ShippingMethod[],
  PaymentMethods: [] as PaymentMethod[],
  selectedPostBox: { Name: 'Velja Póstbox' } as Postbox,
  selectedShippingMethod: '',
  selectedPaymentMethod: '',
} as Cart;
