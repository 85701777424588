import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e337b38a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "imageAndTitleBanner" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _ctx.imageSrc,
      class: "imageAndTitleBanner__image"
    }, null, 8 /* PROPS */, _hoisted_2),
    _createElementVNode("h1", {
      style: _normalizeStyle(_ctx.getTitleStyles)
    }, _toDisplayString(_ctx.getData.title), 5 /* TEXT, STYLE */),
    (_ctx.getData.subtitle)
      ? (_openBlock(), _createElementBlock("h2", {
          key: 0,
          style: _normalizeStyle(_ctx.getTitleStyles)
        }, _toDisplayString(_ctx.getData.subtitle), 5 /* TEXT, STYLE */))
      : _createCommentVNode("v-if", true)
  ]))
}