
  import { defineComponent } from 'vue';
  import { mapState } from 'vuex';
  import useCart from '@/composables/useCart';
  import CartOverviewOrderLine from '@/components/cart/checkout/overview/CartOverviewOrderLine.vue';
  import { OrderLine } from '@/models/cart/orderLine';
  import { CartState } from '@/models/store/cart';

  export default defineComponent({
    components: {
      CartOverviewOrderLine,
    },
    emits: ['onClickDetail'],
    setup(_, { emit }) {
      const { orderLines } = useCart();
      const onClickDetails = (orderLine: OrderLine) => {
        emit('onClickDetail', orderLine);
      };
      return {
        orderLines,
        onClickDetails,
      };
    },
    computed: {
      ...mapState('cart', {
        productIdAreaMap: (state) => (state as CartState).productIdAreaMap,
      }),
    },
  });
