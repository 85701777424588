import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e2b4892a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "imageTitleAndButtonBanner__button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_button = _resolveComponent("custom-button")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.componentTag), {
    class: "imageTitleAndButtonBanner",
    href: _ctx.href,
    to: _ctx.href
  }, {
    default: _withCtx(() => [
      _createElementVNode("img", {
        src: _ctx.imageSrc,
        class: "imageTitleAndButtonBanner__image"
      }, null, 8 /* PROPS */, _hoisted_1),
      _createElementVNode("h1", {
        style: _normalizeStyle(_ctx.getTitleStyles)
      }, _toDisplayString(_ctx.getData.title), 5 /* TEXT, STYLE */),
      (_ctx.getData.subtitle)
        ? (_openBlock(), _createElementBlock("h2", {
            key: 0,
            style: _normalizeStyle(_ctx.getTitleStyles)
          }, _toDisplayString(_ctx.getData.subtitle), 5 /* TEXT, STYLE */))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", _hoisted_2, [
        (_ctx.getButtonData.text !== '')
          ? (_openBlock(), _createBlock(_component_custom_button, {
              key: 0,
              button: _ctx.getData.button
            }, null, 8 /* PROPS */, ["button"]))
          : _createCommentVNode("v-if", true)
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["href", "to"]))
}