
  import { defineComponent, PropType } from 'vue';
  import useOrderLine from '@/composables/useOrderLine';
  import { OrderLine } from '@/models/cart/orderLine';
  import conf from '@/config';

  export default defineComponent({
    props: {
      orderLine: {
        type: Object as PropType<OrderLine>,
        required: true,
      },
    },
    setup(props) {
      const {
        formattedOrderLinePrice,
        formattedProductPrice,
        orderLineHasDiscount,
        orderLineStyles,
        productImagePath,
        selectedQuantity,
        selectedColor,
        selectedVariant,
      } = useOrderLine(props);

      return {
        formattedOrderLinePrice,
        formattedProductPrice,
        orderLineHasDiscount,
        orderLineStyles,
        productImagePath,
        selectedQuantity,
        selectedColor,
        selectedVariant,
      };
    },
    data() {
      return {
        imagePrefix: conf.dwUrl,
      };
    },
  });
