
  /* eslint-disable @typescript-eslint/no-explicit-any */
  import { defineComponent, PropType } from 'vue';

  import { IThumbnailVideoAndText } from '@/models/item-types/paragraph';
  import useParagraph from '@/composables/useParagraph';
  import conf from '@/config';

  export default defineComponent({
    props: {
      componentProps: {
        type: Object as PropType<IThumbnailVideoAndText>,
        required: true,
      },
    },
    setup(props) {
      const { data, getParagraphStyles, getParagraphClasses } = useParagraph(props.componentProps);

      const paragraphData = data.value as unknown as IThumbnailVideoAndText;
      const imagePrefix = conf.dwUrl;
      const imagePath = paragraphData.thumbnail !== undefined ? paragraphData.thumbnail[0].Path : '';

      return {
        paragraphData,
        getParagraphStyles,
        getParagraphClasses,
        imagePrefix,
        imagePath,
      };
    },
    computed: {
      isLayoutRight(): boolean {
        const { layout } = this.$props.componentProps as any;
        if (layout.SelectedValue === 'right') {
          return true;
        }
        return false;
      },
      isDarkMode(): boolean {
        return this.$route.meta.toggleDarkMode as boolean;
      },
    },
  });
