import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fcd30880"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "globalOrderLineCard_Info" }
const _hoisted_3 = { class: "globalOrderLineCard_Info_Manufacturer" }
const _hoisted_4 = { class: "globalOrderLineCard_Info_Name" }
const _hoisted_5 = { class: "globalOrderLineCard_Info_Price" }
const _hoisted_6 = { class: "globalOrderLineCard_Info_Price--WithDiscount" }
const _hoisted_7 = {
  key: 0,
  class: "globalOrderLineCard_Info_Price--WithoutDiscount"
}
const _hoisted_8 = { class: "globalOrderLineCard_Info_Quantity" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.orderLineStyles),
    class: "globalOrderLineCard"
  }, [
    _createVNode(_component_RouterLink, {
      ref: _ctx.productLink,
      class: "globalOrderLineCard_Thumbnail",
      to: _ctx.productLink,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onLinkClick(_ctx.orderLine.Product)))
    }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          class: "cart-item__image",
          src: _ctx.productImagePath,
          alt: "product image"
        }, null, 8 /* PROPS */, _hoisted_1)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["to"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.orderLine.Manufacturer), 1 /* TEXT */),
      _createVNode(_component_RouterLink, {
        ref: _ctx.productLink,
        class: "noLink",
        to: _ctx.productLink,
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onLinkClick(_ctx.orderLine.Product)))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.orderLine.Product.ProductName), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["to"]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.formattedOrderLinePrice), 1 /* TEXT */),
        (_ctx.orderLineHasDiscount)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.formattedProductPrice), 1 /* TEXT */))
          : _createCommentVNode("v-if", true)
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_8, [
        _createElementVNode("span", null, _toDisplayString(_ctx.orderLine.Quantity) + " STK", 1 /* TEXT */)
      ], 512 /* NEED_PATCH */), [
        [_vShow, _ctx.orderLine.Quantity]
      ])
    ])
  ], 4 /* STYLE */))
}