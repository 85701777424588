
  import { defineComponent, reactive } from 'vue';
  import { mapValues, keyBy } from 'lodash';
  import { required, helpers, email } from '@vuelidate/validators';
  import { ValidationArgs } from '@vuelidate/core';
  import dwApi from '@/API/dwApi';
  import Loader from '@/components/shared/Loader.vue';
  import TextInput from '@/components/shared/inputs/TextInputNew.vue';
  import TextAreaInput from '@/components/shared/inputs/TextAreaInputNew.vue';
  import RenderErrors from '@/components/shared/inputs/RenderErrors.vue';
  import useFormValidation from '@/composables/useFormValidation';

  interface FormField {
    required: boolean;
    systemName: string;
    name: string;
    type: string;
  }

  interface HiddenFormField {
    name: string;
    type: string;
    value: string;
  }

  const FIELD_NAMES = {
    NAME: 'ContactUs_Name',
    PHONE: 'ContactUs_Phone',
    EMAIL: 'ContactUs_Email',
    MESSAGE: 'ContactUs_Message',
  };

  export default defineComponent({
    components: {
      TextInput,
      TextAreaInput,
      RenderErrors,
      Loader,
    },
    setup() {
      const state = reactive({
        [FIELD_NAMES.NAME]: '',
        [FIELD_NAMES.PHONE]: '',
        [FIELD_NAMES.EMAIL]: '',
        [FIELD_NAMES.MESSAGE]: '',
      });
      const rules = {
        [FIELD_NAMES.NAME]: { required: helpers.withMessage('Nafn má ekki vera tómt', required) },
        [FIELD_NAMES.PHONE]: {},
        [FIELD_NAMES.EMAIL]: { required: helpers.withMessage('Netfang má ekki vera tómt', required), email: helpers.withMessage('Netfang ekki gilt', email) },
        [FIELD_NAMES.MESSAGE]: {},
      } as ValidationArgs;

      const { touch, formIsValid, hasErrors, getErrorMessages, clearForm } = useFormValidation(rules, state);

      return {
        state,
        touch,
        formIsValid,
        hasErrors,
        getErrorMessages,
        clearForm,
      };
    },
    data() {
      return {
        formFields: [] as FormField[],
        hiddenFormFields: [] as HiddenFormField[],
        sendingForm: false,
        formPosted: false,
      };
    },
    computed: {
      getNameField(): string {
        return FIELD_NAMES.NAME;
      },
      getPhoneField(): string {
        return FIELD_NAMES.PHONE;
      },
      getEmailField(): string {
        return FIELD_NAMES.EMAIL;
      },
      getMessageField(): string {
        return FIELD_NAMES.MESSAGE;
      },
      formData(): { [key: string]: string } {
        const hiddenData = mapValues(
          keyBy(this.hiddenFormFields, ({ name }) => name),
          ({ value }) => value,
        );
        const userData = mapValues(this.state, (value) => value);
        return { ...userData, ...hiddenData };
      },
      getFormPostedSuccessMessage(): string {
        return 'Takk fyrir að hafa samband';
      },
    },
    created() {
      this.initialize();
    },
    methods: {
      async initialize() {
        await this.fetchFormFields();
      },
      async fetchFormFields(): Promise<void> {
        const {
          data: { hiddenFormFields = [], formFields = [] },
        } = await dwApi.getFeed('shared/contactus');
        this.formFields = formFields as FormField[];
        this.hiddenFormFields = hiddenFormFields as HiddenFormField[];
      },
      submit() {
        if (this.formIsValid) {
          this.submitForm();
          this.clearForm();
        }
      },
      async submitForm() {
        this.sendingForm = true;
        await dwApi.postFeed('shared/contactus', this.formData);
        this.sendingForm = false;
        this.formPosted = true;
      },
    },
  });
