import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bac2710e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "breadCrumb"
}
const _hoisted_2 = ["href"]
const _hoisted_3 = ["href"]
const _hoisted_4 = {
  key: 1,
  class: "crumb_Link"
}
const _hoisted_5 = {
  key: 0,
  class: "crumb_products"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.breadCrumbs.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("a", {
          href: _ctx.homeCrumb.path,
          class: "crumb_Link"
        }, " HEIM ", 8 /* PROPS */, _hoisted_2),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadCrumbs, (crumb, index) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: crumb }, [
            (index + 1 !== _ctx.breadCrumbs.length)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: crumb.path,
                  class: "crumb_Link"
                }, _toDisplayString(crumb.meta.name), 9 /* TEXT, PROPS */, _hoisted_3))
              : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createTextVNode(_toDisplayString(crumb.meta.name) + " ", 1 /* TEXT */),
                  (_ctx.productCount > 0)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_5, " (" + _toDisplayString(_ctx.productCount) + " VÖRUR) ", 1 /* TEXT */))
                    : _createCommentVNode("v-if", true)
                ]))
          ], 64 /* STABLE_FRAGMENT */))
        }), 128 /* KEYED_FRAGMENT */))
      ]))
    : _createCommentVNode("v-if", true)
}