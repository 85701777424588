
  import { defineComponent } from 'vue';
  import TextInput from '@/components/shared/inputs/TextInput.vue';
  import { PasswordPayload } from '@/models/user/user';
  import Loader from '@/components/shared/Loader.vue';

  interface UserPasswordFormState {
    oldPassword: string;
    newPassword: string;
    newPasswordConfirm: string;
  }

  export default defineComponent({
    components: {
      TextInput,
      Loader,
    },
    props: {
      /**
       * Username field for accessibility
       */
      username: {
        required: false,
        default: null,
        type: String,
      },

      /**
       * Indicate the form is being submitted
       */
      submitting: {
        required: false,
        type: Boolean,
        default: false,
      },
    },
    emits: ['submit'],
    data() {
      return {
        oldPassword: '',
        newPassword: '',
        newPasswordConfirm: '',
      } as UserPasswordFormState;
    },
    methods: {
      onSubmit() {
        this.$emit('submit', {
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
          newPasswordConfirm: this.newPasswordConfirm,
        } as PasswordPayload);
      },
    },
  });
