import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6275e817"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["value"]
const _hoisted_2 = ["disabled"]
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_Loader = _resolveComponent("Loader")!

  return (_openBlock(), _createElementBlock("form", {
    class: "password-form",
    onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
  }, [
    (_ctx.username)
      ? (_openBlock(), _createElementBlock("input", {
          key: 0,
          class: "hidden",
          name: "username",
          type: "text",
          autocomplete: "username",
          value: _ctx.username
        }, null, 8 /* PROPS */, _hoisted_1))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_TextInput, {
      value: _ctx.oldPassword,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.oldPassword) = $event)),
      label: "Núverandi lykilorð",
      icon: "eyeslash",
      width: "100%",
      type: "password",
      class: "password-form__input",
      autocomplete: "current-password"
    }, null, 8 /* PROPS */, ["value"]),
    _createVNode(_component_TextInput, {
      value: _ctx.newPassword,
      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newPassword) = $event)),
      label: "Nýtt lykliorð",
      icon: "eyeslash",
      width: "100%",
      type: "password",
      class: "password-form__input",
      autocomplete: "new-password"
    }, null, 8 /* PROPS */, ["value"]),
    _createVNode(_component_TextInput, {
      value: _ctx.newPasswordConfirm,
      "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newPasswordConfirm) = $event)),
      label: "Staðfesta nýtt lykilorð",
      icon: "eyeslash",
      width: "100%",
      type: "password",
      class: "password-form__input",
      autocomplete: "new-password"
    }, null, 8 /* PROPS */, ["value"]),
    _createElementVNode("button", {
      disabled: _ctx.submitting,
      type: "submit",
      role: "button",
      class: "password-form__submit btn btn--primary"
    }, [
      (!_ctx.submitting)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, "vista breytingar"))
        : (_openBlock(), _createBlock(_component_Loader, {
            key: 1,
            color: "#FFFFFF",
            size: 24,
            thickness: 5
          }))
    ], 8 /* PROPS */, _hoisted_2)
  ], 32 /* HYDRATE_EVENTS */))
}