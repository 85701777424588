
  /* eslint-disable @typescript-eslint/no-explicit-any */
  import { computed, defineComponent, watch } from 'vue';
  import { useStore } from 'vuex';

  import axios from 'axios';
  import { get } from 'lodash';

  import useWebsiteSettings from '@/composables/useWebsiteSettings';

  import { Facet } from '@/models/product/facet';
  import { ProductListLiveDataPayload, ProductListPayload } from '@/models/productList/productListPayloadModels';
  import { ProductList } from '@/models/productList/productList';

  import ProductListHeader from './ProductListHeader.vue';
  import { ProductListSortDropdownOption } from './ProductListSortDropdown.vue';
  import ProductListFacets from './ProductListFacets.vue';
  import ProductCardList from './ProductCardList.vue';
  import Loader from '@/components/shared/Loader.vue';

  import useProductListSort from '@/composables/useProductListSort';
  import useProductListFacets from '@/composables/useProductListFacets';
  import { GA4E } from '@/ga4ecommerce/GA4E';

  export default defineComponent({
    components: {
      ProductListHeader,
      ProductListFacets,
      ProductCardList,
      Loader,
    },
    props: {
      componentProps: {
        type: Object,
        default: null,
      },
    },
    setup() {
      const store = useStore();
      const { websiteSettings } = useWebsiteSettings();

      const { filtersOpen, toggleFiltersOpen, showMoreSelected, selectedFacets, setSelectedFacets, filterQuery } = useProductListFacets();

      const { sortBy, sortByQuery, setSortOption } = useProductListSort();

      const productList = computed(() => {
        return store.getters['productList/getProductList'] as ProductList;
      });

      GA4E.viewItemList(productList.value);
      watch(productList, (value) => {
        GA4E.viewItemList(value);
      });

      return {
        websiteSettings,

        productList,
        filtersOpen,
        toggleFiltersOpen,
        showMoreSelected,
        selectedFacets,
        setSelectedFacets,
        filterQuery,

        sortBy,
        setSortOption,
        sortByQuery,
      };
    },
    data() {
      return {
        initialLoad: true,
        // General
        GroupID: this.componentProps.groupId,
        ShowOnlyOffers: this.componentProps.showOnlyOffers,
        ShowOnlyOutlet: this.componentProps.showOnlyOutlet,
        BrandName: this.componentProps.brandName,

        // Product
        productCount: 0,
        pageSize: 30,
        currentPage: 1,
        pageCount: 1,
        isFetchMore: false,
        isLastPage: false,
        cancelToken: undefined as any,
        productListLoader: false,
        isActiveBtn: false,
      };
    },
    computed: {
      facets(): Facet[] {
        return this.productList.facets;
      },
      size(): string {
        return this.componentProps.productCardSize.SelectedValue;
      },
    },
    mounted() {
      this.initializePage().then(() => {
        const scroll = get(window, 'history.state.scroll');

        if (scroll) {
          window.scrollTo(window.history.state.scroll);
        }
      });
    },
    methods: {
      async initializePage() {
        const useCache =
          this.productList.group.Id === this.GroupID && this.productList.products.length > 0 && this.productList.routePageId === this.$route.meta?.pageID;
        // && !hasQuery;
        // Þetta var að valda því að ef það var query í urli, þá fór maður ekki á sama stað í vörulista þegar farið var til baka af vöru-details síðu.
        // Stundum er query á urli þegar sendir eru út tölvupóstar til v.v. Þarf hugsanlega að filtera út mismuninn á vöru filter og custom query frá tölvupóstum

        if (useCache) {
          this.setSelectedFacets(this.facets);
          this.initialLoad = false;
        } else {
          const params = new URLSearchParams(this.$route.query as Record<string, string>);
          await this.getProductList(this.pageSize, this.currentPage, `&${params.toString()}`);
          setTimeout(() => this.setSelectedFacets(this.facets), 0);
        }
      },
      async getProductList(paraPageSize, paraPageNumber, parafilterQuery = ''): Promise<unknown> {
        this.setContainerMinHeight();

        if (typeof this.cancelToken !== typeof undefined) {
          this.cancelToken.cancel('Operation canceled due to new request.');
        }
        this.cancelToken = axios.CancelToken.source();

        if (paraPageNumber > 1) {
          this.isFetchMore = true;
        } else {
          this.productListLoader = true;
        }

        const payload = {
          params: {
            repositoryName: this.websiteSettings.repositoryName,
            queryName: this.componentProps.queryName && this.componentProps.facetName ? this.componentProps.queryName : this.websiteSettings.queryName,
            facetName: this.componentProps.facetName && this.componentProps.queryName ? this.componentProps.facetName : this.websiteSettings.facetName,
            groupID: this.GroupID,
            showOnlyOffers: this.ShowOnlyOffers,
            showOnlyOutlet: this.ShowOnlyOutlet,
            brandName: this.BrandName,
            filterQuery: `${parafilterQuery}${this.sortByQuery ? `&${this.sortByQuery}` : ''}`,
            pageNumber: paraPageNumber,
            routePageId: this.$route.meta?.pageID,
            pageSize: paraPageSize,
          },
          cancelToken: this.cancelToken.token,
        } as ProductListPayload;

        return this.$store
          .dispatch('productList/setProductList', payload)
          .then((data) => {
            if (data?.cancelled === undefined || data.cancelled !== true) {
              this.getLiveData(data);
              this.isFetchMore = false;
              this.productListLoader = false;
            }
          })
          .finally(() => {
            this.initialLoad = false;
          });
      },
      getLiveData(data: any) {
        if (data?.Products?.length > 0) {
          const productIds = data.Products?.map((a) => a.Id)?.join(',');
          const liveDataPayload = {
            productIds,
            cancelToken: this.cancelToken.token,
          } as ProductListLiveDataPayload;
          this.$store.dispatch('productList/setLiveData', liveDataPayload);
        }
      },
      getNextPage() {
        // isFetchMore ? null : getProductList(pageSize, (productList.currentPage + 1)
        if (!this.isFetchMore) {
          this.getProductList(this.pageSize, this.productList.currentPage + 1, this.filterQuery);
        }
      },
      onFacetsClear() {
        this.selectedFacets = [];
      },
      onFacetsClose() {
        this.filtersOpen = !this.filtersOpen;
      },
      filterProductList() {
        window.history.replaceState(window.history.state, '', `${window.location.pathname}?${this.filterQuery.substring(1)}`);
        this.getProductList(this.pageSize, 1, this.filterQuery);
      },
      clearFilters() {
        this.selectedFacets = [];
        this.getProductList(this.pageSize, 1);
      },
      onSortUpdate(option: ProductListSortDropdownOption) {
        const lastValue = this.sortBy.value;
        this.setSortOption(option);
        if (lastValue !== option.value) {
          this.filterProductList();
        }
      },
      onSelectedFacetsUpdate(v: string[]) {
        this.selectedFacets = v;
        this.filterProductList();
      },
      hasDiscount(product) {
        return product?.Price?.PriceWithVat < product?.PriceBeforeDiscount?.PriceWithVat;
      },
      setContainerMinHeight() {
        const minValue = 630;
        const contentContainer = document.querySelector<HTMLElement>('.productList_Content') || ({} as HTMLElement);
        const facetContainer = document.querySelector<HTMLElement>('.facetList_NestedContainer') || ({} as HTMLElement);

        if (facetContainer.style !== undefined) {
          const facetHeight = facetContainer.offsetHeight;

          if (facetHeight > minValue) {
            contentContainer.style.minHeight = `${facetHeight + 60}px`;
          } else {
            contentContainer.style.minHeight = '';
          }
        }
      },
    },
  });
