<script lang="js">
  import { defineComponent, h } from 'vue';
  import config from '@/config';

  export default config.env === 'PROD'
    ? defineComponent({
        mounted() {
          // eslint-disable-next-line func-names
          (function (d, s, id) {
            let js;
            const fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            // eslint-disable-next-line prefer-const
            js = d.createElement(s);
            js.id = id;
            js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js#xfbml=1&version=v2.12&autoLogAppEvents=1';
            fjs.parentNode.insertBefore(js, fjs);
          })(document, 'script', 'facebook-jssdk');
        },
      })
    : () => h('div', { innerHTML: '' });
</script>

<template>
  <div id="fb-root" />
  <div
    class="fb-customerchat"
    attribution="install_email"
    page_id="123129514458951"
    theme_color="#000000"
    greeting_dialog_display="hide"
    logged_in_greeting="Góðan dag hvernig getum við aðstoðað?"
    logged_out_greeting="Góðan dag hvernig getum við aðstoðað?"
  />
</template>
