import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImageAndButtonIndependent = _resolveComponent("ImageAndButtonIndependent")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["boxes-container container", _ctx.getStyleClass]),
    style: _normalizeStyle(_ctx.getParagraphStyles)
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paragraphData.boxes, (box, index) => {
      return (_openBlock(), _createBlock(_component_ImageAndButtonIndependent, {
        key: index,
        box: box
      }, null, 8 /* PROPS */, ["box"]))
    }), 128 /* KEYED_FRAGMENT */))
  ], 6 /* CLASS, STYLE */))
}