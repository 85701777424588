import { createI18n } from 'vue-i18n';
import TranslationsApi, { DwTranslation } from './API/translationsApi';

function mapMessages(messages: DwTranslation) {
  return messages.reduce((prev: Record<string, string>, curr) => {
    // eslint-disable-next-line no-param-reassign
    prev[curr.Key] = curr.Value || curr.DefaultValue || '';
    return prev;
  }, {});
}

const defaultMessages = {};

const i18n = createI18n({
  locale: 'is-IS', // set locale
  fallbackLocale: 'is-IS', // set fallback locale
  messages: {
    'is-IS': defaultMessages, // set default locale messages
  },

  // Disables console warning noise, should consider flipping this with a .env var
  silentTranslationWarn: true,
  silentFallbackWarn: true,
  // If you need to specify other options, you can set other options
  // ...
});

export async function getMessages(areaId: number | string): Promise<DwTranslation | undefined | null> {
  const response = await TranslationsApi.getTranslations(areaId);

  if (response.data) {
    i18n.global.mergeLocaleMessage('is-IS', mapMessages(response.data));
  }

  return response.data;
}

export default i18n;
