import { BoxWithBackgroundColor, BoxWithBackgroundImage, BoxWithMobileTitle, BoxWithText, BoxWithTitle, InformationBox } from '@/models/item-types/base/boxes';

function isBoxWithTitle(val: unknown): val is BoxWithTitle {
  return (val as BoxWithTitle).title !== undefined;
}

function isBoxWithMobileTitle(val: unknown): val is BoxWithMobileTitle {
  return (val as BoxWithMobileTitle).titleInMobile !== undefined;
}

function isBoxWithBackgroundColor(val: unknown): val is BoxWithBackgroundColor {
  return (val as BoxWithBackgroundColor).backgroundColor !== undefined;
}

function isBoxWithBackgroundImage(val: unknown): val is BoxWithBackgroundImage {
  return (val as BoxWithBackgroundImage).image !== undefined;
}

function isBoxWithText(val: unknown): val is BoxWithText {
  return (val as BoxWithText).text !== undefined;
}

function isInformationBox(val: unknown): val is InformationBox {
  const box = val as InformationBox;
  return (
    box.component === 'TitleTextAndButton' ||
    box.component === 'TitleAndRichText' ||
    box.component === 'ImageTitleAndButton' ||
    box.component === 'ImageAndButton' ||
    box.component === 'IconTitleAndButton'
  );
}

export { isBoxWithTitle, isBoxWithMobileTitle, isBoxWithBackgroundColor, isBoxWithBackgroundImage, isBoxWithText, isInformationBox };
