import { CartErrorResponse } from '@/models/cart/cartResponseModels';
import { WishlistResponse } from '@/models/wishlist/wishlist';

export function isCartErrorResponse(val: unknown): val is CartErrorResponse {
  return (val as CartErrorResponse).type === 'CartErrorResponse';
}

export function isWishlistResponse(val: unknown): val is WishlistResponse {
  const wishListResponse = val as WishlistResponse;
  return wishListResponse.data.Id !== undefined && wishListResponse.data.WishlistProducts !== undefined;
}
