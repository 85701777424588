import axios from 'axios';
import {
  AddToCartPayload,
  BaseCartStep0Payload,
  DecrementOrderLinePayload,
  DeleteOrderLinePayLoad,
  EmptyCartPayload,
  IncrementOrderLinePayload,
  SetOrAddMultiPayload,
  UpdateCartPayload,
} from '@/models/cart/cartRequestPayloadModels';
import { CartErrorResponse, CartResponse } from '@/models/cart/cartResponseModels';
import conf from '@/config';

class CartService {
  static getUrl(): string {
    return `${conf.dwUrl}/${conf.area}/cart`;
  }

  static appendOrderContext(url: string): string {
    return `${url}&OrderContext=${conf.orderContext}`;
  }

  static createExplicitStep0FormData(payload: BaseCartStep0Payload): FormData {
    const { data } = payload;
    const formData = new FormData();
    formData.append(data.Step, '');
    return formData;
  }

  static async addToCart(payload: AddToCartPayload): Promise<CartResponse> {
    const { params } = payload;
    const variantIdParam = params.Product.VariantID !== undefined ? `&VariantID=${params.Product.VariantID}` : '';
    const url = this.appendOrderContext(
      `${this.getUrl()}?cartcmd=${params.cartcmd}&Quantity=${params.Quantity}&ProductID=${params.Product.ProductID}${variantIdParam}&Redirect=false`,
    );
    const response = await axios.post(url, this.createExplicitStep0FormData(payload), {
      withCredentials: true,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return this.processResponse(response);
  }

  static async updateOrderLine(payload: UpdateCartPayload): Promise<CartResponse> {
    const { params } = payload;
    const url = this.appendOrderContext(`${this.getUrl()}?cartcmd=${params.cartcmd}&QuantityOrderLine${params.OrderLineID}=${params.Quantity}&Redirect=false`);
    const response = await axios.post(url, this.createExplicitStep0FormData(payload), { withCredentials: true });
    return response;
  }

  static async decrementOrderLine(payload: DecrementOrderLinePayload): Promise<CartResponse> {
    return this.editOrderLine(payload);
  }

  static async incrementOrderLine(payload: IncrementOrderLinePayload): Promise<CartResponse> {
    return this.editOrderLine(payload);
  }

  static async deleteOrderLine(payload: DeleteOrderLinePayLoad): Promise<CartResponse> {
    return this.editOrderLine(payload);
  }

  static async editOrderLine(payload: IncrementOrderLinePayload | DecrementOrderLinePayload | DeleteOrderLinePayLoad): Promise<CartResponse> {
    const { params } = payload;
    const url = this.appendOrderContext(`${this.getUrl()}?cartcmd=${params.cartcmd}&Key=${params.OrderLineID}&Redirect=false`);
    const response = await axios.post(url, this.createExplicitStep0FormData(payload), { withCredentials: true });
    return response;
  }

  static async emptyCart(payload: EmptyCartPayload): Promise<CartResponse> {
    const { params } = payload;
    const url = this.appendOrderContext(`${this.getUrl()}?cartcmd=${params.cartcmd}`);
    const response = await axios.post(url, this.createExplicitStep0FormData(payload), { withCredentials: true });
    return response;
  }

  static async getCart(formData: FormData): Promise<CartResponse> {
    const response = await axios.post(`${this.getUrl()}`, formData, {
      withCredentials: true,
    });
    return response;
  }

  static async setOrAddMulti(payload: SetOrAddMultiPayload): Promise<CartResponse> {
    const { params } = payload;
    const url = this.appendOrderContext(`${this.getUrl()}?cartcmd=${params.cartcmd}&Redirect=false`);
    const formData = new FormData();
    formData.append('Comment', payload.couponCode);
    let counter = 0;
    params.items.forEach((item: { ProductID: string; VariantID: string; Quantity: number }) => {
      counter += 1;
      formData.append('CartV2.GotoStep0', '1');
      formData.append(`Quantity${counter}`, item.Quantity.toString());
      formData.append(`ProductID${counter}`, item.ProductID);
      formData.append(`VariantID${counter}`, item.VariantID);
      formData.append(`ProductLoopCounter${counter}`, counter.toString());
    });
    const response = await axios.post(url, formData, { withCredentials: true });
    return this.processResponse(response);
  }

  static async postFormData(formData: FormData): Promise<CartResponse> {
    formData.append('CartV2.GotoStep0', '1');
    const response = await axios.post(this.getUrl(), formData, {
      withCredentials: true,
    });
    return response;
  }

  static processResponse(response: CartResponse, message?: string): CartResponse {
    if (typeof response.data === 'string') {
      const cartErrorResponse = {
        type: 'CartErrorResponse',
        errorDump: response.data,
        message: message || 'Error occured in cart API',
      } as CartErrorResponse;
      return cartErrorResponse;
    }
    return response;
  }

  static async postCheckoutFormData(formData: FormData): Promise<CartResponse> {
    const response = await axios.post(this.getUrl(), formData, {
      withCredentials: true,
    });
    return response;
  }

  static async postCouponData(couponCode: string): Promise<CartResponse> {
    const formData = new FormData();
    formData.append('CartV2.GotoStep0', '1');
    formData.append('Comment', couponCode);
    const response = await axios.post(this.getUrl(), formData, {
      withCredentials: true,
    });
    return this.processResponse(response);
  }

  static async getReceipt(params: string): Promise<CartResponse> {
    const response = await axios.get(this.getUrl() + params, {
      withCredentials: true,
    });
    return response;
  }
}

export default CartService;
