
  import { defineComponent, PropType, computed, ref } from 'vue';
  import { ShippingAgent } from '@/models/cart/shippingMethod';

  export default defineComponent({
    props: {
      shippingAgents: {
        required: false,
        type: Array as PropType<ShippingAgent[]>,
        default: undefined,
      },
      selected: {
        required: false,
        type: Object as PropType<ShippingAgent>,
        default: undefined,
      },
    },
    emits: ['select'],
    setup(props, { emit }) {
      const isOpen = ref<boolean>(false);
      const open = () => {
        isOpen.value = true;
      };
      const close = () => {
        isOpen.value = false;
      };
      const onSelectClick = open;

      const localSelectedAgent = ref<ShippingAgent | null>(null);
      const computedSelectedAgent = computed(() => {
        if (!!localSelectedAgent.value && localSelectedAgent.value?.Code !== props.selected?.Code) {
          return localSelectedAgent.value;
        }

        return props.selected;
      });

      const select = (agent: ShippingAgent) => {
        localSelectedAgent.value = agent;
        emit('select', agent);
        close();
      };

      return {
        computedSelectedAgent,
        localSelectedAgent,
        onSelectClick,
        open,
        close,
        isOpen,
        select,
      };
    },
  });
