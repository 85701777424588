
  /* eslint-disable @typescript-eslint/no-explicit-any */
  import { defineComponent, PropType } from 'vue';

  import useParagraph from '@/composables/useParagraph';

  import OpeningHoursBannerDay from './OpeningHoursBannerDay.vue';
  import OpeningHoursBannerDate from './OpeningHoursBannerDate.vue';

  interface IOpeningHoursBannerImage {
    Extension: string;
    FocalPositionFromLeft: number;
    FocalPositionFromTop: number;
    Name: string;
    Path: string;
    PathUrlEncoded: string;
  }

  interface IOpeningHoursBanner {
    name: string;
    title: string;
    text: string;
    textColor: { Hex: string };
    image: IOpeningHoursBannerImage[] | undefined;
    hasComponent: boolean;
    component: string;
    colorOverlay: { Hex: string };
    opacity: { SelectedValue: string };
    openingHourStoreList: any;
  }

  interface IStoreList {
    title: string;
    place: string;
    days: Array<string>;
  }

  interface IBannerBasic {
    title: string;
    text: string;
    textColor: string;
    image: IOpeningHoursBannerImage[] | undefined;
    colorOverlay: string;
    opacity: string;
  }

  interface IBanner extends IBannerBasic {
    shopList: IStoreList[];
  }

  export default defineComponent({
    components: {
      OpeningHoursBannerDay,
      OpeningHoursBannerDate,
    },
    props: {
      componentProps: {
        type: Object as PropType<IOpeningHoursBanner>,
        required: true,
      },
    },
    setup(props) {
      const { getParagraphStyles, getParagraphClasses } = useParagraph(props.componentProps);
      return {
        getParagraphStyles,
        getParagraphClasses,
      };
    },
    computed: {
      storeListWeek(): [] {
        // List of all stores for week banner
        return this.componentProps.openingHourStoreList[0].Fields[0].Value;
      },
      storeListHolidays(): [] {
        // List of all stores for holiday banner
        return this.componentProps.openingHourStoreList[0].Fields[1].Value;
      },
      mapListWeek(): IStoreList[] {
        // Mapping out all the stores form week array
        return this.storeListWeek.map((store: any) => ({
          title: store.Fields[0].Value,
          place: store.Fields[1].Value,
          days: [
            store.Fields[2].Value,
            store.Fields[3].Value,
            store.Fields[4].Value,
            store.Fields[5].Value,
            store.Fields[6].Value,
            store.Fields[7].Value,
            store.Fields[8].Value,
          ],
        }));
      },
      mapListHolidays(): IStoreList[] {
        // Mapping out all the stores from holidays array
        return this.storeListHolidays.map((store: any) => ({
          title: store.Fields[0].Value,
          place: store.Fields[1].Value,
          days: [
            store.Fields[2].Value,
            store.Fields[3].Value,
            store.Fields[4].Value,
            store.Fields[5].Value,
            store.Fields[6].Value,
            store.Fields[7].Value,
            store.Fields[8].Value,
            store.Fields[9].Value,
            store.Fields[10].Value,
            store.Fields[11].Value,
            store.Fields[12].Value,
            store.Fields[13].Value,
            store.Fields[14].Value,
            store.Fields[15].Value,
            store.Fields[16].Value,
            store.Fields[17].Value,
            store.Fields[18].Value,
          ],
        }));
      },
      bannerBasic(): IBannerBasic {
        return {
          title: this.componentProps.title,
          text: this.componentProps.text,
          textColor: this.componentProps.textColor.Hex,
          image: this.componentProps.image,
          colorOverlay: this.componentProps.colorOverlay.Hex,
          opacity: this.componentProps.opacity.SelectedValue,
        };
      },
      bannerWeek(): IBanner {
        // Mapping out banner data for week banner
        return {
          ...this.bannerBasic,
          shopList: this.mapListWeek,
        };
      },
      bannerHoliday(): IBanner {
        // Mapping out banner data for holiday banner
        return {
          ...this.bannerBasic,
          shopList: this.mapListHolidays,
        };
      },
    },
  });
