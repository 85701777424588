import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5cda8459"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "imageAndButton_Button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_button = _resolveComponent("custom-button")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.wrapperAttribute), {
    href: _ctx.linkUrl,
    style: _normalizeStyle(_ctx.getStyles),
    class: _normalizeClass(["imageAndButton box_Image", `imageAndButton_${_ctx.boxCount}boxes`])
  }, {
    default: _withCtx(() => [
      _createElementVNode("img", {
        src: _ctx.boxImageUrl,
        class: "imageAndButton__image"
      }, null, 8 /* PROPS */, _hoisted_1),
      _createElementVNode("div", _hoisted_2, [
        (_ctx.buttonData.text !== '')
          ? (_openBlock(), _createBlock(_component_custom_button, {
              key: 0,
              button: _ctx.boxData.button
            }, null, 8 /* PROPS */, ["button"]))
          : _createCommentVNode("v-if", true)
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["href", "style", "class"]))
}