import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e4002af4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "anchor-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Anchor = _resolveComponent("Anchor")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["container paragraph-container", _ctx.getParagraphClasses]),
    style: _normalizeStyle(_ctx.getParagraphStyles)
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getData, (anchor, index) => {
        return (_openBlock(), _createBlock(_component_Anchor, {
          key: index,
          title: anchor.title,
          color: anchor.buttonColor,
          "anchor-id": anchor.anchor.ParagraphID
        }, null, 8 /* PROPS */, ["title", "color", "anchor-id"]))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ], 6 /* CLASS, STYLE */))
}