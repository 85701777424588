import { ComputedRef, computed } from 'vue';
import { useStore } from 'vuex';
import {
  CREATE_USER_ADDRESS,
  DELETE_USER_ADDRESS,
  GET_USER_ADDRESSES,
  MAKE_ADDRESS_DEFAULT,
  UPDATE_USER_ADDRESS,
} from '@/constants/store/auth/authActionTypes';
import { USER_ADDRESSES, USER_DEFAULT_ADDRESS } from '@/constants/store/auth/authGetterTypes';
import { Address, AddressPayload } from '@/models/address/address';

interface UseManageUserAddresses {
  userDefaultAddress: ComputedRef<Address | null>;
  userAddresses: ComputedRef<Address[]>;
  getUserAddresses: () => Promise<Address[]>;
  createUserAddress: (address: AddressPayload) => Promise<Address[]>;
  deleteUserAddress: (id: string) => Promise<Address[]>;
  updateUserAddress: (id: string, address: AddressPayload) => Promise<Address[]>;
  makeUserAddressDefault: (id: string) => Promise<Address[]>;
}

export default function useManageUserAddresses(): UseManageUserAddresses {
  const store = useStore();

  const userDefaultAddress = computed(() => store.getters[`auth/${USER_DEFAULT_ADDRESS}`]);
  const userAddresses = computed(() => store.getters[`auth/${USER_ADDRESSES}`]);
  const getUserAddresses = () => store.dispatch(`auth/${GET_USER_ADDRESSES}`);
  const createUserAddress = (address: AddressPayload) => store.dispatch(`auth/${CREATE_USER_ADDRESS}`, address);
  const deleteUserAddress = (id: string) => store.dispatch(`auth/${DELETE_USER_ADDRESS}`, id);
  const updateUserAddress = (id: string, address: AddressPayload) => store.dispatch(`auth/${UPDATE_USER_ADDRESS}`, { id, address });
  const makeUserAddressDefault = (id: string) => store.dispatch(`auth/${MAKE_ADDRESS_DEFAULT}`, id);

  return {
    userDefaultAddress,
    userAddresses,
    getUserAddresses,
    createUserAddress,
    deleteUserAddress,
    updateUserAddress,
    makeUserAddressDefault,
  } as UseManageUserAddresses;
}
