import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d97460f8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "button-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DropshadowButton = _resolveComponent("DropshadowButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["container paragraph-container", _ctx.getParagraphClasses]),
    style: _normalizeStyle(_ctx.getParagraphStyles)
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getData, (button, index) => {
        return (_openBlock(), _createBlock(_component_DropshadowButton, {
          key: index,
          text: button.text,
          link: button.link,
          color: button.color,
          invert: _ctx.isDarkMode
        }, null, 8 /* PROPS */, ["text", "link", "color", "invert"]))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ], 6 /* CLASS, STYLE */))
}