
  import { defineComponent } from 'vue';
  import useAuthenticateUser from '@/composables/useAuthenticateUser';
  import { getUserArea } from '@/utils/routingHelper';

  export default defineComponent({
    emits: ['onLinkClick'],
    setup() {
      const { authenticatedUser, hasAuthenticatedUser, showLoginForm } = useAuthenticateUser();

      return {
        authenticatedUser,
        hasAuthenticatedUser,
        showLoginForm,
      };
    },
    methods: {
      onUsernameClick(): void {
        this.$emit('onLinkClick', getUserArea(this.$router));
      },
      onMyPagesClick(): void {
        this.showLoginForm();
      },
    },
  });
