
  import { defineComponent, PropType } from 'vue';
  import TitleAndSubtitle from '@/components/itemTypes/base/TitleAndSubtitle.vue';
  import CustomButton from '@/components/itemTypes/base/Button.vue';
  import useParagraph from '@/composables/useParagraph';
  import { LatestNewsArticles } from '@/models/item-types/paragraph';

  export default defineComponent({
    components: {
      TitleAndSubtitle,
      // NewsArticlePreview,
      CustomButton,
    },
    props: {
      componentProps: {
        type: Object as PropType<unknown>,
        required: true,
      },
    },
    setup(props) {
      const { data, getParagraphStyles, getParagraphClasses } = useParagraph(props.componentProps);
      const paragraphData = data.value as LatestNewsArticles;

      return {
        paragraphData,
        getParagraphStyles,
        getParagraphClasses,
      };
    },
    mounted() {
      this.getLatestNewsArticles();
    },
    methods: {
      getLatestNewsArticles() {
        // todo - get news articles.
      },
    },
  });
