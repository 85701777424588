
  /* eslint-disable @typescript-eslint/no-explicit-any */
  import { defineComponent } from 'vue';
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import format from 'date-fns/format';
  import isISLocale from 'date-fns/locale/is';
  import { mapGetters } from 'vuex';
  import useParagraph from '@/composables/useParagraph';
  import Loader from '@/components/shared/Loader.vue';
  import NewsService from '@/API/newsApi';
  import ArticleCard from '@/components/itemTypes/NewsArticle/ArticleCard.vue';
  import { extractParagraphComponentDataFields } from '@/utils/dataMapper';

  interface NewsArticle {
    image: string;
    title: string;
    published: string;
    snippet: string;
    Link: string;
    publishDate: string;
  }

  interface Options {
    newsArticlePageId: string;
  }

  export default defineComponent({
    components: {
      Swiper,
      SwiperSlide,
      Loader,
      ArticleCard,
    },
    props: {
      componentProps: {
        type: Object,
        default: null,
      },
    },
    setup(props) {
      const { data, getParagraphStyles, getParagraphClasses } = useParagraph(props.componentProps);
      // eslint-disable-next-line vue/no-setup-props-destructure
      const allNewsLink = props.componentProps.allarFrettirLinkur.Url;
      return {
        data,
        getParagraphStyles,
        getParagraphClasses,
        allNewsLink,
      };
    },
    data() {
      return {
        newsCarouselLoader: false,
        newsList: [] as NewsArticle[],

        // Swiper
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        swiperOption: {
          breakpoints: {
            768: {
              spaceBetween: 24,
            },
            1025: {
              allowTouchMove: false,
            },
          },
        },
      };
    },
    computed: {
      ...mapGetters('website', {
        areaId: 'getAreaID',
      }),
      getOptions(): Options {
        return extractParagraphComponentDataFields(this.data, ['newsArticlePageId']) as Options;
      },
    },
    mounted() {
      this.getNewsList();
    },
    methods: {
      async getNewsList() {
        this.newsCarouselLoader = true;
        this.newsList = await NewsService.getNewsList('', this.areaId, this.getOptions.newsArticlePageId);
        this.newsCarouselLoader = false;
      },
      formatDate(date): string {
        const dateArr = date.split(' ')[0].split('.');
        const rearrangeDate = `${dateArr[1]}/${dateArr[0]}/${dateArr[2]}`;
        const formatedDate = format(new Date(rearrangeDate), 'eeee, d. MMM yyyy', {
          locale: isISLocale,
        });
        return formatedDate;
      },
    },
  });
