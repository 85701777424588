
  import { computed, defineComponent, PropType } from 'vue';
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import SwiperCore, { Pagination, Autoplay } from 'swiper/core';
  import { ItemType } from '@/models/field';
  import InformationBox from '@/components/itemTypes/InformationBox.vue';

  SwiperCore.use([Pagination, Autoplay]);

  export default defineComponent({
    components: {
      Swiper,
      SwiperSlide,
      InformationBox,
    },
    props: {
      boxes: {
        type: Object as PropType<ItemType[]>,
        required: true,
      },
    },
    setup() {
      const autoplay = computed(() => ({
        delay: '5000',
        disableOnInteraction: true,
      }));
      return {
        autoplay,
      };
    },
  });
