import { createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a1480d06"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "desktopNavigation container" }
const _hoisted_2 = { class: "desktopNavigation_IconsAndLevel1_Wrapper" }
const _hoisted_3 = { class: "desktopNavigation_IconsAndLevel1" }
const _hoisted_4 = { class: "desktopNavigation_IconsAndLevel1_Icons" }
const _hoisted_5 = { class: "desktopNavigation_Level2And3_Level2" }
const _hoisted_6 = { class: "desktopNavigation_Level2And3_Level3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode($setup["DesktopIcons"])
        ]),
        _createElementVNode("div", {
          class: "desktopNavigation_IconsAndLevel1_Level1",
          onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
        }, [
          _createVNode($setup["DesktopLevel1Navigation"], {
            routes: $setup.level1Routes,
            "show-next-level": $setup.showLevel2And3Navigation,
            onOnLinkClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.navigation().page().level1($event)))
          }, null, 8 /* PROPS */, ["routes", "show-next-level"])
        ])
      ])
    ]),
    _createVNode(_Transition, {
      onBeforeEnter: $setup.beforeEnter,
      onEnter: $setup.enter
    }, {
      default: _withCtx(() => [
        ($setup.showLevel2And3Navigation)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "desktopNavigation_Level2And3",
              onClick: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop"]))
            }, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode($setup["Level2Navigation"], {
                  routes: $setup.level2Routes,
                  onOnClickLink: _cache[2] || (_cache[2] = ($event: any) => ($setup.closeLevel2And3Navigation()))
                }, null, 8 /* PROPS */, ["routes"])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode($setup["Level3Navigation"], {
                  "routes-array": $setup.level3Routes,
                  onOnClickLink: _cache[3] || (_cache[3] = ($event: any) => ($setup.closeLevel2And3Navigation()))
                }, null, 8 /* PROPS */, ["routes-array"])
              ])
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    })
  ]))
}