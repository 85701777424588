import { BorgunPayload } from '@/models/checkout/borgunPayload';
// import { GiftcardPayload } from "@/models/checkout/GiftcardPayload";
// import { NetgiroPayload } from "@/models/checkout/NetgiroPayload";
// import { SiminnPayPayload } from "@/models/checkout/SiminnPayPayload";

// eslint-disable-next-line no-shadow
enum PaymentType {
  Borgun,
  Giftcard,
  Netgiro,
  SiminnPay,
}

interface PaymentPayload {
  paymentType: PaymentType;
  // gjafakortPayload: GjafakortPayload,
  // netgiroPayload: NetgiroPayload,
  // siminnPayPayload: SiminnPayPayload,

  // giftCard: string,
  // giftCardAmount: string,
}

export { PaymentPayload, PaymentType };
