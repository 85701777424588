import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "img-zoom-wrapper"
}
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_ctx.open)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("img", {
          ref: "draggableContainer",
          class: "productImage",
          src: _ctx.imagePath(_ctx.imageToZoom, 2000, 2000),
          style: _normalizeStyle({ transform: `scale(${_ctx.imgScale})`, top: `${_ctx.imgTop}px`, left: `${_ctx.imgLeft}px` }),
          onWheel: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.zoomImage && _ctx.zoomImage(...args))),
          onMousedown: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.dragMouseDown && _ctx.dragMouseDown(...args)))
        }, null, 44 /* STYLE, PROPS, HYDRATE_EVENTS */, _hoisted_2),
        _createElementVNode("button", {
          class: "close-icon btn-reset",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.closePopup()))
        }, [
          _createVNode(_component_Icon, {
            icon: "close",
            size: "48"
          })
        ])
      ]))
    : _createCommentVNode("v-if", true)
}