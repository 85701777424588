
  import { defineComponent, PropType } from 'vue';
  import { RouteRecord } from 'vue-router';

  export default defineComponent({
    props: {
      route: {
        type: Object as PropType<RouteRecord>,
        required: true,
      },
      image: {
        type: String,
        required: true,
      },
    },
  });
