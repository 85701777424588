import { RouteRecord, useRouter } from 'vue-router';
import { getPrimaryArea } from '@/utils/routingHelper';

interface UseNavigation {
  navigateToPrimaryArea(): void;
  navigateToArea(route: RouteRecord): void;
}

export default function useNavigation(): UseNavigation {
  const router = useRouter();

  const navigateToPrimaryArea = () => router.push(getPrimaryArea(router).path);
  const navigateToArea = (route: RouteRecord) => router.push(route.path);

  return {
    navigateToPrimaryArea,
    navigateToArea,
  };
}
