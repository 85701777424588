
  import { defineComponent, PropType } from 'vue';
  import useOrderLine from '@/composables/useOrderLine';
  import { OrderLine } from '@/models/cart/orderLine';
  import { Product } from '@/models/cart/product';
  import { Variant } from '@/models/cart/variant';

  export default defineComponent({
    props: {
      orderLine: {
        type: Object as PropType<OrderLine>,
        required: true,
      },
    },
    setup(props) {
      const {
        selectedQuantity,
        selectedColor,
        colorOptions,
        selectedVariant,
        variantOptions,
        updateOrderLineQuantity,
        updateOrderLineProduct,
        updateOrderLineVariant,
        deleteOrderLine,
      } = useOrderLine(props);

      const updateQuantity = (event: Event) => {
        const { value } = event.target as HTMLInputElement;
        updateOrderLineQuantity({ orderLine: props.orderLine, quantity: Number(value) });
      };

      const updateColor = (event: Event) => {
        const { value } = event.target as HTMLInputElement;
        const product = JSON.parse(value) as Product;
        if (props.orderLine.Product.ProductID !== product.ProductID) {
          updateOrderLineProduct({ orderLine: props.orderLine, product });
        }
      };

      const updateVariant = (event: Event) => {
        event.preventDefault();
        const { value } = event.target as HTMLInputElement;
        const variant = JSON.parse(value) as Variant;
        updateOrderLineVariant({ orderLine: props.orderLine, variant });
      };

      const deleteItem = () => {
        deleteOrderLine(props.orderLine);
      };

      return {
        selectedQuantity,
        selectedColor,
        colorOptions,
        selectedVariant,
        variantOptions,
        updateQuantity,
        updateColor,
        updateVariant,
        deleteItem,
      };
    },
  });
