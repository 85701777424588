import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9bb76920"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "content__link" }
const _hoisted_3 = { class: "content__header" }
const _hoisted_4 = { class: "content__name" }
const _hoisted_5 = { class: "content__summary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contentList, (item, index) => {
      return (_openBlock(), _createElementBlock("li", { key: index }, [
        _createElementVNode("a", _hoisted_2, [
          _createElementVNode("h4", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(item.Name), 1 /* TEXT */)
          ])
        ]),
        _createElementVNode("p", _hoisted_5, _toDisplayString(item.Summary), 1 /* TEXT */)
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}