import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3271f58c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "video-text" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "video-text__info" }
const _hoisted_5 = { class: "video-text__info-inner" }
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(["container paragraph-container", _ctx.getParagraphClasses]),
    style: _normalizeStyle(_ctx.getParagraphStyles)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(['video-text__media', { 'video-text__media--right': !_ctx.isLayoutRight }])
      }, [
        _createElementVNode("img", {
          class: _normalizeClass(['video-text__media-image', { 'video-text__media-image--right': !_ctx.isLayoutRight }]),
          src: _ctx.imagePrefix + _ctx.imagePath,
          alt: _ctx.paragraphData.title
        }, null, 10 /* CLASS, PROPS */, _hoisted_2),
        _createElementVNode("div", {
          class: _normalizeClass(['video-text__media-video', { 'video-text__media-video--right': !_ctx.isLayoutRight }, { 'video-text__media-video--invert': _ctx.isDarkMode }])
        }, [
          _createElementVNode("iframe", {
            src: `https://www.youtube.com/embed/${_ctx.paragraphData.video}`,
            title: "YouTube video player",
            frameborder: "0",
            allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
            allowfullscreen: ""
          }, null, 8 /* PROPS */, _hoisted_3)
        ], 2 /* CLASS */)
      ], 2 /* CLASS */),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", {
            class: _normalizeClass(['video-text__info-title', { 'video-text__info-title--invert': _ctx.isDarkMode }])
          }, _toDisplayString(_ctx.paragraphData.title), 3 /* TEXT, CLASS */),
          _createElementVNode("div", {
            class: _normalizeClass(['video-text__info-text', { ' video-text__info-text--invert': _ctx.isDarkMode }]),
            innerHTML: _ctx.paragraphData.text
          }, null, 10 /* CLASS, PROPS */, _hoisted_6)
        ])
      ])
    ])
  ], 6 /* CLASS, STYLE */))
}