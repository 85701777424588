
  import { defineComponent, inject, ref } from 'vue';

  import useWebsiteSettings from '@/composables/useWebsiteSettings';

  import { SearchConfigKey } from '@/components/search/SearchSymbols';

  import TextInput from '@/components/shared/inputs/TextInput.vue';
  import CustomButton from '@/components/shared/Button.vue';

  export default defineComponent({
    components: {
      TextInput,
      CustomButton,
    },
    props: {
      initialSearchValue: {
        required: false,
        type: String,
        default: '',
      },
    },
    emits: ['submit', 'cancel'],
    setup(props) {
      const { websiteSettings } = useWebsiteSettings();

      const searchInput = ref<string>(props.initialSearchValue);
      const searchConfig = inject(SearchConfigKey);

      return {
        websiteSettings,
        searchInput,
        searchConfig,
      };
    },
    computed: {
      placeholderText() {
        return this.searchConfig !== undefined ? this.searchConfig.formPlaceholderText : 'Leita';
      },
      masterHref() {
        if (Object.keys(this.searchConfig?.shops || {}).length === 1 && this.searchConfig?.masterSearch) {
          return this.searchConfig?.masterSearch;
        }
        return null;
      },
      showMoreQuery() {
        const params = {};

        if (this.searchInput !== undefined && this.searchInput.length > 0 && this.searchConfig !== undefined) {
          params[this.searchConfig?.queryKey] = this.searchInput;
        }

        return params;
      },
    },
    methods: {
      onSubmit(): void {
        this.$emit('submit', this.searchInput);
      },
      onCancelClick(): void {
        this.$emit('cancel');
      },
    },
  });
