import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, withCtx as _withCtx, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f39e1ed0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "address__header" }
const _hoisted_2 = { class: "address__name" }
const _hoisted_3 = { class: "address__info" }
const _hoisted_4 = { class: "address__content" }
const _hoisted_5 = { class: "address__content" }
const _hoisted_6 = { class: "address__content" }
const _hoisted_7 = { class: "controls" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_UserContentCard = _resolveComponent("UserContentCard")!

  return (_openBlock(), _createBlock(_component_UserContentCard, {
    class: "address",
    underlined: _ctx.underlined,
    "bg-color-class": "grey"
  }, _createSlots({
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.address.Name), 1 /* TEXT */),
        _createElementVNode("span", {
          class: _normalizeClass(["address__icon", { control: _ctx.canMakeAddressDefault }]),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onIconClick && _ctx.onIconClick(...args)))
        }, [
          (_ctx.address.Default)
            ? (_openBlock(), _createBlock(_component_Icon, {
                key: 0,
                icon: "heart-full"
              }))
            : (_openBlock(), _createBlock(_component_Icon, {
                key: 1,
                icon: "heart"
              }))
        ], 2 /* CLASS */)
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.address.Address), 1 /* TEXT */),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.address.Zip) + " " + _toDisplayString(_ctx.address.City), 1 /* TEXT */),
        _createCommentVNode(" <p class=\"address__content\">\r\n        {{ address.Country }}\r\n      </p> "),
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.address.PhoneNumber), 1 /* TEXT */)
      ])
    ]),
    _: 2 /* DYNAMIC */
  }, [
    (_ctx.controls)
      ? {
          name: "footer",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("span", {
                class: "controls__control",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onEditClick && _ctx.onEditClick(...args)))
              }, " Breyta "),
              (_ctx.address.Id !== _ctx.address.UserId)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: "controls__control",
                    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onDeleteClick && _ctx.onDeleteClick(...args)))
                  }, " Eyða "))
                : _createCommentVNode("v-if", true)
            ])
          ])
        }
      : undefined
  ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["underlined"]))
}