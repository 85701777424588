import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c2658f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "productList_MainContainer container"
}
const _hoisted_2 = { class: "productList_Content" }
const _hoisted_3 = {
  key: 1,
  class: "productList_MainContainer container bg-color"
}
const _hoisted_4 = { class: "loaderContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductListHeader = _resolveComponent("ProductListHeader")!
  const _component_ProductListFacets = _resolveComponent("ProductListFacets")!
  const _component_ProductCardList = _resolveComponent("ProductCardList")!
  const _component_Loader = _resolveComponent("Loader")!

  return (!_ctx.initialLoad)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ProductListHeader, {
          filtersOpen: _ctx.filtersOpen,
          "onUpdate:filtersOpen": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filtersOpen) = $event)),
          count: _ctx.productList.productCount,
          "sort-by": _ctx.sortBy.value,
          "onUpdate:sortBy": _ctx.onSortUpdate
        }, null, 8 /* PROPS */, ["filtersOpen", "count", "sort-by", "onUpdate:sortBy"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_ProductListFacets, {
            "selected-facets": _ctx.selectedFacets,
            facets: _ctx.facets,
            closed: !_ctx.filtersOpen,
            "onUpdate:selectedFacets": _ctx.onSelectedFacetsUpdate,
            onClose: _ctx.onFacetsClose,
            onClear: _ctx.onFacetsClear
          }, null, 8 /* PROPS */, ["selected-facets", "facets", "closed", "onUpdate:selectedFacets", "onClose", "onClear"]),
          _createVNode(_component_ProductCardList, {
            size: _ctx.size,
            loading: _ctx.productListLoader,
            "loading-next": _ctx.isFetchMore,
            "product-list": _ctx.productList,
            onNext: _ctx.getNextPage
          }, null, 8 /* PROPS */, ["size", "loading", "loading-next", "product-list", "onNext"])
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Loader, {
            size: 100,
            thickness: 1
          })
        ])
      ]))
}