import { RouteRecordRaw } from 'vue-router';
import CartOverview from '@/components/cart/checkout/overview/CartOverview.vue';
import CartContactInfo from '@/components/cart/checkout/contact-info/CartContactInfo.vue';
import CartGiftWrap from '@/components/cart/checkout/gift-wrap/CartGiftWrap.vue';
import CartShippingMethods from '@/components/cart/checkout/shipping-method/CartShippingMethod.vue';
import CartPaymentMethods from '@/components/cart/checkout/payment-method/CartPaymentMethod.vue';
import CartReceipt from '@/components/cart/checkout/receipt/CartReceipt.vue';
import CartErrorPage from '@/components/cart/checkout/error-page/CartPaymentErrorPage.vue';
import AdminRedirect from '@/components/AdminRedirect.vue';
import ForgottenPasswordLandingPage from '@/components/user/ForgottenPasswordLandingPage.vue';
import CreateUser from '@/components/user/CreateUser.vue';
import conf from '@/config';

export default [
  {
    path: '/cart/overview',
    name: 'CartOverview',
    component: CartOverview,
  },
  {
    path: '/cart/contact-info',
    name: 'CartContactInfo',
    component: CartContactInfo,
  },
  {
    path: '/cart/gift-wrap',
    name: 'CartGiftWrap',
    component: CartGiftWrap,
  },
  {
    path: '/cart/delivery-options',
    name: 'CartshippingMethod',
    component: CartShippingMethods,
  },
  {
    path: '/cart/payment-methods',
    name: 'CartPaymentMethod',
    component: CartPaymentMethods,
  },
  {
    path: '/cart/cart-receipt',
    name: 'CartReceipt',
    component: CartReceipt,
  },
  {
    path: '/cart/error',
    name: 'CartError',
    component: CartErrorPage,
  },
  {
    path: '/password-recovery',
    name: 'Password Recovery',
    component: ForgottenPasswordLandingPage,
  },
  {
    path: '/create-user',
    name: 'Nýskráning',
    component: CreateUser,
  },
  {
    path: '/admin',
    name: 'AdminRedirect',
    component: AdminRedirect,
    beforeEnter: () => {
      window.location.replace(`${conf.dwUrl}/admin`);
    },
  },
] as Array<RouteRecordRaw>;
