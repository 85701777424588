import axios from 'axios';
import conf from '@/config';

class GiftCardService {
  static getUrl(): string {
    return conf.dwUrl;
  }

  static async getWhiteCard(cardNumber: string): Promise<any> {
    const url = `${this.getUrl()}/${conf.area}/stada-gjafakorts-hvitt`;
    const formData = new FormData();
    formData.append('gift-card-number', cardNumber);
    const response = await axios.post(url, formData, { withCredentials: true });
    const giftCardAmount = response.data.giftCardCredit_Normalized;
    return giftCardAmount;
  }

  static async getBlueCard(cardNumber: string): Promise<string> {
    const url = `${this.getUrl()}/api/GiftCard/${cardNumber}`;
    const response = await axios.get(url, { withCredentials: true });
    return response.data.Balance !== null ? response.data.Balance : 'error';
  }
}

export default GiftCardService;
