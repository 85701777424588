
  import { computed, defineComponent, PropType } from 'vue';
  import ProductCard from './ProductCard.vue';
  import Loader from '@/components/shared/Loader.vue';
  import CustomButton from '@/components/shared/Button.vue';
  import { Product } from '@/models/product/product';
  import { SearchProduct } from '@/models/search/searchModels';
  import { GA4E } from '@/ga4ecommerce/GA4E';

  export type ProductCardList = {
    products: (Product | SearchProduct)[];
    currentPage: number | string;
    pageCount: number | string;
  };

  export default defineComponent({
    components: {
      ProductCard,
      Loader,
      CustomButton,
    },
    props: {
      loading: {
        required: false,
        type: Boolean,
        default: false,
      },
      loadingNext: {
        required: false,
        type: Boolean,
        default: false,
      },
      productList: {
        required: true,
        type: Object as PropType<ProductCardList>,
      },
      size: {
        required: false,
        type: String as PropType<'medium' | 'large'>,
        default: 'medium',
      },
      max: {
        required: false,
        type: Number,
        default: null,
      },
      showMore: {
        required: false,
        type: Boolean,
        default: true,
      },
      pathPrefix: {
        required: false,
        type: String,
        default: undefined,
      },
    },
    emits: ['next'],
    setup(props, { emit }) {
      const onLoadNextClick = () => emit('next');

      const products = computed(() => (props.max ? props.productList.products.slice(0, props.max) : props.productList.products));

      const onPressProductCard = (product: any, listIndex: number) => {
        GA4E.selectItem(product, listIndex);
      };

      return {
        onLoadNextClick,
        onPressProductCard,

        products,
      };
    },
  });
