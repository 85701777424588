export type DateConsructor = string | Date | number;

export interface DateFormats {
  [key: string]: (date: Date) => string;
}

export interface DateFormat {
  (date: DateConsructor, format?: string): string;
}

export const dateFormatHandler =
  (formats: DateFormats, defaultFormat: string): DateFormat =>
  (date: DateConsructor, format?: string): string =>
    Object.keys(formats).reduce((curr, key) => curr.replace(key, formats[key](new Date(date))), format || defaultFormat);

const dateFormats = {
  DD: (date: Date): string => date.getDate().toString().padStart(2, '0'),
  MM: (date: Date): string => (date.getMonth() + 1).toString().padStart(2, '0'),
  YY: (date: Date): string => date.getFullYear().toString(),
} as DateFormats;

export const dateFormat = dateFormatHandler(dateFormats, 'DD.MM.YY');
