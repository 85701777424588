import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a68f26a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "adminRedirect_Container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loader = _resolveComponent("Loader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Loader, {
      size: 100,
      thickness: 1
    })
  ]))
}