import config from '@/config';

interface ApiResponse {
  success: boolean;
  errors: string[];
  data: GiftCardBalance;
}

interface GiftCardBalance {
  initialBalance: number;
  remainingBalance: number;
  cardBrand: string;
  cardType: string;
  error: string | null;
}

export const useGift2WalletApi = () => {
  const getUrl = (cardNumber: string) => `${config.dwUrl}/api/advania/g2w/balance/${cardNumber}`;

  const fetchGiftCardBalance = async (cardNumber: string) => {
    const url = getUrl(cardNumber);
    try {
      const response = await fetch(url);
      if (response.ok) {
        const apiResponse = (await response.json()) as ApiResponse;
        if (apiResponse.success) {
          return apiResponse.data;
        }
      }

      throw new Error('Villa kom upp');
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  return {
    fetchGiftCardBalance,
  };
};
