import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6da2cee0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "iconTitleAndDescription_Wrapper" }
const _hoisted_2 = { class: "iconTitleAndDescription_Wrapper_Header" }
const _hoisted_3 = { class: "iconTitleAndDescription_Wrapper_Header_IconAndText" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "iconTitleAndDescription_Wrapper_Body" }
const _hoisted_6 = { class: "box_Text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.getStyles),
    class: _normalizeClass(["iconTitleAndDescription", `iconTitleAndDescription_${_ctx.boxCount}boxes`])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", null, [
            _createElementVNode("img", {
              class: "box_Icon icon-l",
              src: _ctx.getIconPath,
              alt: "icon"
            }, null, 8 /* PROPS */, _hoisted_4)
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("h2", null, _toDisplayString(_ctx.getTitle), 1 /* TEXT */)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.boxData.text), 1 /* TEXT */)
      ])
    ])
  ], 6 /* CLASS, STYLE */))
}