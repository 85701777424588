
  import { defineComponent, PropType } from 'vue';
  import { Order } from '@/models/order/order';
  import { dateFormat } from '@/utils/dateHelper';
  import { formatAsPriceString } from '@/utils/stringHelper';
  import UserContentCard from '../UserContentCard.vue';

  export default defineComponent({
    components: {
      UserContentCard,
    },
    props: {
      order: {
        required: true,
        type: Object as PropType<Order>,
      },
    },
    computed: {
      orderDate(): string {
        return this.order?.Date ? dateFormat(this.order.Date) : '';
      },
      orderTotal(): string {
        return formatAsPriceString(this.order.Price.PriceWithVat, this.order.Price.CurrencySymbol);
      },
    },
  });
