import axios from 'axios';
import conf from '@/config';
import { PageInfo } from '@/models/content/PageInfo';

type GetPageInfo = (pageId: string) => Promise<PageInfo | undefined>;

const isPageParagraph = (pageInfo) => {
  const isPageParagraph = pageInfo.Item?.Fields?.find((obj) => obj.Name === 'isPageParagraph');
  return isPageParagraph !== undefined ? isPageParagraph.Value : false;
};

export default function useGetPageInfo() {
  const getPageInfo: GetPageInfo = async (pageId: string) => {
    if (pageId === undefined) {
      return;
    }

    const response = await axios.get(`${conf.dwUrl}/dwapi/frontend/pageviews/${pageId}`);

    if (!response?.data) {
      return;
    }

    const { data } = response;
    const pageInfo: PageInfo = {
      Name: data.Name ?? '',
      Title: data.Title ?? '',
      Description: data.Description ?? '',
      MetaTags: data.MetaTags ?? '',
      IsPageParagraph: isPageParagraph(data),
    };

    if (data.PropertyItem) {
      pageInfo.PropertyItem = {
        Fields: data.PropertyItem.Fields,
        Id: data.PropertyItem.Id,
        Link: data.PropertyItem.Link,
        PageID: data.PropertyItem.PageID,
        SystemName: data.PropertyItem.SystemName,
      };
    }

    if (data.Item) {
      pageInfo.Item = {
        Fields: data.Item.Fields,
        Id: data.Item.Id,
        Link: data.Item.Link,
        PageID: data.Item.PageID,
        SystemName: data.Item.SystemName,
      };
    }

    return pageInfo;
  };

  return {
    getPageInfo,
  };
}
