import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-03eafb58"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tabs" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabSliderItem = _resolveComponent("TabSliderItem")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["container paragraph-container", _ctx.getParagraphClasses]),
    style: _normalizeStyle(_ctx.getParagraphStyles)
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getData, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass(['tabs__tab', { 'tabs__tab--active': _ctx.getActiveIndex === index }]),
          onClick: ($event: any) => (_ctx.chooseSlide(index))
        }, _toDisplayString(item.tabText), 11 /* TEXT, CLASS, PROPS */, _hoisted_2))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _createVNode(_component_swiper, {
      "slides-per-view": 'auto',
      "space-between": 15,
      navigation: true,
      "auto-height": true,
      class: "my-swiper",
      onSwiper: _ctx.onSwiper,
      onSlideChange: _ctx.slideChanged
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getData, (item, index) => {
          return (_openBlock(), _createBlock(_component_swiper_slide, { key: index }, {
            default: _withCtx(() => [
              _createVNode(_component_TabSliderItem, {
                key: index,
                title: item.title,
                text: item.text,
                label: item.label,
                image: item.image ? _ctx.imagePath(item.image.Path, 500, 500) : '',
                video: item.youTubeId
              }, null, 8 /* PROPS */, ["title", "text", "label", "image", "video"])
            ]),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["onSwiper", "onSlideChange"])
  ], 6 /* CLASS, STYLE */))
}