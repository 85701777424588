/* eslint-disable no-shadow */
import { Commit } from 'vuex';
import ProductAPI from '@/API/productApi';
import { NO_CONTENT } from '@/constants/requests';
import { ProductInformation } from '@/models/product/product';
import { ProductIsFavoritePayload, ProductPayload } from '@/models/productList/productListPayloadModels';

interface ProductState {
  productInfo: ProductInformation;
}

const initialState = {
  product: {} as any,
  relatedProducts: [] as any[],
} as ProductInformation;

const state = (): ProductState => ({
  productInfo: initialState,
});

const getters = {
  getProduct(state: ProductState): any {
    return state.productInfo.product;
  },
  getRelatedProducts(state: ProductState): any[] {
    return state.productInfo.relatedProducts;
  },
};

const mutations = {
  setProduct(state: ProductState, product: any): void {
    state.productInfo.product = product;

    if (state.productInfo?.relatedProducts.filter((item) => item.Id === product.Id).length === 0) {
      state.productInfo.relatedProducts = [] as any[];
    }
  },
  setProductLiveData(state: ProductState, productInfo: any): void {
    state.productInfo.product = { ...state.productInfo.product, ...productInfo };
  },
  setRelatedProducts(state: ProductState, relatedProducts: any): void {
    state.productInfo.relatedProducts = relatedProducts.Products;
  },
  setRelatedProductsLiveData(state: ProductState, data: any): void {
    const relatedProducts = [] as any[];
    const primaryProductId = state.productInfo.product.Id;

    relatedProducts.push(state.productInfo.product);

    if (data.Id === state.productInfo.product.Id) {
      state.productInfo.relatedProducts.forEach((relatedProduct) => {
        data.RelatedProducts.forEach((liveData) => {
          if (liveData.Id === relatedProduct.Id) {
            const product = relatedProduct;
            product.Price = liveData.Price;
            product.PriceBeforeDiscount = liveData.PriceBeforeDiscount;
            product.VariantInfo = liveData.VariantInfo;
            product.StockLevel = liveData.StockLevel;
            relatedProducts.push(product);
          }
        });

        if (relatedProduct.Id === primaryProductId) {
          const { product } = state.productInfo;
          relatedProducts.push(product);
        }
      });
    }

    state.productInfo.relatedProducts = relatedProducts;
    console.debug('setRelatedProductsLiveData: ', data);
  },
  clearProduct(state: ProductState): void {
    state.productInfo.product = initialState;
  },
  toggleIsFavorite(state: ProductState): void {
    state.productInfo.product.IsFavorite = !state.productInfo.product.IsFavorite;
  },
};

const actions = {
  async setProduct({ commit }: { commit: Commit }, payload: ProductPayload): Promise<boolean> {
    const response = await ProductAPI.getProduct(payload);

    if (response?.status === NO_CONTENT) {
      // Nothing found
      return true;
    }
    if (response === undefined) {
      return false;
    }
    if (Object.keys(response).length === 0) {
      // Axios request cancelled
      return false;
    }

    commit('setProduct', response.data);
    return true;
  },
  async setProductLiveData({ commit }: { commit: Commit }, payload: ProductPayload): Promise<boolean> {
    const response = await ProductAPI.getproductLiveData(payload);

    if (response?.status === NO_CONTENT) {
      // Nothing found
      return true;
    }
    if (response === undefined) {
      return false;
    }
    if (Object.keys(response).length === 0) {
      // Axios request cancelled
      return false;
    }

    commit('setProductLiveData', response.data);
    return true;
  },
  async setRelatedProducts({ commit }: { commit: Commit }, payload: ProductPayload): Promise<boolean> {
    const response = await ProductAPI.getRelatedProducts(payload);

    if (response?.status === NO_CONTENT) {
      // Nothing found
      return true;
    }
    if (response === undefined) {
      return false;
    }
    if (Object.keys(response).length === 0) {
      // Axios request cancelled
      return false;
    }

    commit('setRelatedProducts', response.data);
    return true;
  },
  async setRelatedProductsLiveData({ commit }: { commit: Commit }, payload: ProductPayload): Promise<boolean> {
    const response = await ProductAPI.getRelatedProductsLiveData(payload);

    if (response?.status === NO_CONTENT) {
      // Nothing found
      return true;
    }
    if (response === undefined) {
      return false;
    }
    if (Object.keys(response).length === 0) {
      // Axios request cancelled
      return false;
    }

    commit('setRelatedProductsLiveData', response.data);
    return true;
  },
  async toggleIsFavorite({ commit }: { commit: Commit }, payload: ProductIsFavoritePayload): Promise<boolean> {
    commit('toggleIsFavorite', payload.productId);
    return true;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
