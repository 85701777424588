
  import { defineComponent, PropType } from 'vue';
  import TitleTextAndButton from '@/components/itemTypes/base/boxes/TitleTextAndButton.vue';
  import IconsWithText from '@/components/itemTypes/base/boxes/IconsWithText.vue';
  import TitleAndRichText from '@/components/itemTypes/base/boxes/TitleAndRichText.vue';
  import ImageTitleAndButton from '@/components/itemTypes/base/boxes/imageTitleAndButton.vue';
  import ImageAndButton from '@/components/itemTypes/base/boxes/imageAndButton.vue';
  import IconTitleAndText from '@/components/itemTypes/base/boxes/iconTitleAndText.vue';
  import { ItemType } from '@/models/field';
  import useInformationBox from '@/composables/useInformationBox';

  export default defineComponent({
    components: {
      TitleTextAndButton,
      IconsWithText,
      TitleAndRichText,
      ImageTitleAndButton,
      ImageAndButton,
      IconTitleAndText,
    },
    props: {
      box: {
        type: Object as PropType<ItemType>,
        required: true,
      },
      boxCount: {
        type: Number,
        required: true,
      },
    },
    setup(props) {
      const { getData, hide } = useInformationBox(props);
      return { getData, hide };
    },
  });
