import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f60290da"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "titleAndRichText_Wrapper" }
const _hoisted_2 = { class: "titleAndRichText_Wrapper_Title" }
const _hoisted_3 = { class: "box_Title" }
const _hoisted_4 = { class: "titleAndRichText_Wrapper_RichText" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({ ..._ctx.getStyles, ..._ctx.getComponentSpecificStyles }),
    class: _normalizeClass(["titleAndRichText", `titleAndRichText_${_ctx.boxCount}boxes`])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.getTitle), 1 /* TEXT */),
        _createCommentVNode(" <h1 class=\"box_Title box_MobileTitle\">\r\n          {{ getMobileTitle }}\r\n        </h1> ")
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", null, [
          _createElementVNode("span", {
            class: "box_Text",
            innerHTML: _ctx.boxData.richTextOne
          }, null, 8 /* PROPS */, _hoisted_5)
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("span", {
            class: "box_Text",
            innerHTML: _ctx.boxData.richTextTwo
          }, null, 8 /* PROPS */, _hoisted_6)
        ])
      ])
    ])
  ], 6 /* CLASS, STYLE */))
}