import { AxiosResponse } from 'axios';

export interface AddressRaw {
  Id: string;
  Name: string;
  Description: string;
  Address: string;
  Zip: string;
  City: string;
  Country: string;
  CountryCode: string;
  PhoneNumber: string;
  Default: boolean;
}

export interface Address extends AddressRaw {
  UserId: string;
  Email: string;
}

export interface AddressPayload {
  name: string;
  address: string;
  zip: string;
  country: string;
  countryCode?: string;
  email?: string;
  city?: string;
  phoneNumber: string;
  default: boolean;
}

export interface AddressKeyedPayload {
  id: string;
  address: AddressPayload;
}

export type AddressFormAction = 'Add' | 'Update' | 'Delete' | 'MakeDefault';

export interface AddressFormData {
  ManageAddressesFormAction?: AddressFormAction;
  SelectedAddressId?: string;
  UserId?: string;
  AddAddressesForm?: string;
  DefaultAddressId?: string;
  Address?: string;
  Address2?: string;
  Cell?: string;
  City?: string;
  Company?: string;
  Country?: string;
  CountryCode?: string;
  CustomerNumber?: string;
  Default?: boolean;
  Description?: string;
  Email?: string;
  Fax?: string;
  Phone?: string;
  PhoneBusiness?: string;
  State?: string;
  Zip?: string;
}

export const AddAddressFormData = {
  AddAddressesForm: '1',
} as AddressFormData;

export const UpdateAddressFormData = {
  AddAddressesForm: '1',
} as AddressFormData;

export const DeleteAddressFormData = {
  ManageAddressesFormAction: 'Delete',
} as AddressFormData;

export const MakeDefaultFormData = {
  ManageAddressesFormAction: 'MakeDefault',
  Default: true,
} as AddressFormData;

export type AddressResponse = AxiosResponse<AddressRaw>;
export type AddressesResponse = AxiosResponse<AddressRaw[]>;
