/* eslint-disable @typescript-eslint/no-explicit-any */
import { computed, ComputedRef, StyleValue } from 'vue';
import { Paragraph } from '@/models/item-types/paragraph';
import { mapParagraphComponentData } from '@/utils/dataMapper';

interface UseParagraph {
  data: ComputedRef<Paragraph>;
  getParagraphStyles: ComputedRef<StyleValue>;
  getParagraphClasses: ComputedRef<{ [key: string]: unknown }>;
}

export default function useParagraph(componentProps: unknown): UseParagraph {
  const data = computed(() => mapParagraphComponentData(componentProps) as Paragraph);

  const getParagraphStyles = computed(
    (): StyleValue =>
      ({
        '--top-spacing': data.value.topSpacing > 0 ? `${data.value.topSpacing}px` : '',
        '--bottom-spacing': data.value.bottomSpacing > 0 ? `${data.value.bottomSpacing}px` : '',
        '--height': `${data.value.height}px`,
      }) as StyleValue,
  );

  const getParagraphClasses = computed(() => ({
    paragraph_hideInMobile: data.value.disableInMobile,
    paragraph_hideInTablet: data.value.disableInTablet,
    paragraph_hideInLaptop: data.value.disableInLaptop,
    paragraph_hideInDesktop: data.value.disableInDesktop,
  }));

  return {
    data,
    getParagraphStyles,
    getParagraphClasses,
  };
}

export { UseParagraph };
