
  import { defineComponent, PropType } from 'vue';
  import { find, keys } from 'lodash';
  import useOrderLine from '@/composables/useOrderLine';
  import { OrderLine } from '@/models/cart/orderLine';
  import conf from '@/config';

  export default defineComponent({
    props: {
      orderLine: {
        type: Object as PropType<OrderLine>,
        required: true,
      },
      area: {
        type: String,
        required: false,
        default: null,
      },
      webshopToArea: {
        type: Object,
        required: false,
        default: () => ({
          s4s: 's4s',
          elli: 'ellingsen',
          skor: 'skor',
          air: 'air',
          brp: 'brp-farartaeki',
          rafhjol: 'rafhjolasetrid',
        }),
      },
      defaultWebshopKey: {
        type: String,
        required: false,
        default: 's4s',
      },
    },
    emits: ['nav'],
    setup(props) {
      const { formattedOrderLinePrice, formattedProductPrice, orderLineHasDiscount, orderLineStyles, productImagePath } = useOrderLine(props);

      return {
        formattedOrderLinePrice,
        formattedProductPrice,
        orderLineHasDiscount,
        orderLineStyles,
        productImagePath,
      };
    },
    data() {
      return {
        imagePrefix: conf.dwUrl,
      };
    },
    computed: {
      productLink(): string {
        if (this.area !== null) {
          const lastChar = this.area.charAt(this.area.length - 1);
          if (lastChar === '/') {
            return `${this.area}vara?ProductId=${this.orderLine.Product.ProductID}`;
          }
          return `${this.area}/vara?ProductId=${this.orderLine.Product.ProductID}`;
        }
        return `/${this.webshopArea}/vara?ProductId=${this.orderLine.Product.ProductID}`;
      },
      webshopArea(): string {
        const webshopLowerCase = (this.orderLine.Webshops ?? '').toLowerCase();

        const webshopKey = find(keys(this.webshopToArea), (key) => webshopLowerCase.startsWith(key));

        return this.webshopToArea[webshopKey ?? this.defaultWebshopKey];
      },
    },
    methods: {
      selectedSize() {
        const selectedSize = this.orderLine?.Product?.Variants?.find((variant) => variant.Selected === true);
        return selectedSize !== undefined ? selectedSize.VariantText : '';
      },
      selectedColor() {
        return this.orderLine?.Product?.Colors !== undefined ? this.orderLine.Product.Colors : '';
      },
      onLinkClick() {
        this.$emit('nav');
      },
    },
  });
