
  import { defineComponent } from 'vue';
  import { RouteLocationMatched, RouteLocationNormalizedLoaded } from 'vue-router';

  export default defineComponent({
    props: {
      productCount: {
        type: Number,
        required: false,
        default: 0,
      },
    },
    data() {
      return {
        breadCrumbs: [] as RouteLocationMatched[],
        homeCrumb: {} as RouteLocationMatched,
      };
    },
    watch: {
      $route(to, from) {
        if (to !== from) {
          this.breadCrumbs = this.getBreadCrumbs(to);
        }
      },
    },
    mounted() {
      if (this.breadCrumbs.length === 0) {
        this.breadCrumbs = this.getBreadCrumbs(this.$route);
      }
    },
    methods: {
      getBreadCrumbs(route: RouteLocationNormalizedLoaded): RouteLocationMatched[] {
        let breadCrumbs = [] as RouteLocationMatched[];
        // Must get new RouteLocation object from router
        // otherwise "matched" property will somtimes be empty when navigating backwards.
        const resolvedPath = this.$router.resolve(route.path);
        if (resolvedPath.matched !== undefined && resolvedPath.matched.length > 0) {
          [this.homeCrumb] = resolvedPath.matched;
          breadCrumbs = resolvedPath.matched.splice(1);
        }

        return breadCrumbs;
      },
    },
  });
