
  import { defineComponent, InputHTMLAttributes, PropType, StyleValue } from 'vue';
  import Icon from '@/components/shared/Icon.vue';

  /**
   * Text input utility wrapper
   */
  export default defineComponent({
    components: {
      Icon,
    },
    props: {
      /**
       * V-model target (Set in created if initializing from data)
       *
       * @model
       */
      value: {
        type: String,
        required: true,
      },
      inputHTMLAttributes: {
        type: Object as PropType<InputHTMLAttributes>,
        required: false,
        default: () => ({
          placeholder: '-',
          type: 'text',
        }),
      },
      /**
       * Flag for painting the input based on errors or not
       */
      hasErrors: {
        type: Boolean,
        required: false,
        default: false,
      },
      /**
       * Text for the label element
       */
      label: {
        type: String,
        required: false,
        default: '',
      },
      /**
       * CSS height of the input
       */
      height: {
        type: String,
        required: false,
        default: '48px',
      },
      /**
       * CSS width of the input
       */
      width: {
        type: String,
        required: false,
        default: '180px',
      },
      /**
       * Hide label element
       */
      hasLabel: {
        type: Boolean,
        required: false,
        default: true,
      },
      /**
       * Append an icon to the input.
       * @values See Sprites.vue for sprite IDs
       */
      icon: {
        required: false,
        type: String,
        default: null,
      },
    },
    emits: ['update:value', 'focus'],
    setup(props, { emit }) {
      const onInput = (event: Event) => {
        const { value } = event.target as HTMLInputElement;
        emit('update:value', value);
      };

      const onChange = (event: Event) => {
        const { value } = event.target as HTMLInputElement;
        emit('update:value', value);
      };

      return {
        onInput,
        onChange,
      };
    },
    computed: {
      getStyles(): StyleValue {
        return {
          '--width': this.$props.width,
          '--height': this.$props.height,
        } as StyleValue;
      },
      hasIcon(): boolean {
        return this.icon !== null || !!this.$slots.icon;
      },
    },
  });
