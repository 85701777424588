
  import { defineComponent } from 'vue';
  import UserInformationForm from '@/components/user/info/UserInformationForm.vue';
  import UserPasswordForm from '@/components/user/info/UserPasswordForm.vue';
  import UserContentCard from '@/components/user/UserContentCard.vue';
  import OutlineBox from '@/components/shared/OutlineBox.vue';
  import useAuthenticateUser from '@/composables/useAuthenticateUser';
  import { PasswordPayload, UserPayload, User } from '@/models/user/user';
  import useManageUserProfile from '@/composables/useManageUserProfile';

  interface UserInformationState {
    profileSubmitting: boolean;
    passwordForm: boolean;
    passwordSubmitting: boolean;
  }

  export default defineComponent({
    components: {
      UserInformationForm,
      UserPasswordForm,
      UserContentCard,
      OutlineBox,
    },
    setup() {
      const { authenticatedUser } = useAuthenticateUser();
      const { updateUserProfile, updateUserPassword } = useManageUserProfile();

      return {
        authenticatedUser,
        updateUserProfile,
        updateUserPassword,
      };
    },
    data() {
      return {
        profileSubmitting: false,
        passwordForm: false,
        passwordSubmitting: false,
      } as UserInformationState;
    },
    methods: {
      onUserPasswordEdit(): void {
        this.passwordForm = true;
      },
      onPasswordClose(): void {
        this.passwordForm = false;
      },
      onUserInfoFormSubmit(formData: UserPayload): void {
        this.performUserProfileUpdate(formData);
      },
      onPasswordFormSubmit(formData: PasswordPayload): void {
        this.performUserPasswordUpdate(formData);
      },
      async performUserProfileUpdate(formData: UserPayload): Promise<User> {
        this.profileSubmitting = true;
        const data = await this.updateUserProfile(formData);
        this.profileSubmitting = false;
        return data;
      },
      async performUserPasswordUpdate(formData: PasswordPayload): Promise<User> {
        this.passwordSubmitting = true;
        const data = await this.updateUserPassword(formData);
        this.passwordSubmitting = false;
        this.passwordForm = false;
        return data;
      },
    },
  });
