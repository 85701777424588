/* eslint-disable @typescript-eslint/no-explicit-any */
import { WishlistResponse } from '@/models/wishlist/wishlist';
import DynamicWebApi from './dwApi';
import conf from '@/config';

export default class WishlistAPI {
  static async getWishlist(): Promise<WishlistResponse> {
    return DynamicWebApi.getFeed(`${conf.area}/wishlist-feed`);
  }

  static async deleteWishlist(wishlistId: number): Promise<WishlistResponse> {
    return DynamicWebApi.getFeed(`${conf.area}/wishlist-feed?favoritecmd=removefavoritelist&FavoriteListId=${wishlistId}`);
  }

  static async addToFavorites(productId: string): Promise<WishlistResponse> {
    return DynamicWebApi.postFeed(`${conf.area}/wishlist-feed?favoritecmd=AddProductToFavoriteList&ProductId=${productId}`);
  }

  static async removeFromFavorites(productId: string, wishlistId: number): Promise<WishlistResponse> {
    return DynamicWebApi.postFeed(`${conf.area}/wishlist-feed?favoritecmd=RemoveProductFromFavoriteList&ProductId=${productId}&FavoriteListId=${wishlistId}`);
  }
}
