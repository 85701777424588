
  /* eslint-disable @typescript-eslint/no-explicit-any */
  import { defineComponent, PropType } from 'vue';
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import SwiperCore, { Pagination, Navigation } from 'swiper';
  import useParagraph from '@/composables/useParagraph';
  import { ItemType } from '@/models/field';

  import { mapBaseComponentData } from '@/utils/dataMapper';
  import { ImageHandler } from '@/utils/imageHelper';

  import TabSliderItem from '@/components/itemTypes/TabSlider/TabSliderItem.vue';

  import 'swiper/swiper.scss';
  import 'swiper/components/navigation/navigation.scss';
  import 'swiper/components/pagination/pagination.scss';
  import conf from '@/config';

  SwiperCore.use([Pagination, Navigation]);

  interface ITabSlider {
    tabSliderItem: ItemType[];
  }

  interface ITabSliderItem {
    tabText: string;
    title: string;
    label: string;
    text: string;
    image: string;
    youTubeId: string;
  }

  export default defineComponent({
    components: {
      TabSliderItem,
      Swiper,
      SwiperSlide,
    },
    props: {
      componentProps: {
        type: Object as PropType<ITabSlider>,
        required: true,
      },
    },
    setup(props) {
      const { getParagraphStyles, getParagraphClasses } = useParagraph(props.componentProps);
      return {
        getParagraphStyles,
        getParagraphClasses,
      };
    },
    data() {
      return {
        imagePrefix: conf.dwUrl,
        activeIndex: 0,
        mySwiper: null,
      };
    },
    computed: {
      getData(): ITabSliderItem[] {
        const { tabSliderItem } = this.componentProps;
        return tabSliderItem.map((item) => mapBaseComponentData(item));
      },
      getActiveIndex(): number {
        return this.activeIndex;
      },
    },
    methods: {
      imagePath(image: string, width: number, height: number) {
        return this.imagePrefix + ImageHandler(image, width, height);
      },
      slideChanged(swiper) {
        this.activeIndex = swiper.activeIndex;
      },
      chooseSlide(index) {
        if (this.mySwiper) {
          (this.mySwiper as any).slideTo(index);
        }
      },
      onSwiper(swiper) {
        this.mySwiper = swiper;
      },
    },
  });
