import { RouteLocationNormalized } from 'vue-router';
import store from '@/store/index';
import navBus from '@/utils/navBus';
import { getMessages } from '@/i18n';
import { isRouteBRP } from '../isRouteBrp';

export default (to: RouteLocationNormalized, from: RouteLocationNormalized): void => {
  // Til að stopp litabreytingu á banner þegar farið er á brp.is. Ekki fullkomin lausn þar sem logo dettur út í smá
  // stund þegar notandi fer á milli svæða á s4s.is eftirá
  // if (isRouteBRP(to)) {
  //   return;
  // }

  if (to.meta?.areaID && to.meta?.areaID !== from.meta?.areaID) {
    store.commit('website/applySettings', to.meta.areaID);
    navBus.updateArea(to.meta.areaID as number);
    getMessages(to.meta.areaID as number);
  } // else default
};
