import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-16f53d12"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "results__products"
}
const _hoisted_2 = {
  key: 0,
  class: "results__header"
}
const _hoisted_3 = {
  key: 1,
  class: "results__content"
}
const _hoisted_4 = { class: "results__header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchResultsProductSections = _resolveComponent("SearchResultsProductSections")!
  const _component_SearchProductList = _resolveComponent("SearchProductList")!
  const _component_SearchResultsContentList = _resolveComponent("SearchResultsContentList")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["results", { container: _ctx.sectionsWithResults.length > 1 }])
  }, [
    (_ctx.productResults !== null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.sectionsWithResults.length > 1)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_ctx.sectionsWithResults.length > 1)
                  ? (_openBlock(), _createElementBlock("h3", _hoisted_2, " Vöruleit (" + _toDisplayString(_ctx.totalProductCount) + ") ", 1 /* TEXT */))
                  : _createCommentVNode("v-if", true),
                _createVNode(_component_SearchResultsProductSections, { sections: _ctx.productResults }, null, 8 /* PROPS */, ["sections"])
              ], 64 /* STABLE_FRAGMENT */))
            : (_ctx.activeSection && _ctx.activeSectionShop)
              ? (_openBlock(), _createBlock(_component_SearchProductList, {
                  key: 1,
                  "product-list": _ctx.activeSection,
                  shop: _ctx.activeSectionShop
                }, null, 8 /* PROPS */, ["product-list", "shop"]))
              : _createCommentVNode("v-if", true)
        ]))
      : _createCommentVNode("v-if", true),
    (_ctx.contentResults !== null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("h3", _hoisted_4, "Efnisleit (" + _toDisplayString(_ctx.totalContentCount) + ")", 1 /* TEXT */),
          _createVNode(_component_SearchResultsContentList, {
            content: _ctx.contentResults.Results
          }, null, 8 /* PROPS */, ["content"])
        ]))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}