
  import { get, uniqBy } from 'lodash';
  import { defineComponent } from 'vue';
  import { ProductIcon } from '@/models/product/product';

  export default defineComponent({
    props: ['productInfo'],
    computed: {
      certifications(): ProductIcon[] {
        const certifications = [] as ProductIcon[];

        if (this.productInfo?.ProductCategories === undefined) {
          return certifications;
        }

        const clothingIcons = get(this.productInfo || {}, 'ProductCategories.Clothing.Fields.Ico_fot', {});
        const shoeIcons = get(this.productInfo || {}, 'ProductCategories.Shoe.Fields.Ico_skor', {});
        const buggyIcons = get(this.productInfo || {}, 'ProductCategories.Buggy_cars.Fields.ICObuggy', {});
        const snowmobileIcons = get(this.productInfo || {}, 'ProductCategories.Snowmobile.Fields.ICOSnjosledi', {});
        const bicycleIcons = get(this.productInfo || {}, 'ProductCategories.bicycle.Fields.ico_hjol', {});
        const saethoturIcons = get(this.productInfo || {}, 'ProductCategories.Saethotur.Fields.ICOSotur', {});

        const icons = {
          ...clothingIcons,
          ...shoeIcons,
          ...buggyIcons,
          ...snowmobileIcons,
          ...bicycleIcons,
          ...saethoturIcons,
        };

        const productIcons = this.getProductIconsFromCategoryField(icons);
        certifications.push(...productIcons);

        return uniqBy(certifications, 'Name');
      },
    },
    methods: {
      getProductIconsFromCategoryField(categoryField: any): ProductIcon[] {
        const certifications = [] as ProductIcon[];
        if (categoryField !== undefined && categoryField?.Value?.length > 0) {
          Object.values(categoryField.Value).forEach((certificationItem: any) => {
            const { Name, Value } = certificationItem;
            certifications.push({ Name, Value } as ProductIcon);
          });
        }

        return certifications;
      },
    },
  });
