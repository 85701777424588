import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-83c34508"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.route.meta.isExternal)
    ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        href: _ctx.route.path,
        class: "nav-link",
        target: "_blank"
      }, _toDisplayString(_ctx.route.meta.name), 9 /* TEXT, PROPS */, _hoisted_1))
    : (!_ctx.route.meta.isExternal && !_ctx.route.children.length)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 1,
          class: _normalizeClass(['nav-link', { 'nav-link--active': _ctx.isActive }]),
          to: _ctx.route.path,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClickLink(_ctx.route)))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.route.meta.name), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["class", "to"]))
      : (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass(['nav-link', { 'nav-link--active': _ctx.isActive }]),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onOpenDropdown(_ctx.route)))
        }, _toDisplayString(_ctx.route.meta.name), 3 /* TEXT, CLASS */))
}