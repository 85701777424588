import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f4733bf2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "swiper-item" }
const _hoisted_2 = {
  key: 0,
  class: "swiper-item__info"
}
const _hoisted_3 = { class: "swiper-item__title" }
const _hoisted_4 = { class: "swiper-item__text swiper-item__text--label" }
const _hoisted_5 = { class: "swiper-item__text" }
const _hoisted_6 = {
  key: 1,
  class: "swiper-item__media"
}
const _hoisted_7 = ["src", "alt"]
const _hoisted_8 = {
  key: 1,
  class: "swiper-item__video"
}
const _hoisted_9 = ["src"]
const _hoisted_10 = {
  key: 2,
  class: "swiper-item__video-full"
}
const _hoisted_11 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.title !== '')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.label), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.text), 1 /* TEXT */)
        ]))
      : _createCommentVNode("v-if", true),
    (_ctx.title !== '' && (_ctx.image !== '' || _ctx.video !== ''))
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          (_ctx.image !== '')
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.image,
                alt: _ctx.label,
                class: "swiper-item__image"
              }, null, 8 /* PROPS */, _hoisted_7))
            : _createCommentVNode("v-if", true),
          (_ctx.title !== '' && _ctx.image === '' && _ctx.video !== '')
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("iframe", {
                  src: `https://www.youtube.com/embed/${_ctx.video}`,
                  title: "YouTube video player",
                  frameborder: "0",
                  allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
                  allowfullscreen: ""
                }, null, 8 /* PROPS */, _hoisted_9)
              ]))
            : _createCommentVNode("v-if", true)
        ]))
      : _createCommentVNode("v-if", true),
    (_ctx.title === '' && _ctx.image === '' && _ctx.video !== '')
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("iframe", {
            src: `https://www.youtube.com/embed/${_ctx.video}`,
            title: "YouTube video player",
            frameborder: "0",
            allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
            allowfullscreen: ""
          }, null, 8 /* PROPS */, _hoisted_11)
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}