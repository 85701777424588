
  import { defineComponent } from 'vue';

  interface AccordionState {
    bodyVisible: boolean;
  }

  export default defineComponent({
    props: {
      /**
       * Use to toggle accordion body. Use this or slot provided function
       */
      showBody: {
        required: false,
        type: Boolean,
        default: false,
      },
      /**
       * Determine if the accordion should be animated
       */
      animated: {
        required: false,
        type: Boolean,
        default: true,
      },

      /**
       * Determine if the accordion body should be animated initially, if showBody is intialized as true
       */
      animateInitially: {
        required: false,
        type: Boolean,
        default: false,
      },

      /**
       * Specify the CSS transition duration
       */
      transitionDuration: {
        required: false,
        type: String,
        default: '.2s',
      },

      /**
       * Specify the CSS transition timing function
       */
      transitionTimingFunction: {
        required: false,
        type: String,
        default: 'ease-out',
      },
    },
    data() {
      return {
        bodyVisible: false,
      } as AccordionState;
    },
    watch: {
      showBody(value) {
        this.bodyVisible = value;
      },
    },
    created() {
      this.bodyVisible = this.showBody;
    },
    methods: {
      accordionToggle(): void {
        this.bodyVisible = !this.bodyVisible;
      },
      accordionShow(): void {
        this.bodyVisible = true;
      },
      accordionHide(): void {
        this.bodyVisible = false;
      },
      handleResize(): void {
        if (this.$refs.accordionBody) {
          const el = this.$refs.accordionBody as HTMLElement;
          el.style.maxHeight = `${el.scrollHeight}px`;
        }
      },
      onAnimBeforeEnter(el: HTMLElement): void {
        // eslint-disable-next-line no-param-reassign
        el.style.maxHeight = '0px';
      },
      onAnimEnter(el: HTMLElement): void {
        // eslint-disable-next-line no-param-reassign
        el.style.maxHeight = `${el.scrollHeight}px`;
      },
      onAnimAfterEnter(): void {
        window.addEventListener('resize', this.handleResize);
      },
      onAnimBeforeLeave(el: HTMLElement): void {
        window.removeEventListener('resize', this.handleResize);
        // eslint-disable-next-line no-param-reassign
        el.style.maxHeight = `${el.scrollHeight}px`;
      },
      onAnimLeave(el: HTMLElement): void {
        // eslint-disable-next-line no-param-reassign
        el.style.maxHeight = '0px';
      },
    },
  });
