import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78fbd254"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "name", "value", "maxlength"]
const _hoisted_3 = { class: "text-area__characters" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.getStyles),
    class: "text-area"
  }, [
    _createElementVNode("label", {
      class: "text-area__label",
      for: _ctx.id
    }, _toDisplayString(_ctx.label), 9 /* TEXT, PROPS */, _hoisted_1),
    _createElementVNode("textarea", {
      id: _ctx.id,
      class: "text-area__textarea",
      name: _ctx.id,
      cols: "30",
      rows: "10",
      value: _ctx.input,
      placeholder: "-",
      maxlength: _ctx.maxLength,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
    }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_2),
    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.getLetterCount), 1 /* TEXT */)
  ], 4 /* STYLE */))
}