
  import { defineComponent, PropType } from 'vue';
  import { SearchContent } from '@/models/search/searchModels';

  export default defineComponent({
    props: {
      content: {
        required: false,
        type: Array as PropType<Array<SearchContent>>,
        default: () => [],
      },
    },
    computed: {
      contentList(): Array<SearchContent> {
        return this.content.filter(({ Id }) => Id);
      },
    },
  });
