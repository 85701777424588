
  import { defineComponent, PropType } from 'vue';
  import { get } from 'lodash';
  import { Image } from '@/models/field';
  import useAuthenticateUser from '@/composables/useAuthenticateUser';
  import useNavigation from '@/composables/useNavigation';
  import { ImageHandler } from '@/utils/imageHelper';
  import conf from '@/config';

  interface ComponentProps {
    title: string;
    logoutText?: string;
    logoutIcon?: Array<Image>;
  }

  export default defineComponent({
    props: {
      /**
       * Component props loaded from DynamicWeb
       */
      componentProps: {
        type: Object as PropType<ComponentProps>,
        required: true,
      },
    },
    setup() {
      const { logoutUser } = useAuthenticateUser();
      const { navigateToPrimaryArea } = useNavigation();

      return {
        logoutUser,
        navigateToPrimaryArea,
      };
    },
    computed: {
      title(): string {
        return this.componentProps.title;
      },
      logoutText(): string {
        return get(this.componentProps, 'logoutText', 'Útskrá');
      },
      logoutIcon(): string | null {
        const { Path } = get(this.componentProps, 'logoutIcon.0', {});

        if (!Path) {
          return null;
        }

        return `${conf.dwUrl}${ImageHandler(Path, 24, 24)}`;
      },
    },
    methods: {
      onLogoutClick() {
        this.logoutUser().then(() => {
          this.navigateToPrimaryArea();
        });
      },
    },
  });
