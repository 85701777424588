import {
  AddressesResponse,
  AddressFormData,
  AddressPayload,
  AddAddressFormData,
  DeleteAddressFormData,
  MakeDefaultFormData,
  UpdateAddressFormData,
} from '@/models/address/address';
import DynamicWebApi from './dwApi';
import conf from '@/config';

export default class AddressApi {
  static async getUserAddresses(): Promise<AddressesResponse> {
    return this.getAddressList();
  }

  static async createUserAddress(userId: string, address: AddressPayload): Promise<AddressesResponse> {
    return this.createAddress({
      ...AddAddressFormData,
      UserId: userId,
      Description: address.address,
      Email: address.email,
      Address: address.address,
      Zip: address.zip,
      City: address.city,
      Country: address.country,
      CountryCode: 'IS',
      Phone: address.phoneNumber,
    } as AddressFormData);
  }

  static async updateUserAddress(userId: string, addressId: string, address: AddressPayload): Promise<AddressesResponse> {
    return this.createAddress({
      ...UpdateAddressFormData,
      UserId: userId,
      SelectedAddressId: addressId,
      Description: address.address,
      Email: address.email,
      Address: address.address,
      Zip: address.zip,
      City: address.city,
      Country: address.country,
      Phone: address.phoneNumber,
      Default: address.default,
    });
  }

  static async deleteUserAddress(userId: string, addressId: string): Promise<AddressesResponse> {
    return this.createAddress({
      ...DeleteAddressFormData,
      UserId: userId,
      SelectedAddressId: addressId,
    } as AddressFormData);
  }

  static async makeUserAddressDefault(userId: string, addressId: string): Promise<AddressesResponse> {
    return this.createAddress({
      ...MakeDefaultFormData,
      UserId: userId,
      SelectedAddressId: addressId,
    } as AddressFormData);
  }

  static async getAddressList(): Promise<AddressesResponse> {
    return DynamicWebApi.getFeed(`${conf.area}/usermanagement/manage-addresses`);
  }

  static async createAddress(address: AddressFormData): Promise<AddressesResponse> {
    return DynamicWebApi.postFeed(`${conf.area}/usermanagement/manage-addresses`, address);
  }
}
