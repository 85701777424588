import axios from 'axios';
import { Commit } from 'vuex';
import ProductAPI from '@/API/productApi';
import { MultiWrapOrderLine } from '@/models/store/cart';

export default {
  setSingleGiftWrap({ commit }: { commit: Commit }): void {
    commit('setSingleGiftWrap');
  },

  setMultiGiftWrap({ commit }: { commit: Commit }): void {
    commit('setMultiGiftWrap');
  },

  setSelectedGiftWrapCount({ commit }: { commit: Commit }, payload: number): void {
    commit('setSelectedGiftWrapCount', payload);
  },

  updateMultiGiftWrapOrderLine({ commit }: { commit: Commit }, orderLine: MultiWrapOrderLine): void {
    commit('updateMultiGiftWrapOrderLine', orderLine);
  },

  updateMultiGiftWrapPackage({ commit }: { commit: Commit }, orderLine: MultiWrapOrderLine): void {
    commit('updateMultiGiftWrapPackage', orderLine);
  },

  async getGiftWrapProduct({ commit }: { commit: Commit }): Promise<void> {
    const response = await ProductAPI.getProduct({
      productId: 'GJAFAINNPÖKKUN',
      cancelToken: axios.CancelToken.source().token,
    });

    commit('setGiftWrapProduct', response.data);
  },
};
