import { ComputedRef } from 'vue';
import { ImageDimensions } from '@/models/field';
import { ImageAndButton } from '@/models/item-types/base/boxes';
import { mapBaseComponentData } from '@/utils/dataMapper';
import useBaseBoxComponent from './useBaseBoxComponent';

interface ImageAndButtonBoxData {
  boxData: ImageAndButton;
  buttonData: unknown;
  getTitle: ComputedRef<string>;
  getStyles: ComputedRef<{
    '--background-color': string;
    '--title-color': string;
    '--background-image': string;
    '--background-position': string;
    '--text-color': string;
  }>;
  boxImageUrl: ComputedRef<string>;
}

export default function useImageAndButtonBox(props): ImageAndButtonBoxData {
  const imageDimensions = { width: 800, height: 800 } as ImageDimensions;
  const { data, getTitle, getStyles, boxImageUrl } = useBaseBoxComponent(props.box, imageDimensions);
  const boxData = data.value as ImageAndButton;
  const buttonData = mapBaseComponentData(boxData.button);
  return {
    boxData,
    buttonData,
    getTitle,
    getStyles,
    boxImageUrl,
  };
}
