
  import { defineComponent } from 'vue';
  import OutlineBox from './OutlineBox.vue';

  export default defineComponent({
    components: {
      OutlineBox,
    },
    props: {
      primary: {
        required: false,
        type: Boolean,
        default: false,
      },
      addIcon: {
        required: false,
        type: Boolean,
        default: false,
      },
    },
    emits: ['click'],
    computed: {
      iconClass(): string | null {
        if (this.addIcon) {
          return 'plusButton';
        }

        return null;
      },
    },
    methods: {
      onBoxClick(e: Event) {
        this.$emit('click', e);
      },
    },
  });
