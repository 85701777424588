
  import { defineComponent } from 'vue';

  import { get } from 'lodash';
  import gsap from 'gsap';

  export default defineComponent({
    props: {
      facet: {
        type: Object,
        required: true,
      },
      startOpen: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['selectedFilters'],
    data() {
      return {
        selectedFilters: [],
        filters: [],
        isOpen: false,
      };
    },
    computed: {
      getFacetGroupTitle(): string {
        if (this.facet.Name) {
          return this.facet.Name;
        }

        const label = this.facet.QueryParameter;
        return get(
          {
            Size: 'Stærð',
            Manufacturer: 'Vörumerki',
            BaseColor: 'Litur',
            Fabric: 'Efni',
            ProductCategory: 'Flokkur',
          },
          label,
          label,
        );
      },
    },
    watch: {
      selectedFilters(newVal) {
        this.$emit('selectedFilters', [this.facet.QueryParameter, newVal]);
      },
    },
    mounted() {
      if (this.facet.QueryParameter === 'Size') {
        this.isOpen = true;
      }
      this.filters = this.facet.Options.slice(0, 8);
      this.isOpen = this.startOpen;
    },
    methods: {
      showAllOptions() {
        this.filters = this.facet.Options;
      },
      toggleFacetGroup() {
        this.isOpen = !this.isOpen;
      },
      getCheckboxStyles(facet: any, option: any) {
        let style = '';
        if (facet.QueryParameter === 'BaseColor') {
          const color = option.Label.split(' ')[1];
          style = `background-color: ${color}`;
        }
        return style;
      },
      getCheckboxLabel(facet: any, option: any) {
        let label = option.Label;
        if (facet.QueryParameter === 'BaseColor') {
          const [name] = option.Label.split(' ');
          label = name;
        }
        return label;
      },
      beforeEnter(el) {
        gsap.set(el, {
          height: 0,
        });
      },
      enter(el, done) {
        gsap.to(el, {
          height: 'auto',
          duration: 0.3,
          onComplete: done,
        });
      },
      leave(el, done) {
        gsap.to(el, {
          height: 0,
          duration: 0.1,
          onComplete: done,
        });
      },
    },
  });
