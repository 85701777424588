import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-17ff595a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cartOverview_SideInfo_InfoBoxes" }
const _hoisted_2 = { class: "icon-24" }
const _hoisted_3 = ["xlink:href"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "cartOverview_SideInfo",
    style: _normalizeStyle({ 'grid-template-rows': `${$setup.props.sideInfoItems.length * 150}px` })
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.sideInfoItems, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(['cartOverview_SideInfo_InfoBoxes_Box', { outlet: item.isOutlet }]),
          key: item.title
        }, [
          (_openBlock(), _createElementBlock("svg", _hoisted_2, [
            _createElementVNode("use", {
              "xlink:href": item.iconXLink
            }, null, 8 /* PROPS */, _hoisted_3)
          ])),
          _createElementVNode("h2", null, _toDisplayString(item.title), 1 /* TEXT */),
          (item.subtitle)
            ? (_openBlock(), _createElementBlock("h3", _hoisted_4, _toDisplayString(item.subtitle), 1 /* TEXT */))
            : _createCommentVNode("v-if", true),
          _createElementVNode("p", null, _toDisplayString(item.description), 1 /* TEXT */),
          (item.isOutlet)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, "outlet vörur"))
            : _createCommentVNode("v-if", true)
        ], 2 /* CLASS */))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ], 4 /* STYLE */))
}