import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-187e1b76"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "productList_Content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchResultsProductSection = _resolveComponent("SearchResultsProductSection")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sections, (section, shop) => {
      return (_openBlock(), _createBlock(_component_SearchResultsProductSection, {
        key: shop,
        shop: shop,
        section: section
      }, null, 8 /* PROPS */, ["shop", "section"]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}