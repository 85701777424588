import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2fa642f3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal__content-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.active)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "modal",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleOutsideContentClick && _ctx.handleOutsideContentClick(...args)))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "default", { close: _ctx.close }, undefined, true)
        ])
      ]))
    : _createCommentVNode("v-if", true)
}