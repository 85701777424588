
  import { computed, defineComponent, ref, nextTick } from 'vue';
  import { useRouter } from 'vue-router';

  import useCart from '@/composables/useCart';
  import useCreditCard from '@/composables/paymentMethods/useCreditCard';

  import { PaymentMethod } from '@/models/cart/paymentMethod';

  import * as checkoutHelper from '@/components/cart/checkout/payment-method/checkoutHelper';

  import RadioButtonInput from '@/components/shared/inputs/RadioButtonInput.vue';
  import CartBackAndNextButtons from '@/components/cart/checkout/shared/CartBackAndNextButtons.vue';
  import TextInput from '@/components/shared/inputs/TextInput.vue';
  import Loader from '@/components/shared/Loader.vue';
  import CartSideOverviewOrderLines from '@/components/cart/checkout/shared/CartSideOverviewOrderLines.vue';
  import CreditCardPayment from '@/components/cart/checkout/payment-method/CreditCardPayment.vue';
  import CustomButton from '@/components/shared/Button.vue';
  import useRapydCheckoutHandler from '@/features/checkout/composables/useRapydCheckoutHandler';
  import { GA4E } from '@/ga4ecommerce/GA4E';
  import conf from '@/config';
  import GiftCardPayment from './GiftCardPayment.vue';
  import { useStore } from 'vuex';
  import { CartState } from '@/models/store/cart';
  import { CheckoutStepValidation } from '@/components/cart/checkout/composable/useCheckoutStepValidation';

  export default defineComponent({
    components: {
      CartSideOverviewOrderLines,
      CartBackAndNextButtons,
      RadioButtonInput,
      TextInput,
      Loader,
      CreditCardPayment,
      CustomButton,
      GiftCardPayment,
    },
    setup() {
      const router = useRouter();
      const store = useStore();
      const state = store.state.cart as CartState;
      const { paymentMethods, setPaymentMethod, postCheckoutData, getSiminnPayLoanInfo, isLoading, getDeliveryInfo, selectedShippingMethod, orderLines } =
        useCart();

      const { creditCardState, isCardValid } = useCreditCard();
      const { isContactInfoValid } = CheckoutStepValidation();
      const DroppLocationID = computed(() => (getDeliveryInfo.value?.DroppLocationID !== undefined ? getDeliveryInfo.value?.DroppLocationID : ''));
      const DroppLocationName = computed(() => (getDeliveryInfo.value?.DroppLocationName !== undefined ? getDeliveryInfo.value?.DroppLocationName : ''));
      const rapydPayErrorMessage = ref('');

      const giftCardPaymentStatus = ref('');
      const siminnPayPhoneNumber = ref('');
      const siminnPayError = ref('');
      const imagePrefix = conf.dwUrl;
      const customerInfo = computed(() => state.cart.CustomerInfo);
      const selectedPaymentMethodId = computed(() => {
        const paymentMethodsList = paymentMethods.value as PaymentMethod[];
        let paymentMethod = paymentMethodsList?.find((item) => item.Selected === true);
        if (paymentMethod?.Name?.startsWith('Gjafakort') && giftCardPaymentStatus.value === 'ShowCreditCardForm') {
          paymentMethod = paymentMethodsList?.find((item) => item.Name.startsWith('Greiða með greiðslukorti') || item.Name.startsWith('Greiðslukort'));
        }
        return paymentMethod?.PaymentMethodID;
      });

      const selectedPaymentMethod = computed((): PaymentMethod => {
        const paymentMethodsList = paymentMethods.value as PaymentMethod[];
        return paymentMethodsList?.find((item) => item.Selected === true) as PaymentMethod;
      });

      const goToReceipt = () => {
        router.push('/cart/cart-receipt');
      };

      const goBack = () => {
        router.push('/cart/delivery-options');
      };

      const submitPaymentForm = () => {
        // Þegar kallað er á submitPaymentForm á selectedPaymentMethod alltaf að hafa value
        GA4E.addPaymentInfo(orderLines.value, selectedPaymentMethod.value.PaymentMethodID);
        checkoutHelper.submitForm();
      };

      const cardValidCallback = async (status, data) => {
        if (status.message === 'OK' && data.Token.length > 0) {
          creditCardState.token = data.Token;
          await nextTick();
          submitPaymentForm();
        }
      };

      const { borgunLibraryUrl } = checkoutHelper.getBorgunInfo();
      checkoutHelper.appendBorgunScriptToHeader(borgunLibraryUrl);

      const { startCheckout: startRapydCheckout } = useRapydCheckoutHandler();

      const isCompletingRapydPayment = ref(false);
      const completeRapydPayment = async (paymentMethodId: string) => {
        rapydPayErrorMessage.value = '';
        isCompletingRapydPayment.value = true;
        try {
          GA4E.addPaymentInfo(orderLines.value, paymentMethodId);
          const result = await startRapydCheckout({
            DroppLocationID: DroppLocationID.value,
            DroppLocationName: DroppLocationName.value,
            EcomCartPaymethodID: paymentMethodId,
          });

          if (result.success && result.response?.rapydPaymentRedirectUrl) {
            window.location.assign(result.response.rapydPaymentRedirectUrl);
          } else {
            rapydPayErrorMessage.value = result.errorMessage;
          }
        } catch (error: any) {
          rapydPayErrorMessage.value = error?.message ?? 'Villa kom upp';
        } finally {
          isCompletingRapydPayment.value = false;
        }
      };

      const goForward = async () => {
        const paymentMethodName = selectedPaymentMethod?.value?.Name;
        if (paymentMethodName.startsWith('Greiða með greiðslukorti') || paymentMethodName.startsWith('Greiðslukort')) {
          await completeRapydPayment(selectedPaymentMethod.value.PaymentMethodID);
        } else if (paymentMethodName.includes('Gjafakort')) {
          if (giftCardPaymentStatus.value === 'ShowCreditCardForm') {
            const rapydPaymentMethod = paymentMethods.value.find((pv) => pv.Name?.toLowerCase()?.includes('greiðslukort'));
            if (rapydPaymentMethod?.PaymentMethodID) {
              await completeRapydPayment(rapydPaymentMethod.PaymentMethodID);
            } else {
              console.error('rapydPaymentMethod?.PaymentMethodID is falsey:', rapydPaymentMethod?.PaymentMethodID);
            }
          } else if (giftCardPaymentStatus.value === 'HasFundsAllowPurchase') {
            submitPaymentForm();
          }
        } else if (paymentMethodName.includes('Síminn')) {
          if (siminnPayPhoneNumber.value !== '' && siminnPayPhoneNumber.value.length === 7) {
            siminnPayError.value = '';
            GA4E.addPaymentInfo(orderLines.value, selectedPaymentMethod.value.PaymentMethodID);
            submitPaymentForm();
          } else {
            siminnPayError.value = 'Ógilt símanúmer';
          }
        } else if (paymentMethodName.includes('Netgiro')) {
          submitPaymentForm();
        } else if (paymentMethodName.startsWith('Test Payment')) {
          submitPaymentForm();
        }
      };

      return {
        goBack,
        goForward,
        paymentMethods,
        setPaymentMethod,
        getSiminnPayLoanInfo,
        postCheckoutData,
        goToReceipt,
        creditCardState,
        selectedPaymentMethod,
        selectedPaymentMethodId,
        isLoading,
        giftCardPaymentStatus,
        siminnPayPhoneNumber,
        imagePrefix,
        siminnPayError,
        getDeliveryInfo,
        DroppLocationID,
        DroppLocationName,
        selectedShippingMethod,
        rapydPayErrorMessage,
        isCompletingRapydPayment,
        customerInfo,
        isContactInfoValid,
        router,
      };
    },
    data() {
      return {
        formAction: `${conf.dwUrl}/${conf.area}/cart/checkouthandler`,
        giftCard: '',
        giftCardAmount: '',
        publicToken: '',
        borgunOneTimeToken: '',
      };
    },
    mounted() {
      if (!this.isContactInfoValid(this.customerInfo)) {
        this.router.replace('/cart/delivery-options');
      }
    },
  });
