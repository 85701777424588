
  import { shuffle } from 'lodash';
  import { defineComponent, PropType } from 'vue';

  export default defineComponent({
    props: {
      terms: {
        required: false,
        type: Array as PropType<Array<string>>,
        default: () => [],
      },
      maxTermsToDisplay: {
        required: false,
        type: Number,
        default: 6,
      },
    },
    computed: {
      shuffledTerms(): Array<string> {
        return shuffle(this.terms).slice(0, this.maxTermsToDisplay);
      },
    },
  });
