
  /* eslint-disable @typescript-eslint/no-explicit-any */
  import { defineComponent } from 'vue';

  export default defineComponent({
    props: {
      title: {
        type: String,
        required: true,
      },
      color: {
        type: String,
        required: true,
      },
      anchorId: {
        type: Number,
        required: true,
      },
    },
  });
