import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9a01316c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-nav" }
const _hoisted_2 = { class: "animation-wrapper animation-wrapper--logo" }
const _hoisted_3 = { class: "animation-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeLink = _resolveComponent("HomeLink")!
  const _component_NavigationLink = _resolveComponent("NavigationLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_Transition, {
        onBeforeEnter: _ctx.beforeEnterLogoOffset,
        onEnter: _ctx.enterLogoOffset
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createElementBlock("div", {
            key: _ctx.getHomeLink,
            class: "main-nav__home main-nav__home--offset"
          }, [
            (_ctx.getHomeLink !== undefined)
              ? (_openBlock(), _createBlock(_component_HomeLink, {
                  key: 0,
                  route: _ctx.getHomeLink,
                  image: _ctx.getLogo
                }, null, 8 /* PROPS */, ["route", "image"]))
              : _createCommentVNode("v-if", true)
          ]))
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onBeforeEnter", "onEnter"]),
      _createVNode(_Transition, {
        onBeforeEnter: _ctx.beforeEnterLogo,
        onEnter: _ctx.enterLogo
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createElementBlock("div", {
            key: _ctx.getHomeLink,
            class: "main-nav__home",
            style: _normalizeStyle({
            backgroundColor: _ctx.oldBgColor,
          })
          }, [
            (_ctx.getHomeLink !== undefined)
              ? (_openBlock(), _createBlock(_component_HomeLink, {
                  key: 0,
                  route: _ctx.getHomeLink,
                  image: _ctx.oldLogo
                }, null, 8 /* PROPS */, ["route", "image"]))
              : _createCommentVNode("v-if", true)
          ], 4 /* STYLE */))
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onBeforeEnter", "onEnter"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_Transition, {
        onBeforeEnter: _ctx.beforeEnterLinkOffset,
        onEnter: _ctx.enterLinkOffset
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createElementBlock("div", {
            key: _ctx.getLinksToShow,
            class: "main-nav__items main-nav__items--offset"
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getLinksToShow, (item, index) => {
              return (_openBlock(), _createBlock(_component_NavigationLink, {
                key: index,
                route: item,
                "active-route": _ctx.activeRoute,
                onOnLinkClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClickLink($event)))
              }, null, 8 /* PROPS */, ["route", "active-route"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]))
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onBeforeEnter", "onEnter"]),
      _createVNode(_Transition, {
        onBeforeEnter: _ctx.beforeEnterLink,
        onEnter: _ctx.enterLink,
        onAfterEnter: _ctx.afterEnterLink
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createElementBlock("div", {
            key: _ctx.getLinksToShow,
            class: "main-nav__items"
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.oldRoutes, (item, index) => {
              return (_openBlock(), _createBlock(_component_NavigationLink, {
                key: index,
                route: item
              }, null, 8 /* PROPS */, ["route"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]))
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onBeforeEnter", "onEnter", "onAfterEnter"])
    ])
  ]))
}