import { GetterTree } from 'vuex';
import * as GETTERS from '@/constants/store/auth/authGetterTypes';
import { AuthState } from '@/models/store/auth';
import { User } from '@/models/user/user';
import { Address } from '@/models/address/address';
import { Order } from '@/models/order/order';
import { WishlistProduct } from '@/models/wishlist/wishlist';

export default {
  [GETTERS.HAS_AUTHENTICATED_USER](state: AuthState): boolean {
    return state.user !== null && state.user?.information !== null && state.user?.information !== undefined;
  },
  [GETTERS.AUTHENTICATED_USER](state: AuthState): User | null {
    return state.user?.information || null;
  },
  [GETTERS.USER_ADDRESSES](state: AuthState): Address[] {
    return state.user?.addresses || [];
  },
  [GETTERS.USER_DEFAULT_ADDRESS](state: AuthState): Address | null {
    return (state.user?.addresses || []).find(({ Default }: Address) => Default) || null;
  },
  [GETTERS.USER_ORDERS](state: AuthState): Order[] {
    return state.user?.orders?.Items || [];
  },
  [GETTERS.USER_LAST_ORDER](state: AuthState): Order | null {
    if (state.user?.orders?.Items?.length === 0) {
      return null;
    }
    return state.user?.orders?.Items?.reduce((last: Order, next: Order) => (last.Date < next.Date ? next : last)) || null;
  },
  [GETTERS.USER_WISHLIST](state: AuthState): WishlistProduct[] | null {
    return state.user?.wishlist || null;
  },
  [GETTERS.USER_WISHLIST_ID](state: AuthState): number | null {
    return state.user?.wishlistId || null;
  },
} as GetterTree<AuthState, unknown>;
