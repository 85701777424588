import { ref } from 'vue';
import config from '@/config';

interface StartCheckoutResponse {
  rapydPaymentRedirectUrl: string;
}

interface StartCheckoutResult {
  success: boolean;
  errorMessage: string;
  response?: StartCheckoutResponse;
}

interface NameValuePair {
  name: string;
  value: string;
}

interface StartCheckoutInput {
  DroppLocationID?: string;
  DroppLocationName?: string;
  EcomCartPaymethodID?: string;
}

const formFields: NameValuePair[] = [
  { name: 'Action', value: 'Approve' },
  { name: 'EcomOrderCustomerAccepted', value: '1' },
  { name: 'EcomCartPaymethodID', value: 'PAY15' },
  { name: 'DroppLocationID', value: 'DroppLocationID' },
  { name: 'DroppLocationName', value: 'DroppLocationName' },
  { name: 'CartV2.GotoStep1', value: '1' },
  { name: 'Type', value: '30' },
];

const url = `${config.dwUrl}/${config.area === 'shared' ? 'shared' : 'brp-ellingsen'}/cart/jsoncheckouthandler`;

export default function useRapydCheckoutHandler() {
  const isLoading = ref<boolean>(false);

  const startCheckout = async (input: StartCheckoutInput) => {
    const fields = formFields.map((ff) => {
      const inputValue = input[ff.name];
      return inputValue ? { name: ff.name, value: inputValue } : { ...ff };
    });

    const formData = new FormData();
    fields.forEach(({ name, value }) => {
      formData.append(name, value);
    });

    // @ts-ignore
    const body = new URLSearchParams(formData).toString();
    const options: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body,
    };

    const result: StartCheckoutResult = {
      errorMessage: '',
      success: false,
    };

    try {
      isLoading.value = true;
      const response = await fetch(url, options);
      const startCheckoutResponse = (await response.json()) as StartCheckoutResponse;

      result.success = true;
      result.response = startCheckoutResponse;

      return result;
    } catch (error: any) {
      console.log(error);
      result.success = false;
      result.errorMessage = error?.message ?? 'Óþekkt villa kom upp í greiðsluferli';

      return result;
    } finally {
      isLoading.value = false;
    }
  };

  return {
    startCheckout,
    isLoading,
  };
}
