import paymentMethods from './paymentMethods';
import shippingMethod from './shippingMethod';
import contactInfo from './contactInfo';
import deliveryInfo from './deliveryInfo';
import orderLines from './orderLines/orderLines';
import postCodes from './postCodes';
import giftWrap from './giftWrap';
import cart from './cart';

export default {
  ...orderLines,
  ...postCodes,
  ...contactInfo,
  ...deliveryInfo,
  ...giftWrap,
  ...cart,
  ...shippingMethod,
  ...paymentMethods,
};
