
  /* eslint-disable @typescript-eslint/no-explicit-any */
  import { defineComponent, PropType } from 'vue';
  import { ItemType } from '@/models/field';
  import { mapBaseComponentData } from '@/utils/dataMapper';

  import useParagraph from '@/composables/useParagraph';

  import DropshadowButton from '@/components/itemTypes/DropshadowButton/DropshadowButton.vue';

  interface IButtonList {
    dropshadowButtonList: ItemType[];
  }

  export default defineComponent({
    components: {
      DropshadowButton,
    },
    props: {
      componentProps: {
        type: Object as PropType<IButtonList>,
        required: true,
      },
    },
    setup(props) {
      const { getParagraphStyles, getParagraphClasses } = useParagraph(props.componentProps);
      return {
        getParagraphStyles,
        getParagraphClasses,
      };
    },
    data() {
      return {
        showBodyAll: false,
        buttons: [] as IButtonList[],
      };
    },
    computed: {
      getData(): IButtonList[] {
        const { dropshadowButtonList } = this.componentProps;
        return dropshadowButtonList.map((item) => mapBaseComponentData(item));
      },
      isDarkMode(): boolean {
        return this.$route.meta.toggleDarkMode as boolean;
      },
    },
    methods: {
      toggleAll(): void {
        this.showBodyAll = !this.showBodyAll;
      },
    },
  });
