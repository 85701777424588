import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';
import { get } from 'lodash';
import { Field } from '@/models/field';
import store from '@/store/index';
import { HAS_AUTHENTICATED_USER } from '@/constants/store/auth/authGetterTypes';
import { SET_DISPLAY_LOGIN_FORM } from '@/constants/store/auth/authMutationTypes';

const authFieldName = 'Auth';

export default (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): void => {
  const areaSettings = to.meta?.areaSettings as { Fields?: Array<Field> };

  if (areaSettings) {
    const authField = (areaSettings.Fields || []).find(({ Name }) => Name === authFieldName);

    if (authField && get(authField, 'Value', false) && !store.getters[`auth/${HAS_AUTHENTICATED_USER}`]) {
      store.commit(`auth/${SET_DISPLAY_LOGIN_FORM}`, true);
      next(false);
    } else {
      next();
    }
  } else {
    next();
  }
};
