
  /* eslint-disable @typescript-eslint/no-explicit-any */
  import { defineComponent } from 'vue';

  declare const window: any;

  export default defineComponent({
    mounted() {
      const externalScript = document.createElement('script');
      externalScript.setAttribute('src', '//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js');
      document.head.appendChild(externalScript);
    },
    methods: {
      onStart() {
        window.fnames = [];
        window.ftypes = [];
        window.fnames[0] = 'EMAIL';
        window.ftypes[0] = 'email';
        window.fnames[1] = 'FNAME';
        window.ftypes[1] = 'text';
        window.fnames[2] = 'LNAME';
        window.ftypes[2] = 'text';
        window.fnames[5] = 'BIRTHDAY';
        window.ftypes[5] = 'birthday';
        window.fnames[6] = 'MMERGE6';
        window.ftypes[6] = 'text';
      },
    },
  });
