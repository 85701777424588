import axios from 'axios';
import { AdditionalField, Booking, BookingRequest, ServiceUnavailableError } from '@/components/itemTypes/paragraphs/WorkshopRegistrationForm/models';

function isError(val: unknown): val is ServiceUnavailableError {
  const error = val as ServiceUnavailableError;
  return error?.data?.StatusCode === 503 ?? false;
}

export default class SimplyBookMeService {
  static url(relative?: string): string {
    return [process.env.VUE_APP_DW_URL, 'api/simplybookme', relative].join('/');
  }

  static async getAdditionalFields(): Promise<AdditionalField[]> {
    let additionalFields: AdditionalField[] = [];
    try {
      const response = await axios.get(SimplyBookMeService.url('additional-fields'), { withCredentials: true });
      if (!isError(response)) additionalFields = response.data;
      else throw new Error();
    } catch (error) {
      console.error('Error retrieving additional fields from SimplyBook.me, the issue could be due to missing credentials in the backend API');
      console.error(error);
      throw new Error();
    }
    return additionalFields;
  }

  static async getBookings(query = ''): Promise<Booking[]> {
    let bookings: Booking[] = [];
    const url = SimplyBookMeService.url('bookings');
    const path = `${url}?${query}`;
    try {
      const response = await axios.get(path, { withCredentials: true });
      if (!isError(response)) bookings = response.data;
      else throw new Error();
    } catch (error) {
      console.error('Error retrieving bookings from SimplyBook.me');
      console.error(error);
      throw new Error();
    }
    return bookings;
  }

  static async createBooking(request: BookingRequest): Promise<Booking> {
    let booking: Booking = {} as Booking;
    if (process.env.VUE_APP_ENVIRONMENT === 'PROD') {
      try {
        const response = await axios.post(SimplyBookMeService.url('bookings'), request, {
          withCredentials: true,
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        });
        // eslint-disable-next-line prefer-destructuring
        if (!isError(response)) booking = response.data[0];
        else throw new Error();
      } catch (error) {
        console.error('Error creating booking with SimplyBook.me');
        console.error(error);
        throw new Error();
      }
    } else {
      console.info('Cannot create bookings on dev and staging since the account has limited bookings per month');
      console.info(request);
    }
    return booking;
  }
}
