
  /* eslint-disable @typescript-eslint/no-explicit-any */
  import { defineComponent, PropType } from 'vue';
  import { Field, Image, Color, ImageDimensions } from '@/models/field';
  import { mapBaseComponentData } from '@/utils/dataMapper';
  import { getBackgroundImageStyles, getBackgroundImageValue } from '@/utils/imageHelper';

  interface ImageAndTitle {
    image: Image;
    title: string;
    subtitle: string;
    titleColor: Color;
  }

  export default defineComponent({
    props: {
      banner: {
        type: Object as PropType<Field>,
        required: true,
      },
    },
    computed: {
      getData(): ImageAndTitle {
        const { banner } = this.$props;
        const data = mapBaseComponentData(banner) as ImageAndTitle;
        return data;
      },
      getContainerStyles(): unknown {
        const imageDimensions = { width: 1500, height: 700 } as ImageDimensions;
        return getBackgroundImageStyles(this.getData.image, imageDimensions);
      },
      getTitleStyles(): unknown {
        return {
          color: this.getData.titleColor.Hex,
        };
      },
      imageSrc(): string {
        return getBackgroundImageValue(this.getData.image, { width: 1500, height: 700 } as ImageDimensions);
      },
    },
  });
