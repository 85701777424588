import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d7ce4ba2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = ["value"]
const _hoisted_3 = {
  key: 1,
  class: "text-input__icon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.getStyles),
    class: _normalizeClass(['text-input', { 'text-input--error': _ctx.hasErrors }])
  }, [
    (_ctx.hasLabel)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: _normalizeClass(["text-input__label", { error: _ctx.hasErrors }]),
          for: _ctx.inputHTMLAttributes.id
        }, _toDisplayString(_ctx.label), 11 /* TEXT, CLASS, PROPS */, _hoisted_1))
      : _createCommentVNode("v-if", true),
    _createElementVNode("input", _mergeProps({
      class: [
        'text-input__input',
        {
          'text-input__input--error': _ctx.hasErrors,
          'text-input__input--icon': _ctx.hasIcon,
        },
      ],
      value: _ctx.value
    }, _ctx.inputHTMLAttributes, {
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
    }), null, 16 /* FULL_PROPS */, _hoisted_2),
    (_ctx.hasIcon)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
          _renderSlot(_ctx.$slots, "icon", {}, () => [
            _createVNode(_component_Icon, { icon: _ctx.icon }, null, 8 /* PROPS */, ["icon"])
          ], true)
        ]))
      : _createCommentVNode("v-if", true),
    _renderSlot(_ctx.$slots, "error", {}, undefined, true)
  ], 6 /* CLASS, STYLE */))
}