import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0976a48b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container user" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserInformationForm = _resolveComponent("UserInformationForm")!
  const _component_UserContentCard = _resolveComponent("UserContentCard")!
  const _component_UserPasswordForm = _resolveComponent("UserPasswordForm")!
  const _component_OutlineBox = _resolveComponent("OutlineBox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_UserContentCard, {
      class: "user__card",
      "bg-color-class": "lightGrey"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_UserInformationForm, {
          "old-value": _ctx.authenticatedUser,
          submitting: _ctx.profileSubmitting,
          onPassword: _ctx.onUserPasswordEdit,
          onSubmit: _ctx.onUserInfoFormSubmit
        }, null, 8 /* PROPS */, ["old-value", "submitting", "onPassword", "onSubmit"])
      ]),
      _: 1 /* STABLE */
    }),
    (_ctx.passwordForm)
      ? (_openBlock(), _createBlock(_component_OutlineBox, {
          key: 0,
          class: "user__card",
          primary: "",
          "text-primary": false,
          closable: "",
          onClose: _ctx.onPasswordClose
        }, {
          default: _withCtx(() => [
            _createVNode(_component_UserContentCard, null, {
              default: _withCtx(() => [
                _createVNode(_component_UserPasswordForm, {
                  username: _ctx.authenticatedUser.UserName,
                  submitting: _ctx.passwordSubmitting,
                  onSubmit: _ctx.onPasswordFormSubmit
                }, null, 8 /* PROPS */, ["username", "submitting", "onSubmit"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClose"]))
      : _createCommentVNode("v-if", true)
  ]))
}