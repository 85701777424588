
  import { defineComponent } from 'vue';

  export default defineComponent({
    props: {
      /**
       * Sepcify top level tag. Default 'div'
       */
      tag: {
        type: String,
        required: false,
        default: 'div',
      },
      /**
       * Add primary color styling
       */
      primary: {
        required: false,
        type: Boolean,
        default: false,
      },
      /**
       * Add primary color text styling (defaults to true if primary is true)
       */
      textPrimary: {
        required: false,
        type: Boolean,
        default: false,
      },
      /**
       * Make the element clickable (emits event and styles)
       */
      clickable: {
        required: false,
        type: Boolean,
        default: false,
      },
      /**
       * Add close button
       */
      closable: {
        required: false,
        type: Boolean,
        default: false,
      },
    },
    emits: ['click', 'close'],
    computed: {
      hasPrimaryText(): boolean {
        return this.textPrimary || this.primary;
      },
    },
    methods: {
      onClick(e: Event) {
        if (this.clickable) {
          this.$emit('click', e);
        }
      },
      onCloseClick() {
        if (this.closable) {
          this.$emit('close');
        }
      },
    },
  });
