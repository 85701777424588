import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OutlineBox = _resolveComponent("OutlineBox")!

  return (_openBlock(), _createBlock(_component_OutlineBox, {
    tag: "button",
    primary: _ctx.primary,
    clickable: "",
    onClick: _ctx.onBoxClick
  }, {
    default: _withCtx(() => [
      (_ctx.iconClass)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass(["buttonIcon", `${_ctx.primary ? 'primary' : ''} ${_ctx.iconClass}`])
          }, null, 2 /* CLASS */))
        : _createCommentVNode("v-if", true),
      _createElementVNode("span", {
        class: _normalizeClass(["button__text", { primary: _ctx.primary }])
      }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 2 /* CLASS */)
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["primary", "onClick"]))
}