/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { merge } from 'lodash';
import ProductAPI from '@/API/productApi';
import { ProductListPayload } from '@/models/productList/productListPayloadModels';
import { SearchParams } from '@/models/search/searchModels';

export interface ProductSearch {
  searchForProducts: (searchValue: string, params: SearchParams, query: string) => Promise<any>;
}

export default function useProductSearch(searchKw = 'Leit'): ProductSearch {
  const searchForProducts = async (searchValue: string, params: SearchParams, query: string): Promise<any> => {
    const payload = {
      cancelToken: axios.CancelToken.source().token,
      params: merge(
        {
          groupID: '',
          brandName: '',
          showOnlyOffers: false,
          filterQuery: `&${searchKw}=${searchValue}${query.length > 0 ? `&${query}` : ''}`,
          pageNumber: '1',
          pageSize: '12',
        },
        params,
      ),
    } as ProductListPayload;

    const response = await ProductAPI.getProductList(payload);

    if (response.status === 204) {
      return {};
    }

    return response.data || {};
  };

  return {
    searchForProducts,
  } as ProductSearch;
}
