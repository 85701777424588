import { MutationTree } from 'vuex';
import { filter } from 'lodash';
import * as MUTATIONS from '@/constants/store/auth/authMutationTypes';
import { User } from '@/models/user/user';
import { AuthState, AuthUser } from '@/models/store/auth';
import { Address } from '@/models/address/address';
import { OrderPagination } from '@/models/order/order';
import { WishlistProduct } from '@/models/wishlist/wishlist';

export default {
  [MUTATIONS.SET_DISPLAY_LOGIN_FORM](state: AuthState, displayLoginForm: boolean) {
    state.displayLoginForm = displayLoginForm;
  },
  [MUTATIONS.SET_AUTHENTICATED_USER](state: AuthState, user: User) {
    state.user = { ...state.user, information: user } as AuthUser;
  },
  [MUTATIONS.CLEAR_AUTHENTICATED_USER](state: AuthState) {
    state.user = null;
  },
  [MUTATIONS.SET_USER_ADDRESSES](state, addresses: Address[]) {
    state.user = { ...state.user, addresses } as AuthUser;
  },
  [MUTATIONS.SET_USER_ORDERS](state, orders: OrderPagination) {
    state.user = { ...state.user, orders } as AuthUser;
  },
  [MUTATIONS.SET_USER_WISHLIST](state, wishlist: WishlistProduct[]) {
    state.user = { ...state.user, wishlist } as AuthUser;
  },
  [MUTATIONS.SET_USER_WISHLIST_ID](state, wishlistId: number) {
    state.user = { ...state.user, wishlistId } as AuthUser;
  },
  [MUTATIONS.DELETE_PRODUCT_FROM_WISHLIST](state, id: string) {
    const wishlist = filter(state.user?.wishlist, (product) => product.Id !== id);
    state.user = { ...state.user, wishlist } as AuthUser;
  },
  [MUTATIONS.DELETE_USER_WISHLIST](state: AuthState) {
    state.user = { ...state.user, wishlist: null } as AuthUser;
  },
  [MUTATIONS.UPDATE_USER](state, userPartial: User) {
    const information = { ...(state.user?.information || {}), ...userPartial } as User;
    state.user = { ...state.user, information } as AuthUser;
  },
} as MutationTree<AuthState>;
