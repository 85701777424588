import { Commit } from 'vuex';
import { postboxPayload } from '@/models/cart/shippingMethod';
import { CartState, PartialUpdatePayload } from '@/models/store/cart';
import CartAPI from '@/API/cartApi';
import { isCartErrorResponse } from '@/utils/model_checkers/responses';

export default {
  selectShippingMethod({ commit }: { commit: Commit }, payload: string): void {
    commit('selectShippingMethod', payload);
  },
  selectPostBox({ commit }: { commit: Commit }, payload: postboxPayload): void {
    commit('selectShippingMethod', payload);
  },
  async postShippingMethod(actionParams: { commit: Commit; state: CartState }): Promise<void> {
    const { commit, state } = actionParams;
    const formData = new FormData();
    formData.append('EcomCartShippingMethodID', state.cart.selectedShippingMethod);
    const response = await CartAPI.postFormData(formData);
    if (isCartErrorResponse(response)) {
      console.error(`failed calling postPaymentMethod from cart`, response.data);
    }
    commit('partialCartUpdate', {
      cart: response.data,
      fields: ['DeliveryInfo', 'ShippingFee', 'General'],
    });
  },
};
