import { reactive } from 'vue';
import * as CheckoutHelper from '@/components/cart/checkout/payment-method/checkoutHelper';

interface CreditCardState {
  cardHolderName: string;
  cardPan: string;
  cardExpMonth: string;
  cardExpYear: string;
  cardCVC: string;
  errorMessage: string;
  token: string;
}

interface ReturnValue {
  creditCardState: CreditCardState;
  isCardValid: (callback, publicToken) => Promise<string>;
  resetState: () => void;
}

const creditCardState: CreditCardState = reactive({
  cardHolderName: '',
  cardPan: '',
  cardExpMonth: '',
  cardExpYear: '',
  cardCVC: '',
  errorMessage: '',
  token: '',
});

export default function useCreditCard(): ReturnValue {
  async function isCardValid(callback, publicToken): Promise<string> {
    if (creditCardState.cardExpYear.length === 4) {
      creditCardState.cardExpYear = creditCardState.cardExpYear.slice(-2);
    }

    const borgunResponse = await CheckoutHelper.default.borgunValidation(
      creditCardState.cardPan,
      creditCardState.cardExpMonth,
      creditCardState.cardExpYear,
      creditCardState.cardCVC,
      publicToken,
      callback,
    );
    const { borgunErrorMessage, isBorgunErrorMessage, cardToken } = borgunResponse;

    if (isBorgunErrorMessage) {
      creditCardState.errorMessage = borgunErrorMessage;
    } else {
      creditCardState.errorMessage = '';
    }

    return cardToken;
  }

  function resetState() {
    creditCardState.cardHolderName = '';
    creditCardState.cardPan = '';
    creditCardState.cardExpMonth = '';
    creditCardState.cardExpYear = '';
    creditCardState.cardCVC = '';
    creditCardState.errorMessage = '';
    creditCardState.token = '';
  }

  return {
    creditCardState,
    isCardValid,
    resetState,
  };
}
