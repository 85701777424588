import { Image, ImageDimensions } from '@/models/field';
import conf from '@/config';

function transformFocalX(focalX: number | undefined): string {
  if (focalX === undefined) return `${50}%`;
  return `${50 + focalX / 2}%`;
}

function transformFocalY(focalY: number | undefined): string {
  if (focalY === undefined) return `${50}%`;
  return `${50 - focalY / 2}%`;
}

export function getBackgroundImagePositionFromFocalPoints(image: Image): string {
  if (image.FocalX !== undefined && image.FocalY !== undefined) {
    return `${transformFocalX(image.FocalX)} ${transformFocalY(image.FocalY)}`;
  }
  return '';
}

export function UrlContainsQuery(url: string): boolean {
  return url.indexOf('?') > -1;
}

export function AddParameterToQuery(url: string, parameter: string): string {
  let path = url;

  if (!UrlContainsQuery(path)) {
    path += '?';
  } else {
    path += '&';
  }

  path += parameter;
  return path;
}

export function ImageHandler(image: string, width: number, height = 0): string {
  if (image.endsWith('.gif')) {
    return image;
  }

  let path = '/Admin/Public/GetImage.ashx';

  if (width > 0) {
    const parameter = `Width=${width}`;
    path = AddParameterToQuery(path, parameter);
  }

  if (height > 0) {
    const parameter = `Height=${height}`;
    path = AddParameterToQuery(path, parameter);
  }

  path = AddParameterToQuery(path, 'Compression=99');
  path = AddParameterToQuery(path, 'Quality=99');
  path = AddParameterToQuery(path, `Image=${image}`);

  return path;
}

export function getBackgroundImageValue(image: Image, imageDimensions?: ImageDimensions): string {
  if (image.Path !== undefined && imageDimensions !== undefined) {
    const { width, height } = imageDimensions;
    return `${conf.dwUrl}${ImageHandler(image.Path, width, height)}`;
  }
  return '';
}

export function getBackgroundImageUrlValue(image: Image, imageDimensions?: ImageDimensions): string {
  const val = getBackgroundImageValue(image, imageDimensions);
  if (val.length <= 0) {
    return val;
  }
  return `url('${val}')`;
}

export function getBackgroundImageStyles(image: Image, imageDimensions?: ImageDimensions): { backgroundImage: string; backgroundPosition: string } {
  return {
    backgroundImage: getBackgroundImageUrlValue(image, imageDimensions),
    backgroundPosition: getBackgroundImagePositionFromFocalPoints(image),
  };
}
