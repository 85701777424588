import { computed, ComputedRef } from 'vue';
import { useStore } from 'vuex';
import { CreateUserPayload, User } from '@/models/user/user';
import { AUTHENTICATED_USER, HAS_AUTHENTICATED_USER } from '@/constants/store/auth/authGetterTypes';
import { SET_DISPLAY_LOGIN_FORM } from '@/constants/store/auth/authMutationTypes';
import * as ACTIONS from '@/constants/store/auth/authActionTypes';
import { LoginPayload } from '@/models/store/auth';
import { PasswordRecoverRequestResponse, UserCreatedResponse } from '@/models/user/responseModels';

interface AuthenticateUser {
  hasAuthenticatedUser: ComputedRef<boolean>;
  authenticatedUser: ComputedRef<User | null>;
  showLoginForm: () => void;
  hideLoginForm: () => void;
  loginUser: (email: string, password: string) => Promise<User>;
  logoutUser: () => Promise<void>;
  loadUser: () => Promise<unknown>;
  sendpasswordRecoveryEmail: (email: string) => Promise<PasswordRecoverRequestResponse>;
  passwordRecoveryInfo: (token: string) => Promise<PasswordRecoverRequestResponse>;
  changePassword: (password: string, confirmPassword: string, token: string) => Promise<PasswordRecoverRequestResponse>;
  createUser: (formFields: CreateUserPayload) => Promise<UserCreatedResponse>;
  isAuthenticated: () => Promise<unknown>;
}

export default function useAuthenticateUser(): AuthenticateUser {
  const store = useStore();

  const hasAuthenticatedUser = computed(() => store.getters[`auth/${HAS_AUTHENTICATED_USER}`]);
  const authenticatedUser = computed(() => store.getters[`auth/${AUTHENTICATED_USER}`]);
  const showLoginForm = (): void => store.commit(`auth/${SET_DISPLAY_LOGIN_FORM}`, true);
  const hideLoginForm = (): void => store.commit(`auth/${SET_DISPLAY_LOGIN_FORM}`, false);
  const loginUser = async (email: string, password: string): Promise<User> =>
    store.dispatch(`auth/${ACTIONS.AUTH_LOGIN}`, {
      username: email,
      password,
    } as LoginPayload);
  const logoutUser = async (): Promise<void> => store.dispatch(`auth/${ACTIONS.AUTH_LOGOUT}`);
  const loadUser = async (): Promise<unknown> => store.dispatch(`auth/${ACTIONS.GET_AUTH_USER_DATA}`);

  const sendpasswordRecoveryEmail = async (email: string): Promise<PasswordRecoverRequestResponse> =>
    store.dispatch(`auth/${ACTIONS.PASSWORD_RECOVERY}`, email);
  const passwordRecoveryInfo = async (token: string): Promise<PasswordRecoverRequestResponse> =>
    store.dispatch(`auth/${ACTIONS.PASSWORD_RECOVERY_INFO}`, token);
  const changePassword = async (password: string, confirmPassword: string, token: string): Promise<PasswordRecoverRequestResponse> =>
    store.dispatch(`auth/${ACTIONS.CHANGE_PASSWORD}`, { password, confirmPassword, token });

  const createUser = async (formFields: CreateUserPayload): Promise<UserCreatedResponse> => store.dispatch(`auth/${ACTIONS.CREATE_USER}`, formFields);

  const isAuthenticated = async (): Promise<unknown> => store.dispatch(`auth/${ACTIONS.IS_AUTHENTICATED}`);

  return {
    hasAuthenticatedUser,
    authenticatedUser,
    showLoginForm,
    hideLoginForm,
    loginUser,
    logoutUser,
    loadUser,
    sendpasswordRecoveryEmail,
    passwordRecoveryInfo,
    changePassword,
    createUser,
    isAuthenticated,
  } as AuthenticateUser;
}
