
  import { defineComponent } from 'vue';

  import { ImageHandler } from '@/utils/imageHelper';
  import Icon from './Icon.vue';
  import conf from '@/config';

  export default defineComponent({
    components: { Icon },
    props: {
      imageToZoom: {
        required: true,
        type: String,
        default: '',
      },
      open: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['close-popup'],
    data() {
      return {
        imgScale: 1,
        imagePrefix: conf.dwUrl,
        positions: {
          clientX: 0,
          clientY: 0,
          movementX: 0,
          movementY: 0,
        },
      };
    },
    watch: {
      open(val) {
        if (val) {
          // Remove scroll from html tag
          document.getElementsByTagName('html')[0].style.overflowY = 'hidden';
        } else {
          document.getElementsByTagName('html')[0].style.overflowY = 'initial';
        }
      },
    },
    unmounted() {
      document.getElementsByTagName('html')[0].style.overflowY = 'initial';
    },
    methods: {
      zoomImage(event) {
        if (event.deltaY < 0 && this.imgScale <= 3) {
          this.imgScale += 0.2;
        } else if (event.deltaY > 0 && this.imgScale > 1) {
          this.imgScale -= 0.2;
        }
      },
      imagePath(image: string, width: number, height: number) {
        return this.imagePrefix + ImageHandler(image, width, height);
      },
      closePopup() {
        // Add back scroll for html tag
        document.getElementsByTagName('html')[0].style.overflowY = 'initial';
        this.$emit('close-popup');
      },
      dragMouseDown(event) {
        event.preventDefault();
        // get the mouse cursor position at startup:
        this.positions.clientX = event.clientX;
        this.positions.clientY = event.clientY;
        document.onmousemove = this.elementDrag;
        document.onmouseup = this.closeDragElement;
      },
      elementDrag(event) {
        event.preventDefault();
        this.positions.movementX = this.positions.clientX - event.clientX;
        this.positions.movementY = this.positions.clientY - event.clientY;
        this.positions.clientX = event.clientX;
        this.positions.clientY = event.clientY;
        // set the element's new position:
        (this.$refs.draggableContainer as HTMLElement).style.top = `${(this.$refs.draggableContainer as HTMLElement).offsetTop - this.positions.movementY}px`;
        (this.$refs.draggableContainer as HTMLElement).style.left = `${(this.$refs.draggableContainer as HTMLElement).offsetLeft - this.positions.movementX}px`;
      },
      closeDragElement() {
        document.onmouseup = null;
        document.onmousemove = null;
      },
    },
  });
