import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "tabContent" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(["tabHeader", $setup.tabHeaderClass])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.titles, (title) => {
        return (_openBlock(), _createElementBlock("p", {
          key: title,
          class: _normalizeClass(["tabHeader_Item noselect", { active: $setup.selectedTitle === title }]),
          onClick: ($event: any) => ($setup.onTabClick(title))
        }, _toDisplayString(title), 11 /* TEXT, CLASS, PROPS */, _hoisted_1))
      }), 128 /* KEYED_FRAGMENT */))
    ], 2 /* CLASS */),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}