
  import { defineComponent } from 'vue';
  import { get, pick } from 'lodash';
  import UserWishlistTable from '@/components/user/wishlist/UserWishlistTable.vue';
  import UserWishlistMobileMenu from '@/components/user/wishlist/UserWishlistMobileMenu.vue';
  import useManageUserWishlist from '@/composables/useManageUserWishlist';
  import { SearchParams } from '@/models/search/searchModels';
  import useWebsiteSettings from '@/composables/useWebsiteSettings';
  import { WishlistProduct } from '@/models/wishlist/wishlist';
  import useLiveProductPrices from '@/composables/useLiveProductPrices';
  import { Product, VariantInfo } from '@/models/product/product';
  import Icon from '@/components/shared/Icon.vue';

  interface WishlistViewState {
    loading: boolean;
    wishlistProductIds: string[];
    wishlist: WishlistProduct[] | null;
    productLivePrices: {
      [key: string]: {
        Price: string;
        PriceBeforeDiscount: string;
      };
    };
    relatedProducts: {
      [key: string]: {
        RelatedProducts: any[];
        VariantInfo: VariantInfo;
      };
    };
    pageSize: number;
    pageCount: number;
    currentPage: number;
    isFetchMore: boolean;
    menuProduct: WishlistProduct | null;
  }

  export default defineComponent({
    components: {
      UserWishlistTable,
      Icon,
      UserWishlistMobileMenu,
    },
    setup() {
      const { getWishlistProductIds, getUserWishlist, userWishlistId, userWishlist, removeWishlistProduct, deleteWishlist } = useManageUserWishlist();

      const { getLivePricesForProducts, getProductListRelatedProductsLivePrices } = useLiveProductPrices();

      const { websiteSettings } = useWebsiteSettings();

      return {
        getWishlistProductIds,
        userWishlistId,
        userWishlist,
        getUserWishlist,
        removeWishlistProduct,
        deleteWishlist,
        getLivePricesForProducts,
        getProductListRelatedProductsLivePrices,
        websiteSettings,
      };
    },
    data(): WishlistViewState {
      return {
        loading: false,
        wishlistProductIds: [],
        wishlist: null,
        productLivePrices: {},
        relatedProducts: {},
        pageSize: 10,
        pageCount: 1,
        currentPage: 1,
        isFetchMore: false,
        menuProduct: null,
      };
    },
    computed: {
      productResultsWithLivePrices(): WishlistProduct[] {
        if (this.userWishlist === null) {
          return [];
        }

        return this.userWishlist.map((product) => ({
          ...product,
          ...pick(get(this.productLivePrices, product?.Id || '', {}), ['Price', 'PriceBeforeDiscount', 'StockLevel', 'SoldOut']),
          ...pick(get(this.relatedProducts, product?.Id || '', {}), ['RelatedProducts', 'VariantInfo', 'BaseColor']),
        }));
      },
    },
    created() {
      this.getWishlist();
    },
    methods: {
      async getWishlist() {
        const searchParams = this.searchParams();
        this.wishlistProductIds = await this.getWishlistProductIds();

        if (this.wishlistProductIds.length > 0) {
          const isFirstPage = true;
          this.pageCount = Math.ceil(this.wishlistProductIds.length / this.pageSize);
          const productIds = this.wishlistProductIds.slice(0, this.pageSize);
          await this.getUserWishlist(searchParams, productIds, isFirstPage);
          this.getLiveData(productIds);
        }
      },
      async getNextPage() {
        this.isFetchMore = true;
        const searchParams = this.searchParams();
        const sliceStart = this.pageSize * this.currentPage;
        const sliceEnd = sliceStart + this.pageSize;
        const productIds = this.wishlistProductIds.slice(sliceStart, sliceEnd);
        const isFirstPage = false;
        await this.getUserWishlist(searchParams, productIds, isFirstPage);
        this.getLiveData(productIds);
        this.currentPage += 1;
        this.isFetchMore = false;
      },
      async getLiveData(productIds: string[]) {
        const productLivePrices = this.getLivePricesForProducts(productIds);
        const relatedProducts = this.getProductListRelatedProductsLivePrices(productIds);
        this.productLivePrices = { ...this.productLivePrices, ...(await productLivePrices) };
        this.relatedProducts = { ...this.relatedProducts, ...(await relatedProducts) };
      },
      searchParams(): SearchParams {
        return {
          repositoryName: this.websiteSettings.repositoryName,
          queryName: this.websiteSettings.queryName,
          pageNumber: '1',
          pageSize: '30',
        } as SearchParams;
      },
      onWishlistProductDelete({ Id }: WishlistProduct) {
        this.removeWishlistProduct(Id);
        this.onCloseMenu();
      },
      onWishlistProductAddToCart(product, variant) {
        const productObject = {
          ProductID: product.Id,
          VariantID: variant.OptionID,
          Manufacturer: product.Manufacturer,
          ProductName: product.Name,
          Price: product.Price?.PriceWithVat,
          OriginalPrice: product.PriceBeforeDiscount?.PriceWithVat,
          CurrencySymbol: 'KR',
          ImagePath: product.Image,
          Quantity: 1,
        } as Product;

        this.$store.dispatch('cart/addToCart', productObject);
        this.onCloseMenu();
      },
      onWishlistRemoveAll() {
        this.deleteWishlist(this.userWishlistId);
      },
      onShowMenu(product: WishlistProduct) {
        this.menuProduct = product;
      },
      onCloseMenu() {
        this.menuProduct = null;
      },
    },
  });
