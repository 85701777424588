
  import { defineComponent } from 'vue';
  import useAuthenticateUser from '@/composables/useAuthenticateUser';
  import { getUserArea } from '@/utils/routingHelper';

  export default defineComponent({
    emits: ['onLinkClick'],
    setup() {
      const { hasAuthenticatedUser, authenticatedUser, showLoginForm } = useAuthenticateUser();

      return {
        hasAuthenticatedUser,
        authenticatedUser,
        showLoginForm,
      };
    },
    methods: {
      onUsernameClick() {
        this.$emit('onLinkClick', getUserArea(this.$router));
      },
      onMyPagesClick() {
        const body = document.getElementsByTagName('body')[0];

        this.showLoginForm();
        body.classList.add('overflow--hidden');
      },
    },
  });
