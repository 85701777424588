
  import { defineComponent, PropType } from 'vue';
  import { Order } from '@/models/order/order';
  import Accordion from '@/components/shared/Accordion.vue';
  import { formatAsPriceString } from '@/utils/stringHelper';
  import Icon from '@/components/shared/Icon.vue';
  import { dateFormat } from '@/utils/dateHelper';
  import UserOrderTableRowLayout from './UserOrderTableRowLayout.vue';

  export default defineComponent({
    components: {
      Accordion,
      UserOrderTableRowLayout,
      Icon,
    },
    props: {
      order: {
        required: true,
        type: Object as PropType<Order>,
      },
    },
    computed: {
      orderTotalPrice(): string {
        return formatAsPriceString(this.order.Price.PriceWithVat, this.order.Price.CurrencySymbol);
      },
      orderDate(): string {
        return this.order?.DateCompleted ? dateFormat(this.order.DateCompleted) : '';
      },
    },
  });
