import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-60056472"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    class: "home-link",
    to: _ctx.route.path,
    "data-url": _ctx.route.path
  }, {
    default: _withCtx(() => [
      _createElementVNode("img", {
        class: "home-link__logo",
        src: _ctx.image,
        alt: `${_ctx.route.meta.name} logo`
      }, null, 8 /* PROPS */, _hoisted_1)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to", "data-url"]))
}