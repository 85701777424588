
  import { computed, defineComponent, PropType, ref } from 'vue';
  import useInputValidator from '@/composables/useInputValidator';

  /**
   * @deprecated Nota TextAreaInputNew í staðinn og nota useFormValidator composable í form parent
   */
  export default defineComponent({
    props: {
      value: {
        type: String,
        required: true,
      },
      label: {
        type: String,
        required: false,
        default: '',
      },
      id: {
        type: String,
        required: true,
      },
      width: {
        type: String,
        required: false,
        default: '400px',
      },
      height: {
        type: String,
        required: false,
        default: '96px',
      },
      maxLength: {
        type: Number,
        required: false,
        default: 400,
      },
      validators: {
        type: Object as PropType<Array<(input: string) => string | null>>,
        required: false,
        default: [] as Array<(input: string) => string | null>,
      },
    },
    emits: ['update:value'],
    setup(props, { emit }) {
      const totalChars = ref(0);
      const prevInputState = ref('');
      const { input, errors } = useInputValidator(props.value, props.validators, (value) => emit('update:value', value));

      const onInput = (event: Event) => {
        if (props.validators.length === 0) {
          const { value } = event.target as HTMLInputElement;
          totalChars.value = value.length;
          if (totalChars.value > props.maxLength) {
            input.value = prevInputState.value;
            totalChars.value = props.maxLength;
          } else {
            prevInputState.value = value;
            input.value = value;
          }
        }
      };

      const onChange = (event: Event) => {
        if (props.validators.length > 0) {
          const { value } = event.target as HTMLInputElement;
          totalChars.value = value.length;
          if (totalChars.value > props.maxLength) {
            input.value = prevInputState.value;
            totalChars.value = props.maxLength;
          } else {
            prevInputState.value = value;
            input.value = value;
          }
        }
      };

      const getStyles = computed(() => ({
        '--width': props.width,
        '--height': props.height,
      }));

      const getLetterCount = computed(() => `${totalChars.value}/${props.maxLength}`);

      return {
        input,
        errors,
        onInput,
        getStyles,
        getLetterCount,
        totalChars,
        onChange,
      };
    },
  });
