import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6a180d2c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "titleAndSubtitle" }
const _hoisted_2 = { class: "titleAndSubtitle_Title" }
const _hoisted_3 = { class: "titleAndSubtitle_Subtitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.getData.title), 1 /* TEXT */),
    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.getData.subtitle), 1 /* TEXT */)
  ]))
}