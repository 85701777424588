import { ComputedRef, computed } from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';
import { merge } from 'lodash';
import { WishlistProduct, WishlistResponse } from '@/models/wishlist/wishlist';
import {
  DELETE_PRODUCT_FROM_WISHLIST,
  DELETE_USER_WISHLIST,
  GET_USER_WISHLIST,
  GET_WISHLIST_PRODUCTS,
  TOGGLE_PRODUCT_IN_WISHLIST,
} from '@/constants/store/auth/authActionTypes';
import { USER_WISHLIST, USER_WISHLIST_ID } from '@/constants/store/auth/authGetterTypes';
import { ProductIsFavoritePayload, ProductListPayload } from '@/models/productList/productListPayloadModels';
import { SearchParams } from '@/models/search/searchModels';
import { SET_USER_WISHLIST } from '@/constants/store/auth/authMutationTypes';
import { isWishlistResponse } from '@/utils/model_checkers/responses';

interface UseManageUserWishlist {
  getWishlistProductIds: () => Promise<string[]>;
  getUserWishlist: (params: SearchParams, productIds: string[], firstPage: boolean) => Promise<WishlistProduct[]>;
  userWishlistId: ComputedRef<number>;
  userWishlist: ComputedRef<WishlistProduct[]>;
  removeWishlistProduct: (id: string) => Promise<WishlistProduct[]>;
  deleteWishlist: (id: number) => Promise<WishlistProduct[]>;
  toggleProductInWishlist: (payload: ProductIsFavoritePayload) => Promise<WishlistResponse | undefined>;
}

export default function useManageUserWishlist(): UseManageUserWishlist {
  const store = useStore();

  const getWishlistProductIds = async (): Promise<string[]> => {
    const wishlist = (await store.dispatch(`auth/${GET_USER_WISHLIST}`)) as WishlistProduct[];
    return wishlist.map(({ Id }) => Id || '');
  };

  const getUserWishlist = async (params: SearchParams, productIdsArray: string[], firstPage: boolean): Promise<any> => {
    const payload = {
      cancelToken: axios.CancelToken.source().token,
      params: merge(
        {
          groupID: '',
          brandName: '',
          showOnlyOffers: false,
          filterQuery: '',
          productIds: productIdsArray.join(','),
        },
        params,
      ),
    } as ProductListPayload;

    let wishlistProducts = await store.dispatch(`auth/${GET_WISHLIST_PRODUCTS}`, payload);

    if (!firstPage) {
      const currentProducts = store.getters[`auth/${USER_WISHLIST}`];
      wishlistProducts = [...currentProducts, ...wishlistProducts];
    }

    store.commit(`auth/${SET_USER_WISHLIST}`, wishlistProducts);
    return wishlistProducts || {};
  };

  const userWishlistId = computed(() => store.getters[`auth/${USER_WISHLIST_ID}`]);
  const userWishlist = computed(() => store.getters[`auth/${USER_WISHLIST}`]);
  const removeWishlistProduct = (id: string) => store.dispatch(`auth/${DELETE_PRODUCT_FROM_WISHLIST}`, id);
  const deleteWishlist = (id: number) => store.dispatch(`auth/${DELETE_USER_WISHLIST}`, id);
  const toggleProductInWishlist = async (payload: ProductIsFavoritePayload) => {
    const response = await store.dispatch(`auth/${TOGGLE_PRODUCT_IN_WISHLIST}`, payload);
    if (isWishlistResponse(response)) {
      store.dispatch('productList/toggleIsFavorite', payload);
      store.dispatch('product/toggleIsFavorite', payload);
    }
  };

  return {
    getWishlistProductIds,
    getUserWishlist,
    userWishlistId,
    userWishlist,
    removeWishlistProduct,
    deleteWishlist,
    toggleProductInWishlist,
  } as UseManageUserWishlist;
}
