import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bf88f2fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title-container flex flex--justify-center" }
const _hoisted_2 = { class: "carousel-title" }
const _hoisted_3 = {
  key: 0,
  class: "flex flex--center-center loader-container"
}
const _hoisted_4 = {
  key: 1,
  class: "carousel-body"
}
const _hoisted_5 = { class: "button-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loader = _resolveComponent("Loader")!
  const _component_ProductCard = _resolveComponent("ProductCard")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["container product-carousel paragraph-container", _ctx.getParagraphClasses]),
    style: _normalizeStyle(_ctx.getParagraphStyles)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.title), 1 /* TEXT */)
    ]),
    (_ctx.initialLoad)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_Loader, {
            size: 100,
            thickness: 2
          })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_swiper, {
            "slides-per-view": 4,
            "space-between": 80,
            breakpoints: _ctx.swiperOption.breakpoints
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productList.products, (product, index) => {
                return (_openBlock(), _createBlock(_component_swiper_slide, { key: index }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ProductCard, {
                      key: index,
                      "product-info": product,
                      size: 'medium'
                    }, null, 8 /* PROPS */, ["product-info"])
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["breakpoints"])
        ])),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("button", {
        class: "btn btn--dark btn--medium",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push(_ctx.buttonUrl)))
      }, _toDisplayString(_ctx.buttonText), 1 /* TEXT */)
    ])
  ], 6 /* CLASS, STYLE */))
}