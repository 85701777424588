
  import { computed, defineComponent, PropType, ref, StyleValue, TextareaHTMLAttributes } from 'vue';

  export default defineComponent({
    props: {
      value: {
        type: String,
        required: true,
      },
      label: {
        type: String,
        required: false,
        default: '',
      },
      textAreaHTMLAttributes: {
        type: Object as PropType<TextareaHTMLAttributes>,
        required: false,
        default: () => ({
          placeholder: '-',
        }),
      },
      width: {
        type: String,
        required: false,
        default: '100%',
      },
      minHeight: {
        type: String,
        required: false,
        default: '400px',
      },
      maxLength: {
        type: Number,
        required: false,
        default: 0,
      },
    },
    emits: ['update:value'],
    setup(props, { emit }) {
      const totalChars = ref(0);
      const prevInputState = ref('');

      const hasMaxLength = computed(() => props.maxLength > 0);

      const getLetterCount = computed(() => `${totalChars.value}/${props.maxLength}`);

      const update = (event: Event) => {
        const { value } = event.target as HTMLInputElement;
        totalChars.value = value.length;
        if (!hasMaxLength.value) {
          emit('update:value', value);
        } else if (totalChars.value > props.maxLength) {
          emit('update:value', prevInputState.value);
          totalChars.value = props.maxLength;
        } else {
          emit('update:value', value);
          prevInputState.value = value;
        }
      };

      const onInput = (event: Event) => update(event);

      const onChange = (event: Event) => update(event);

      const getStyles = computed(
        (): StyleValue =>
          ({
            '--width': props.width,
            '--minHeight': props.minHeight,
          }) as StyleValue,
      );

      return {
        onInput,
        getStyles,
        getLetterCount,
        totalChars,
        onChange,
        hasMaxLength,
      };
    },
  });
