import axios from 'axios';
import { keyBy } from 'lodash';
import ProductAPI from '@/API/productApi';
import { ProductListLiveDataPayload } from '@/models/productList/productListPayloadModels';

/* eslint-disable @typescript-eslint/no-explicit-any */

export interface LiveProductPrices {
  getLivePricesForProducts: (productIds: Array<string>) => Promise<any>;
  getProductListRelatedProductsLivePrices: (productIds: Array<string>) => Promise<any>;
}

export default function useLiveProductPrices(): LiveProductPrices {
  const getLivePricesForProducts = async (productIds: Array<string>) => {
    const payload = {
      cancelToken: axios.CancelToken.source().token,
      productIds: productIds.join(','),
    } as ProductListLiveDataPayload;

    const response = await ProductAPI.getProductListLiveData(payload);

    if (response.status === 204) {
      return {};
    }

    const livePrices = keyBy(response?.data?.Products || {}, 'Id');

    return livePrices;
  };

  const getProductListRelatedProductsLivePrices = async (productIds: Array<string>) => {
    const payload = {
      cancelToken: axios.CancelToken.source().token,
      productIds: productIds.join(','),
    } as ProductListLiveDataPayload;

    const response = await ProductAPI.getProductListRelatedProductsLiveData(payload);

    if (response.status === 204) {
      return {};
    }

    const livePrices = keyBy(response?.data || {}, 'Id');

    return livePrices;
  };

  return {
    getLivePricesForProducts,
    getProductListRelatedProductsLivePrices,
  };
}
