
  import { defineComponent, PropType } from 'vue';
  import { Order } from '@/models/order/order';
  import UserOrderTableHeader from './UserOrderTableHeader.vue';
  import UserOrderTableRow from './UserOrderTableRow.vue';

  export default defineComponent({
    components: {
      UserOrderTableHeader,
      UserOrderTableRow,
    },
    props: {
      orders: {
        type: Array as PropType<Array<Order>>,
        required: false,
        default: () => [] as Order[],
      },
    },
  });
