import { computed, ComputedRef } from 'vue';
import { Field, ImageDimensions } from '@/models/field';
import { Box } from '@/models/item-types/base/boxes';
import { mapBaseComponentData } from '@/utils/dataMapper';
import { isBoxWithBackgroundColor, isBoxWithTitle, isBoxWithBackgroundImage, isBoxWithText, isBoxWithMobileTitle } from '@/utils/model_checkers/boxes';
import { getBackgroundImageStyles, ImageHandler } from '@/utils/imageHelper';
import { hyphenate } from '@/utils/stringHelper';
import conf from '@/config';

interface ReturnValue {
  data: ComputedRef<Box>;
  getTitle: ComputedRef<string>;
  getMobileTitle: ComputedRef<string>;
  getStyles: ComputedRef<{
    '--background-color': string;
    '--title-color': string;
    '--background-image': string;
    '--background-position': string;
    '--text-color': string;
  }>;
  boxImagePath: ComputedRef<string>;
  boxImageUrl: ComputedRef<string>;
}

export default function useBaseComponent(box: Field, imageDimensions?: ImageDimensions): ReturnValue {
  const data = computed(() => mapBaseComponentData(box) as Box);
  const getTitle = computed(() => (isBoxWithTitle(data.value) ? hyphenate(data.value.title) : ''));
  // eslint-disable-next-line no-nested-ternary
  const getMobileTitle = isBoxWithMobileTitle(data.value)
    ? computed(() =>
        isBoxWithMobileTitle(data.value) ? (data.value.titleInMobile.length > 0 ? hyphenate(data.value.titleInMobile) : hyphenate(data.value.title)) : '',
      )
    : getTitle;
  const backgroundImageStyles = isBoxWithBackgroundImage(data.value)
    ? getBackgroundImageStyles(data.value.image, imageDimensions)
    : ({} as { backgroundImage: string; backgroundPosition: string });
  const getStyles = computed(() => ({
    '--background-color': isBoxWithBackgroundColor(data.value) ? data.value.backgroundColor.Hex : '#FFFFFF',
    '--background-image': backgroundImageStyles.backgroundImage,
    '--background-position': backgroundImageStyles.backgroundPosition,
    '--title-color': isBoxWithTitle(data.value) ? data.value.titleColor.Hex : '#000000',
    '--text-color': isBoxWithText(data.value) ? data.value.textColor.Hex : '#000000',
  }));
  const boxImagePath = computed(() =>
    isBoxWithBackgroundImage(data.value) && imageDimensions ? ImageHandler(data.value.image?.Path ?? '', imageDimensions.width, imageDimensions.width) : '',
  );
  const boxImageUrl = computed(() => (boxImagePath.value ? `${conf.dwUrl}${boxImagePath.value}` : ''));

  return {
    data,
    getTitle,
    getMobileTitle,
    getStyles,
    boxImagePath,
    boxImageUrl,
  };
}
