
  import { defineComponent, PropType } from 'vue';
  import { RouteRecord } from 'vue-router';

  export default defineComponent({
    props: {
      routes: {
        type: Object as PropType<RouteRecord[]>,
        required: true,
      },
    },
    emits: ['onLinkClick'],
    computed: {
      firstRoute(): any {
        return this.routes[0];
      },
    },
    methods: {
      onLinkClick(route: RouteRecord) {
        this.$emit('onLinkClick', route);
      },
    },
  });
