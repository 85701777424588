
  import { computed, defineComponent, PropType } from 'vue';
  import { Field, ImageDimensions } from '@/models/field';
  import { ImageTitleAndButton } from '@/models/item-types/base/boxes';
  import CustomButton from '@/components/itemTypes/base/Button.vue';
  import useBaseBoxComponent from '@/composables/useBaseBoxComponent';
  import { mapBaseComponentData } from '@/utils/dataMapper';

  interface Button {
    text: string;
    link: {
      IsExternal: boolean;
      Url: string;
    };
    type: string;
    buttonSize: string;
  }

  export default defineComponent({
    components: {
      CustomButton,
    },
    props: {
      box: {
        type: Object as PropType<Field>,
        required: true,
      },
      boxCount: {
        type: Number,
        required: true,
      },
      options: {
        type: Object,
        required: true,
      },
    },
    setup(props) {
      const imageDimensions = { width: 800, height: 800 } as ImageDimensions;
      const { data, getTitle, getStyles, boxImageUrl: imageSrc } = useBaseBoxComponent(props.box, imageDimensions);
      const boxData = data.value as ImageTitleAndButton;
      const getComponentSpecificStyles = computed(() => ({
        '--title-align-self': boxData.titlePosition,
        '--title-width': boxData.titlePosition === 'flex-end' ? '60%' : '100%',
      }));
      const linkUrl = (mapBaseComponentData(boxData.button) as Button).link?.Url;

      return {
        boxData,
        getTitle,
        getStyles,
        getComponentSpecificStyles,
        linkUrl,
        imageSrc,
      };
    },
    computed: {
      wrapperAttribute() {
        return this.options.entireFrameClickable ? 'a' : 'div';
      },
    },
  });
