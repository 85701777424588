<template>
  <transition name="back-to-top-fade">
    <div
      v-show="visible"
      class="back-to-top"
      :style="getStyles"
      @click="backToTop"
    >
      <slot>
        <div class="default">
          <svg class="icon-16 icon-chevron">
            <use xlink:href="#icon-chevron" />
          </svg>
          <span>
            {{ text }}
          </span>
        </div>
      </slot>
    </div>
  </transition>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { colorIsDark } from '@/utils/colorHelper';

  export default {
    name: 'BackToTop',
    props: {
      text: {
        type: String,
        default: 'EFST',
      },
      visibleoffset: {
        type: [String, Number],
        default: 600,
      },
      visibleoffsetbottom: {
        type: [String, Number],
        default: 0,
      },
      right: {
        type: String,
        default: '84px',
      },
      bottom: {
        type: String,
        default: '24px',
      },
    },
    data() {
      return {
        visible: false,
      };
    },
    computed: {
      ...mapGetters('website', {
        websiteSettings: 'getWebsiteSettings',
      }),
      getStyles() {
        return {
          bottom: this.bottom,
          right: this.right,
        };
      },
    },
    mounted() {
      window.smoothscroll = () => {
        const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
        if (currentScroll > 0) {
          window.scrollTo(0, 0);
        }
      };
      window.addEventListener('scroll', this.catchScroll);
    },
    unmounted() {
      window.removeEventListener('scroll', this.catchScroll);
    },
    methods: {
      catchScroll() {
        const pastTopOffset = window.pageYOffset > parseInt(this.visibleoffset, 10);
        const pastBottomOffset = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - parseInt(this.visibleoffsetbottom, 10);
        this.visible = parseInt(this.visibleoffsetbottom, 10) > 0 ? pastTopOffset && !pastBottomOffset : pastTopOffset;
      },
      backToTop() {
        window.smoothscroll();
        this.$emit('scrolled');
      },
    },
  };
</script>

<style scoped lang="scss">
  .back-to-top-fade-enter-active,
  .back-to-top-fade-leave-active {
    transition: opacity 0.7s;
  }
  .back-to-top-fade-enter,
  .back-to-top-fade-leave-to {
    opacity: 0;
  }

  .back-to-top {
    cursor: pointer;
    position: fixed;
    z-index: 1000;
  }

  .back-to-top .default {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px;

    background-color: $black;
    border-radius: 40px;
    border: 1px solid var(--text-color);
    color: $white;
    height: 57px;
    width: 57px;
    line-height: 30px;
    text-align: center;
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.24);
  }

  .back-to-top .default span {
    color: $white;
    font-size: 12px;
    line-height: 16px;
  }

  .back-to-top--is-footer {
    bottom: 50% !important;
    position: absolute;
    transform: translateY(50%);
  }

  .icon-chevron {
    stroke-width: 2px;
  }

  @media only screen and (max-width: $old-mobile) {
    .back-to-top {
      display: none;
    }
  }
</style>
