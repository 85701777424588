import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-82c92166"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "section__header" }
const _hoisted_2 = { class: "section__shop" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_ProductCardList = _resolveComponent("ProductCardList")!

  return (_openBlock(), _createElementBlock("div", {
    class: "section",
    style: _normalizeStyle(_ctx.getSectionStyles)
  }, [
    _createElementVNode("h4", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.shopName), 1 /* TEXT */),
      _createVNode(_component_RouterLink, {
        to: { path: _ctx.showMoreHref, query: _ctx.showMoreQuery },
        class: "section__expand"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.showMoreText), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["to"])
    ]),
    _createVNode(_component_ProductCardList, {
      max: _ctx.maxDisplaySize,
      "product-list": _ctx.productList,
      "show-more": false,
      size: 'small',
      "path-prefix": _ctx.pathPrefix
    }, null, 8 /* PROPS */, ["max", "product-list", "path-prefix"])
  ], 4 /* STYLE */))
}