import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6b79a574"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search" }
const _hoisted_2 = { class: "search__header container" }
const _hoisted_3 = { class: "search__body" }
const _hoisted_4 = {
  key: 0,
  class: "flex flex--center-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchForm = _resolveComponent("SearchForm")!
  const _component_Loader = _resolveComponent("Loader")!
  const _component_SearchPopularTerms = _resolveComponent("SearchPopularTerms")!
  const _component_SearchResults = _resolveComponent("SearchResults")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createVNode(_component_SearchForm, {
        "initial-search-value": _ctx.searchValue,
        onSubmit: _ctx.onSearchSubmit,
        onCancel: _ctx.onSearchCancel
      }, null, 8 /* PROPS */, ["initial-search-value", "onSubmit", "onCancel"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_Loader)
          ]))
        : (_ctx.hasNotSearched && _ctx.popularSearchTerms.length > 0)
          ? (_openBlock(), _createBlock(_component_SearchPopularTerms, {
              key: 1,
              terms: _ctx.popularSearchTerms
            }, null, 8 /* PROPS */, ["terms"]))
          : (_openBlock(), _createBlock(_component_SearchResults, {
              key: 2,
              "content-results": _ctx.contentSearchResults,
              "product-results": _ctx.productResultsWithLivePrices
            }, null, 8 /* PROPS */, ["content-results", "product-results"]))
    ])
  ]))
}