
  /* eslint-disable @typescript-eslint/no-explicit-any */
  import { defineComponent, PropType } from 'vue';
  import useParagraph from '@/composables/useParagraph';
  import { ItemType } from '@/models/field';

  import { mapBaseComponentData } from '@/utils/dataMapper';
  import AccordionItem from '@/components/itemTypes/AccordionList/AccordionItem.vue';
  import Icon from '@/components/shared/Icon.vue';

  interface IAccordionList {
    title: string;
    accordionItemsList: ItemType[];
    gridLayout: {
      SelectedValue: string;
    };
  }

  interface IAccordionItem {
    description: string;
    title: string;
  }

  export default defineComponent({
    components: {
      AccordionItem,
      Icon,
    },
    props: {
      componentProps: {
        type: Object as PropType<IAccordionList>,
        required: true,
      },
    },
    setup(props) {
      const { getParagraphStyles, getParagraphClasses } = useParagraph(props.componentProps);
      return {
        getParagraphStyles,
        getParagraphClasses,
      };
    },
    data() {
      return {
        showBodyAll: false,
        accordionItems: [] as IAccordionItem[],
      };
    },
    computed: {
      getData(): IAccordionItem[] {
        const { accordionItemsList } = this.componentProps;
        return accordionItemsList.map((item) => mapBaseComponentData(item));
      },
      getGrid(): string {
        const { gridLayout } = this.componentProps;
        return gridLayout.SelectedValue;
      },
      getItemsEven(): IAccordionItem[] {
        return this.getData.filter((item, index) => index % 2);
      },
      getItemsOdd(): IAccordionItem[] {
        return this.getData.filter((item, index) => !(index % 2));
      },
    },
    methods: {
      toggleAll(): void {
        this.showBodyAll = !this.showBodyAll;
      },
    },
  });
