import { useStore } from 'vuex';
import { UPDATE_USER_PROFILE, UPDATE_USER_PASSWORD } from '@/constants/store/auth/authActionTypes';
import { PasswordPayload, UserPayload, User } from '@/models/user/user';

interface UseManageUserProfile {
  updateUserProfile: (user: UserPayload) => Promise<User>;
  updateUserPassword: (passwordPayload: PasswordPayload) => Promise<User>;
}

export default function useManageUserProfile(): UseManageUserProfile {
  const store = useStore();

  const updateUserProfile = (user: UserPayload) => store.dispatch(`auth/${UPDATE_USER_PROFILE}`, user);
  const updateUserPassword = (passwordPayload: PasswordPayload) => store.dispatch(`auth/${UPDATE_USER_PASSWORD}`, passwordPayload);

  return {
    updateUserProfile,
    updateUserPassword,
  } as UseManageUserProfile;
}
