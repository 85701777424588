
  /* eslint-disable @typescript-eslint/no-explicit-any */
  import { defineComponent, PropType } from 'vue';
  import format from 'date-fns/format';
  import isISLocale from 'date-fns/locale/is';
  import conf from '@/config';

  interface NewsArticle {
    image: string;
    title: string;
    published: string;
    snippet: string;
    link: string;
    publishDate: string;
  }

  export default defineComponent({
    props: {
      article: {
        type: Object as PropType<NewsArticle>,
        default: null,
      },
    },
    data() {
      return {
        imagePrefix: conf.dwUrl,
      };
    },
    methods: {
      formatDate(date): string {
        const dateArr = date.split(' ')[0].split('.');
        const rearrangeDate = `${dateArr[1]}/${dateArr[0]}/${dateArr[2]}`;
        const formatedDate = format(new Date(rearrangeDate), 'eeee, d. MMM yyyy', {
          locale: isISLocale,
        });
        return formatedDate;
      },
      imagePath(image: string) {
        return this.imagePrefix + image;
      },
    },
  });
