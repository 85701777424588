import { get, camelCase, has, isEmpty } from 'lodash';

const getGenderCategories = (product) => {
  const categories = get(product, 'ProductFields.SEX.Value', []);
  return categories.reduce((acc, curr) => {
    const key = curr.Value.toLowerCase();
    if (key === 'kk') {
      acc[key] = 'Herrar';
    } else if (key === 'kvk') {
      acc[key] = 'Dömur';
    } else if (key === 'born') {
      acc[key] = 'Börn';
    }
    return acc;
  }, {});
};

export default {
  settings: {
    productHasValidConfig: (config, product) => {
      const productFamily = camelCase(product?.ProductFields?.ProductFamily?.Value[0]?.Value) || '';
      const hasData = has(config, `${productFamily}.data`);
      const hasSettings = has(config, `${productFamily}.settings`);
      if (hasData && hasSettings) {
        const settings = get(config, `${productFamily}.settings`, {});
        if (!isEmpty(settings.getCategories(product))) {
          return true;
        }
      }
      return false;
    },
  },
  skor: {
    settings: {
      title: 'Skóstærðin þín',
      text:
        "<ul style='list-style-position:inside'>Til að finna út þína skóstærð skaltu fylgja eftirfarandi skrefum:<br><br>" +
        '<li>Vertu með blað, penna og reglustiku. Gerðu línu fyrir framan lengstu tána á fætinum og síðan aðra línu aftan við hælinn og mældu lengdina milli línanna.</li>' +
        '<li>Mundu að mæla báða fætur því þeir geta verið mismunandi að stærð</li>' +
        '<li>Hægt er að setja auka innlegg í flesta skó til að þeir passi þér betur. Innlegg geta minnkað skó um c.a. 1/5 skónúmer.</li>' +
        '<li>Einnig er hægt að taka innleggið úr sumum skóm og setja sitt eigið innlegg í skóinn.</li></ul>',
      image: '/Files/Images/SizeTables/fotur.png',
      getCategories(product) {
        return getGenderCategories(product);
      },
    },
    data: {
      default: {
        kk: [
          ['', '', '', '', '', '', '', '', '', '', '', '', ''],
          ['EUR', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50'],
          ['USA', '5/5,5', '6/6,5', '7/7,5', '8/8,5', '9/9,5', '10/10,5', '11/11,5', '12/12,5', '13/13,5', '14/14,5', '15/15,5', '16/16,5'],
          ['UK', '5,5/6', '6,5/7', '7,5', '8/8,5', '9', '9,5/10', '10,5', '11/11,5', '12/12,5', '13', '13,5/14', '13,5/14'],
          ['CM', '24/24,5', '25', '26,3', '27', '27,7', '28,3', '29', '29,7', '30,3', '31', '31,6', '32,3'],
        ],
        kvk: [
          ['', '', '', '', '', '', '', '', '', ''],
          ['EUR', '35', '36', '37', '38', '39', '40', '41', '42', '43'],
          ['USA', '3/3,5', '4', '4,5/5', '5,5', '5/5,5', '6/6,5', '7/7,5', '8/8,5', '9/9,5'],
          ['UK', '2,5/3', '3,5', '4/4,5', '5', '5,5/6', '6,5/7', '7,5', '8/8,5', '9'],
          ['CM', '21,5', '22/22,5', '23', '23,5', '24/24,5', '25', '26,3', '27', '27,7'],
        ],
        born: [
          ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
          ['EUR', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38'],
          [
            'USA',
            '3/3,5',
            '4',
            '4,5',
            '5/5,5',
            '6',
            '6,5/7',
            '7,5',
            '8/8,5',
            '9/9,5',
            '10',
            '10,5',
            '12',
            '12,5',
            '13/13,5',
            '1',
            '1,5/2',
            '2,5',
            '3/3,5',
            '4',
            '4,5/5',
            '5,5',
          ],
          [
            'UK',
            '3',
            '3,5',
            '4',
            '4,5/5',
            '5,5',
            '6/6,5',
            '7',
            '7,5/8',
            '8,5/9',
            '9,5',
            '10/10,5',
            '11/11,5',
            '12',
            '12,5/13',
            '13,5',
            '1,5',
            '2',
            '2,5/3',
            '3,5',
            '4/4,5',
            '5',
          ],
          [
            'CM',
            '11,5',
            '12',
            '12,5',
            '13',
            '13,5',
            '14',
            '14,5/15',
            '15,5',
            '16',
            '16,5',
            '17/17,5',
            '18',
            '18,5',
            '19',
            '19,5/20',
            '20,5',
            '21',
            '21,5',
            '22/22,5',
            '23',
            '23,5',
          ],
        ],
      },
      nike: {
        kk: [
          ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
          ['EUR', '40', '40,5', '41', '42', '42,5', '43', '44', '44,5', '45', '45,5', '46', '47', '47,5', '48', '48,5'],
          ['USA', '7', '7,5', '8', '8,5', '9', '9,5', '10', '10,5', '11', '11,5', '12', '12,5', '13', '13,5', '14'],
          ['CM', '25', '25,5', '26', '26,5', '27', '27,5', '28', '28,5', '29', '29,5', '30', '30,5', '31', '31,5', '32'],
        ],
        kvk: [
          ['', '', '', '', '', '', '', '', '', '', '', ''],
          ['EUR', '35,5', '36', '36,5', '37,5', '38', '38,5', '39', '40', '40,5', '41', '42'],
          ['USA', '5', '5,5', '6', '6,5', '7', '7,5', '8', '8,5', '9', '9,5', '10'],
          ['CM', '22', '22,5', '23', '23,5', '24', '24,5', '25', '25,5', '26', '26,5', '27'],
        ],
        born: [
          ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
          [
            'EUR',
            '16',
            '17',
            '18,5',
            '19,5',
            '21',
            '22',
            '23,5',
            '25',
            '26',
            '27',
            '27,5',
            '28',
            '28,5',
            '29,5',
            '30',
            '31',
            '31,5',
            '32',
            '33',
            '33,5',
            '34',
            '35',
            '35,5',
            '36',
            '36,5',
            '37,5',
            '38',
            '38,5',
            '39',
            '40',
          ],
          [
            'USA',
            '1C',
            '2C',
            '3C',
            '4C',
            '5C',
            '6C',
            '7C',
            '8C',
            '9C',
            '10C',
            '10,5C',
            '11C',
            '11,5C',
            '12C',
            '12,5C',
            '13C',
            '13,5C',
            '1Y',
            '1,5Y',
            '2Y',
            '2,5Y',
            '3Y',
            '3,5Y',
            '4Y',
            '4,5Y',
            '5Y',
            '5,5Y',
            '6Y',
            '6,5Y',
            '7Y',
          ],
          [
            'CM',
            '7',
            '8',
            '9',
            '10',
            '11',
            '12',
            '13',
            '14',
            '15',
            '16',
            '16,5',
            '17',
            '17,5',
            '18',
            '18,5',
            '19',
            '19,5',
            '20',
            '20,5',
            '21',
            '21,5',
            '22',
            '22,5',
            '23',
            '23,5',
            '23,5',
            '24',
            '24',
            '24,5',
            '25',
          ],
        ],
      },
    },
  },
  fatnadur: {
    settings: {
      title: 'Fata stærðin þín',
      text: `
        Mældu þig með málbandi, þétt upp að líkamanum, til að finna þína stærð. Til að finna rétta stærð á yfirhöfnum og fatnaði fyrir efri part er mikilvægt að mæla brjóstmál. Fyrir buxur skaltu mæla mitti, mjaðmir og lengd á innanverðum fótlegg.<br><br>
        <strong>A: Brjóstmál</strong><br>
        Mældu yfir brjóstkassann og herðablöðin þar sem ummálið er stærst.<br><br>
        <strong>B: Mitti</strong><br>
        Mældu mittið þar sem ummálið er sem minnst. Þegar þú velur stærð á buxum eru mjaðmirnar mikilvægasta mælingin en þar á eftir mittið<br><br>
        <strong>C: Mjaðmir</strong><br>
        Mældu mjaðmirnar þar sem þær eru breiðastar, sem er u.þ.b 20 cm fyrir neðan mitti.<br><br>
        <strong>D: Innri fótsaumur</strong>
        Til að fá rétta mælingu fyrir skálmastærð skaltu mæla þig frá klofi og alveg niður á gólf.<br><br>
        `,

      image: {
        kk: '/Files/Images/SizeTables/Herra.png',
        kvk: '/Files/Images/SizeTables/domu.png',
        born: '/Files/Images/SizeTables/born.png',
      },
      getCategories(product) {
        return getGenderCategories(product);
      },
    },
    data: {
      default: {
        kk: [
          ['', '', 'S', 'M', 'L', 'XL', '2XL'],
          ['A', 'Brjóstmál', '91-97', '97-103', '103-110', '110-118', '118-128'],
          ['B', 'Mitti', '80-86', '86-92', '92-99', '99-107', '107-115'],
          ['C', 'Mjaðmir', '92-98', '98-104', '104-111', '111-119', '119-127'],
          ['D', 'Innri fótasaumur', '82-84', '84-86', '86-88', '88-90', '90-92'],
        ],
        kvk: [
          ['', '', 'XS', 'S', 'M', 'L', 'XL', '2XL'],
          ['A', 'Brjóstmál', '79-85', '85-91', '91-97', '97-104', '104-112', '112-122'],
          ['B', 'Mitti', '63-69', '69-75', '75-81', '81-88', '88-96', '96-104'],
          ['C', 'Mjaðmir', '87-93', '93-99', '99-105', '105-112', '112-119', '112-125'],
          ['D', 'Innri fótasaumur', '75-77', '77-79', '79-81', '81-83', '83-85', '85-87'],
        ],
        born: [
          ['Aldur', '2mán', '4mán', '6mán', '9mán', '1árs', '1,5árs', '2ára', '3ára', '4ára', '6ára', '8ára', '10ára', '12ára', '14ára', '16ára'],
          ['CM', '56', '62', '68', '74', '80', '86', '92', '98', '104', '116', '128', '140', '152', '164', '176'],
        ],
      },
      nike: {
        kk: [
          ['', '', 'XS', 'S', 'S Tall', 'M', 'M Tall', 'L', 'L Tall', 'XL', 'XL Tall', '2XL', '2XL Tall'],
          ['A', 'Brjóstmál', '80-88', '88-96', '88-96', '96-104', '96-104', '104-112', '104-112', '112-124', '112-124', '124-136', '124-136'],
          ['B', 'Mitti', '65-73', '73-81', '73-81', '81-89', '81-89', '89-97', '89-97', '97-109', '97-109', '109-121', '109-121'],
          ['C', 'Mjaðmir', '80-88', '88-96', '88-96', '96-104', '96-104', '104-112', '104-112', '112-120', '112-120', '120-128', '120-128'],
          ['D', 'Hæð', '170-183', '170-183', '183-196', '170-183', '183-196', '170-183', '183-196', '170-183', '183-196', '170-183', '183-196'],
          ['E', 'Innri fótasaumur', '82', '82,5', '87,5', '83', '88', '83,5', '88,5', '84', '89', '84,5', '89,5'],
        ],
        kvk: [
          ['', '', 'XS', 'S', 'M', 'L', 'XL', '2XL', '1X', '2X', '3X'],
          ['A', 'Brjóstmál', '76-83', '83-90', '90-97', '97-104', '104-114', '114-124', '114-124', '124-134', '134-144'],
          ['B', 'Mitti', '60-67', '67-74', '74-81', '81-88', '88-98', '98-108', '104-114', '114-124', '124-134'],
          ['C', 'Mjaðmir', '84-91', '91-98', '98-105', '105-112', '112-120', '120-128', '118-128', '128-138', '138-148'],
          ['D', 'Hæð', '163-173', '163-173', '163-173', '163-173', '163-173', '163-173', '163-173', '163-173', '163-173'],
        ],
        born: [
          ['', '', '4', '5', '6', '6X/7', 'XS', 'S', 'S+', 'M', 'M+', 'L', 'L+', 'XL', 'XL+'],
          ['A', 'Aldur', '3-4', '4-5', '5-6', '6-7', '7-8', '8-10', '8-10', '10-12', '10-12', '12-13', '12-13', '13-15', '13-15'],
          [
            'B',
            'Hæð',
            '98-104',
            '104-110',
            '110-116',
            '116-122',
            '122-128',
            '128-137',
            '128-137',
            '137-147',
            '137-147',
            '147-158',
            '147-158',
            '158-170',
            '158-170',
          ],
          [
            'C',
            'Brjóstmál',
            '56-58',
            '58,5-61',
            '61-63',
            '63-66',
            '64,5-66',
            '66-69',
            '70,5-76,5',
            '69-75',
            '76,5-83,5',
            '75-81,5',
            '83,5-91',
            '81,5-88,5',
            '91-98',
          ],
          [
            'D',
            'Mitti',
            '54,5-56',
            '56-57',
            '57-58,5',
            '58,5-61',
            '59,5-61,5',
            '61,5-65',
            '65,5-70,5',
            '65-69',
            '70,5-76',
            '69-72,5',
            '76-82',
            '72,5-75,5',
            '82-88',
          ],
          [
            'E',
            'Mjaðmir',
            '57-60',
            '60-62',
            '62-65',
            '65-67',
            '68,5-71',
            '71-74,5',
            '75,5-81',
            '74,5-79,5',
            '81-87',
            '79,5-84,5',
            '87-93,5',
            '84,5-89,5',
            '93,5-100',
          ],
        ],
      },
    },
  },
  reidhjol: {
    settings: {
      title: 'Hjól sem passar',
      text: 'Follow these three easy steps:\nTo measure your feet from heel to toe all you need is a piece of paper, a pen and a ruler.\nDraw a line in front of your longest toe and measure the distance between the edge of the paper and the line.\nUse this measurement in the slider below to find your shoe size.\nRemember to measure both feet as they might be slightly different. Let the longest foot decide your shoe size.\n\n\nFit tip: Many styles have removable inlay soles for adjustments. Try one of our other inlay soles, which vary in thickness, to perfect your fit based on your needs.',
      image: '/Files/Images/SizeTables/hjol.png',
      getCategories(product) {
        const categories = get(product, 'ProductCategories.bicycle.Fields.icon_hjl.Value', []);
        return categories.reduce((acc, curr) => {
          acc[curr.Name.toLowerCase()] = curr.Name.toUpperCase();
          return acc;
        }, {});
      },
    },
    data: {
      default: {
        fjallahjól: [
          ['', '', '2XS', 'XS', 'S', 'M', 'L', 'XL', '2XL', '3XL'],
          ['A', 'Brjóstmál', '38,5', '39', '40', '40,5', '41', '42', '42,5', '43'],
          ['B', 'Mitti', '6', '6,5', '7', '7,5', '8', '8,5', '9', '10'],
          ['C', 'Mjaðmir', '5,5', '6', '6', '6,5', '7', '7,5', '8', '8,5'],
          ['D', 'Innri Fótasaumur', '24', '24,5', '25', '25,5', '26', '26,5', '27', '28'],
          ['E', 'Hæð', '24', '24,5', '25', '25,5', '26', '26,5', '27', '28'],
        ],
        borgarhjól: [
          ['', '', '2XS', 'XS', 'S', 'M', 'L', 'XL', '2XL', '3XL'],
          ['A', 'Brjóstmál', '38,5', '39', '40', '40,5', '41', '42', '42,5', '43'],
          ['B', 'Mitti', '6', '6,5', '7', '7,5', '8', '8,5', '9', '10'],
          ['C', 'Mjaðmir', '5,5', '6', '6', '6,5', '7', '7,5', '8', '8,5'],
          ['D', 'Innri Fótasaumur', '24', '24,5', '25', '25,5', '26', '26,5', '27', '28'],
          ['E', 'Hæð', '24', '24,5', '25', '25,5', '26', '26,5', '27', '28'],
        ],
        cargohjól: [
          ['', '', '2XS', 'XS', 'S', 'M', 'L', 'XL', '2XL', '3XL'],
          ['A', 'Brjóstmál', '38,5', '39', '40', '40,5', '41', '42', '42,5', '43'],
          ['B', 'Mitti', '6', '6,5', '7', '7,5', '8', '8,5', '9', '10'],
          ['C', 'Mjaðmir', '5,5', '6', '6', '6,5', '7', '7,5', '8', '8,5'],
          ['D', 'Innri Fótasaumur', '24', '24,5', '25', '25,5', '26', '26,5', '27', '28'],
          ['E', 'Hæð', '24', '24,5', '25', '25,5', '26', '26,5', '27', '28'],
        ],
      },
    },
  },
};
