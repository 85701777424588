
  /* eslint-disable @typescript-eslint/no-explicit-any */
  import { defineComponent, PropType } from 'vue';
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import 'swiper/swiper.scss';
  import 'swiper/components/navigation/navigation.scss';
  import 'swiper/components/pagination/pagination.scss';
  import SwiperCore, { Pagination } from 'swiper';

  SwiperCore.use([Pagination]);

  interface IOpeningHoursBannerImage {
    Extension: string;
    FocalPositionFromLeft: number;
    FocalPositionFromTop: number;
    Name: string;
    Path: string;
    PathUrlEncoded: string;
  }

  type IStoreList = {
    title: string;
    place: string;
    days: Array<string>;
  };

  interface IBannerBasic {
    title: string;
    text: string;
    textColor: string;
    image: IOpeningHoursBannerImage[] | undefined;
    colorOverlay: string;
    opacity: string;
  }

  interface IBanner extends IBannerBasic {
    shopList: IStoreList[];
  }

  export default defineComponent({
    components: {
      Swiper,
      SwiperSlide,
    },
    props: {
      bannerData: {
        type: Object as PropType<IBanner>,
        required: true,
      },
    },
    computed: {
      title(): string {
        return this.bannerData.title;
      },
      text(): string {
        return this.bannerData.text;
      },
      textColor(): string {
        return this.bannerData.textColor;
      },
      image(): string | null {
        if (this.bannerData.image) {
          return this.bannerData.image[0].Path;
        }

        return null;
      },
      overlay(): string {
        return this.bannerData.colorOverlay;
      },
      opacity(): string {
        return this.bannerData.opacity;
      },
      shopList(): IStoreList[] {
        return this.bannerData.shopList;
      },
    },
  });
